<template>
  <div class="information">
    <div class="wrap">
      <field-set
        v-for="field in requiredBiometrics"
        id="exam-info"
        :key="field.id"
        :label="fieldLabel(field.name, field.unitOfMeasure, field.isRequired)"
        class="field"
      >
        <input-text
          v-if="field.type === 'number'"
          :id="field.id"
          :model-value="field.value"
          :name="field.id"
          required
          placeholder="Insert value"
          type="number"
          :min="field.minValue"
          :max="field.maxValue"
          :step="fieldNumericMask(field.editMask)"
          :disabled="!canEdit"
          @update:model-value="updateValue"
        />
      </field-set>
      <field-set
        label="Nicotine*"
        class="field"
      >
        <select-dropdown
          :id="nicotineBiometric.id"
          :model-value="nicotineBiometric.value"
          :options="nicotineBiometric.options"
          placeholder="Select an option"
          required
          :disabled="!canEdit"
          @update:model-value="updateNicotineValue"
        />
      </field-set>
    </div>
    <div v-if="firstBloodPressureBiometrics && secondBloodPressureBiometrics" class="wrap">
      <h3>Blood Pressure</h3>
      <div class="wrap">
        <field-set
          v-for="field in firstBloodPressureBiometrics.components"
          id="aditional-info"
          :key="field.id"
          :label="`${field.name}*`"
          class="field"
        >
          <input-text
            v-if="field.type === 'number'"
            :id="field.id"
            :model-value="field.value"
            :name="field.id"
            required
            placeholder="Insert value"
            type="number"
            :min="field.minValue"
            :max="field.maxValue"
            :disabled="!canEdit"
            @update:model-value="updateFirstBloodPressure"
          />
        </field-set>
      </div>
      <div class="wrap">
        <field-set
          v-for="field in secondBloodPressureBiometrics.components"
          id="aditional-info"
          :key="field.id"
          :label="`${field.name} (2nd reading)`"
          class="field"
        >
          <input-text
            v-if="field.type === 'number'"
            :id="field.id"
            :model-value="field.value"
            :name="field.id"
            placeholder="Insert value"
            type="number"
            :min="field.minValue"
            :max="field.maxValue"
            :disabled="!canEdit"
            @update:model-value="updateSecondBloodPressure"
          />
        </field-set>
      </div>
    </div>
    <div v-if="optionalBiometrics?.length" class="optional">
      <h3>Additional <span>(optional)</span></h3>
    </div>
    <div v-if="optionalBiometrics?.length" class="wrap">
      <field-set
        v-for="field in optionalBiometrics"
        id="aditional-info"
        :key="field.id"
        :label="fieldLabel(field.name, field.unitOfMeasure, field.isRequired)"
        class="field"
      >
        <input-text
          v-if="field.type === 'number'"
          :id="field.id"
          :model-value="field.value"
          :name="field.id"
          placeholder="Insert value"
          type="number"
          :min="field.minValue"
          :max="field.maxValue"
          :step="fieldNumericMask(field.editMask)"
          :disabled="!canEdit"
          @update:model-value="updateValue"
        />
      </field-set>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Emit} from "vue-property-decorator";
import FieldSet from "@/vue/molecules/field-set.vue";
import InputText from "@/vue/atoms/input-text.vue";
import {BiometricPhysicianFormState} from "@/ts/store/biometric-physician-form/biometric-physician-form-store-instance";
import type {PhysicianFormBiometric} from "@/ts/types/store/biometric-physician-form-store-types";
import type {PhysicianFormBiometric as PhysicianFormBiometricType} from "@/ts/types/dto/physician-form.dto";
import SelectDropdown from "@/vue/atoms/select-dropdown.vue";
import {PhysicianFormStatus} from "@/ts/types/dto/physician-form.dto";

@Component({
  components: {FieldSet, InputText, SelectDropdown},
})
export default class PhysicianFormExamInformation extends Vue {
  @BiometricPhysicianFormState
  physicianForm!: PhysicianFormBiometric;

  /* This are currently unknown how to handle fields */
  notSupportedFields = ["component", "component_optional"];

  get canEdit(): boolean {
    return this.physicianForm.status != PhysicianFormStatus.approved && this.physicianForm.status != PhysicianFormStatus.overdue;
  }

  get requiredBiometrics(): PhysicianFormBiometricType[] | undefined {
    return this.physicianForm.biometrics?.filter(
      (item) => item.isRequired && !item.isSecondaryMeasurement && !this.notSupportedFields.includes(item.type) && item.name != "Nicotine",
    );
  }

  get nicotineBiometric(): any {
    return this.physicianForm.biometrics
        ?.filter((item) => item.name == "Nicotine")[0];
  }

  get firstBloodPressureBiometrics(): any {
      return this.physicianForm.biometrics
          ?.filter((item) => item.name == "Blood Pressure" && !item.isSecondaryMeasurement)[0];
  }

  get secondBloodPressureBiometrics(): any {
    return this.physicianForm.biometrics
        ?.filter((item) => item.name == "Blood Pressure" && item.isSecondaryMeasurement)[0];
  }

  get optionalBiometrics(): PhysicianFormBiometricType[] | undefined {
    return this.physicianForm.biometrics?.filter(
      (item) => {
        if (typeof item.isRequired === "undefined") {
          return !this.notSupportedFields.includes(item.type);
        }

        return !item.isRequired && item?.isSecondaryMeasurement && !this.notSupportedFields.includes(item.type);
      },
    );
  }

  fieldLabel(name: string, unit: string, isRequired: boolean): string {
    const unitOfMeasure = Boolean(unit);
    return unitOfMeasure ? `${name}${isRequired ? "*" : ""} (${unit})` : name;
  }

  fieldNumericMask(editMask: string): string {
    // eslint-disable-next-line require-unicode-regexp
    return editMask.replace(/.$/, "1");
  }

  @Emit("submitform")
  submit(): void {
  }

  @Emit("update:modelValue")
  updateValue(
    value: string,
    event: Event | InputEvent,
  ): PhysicianFormBiometricType[] {
    const field = this.physicianForm.biometrics!.findIndex(
      (item) => item.id === (event.target as HTMLInputElement).id,
    );
    this.physicianForm.biometrics![field].value = value;
    return this.physicianForm.biometrics!;
  }

  // TODO quick fix for now, given the nested form data structure
  @Emit("update:modelValue")
  updateFirstBloodPressure(
      value: string,
      event: Event | InputEvent,
  ): PhysicianFormBiometricType[] {
    const biometricIndex = this.physicianForm.biometrics!.findIndex(
        (item) => item.id === this.firstBloodPressureBiometrics.id && !item.isSecondaryMeasurement,
    );
    const componentIndex = this.physicianForm.biometrics![biometricIndex].components.findIndex(
        (item) => item.id === (event.target as HTMLInputElement).id,
    );
    this.physicianForm.biometrics![biometricIndex].components[componentIndex].value = value;
    return this.physicianForm.biometrics!;
  }

  @Emit("update:modelValue")
  updateSecondBloodPressure(
      value: string,
      event: Event | InputEvent,
  ): PhysicianFormBiometricType[] {
    const biometricIndex = this.physicianForm.biometrics!.findIndex(
        (item) => item.id === this.secondBloodPressureBiometrics.id && item.isSecondaryMeasurement,
    );
    const componentIndex = this.physicianForm.biometrics![biometricIndex].components.findIndex(
        (item) => item.id === (event.target as HTMLInputElement).id,
    );
    this.physicianForm.biometrics![biometricIndex].components[componentIndex].value = value;
    return this.physicianForm.biometrics!;
  }

  @Emit("update:modelValue")
  updateNicotineValue(
      value: string,
      event: Event | InputEvent,
  ): PhysicianFormBiometricType[] {
    const biometricIndex = this.physicianForm.biometrics!.findIndex(
        (item) => item.id === this.nicotineBiometric.id,
    );
    this.physicianForm.biometrics![biometricIndex].value = value;
    return this.physicianForm.biometrics!;
  }
}
</script>

<style lang="sass" scoped>

.information
  width: 681px
  display: flex
  flex-direction: column
  gap: 24px

  @include max-device(md)
    flex-direction: row

.wrap
  display: flex
  flex-wrap: wrap
  gap: 24px

.row
  display: flex
  justify-content: space-between

.field
  width: 300px

.checkbox
  @include Roboto400
  font-size: 14px
  line-height: 22px
  color: $color-primary-hc-navy-100

.release
  @include Asap700
  font-size: 16px
  line-height: 22px
  color: $color-primary-hc-navy-100


h3
  @include Asap700
  font-size: 20px
  color: $color-primary-hc-navy-100

  span
    @include Roboto400
    font-size: 14px
    color: $color-primary-hc-navy-50

p
  @include Roboto400
  font-size: 14px
  color: $color-primary-hc-navy-50


/* Chrome, Safari, Edge, Opera */
/* stylelint-disable */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0


/* Firefox */
input[type=number]
  -moz-appearance: textfield
</style>
