<template>
  <navbar-page title="Edit Profile" :back-link="$pagesPath.profile.main" class="body">
    <div>
      <profile-motivation/>
      <profile-personal-information :hide-gender="defaultStore && defaultStore.profile && defaultStore.profile.hideGender"/>
      <profile-company-information/>
      <profile-contact-information/>
    </div>
  </navbar-page>
</template>

<script lang="ts" setup>
import ProfilePersonalInformation from "@/vue/organisms/profile/block/profile-personal-information.vue";
import ProfileCompanyInformation from "@/vue/organisms/profile/block/profile-company-information.vue";
import ProfileContactInformation from "@/vue/organisms/profile/block/profile-contact-information.vue";
import ProfileMotivation from "@/vue/organisms/profile/block/profile-motivation.vue";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import { onMounted, ref } from "vue";
import {DefaultStore} from "@/ts/store/default/default-store";
import { getModule } from "vuex-module-decorators";
import {vueStore} from "@/ts/store/vue-store";

const defaultStore = ref<DefaultStore>();

onMounted(() => {
 defaultStore.value =  getModule(DefaultStore, vueStore);
});
</script>
<style lang="sass" scoped>
.body
  background-color: $color-neutral-platinum-40
  background-size: contain
  color: $color-primary-hc-navy-100
  @include Asap700
  width: 100%
  height: 100%
  padding-left: 100px
</style>
