<template>
  <section class="program">
    <div class="description">
      <markdown-it :text="program.description"/>
    </div>
    <div class="info">
      <program-info
        :image="startIcon"
        title="Start Date"
        :text="formattedStartDate"
      />
      <program-info
        :image="endIcon"
        title="End Date"
        :text="formattedEndDate"
      />
      <hc-button 
        label="Go To Rewards" 
        :to="rewardPath"
        :icon="rightArrowIcon"
        iconPosition="right"
      />
    </div>
  </section>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import type {MyProgramResponse} from "@/ts/types/dto/program.dto";
import {PointsInfo} from "@/ts/types/dto/reward-tracker";
import ProgramInfo from "@/vue/molecules/program-info.vue";
import endIcon from "@/assets/img/calendar-icon.svg";
import startIcon from "@/assets/img/calendar-icon-empty.svg"
import rightArrowIcon from "@/assets/img/right-arrow-white.svg";
import {formatDateShortMonthName} from "@/ts/utils/date-pure-functions";
import MarkdownIt from "@/vue/atoms/markdown-it.vue";
import HcButton from "@/vue/atoms/hc-button.vue";
import {formatCash} from "@/ts/utils/pure-functions";

@Component({
  name: "ProgramDetails",
  components: {ProgramInfo, MarkdownIt, HcButton},
})
export default class ProgramDetails extends Vue {
  @Prop()
  program!: MyProgramResponse;

  @Prop()
  pointsInfo!: PointsInfo;

  get rewardPath(): string {
    return this.$pagesPath.main.rewards;
  }

  get startIcon(): string {
    return startIcon;
  }

  get endIcon(): string {
    return endIcon;
  }

  get rightArrowIcon(): string {
    return rightArrowIcon;
  }

  get cashFormatted(): string {
    return formatCash(this.pointsInfo.maxCash);
  }

  get formattedEndDate(): string {
    return formatDateShortMonthName(this.program.endDate);
  }

  get formattedStartDate(): string {
    return formatDateShortMonthName(this.program.startDate);
  }
}
</script>
<style lang="sass" scoped>
.program
  display: flex
  gap: 8px
  flex-direction: column

  @include max-device(md)
    padding: 0 16px

.info
  display: flex
  align-items: flex-start
  justify-content: space-between

.description
  font-size: 14px
  line-height: 20px
  color: $color-primary-hc-navy-100
  @include Roboto400
  max-width: 600px
</style>
