<template>
  <input
    :id="id"
    :name="name"
    type="date"
    :autocomplete="autocomplete"
    :value="modelValueStr"
    :required="required"
    :min="min"
    :max="max"
    :class="{empty: !modelValueStr}"
    :disabled="disabled"
    @change="onChangeInput"
  />
</template>

<script lang="ts" setup>
import {
  convertDateToServerFormat,
  parseDate,
  isValidDate,
} from "@/ts/utils/date-pure-functions";
import {computed} from "vue";

const props = defineProps<{
  modelValue: Date | null;
  name?: string;
  required?: boolean;
  autocomplete?: string;
  id?: string;
  min?: string;
  max?: string;
  disabled?: boolean;
}>();

const emit = defineEmits(["update:modelValue"]);

const modelValueStr = computed((): string => {
  if (!isValidDate(props.modelValue!)) {
    return "";
  }
  return convertDateToServerFormat(props.modelValue!);
});

const updateValue = (newVal: Date | null): void => {
  emit("update:modelValue", newVal);
};

const onChangeInput = (event: any): void => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
  const newValue: Date = parseDate(event.target.value);
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  if (newValue.getFullYear() > 999) {
    // do not update before user added four digits
    updateValue(newValue);
  }

  if (props.modelValue && !isValidDate(newValue)) {
    updateValue(null);
  }
};

</script>

<style lang="sass" scoped>
input
  @include input-field

/* stylelint-disable */
input[type="date"]::-webkit-datetime-edit-text,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-day-field,
input[type="date"]::-webkit-datetime-edit-year-field
  color: $color-primary-hc-navy-100

input[type="date"].empty:not(:focus)::-webkit-datetime-edit-text,
input[type="date"].empty:not(:focus)::-webkit-datetime-edit-month-field,
input[type="date"].empty:not(:focus)::-webkit-datetime-edit-day-field,
input[type="date"].empty:not(:focus)::-webkit-datetime-edit-year-field
  color: $color-primary-hc-navy-50

/* stylelint-enable */

</style>
