<template>
  <popup-modal :model-value="showModal" @update:model-value="close">
    <loading-suspense :error="error" :loading="loading" class="container">
      <main v-if="proofsToShow">
        <activity-proof-col
          title="Pending"
          :proofs="pendingProofs"
        />
        <activity-proof-col
          title="Approved"
          :proofs="approvedProofs"
        />
        <activity-proof-col
          title="Denied"
          :proofs="deniedProofs"
        />
      </main>
      <empty-state
        v-else
        :image="noActivityImage"
        title="No Submissions"
        description="Submit proof for your admin to approve"
      />
    </loading-suspense>
  </popup-modal>
</template>

<script lang="ts" setup>
import {computed, ref} from "vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import EmptyState from "@/vue/atoms/empty-state.vue";
import noActivityImageSvg from "@/assets/img/illustrations/no-activities-available-1.svg";
import ActivityProofCol from "@/vue/molecules/activities/activity-proof-col.vue";
import type {ActivityApprovalRequest} from "@/ts/types/dto/activity.dto";
import {AdminRequestStatus} from "@/ts/types/dto/activity.dto";
import type {ActivityCardModel} from "@/ts/types/component/activity-card";

const error = ref();
const loading = ref(false);

const props = defineProps<{
    showModal: boolean;
    activity: ActivityCardModel;
}>();

const proofsToShow = computed(() => pendingProofs.value.length > 0 || approvedProofs.value.length > 0 || deniedProofs.value.length > 0);

const sortProofsByDateDescending = (p1: ActivityApprovalRequest, p2: ActivityApprovalRequest, date: keyof ActivityApprovalRequest): number => {
    return new Date(p2[date]!).getTime() - new Date(p1[date]!).getTime();
};

const filterAndSortProofs = (status: AdminRequestStatus, date: keyof ActivityApprovalRequest): ActivityApprovalRequest[] => {
    return props.activity.proof!.filter((proof) => proof.status === status)
      .sort((p1, p2) => sortProofsByDateDescending(p1, p2, date)) as ActivityApprovalRequest[];
};

const pendingProofs = computed<ActivityApprovalRequest[]>(() => filterAndSortProofs(AdminRequestStatus.pending, "submitDate"));

const approvedProofs = computed<ActivityApprovalRequest[]>(() => filterAndSortProofs(AdminRequestStatus.approved, "resolveDate"));

const deniedProofs = computed<ActivityApprovalRequest[]>(() => filterAndSortProofs(AdminRequestStatus.declined, "resolveDate"));

const noActivityImage = computed((): string => {
  return noActivityImageSvg;
});

const emit = defineEmits(["closeModal"]);

const close = (): void => {
    emit("closeModal");
};

</script>

<style lang="sass" scoped>
.container
  padding-bottom: 5rem
</style>
