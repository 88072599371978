<template>
  <div v-if="current != 0" :class="`loading-bar ${theme}`">
    <div class="loading-bar-inner" :style="{width}"/>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";

@Component({
  name: "LoadingBar",
})
export default class LoadingBar extends Vue {
  @Prop({default: 100}) steps!: number;

  @Prop({default: 1}) current!: number;

  @Prop({default: "blue"})
  theme!: "blue" | "pink" | "secondary-blue";

  get width(): string {
    return `${this.current / this.steps * 100}%`; // eslint-disable-line @typescript-eslint/no-magic-numbers
  }
}
</script>

<style lang="sass" scoped>
.loading-bar
  align-items: center
  display: flex
  margin: auto

.loading-bar-inner
  border-radius: 4px
  height: 4px
  transition: width 0.3s ease-in-out
  min-width: 5px

.blue
  background-color: $color-white
  border-radius: 8px

  .loading-bar-inner
    background-color: $color-secondary-sea-foam-100
    border-radius: 8px
    height: 12px

.pink
  background-color: $color-neutral-platinum-100

  .loading-bar-inner
    background-color: $color-secondary-sea-foam-100


.secondary-blue
  background-color: $color-neutral-platinum-100
  border-radius: 8px
  height: 5px

  .loading-bar-inner
    background-color: $color-secondary-sea-foam-100
    height: 5px
</style>
