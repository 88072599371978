<template>
  <div class="page-login">
    <hc-logo/>
    <div class="image-box">
      <img src="@/assets/img/illustrations/login-page.svg" alt="Image Box"/>
    </div>
    <form class="login-group" @submit.prevent="login">
      <div v-if="!showMfaInput" class="container">
        <field-set id="username" label="Username">
          <input-text
            id="username"
            v-model="username"
            placeholder="Enter username"
            required
          />
          <template #right>
            <span class="forgot" @click="showForgotUsername">Forgot username?</span>
          </template>
        </field-set>
        <field-set id="password" label="Password">
          <input-password
            id="password"
            v-model="password"
            placeholder="Enter password"
            required
          />
          <template #right>
            <span class="forgot" @click="showForgotPassword">Forgot password?</span>
          </template>
        </field-set>
      </div>
      <div v-if="showMfaInput">
        <mfa-input :user-info="userInfo" />
      </div>

      <error-text :error="error"/>
      <button-primary
        v-if="!showMfaInput"
        class="submit"
        type="submit"
        text="Login"
      />

      <h4 v-if="!showMfaInput" class="or-txt"><hr style="flex: 1; margin-right: 10px;">OR<hr style="flex: 1; margin-left: 10px;"></h4>
      <button-primary
        v-if="!showMfaInput"
        class="sso"
        @click="showCompanyCodePopup(true)"
        text="Login With Company SSO"
        :theme="'white'"
      />
      <div v-if="!showMfaInput" class="go-to-register">
        <span class="no-account">Don't have an account?</span>
        <span class="register-btn" @click="showCompanyCodePopup(false)">Register</span>
      </div>
      <div v-if="!showMfaInput" class="privacy-policy">
        <span class="no-account">Learn more about our </span>
        <router-link :to="$pagesPath.auth.privacyPolicy" class="privacy-policy-btn">
          Privacy Policy
        </router-link>
      </div>
    </form>
    <email-confirmation-block-popup
      :displayed="isBlockUntilEmailConfirmed"
      @update:displayed="handleEmailConfirmed"
    />

    <forgot-password-popup
      :displayed="isForgotPassword"
      @close-popup="hideForgotPassword"
    />

    <forgot-username-popup
      :displayed="isForgotUsername"
      @close-popup="hideForgotUsername"
    />

    <company-code-popup
      v-if="isCompanyCodePopup"
      :displayed="isCompanyCodePopup"
      :from="fromSsoButton ? 'sso': 'registration'"
      @close-popup="hideCompanyCodePopup"
    />
  </div>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import HcLogo from "@/vue/molecules/hc-logo.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import InputText from "@/vue/atoms/input-text.vue";
import FieldSet from "@/vue/molecules/field-set.vue";
import InputPassword from "@/vue/atoms/input-password.vue";
import {AuthLoginResponseDTO, CensusData, MfaVerificationRequestDTO} from "@/ts/types/dto/auth.dto";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
import ForgotPasswordPopup from "@/vue/organisms/forgot-password-popup.vue";
import ForgotUsernamePopup from "@/vue/organisms/forgot-username-popup.vue";
import EmailConfirmationBlockPopup from "@/vue/organisms/email-confirmation-block-popup.vue";
import CompanyCodePopup from "@/vue/organisms/company-code-popup.vue";
import {sessionStore} from "@/ts/instances/localstorage";
import MfaInput from "@/vue/organisms/mfa-input.vue";

@Component({
  name: "LoginPage",
  components: {
    HcLogo,
    ButtonPrimary,
    ErrorText,
    InputText,
    FieldSet,
    InputPassword,
    ForgotPasswordPopup,
    ForgotUsernamePopup,
    CompanyCodePopup,
    EmailConfirmationBlockPopup,
    MfaInput
  },
})
export default class LoginPage extends mixins(LoadingMixin, DefaultStoreMixin) {
  username: string = "";

  password: string = "";

  isForgotPassword: boolean = false;

  isForgotUsername: boolean = false;

  isCompanyCodePopup: boolean = false;

  isBlockUntilEmailConfirmed: boolean = false;

  showMfaInput: boolean = false;

  fromSsoButton: boolean = false;

  userInfo: CensusData | null = null;

  mounted(): void {
    void this.checkForToken();
  }

  @DefaultGrowlError
  async login(): Promise<void> {
    if (this.showMfaInput) {
      return;
    }

    this.hideEmailConfirmationBlock()

    this.error = "";
    try {
      const data: AuthLoginResponseDTO = await this.$api.login({
      password: this.password,
      username: this.username,
      });
      this.defaultStore.setAuthTokens(data);
      if (data.mfaToken) {
        this.userInfo = data.censusData ?? null;
        this.showMfaInput = true;
      } else {
        void this.$router.push("/");
      }
    } catch (error) {
      const err = error as Error;
      if (typeof err.message === "string" && err.message.includes("(403)")) {
        this.showEmailConfirmationBlock();
        return;
      } else {
        throw error;
      }
    }

  }

  showEmailConfirmationBlock(): void {
    this.isBlockUntilEmailConfirmed = true;
  }

  hideEmailConfirmationBlock(): void {
    this.isBlockUntilEmailConfirmed = false;
  }

  showForgotPassword(): void {
    this.isForgotPassword = true;
  }

  hideForgotPassword(): void {
    this.isForgotPassword = false;
  }

  handleEmailConfirmed(value: boolean) {
    this.login();
  }

  showForgotUsername(): void {
    this.isForgotUsername = true;
  }

  hideForgotUsername(): void {
    this.isForgotUsername = false;
  }

  showCompanyCodePopup(val: boolean): void {
    this.fromSsoButton = val;
  this.isCompanyCodePopup = true;
}

  hideCompanyCodePopup(): void {
    this.isCompanyCodePopup = false;
  }

  async checkForToken(): Promise<void> {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const redirectUri = urlParams.get("redirect_uri"); // Extract the redirect_uri parameter

    if (token) {
      try {
        const data = await this.$api.loginToken(token);
        this.defaultStore.setAuthTokens(data);

        // Redirect only if redirectUri is a non-null string
        if (redirectUri && this.isValidRedirectUri(redirectUri)) {
          window.location.href = redirectUri;
        } else {
          // Default redirection or handle the absence of a valid redirectUri
          void this.$router.push("/");
        }
      } catch (error) {
        this.error = "Error validating token";
      }
    }
  }

  // Utility method to validate the redirect URI
  isValidRedirectUri(uri: string): boolean {
    // Basic validation: check if the URI is a valid URL
    try {
      new URL(uri);
      return true;
    } catch (e) {
      return false;
    }

    // todo: Add more sophisticated checks as necessary, such as ensuring the URI belongs to your domain
  }

}
</script>

<style lang="sass" scoped>

.container
  max-width: max-container-width(24px, $container-width, 2)
  @include flex-container(24px, $container-width, 2)
  justify-content: center

.page-login
  padding: 30px 20px

.image-box
  height: 300px
  width: 300px
  justify-self: center
  align-items: center
  display: flex
  flex-direction: column
  margin-top: 30px
  margin-bottom: 6vh

.forgot
  @include Roboto600
  color: $color-secondary-sea-foam-100

  &:hover
    text-decoration: underline
    cursor: pointer

.no-account
  @include Roboto400
  font-size: 14px
  letter-spacing: 0.2px
  color: $color-primary-hc-navy-100

.register-btn
  color: $color-secondary-sea-foam-100
  @include Roboto600
  text-decoration: underline
  cursor: pointer

.privacy-policy-btn
  color: $color-secondary-sea-foam-100
  @include Roboto600

.login-group
  align-items: center
  display: flex
  flex-direction: column
  justify-content: center

.submit
  margin-top: calc(10vh - 20px)
  width: $container-width

.sso
  margin-bottom: 4vh
  width: $container-width

.go-to-register
  display: flex
  padding: 16px 0

  span
    margin-right: 10px

.privacy-policy
  display: flex
  padding: 16px 0

  span
    margin-right: 10px

.or-txt
  @include Asap700
  color: $color-primary-hc-navy-100
  display: flex
  justify-content: center
  width: $container-width

</style>
