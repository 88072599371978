<template>
  <div class="tooltip-container">
    <slot/>
    <div class="tooltip-text">
      {{ tooltipText }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "TooltipWrapper",
  props: {
    tooltipText: {
      type: String as PropType<string>,
      required: true,
    },
  },
});
</script>

<style lang="sass" scoped>
.tooltip-container
  position: relative
  display: inline-block

.tooltip-text
  visibility: hidden
  max-width: 800px
  min-width: 200px
  background-color: $color-primary-hc-navy-100
  color: $color-white
  text-align: center
  font-size: 16px
  font-weight: normal
  border-radius: 8px
  padding: 10px
  position: absolute
  z-index: 1
  bottom: 100%
  left: 50%
  transform: translateX(-50%)
  opacity: 0
  transition: opacity 0.3s, transform 0.3s
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25)
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.tooltip-container:hover .tooltip-text
  visibility: visible
  opacity: 1
  transform: translateX(-50%) translateY(-10px)
  white-space: normal
</style>