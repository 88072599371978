<template>
  <div class="nav-buttons">
    <router-link :to="$pagesPath.notifications.main">
      <div class="notification-container">
        <img src="@/assets/img/notification-icons/bell.svg" class="notification-bell"/>
        <i v-if="hasUnreadNotifications" class="unread-icon"></i>
      </div>
    </router-link>
    <router-link :to="$pagesPath.profile.main">
      <user-avatar
          size="mid-small"
          :firstname="profile.firstName"
          :lastname="profile.lastName"
          :avatar="profile.avatarPath"
          :is-circular="true"
      />
    </router-link>
  </div>
</template>

<script lang="ts" setup>
import {computed, inject, onMounted, ref} from "vue";
import {Api} from "@/ts/classes/api";
import UserAvatar from "@/vue/atoms/user-avatar.vue";
import {DefaultStore} from "@/ts/store/default/default-store";
import {getModule} from "vuex-module-decorators";
import {vueStore} from "@/ts/store/vue-store";
import type {Profile} from "@/ts/types/store/default-store-types";

const defaultStore: DefaultStore = getModule(DefaultStore, vueStore);
const profile = computed((): Profile => {
  return defaultStore.profile!;
});

const $api: Api = inject("$api")! as Api;
const hasUnreadNotifications = ref(false);

onMounted(async () => {
  try {
    const {unreadNotifications} = await $api.getNotificationSummary();
    hasUnreadNotifications.value = unreadNotifications > 0;
  } catch (e) {
    console.error(e);
  }
});
</script>

<style lang="sass" scoped>
.nav-buttons
  display: flex
  margin: 1rem
  gap: 13px

  > *
    margin-right: 0.5rem

.notification-container
  position: relative
  display: inline-block

.notification-bell
  padding: 4px
  width: 36px
  height: 36px

.unread-icon
  position: absolute
  top: 2px
  right: 2px
  width: 10px
  height: 10px
  background-color: $color-status-error-red
  border-radius: 50%

.profile-img
  background-color: white
  border-radius: 50%
  padding: 10px
</style>