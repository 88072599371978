/* eslint-disable line-comment-position */
import type {TaskType} from "@/ts/types/dto/activity.dto";
import type {ChallengeParticipant, ChallengeStats, LeaderboardPosition} from "@/ts/types/dto/leaderboard-challenge.dto";
import type {FinishedFriend} from "@/ts/types/dto/pathway.dto";
import { Pagination } from "./pagination.dto";
import { ChallengeStatus } from "../component/challenges";
import {ContentCategory, ContentCategoryIcon} from "@/ts/types/dto/program.dto";

export enum ChallengeType {
  map_race = "map_race",
  leaderboard = "leaderboard",
  target = "target",
  relay_race = "relay_race",
}
export enum ChallengeDisplayType {
  total_contributions = "total_contribution",
  daily_average = "daily_average",
}
export interface ChallengeDTO {
  id: string;
  type: ChallengeType;
  coverPath: string;
  name: string;
  description: string;
  durationDays: number;
  startDate: string;
  endDate: string;
  taskType: TaskType;
  pointsFirstPositionReward: number;
  pointsSecondPositionReward: number;
  pointsThirdPositionReward: number;
  author: ChallengeParticipant;
  myLeaderboardPosition?: LeaderboardPosition;
  stats: ChallengeStats;
  joinedFriends?: FinishedFriend[];
  hasUserJoined: boolean;
  joinedOn?: Date;
  isCompleted: boolean;
  displayType?: ChallengeDisplayType;
  challengeStatus: ChallengeStatus;
  primaryCategory: ContentCategory;
  categoryIcon: ContentCategoryIcon;
}

export interface PaginatedChallengeDTO {
  pagination: Pagination;
  items: ChallengeDTO[];
}

