<template>
  <section v-if="ranking" class="ranking-list">
    <h3>Leaderboard</h3>
    <challenge-ranking-card
      v-for="rank in orderedRanking"
      :key="rank.challengeParticipant.id"
      :ranking="rank"
    />
  </section>
</template>
<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import UserAvatar from "@/vue/atoms/user-avatar.vue";
import {DefaultState} from "@/ts/store/default/default-store-instance";
import type {Profile} from "@/ts/types/store/default-store-types";
import type {RankingPosition} from "@/ts/types/dto/leaderboard-challenge.dto";
import CompanyDefaultAvatar from "@/vue/atoms/company-default-avatar.vue";
import ChallengeRankingCard from "@/vue/molecules/challenge/challenge-ranking-card.vue";

@Component({
  name: "ChallengeRankingList",
  components: { CompanyDefaultAvatar, UserAvatar, ChallengeRankingCard },
})
export default class ChallengeRankingList extends Vue {
  @Prop()
  ranking!: RankingPosition[];

  @DefaultState
  public profile!: Profile;

  // ideally this should come ordered from backend
  get orderedRanking(): RankingPosition[] {
    // ensures user is always first in list regardless of their ranking
    return [
      ...this.ranking.filter((ranking) => ranking.challengeParticipant.id === this.profile.id),
      ...this.ranking.filter((ranking) => ranking.challengeParticipant.id !== this.profile.id)
        .sort((rank1, rank2) => rank1.position - rank2.position),
    ];
  }
}
</script>

<style lang="sass" scoped>
.ranking-list
  margin: 3rem auto
  max-width: 686px

  h3
    @include Asap700
    font-size: 24px
    line-height: 32px
    color: $color-primary-hc-navy-100
</style>
