<template>
  <span class="text">{{ typeChallenge }}</span>
</template>


<script lang="ts">
import {ChallengeType as ChallengeTypeDTO} from "@/ts/types/dto/challenges.dto";
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";


@Component({
  name: "ChallengeType",
})
export default class ChallengeType extends Vue {
  @Prop() type!: ChallengeTypeDTO;


  get typeChallenge(): string {
    const typeMap: Record<ChallengeTypeDTO, string> = {
      [ChallengeTypeDTO.leaderboard]: "Leaderboard Challenge",
      [ChallengeTypeDTO.map_race]: "Map Race",
      [ChallengeTypeDTO.relay_race]: "Relay Race",
      [ChallengeTypeDTO.target]: "Target Challenge",
    };

    return typeMap[this.type];
  }
}
</script>

<style lang="sass" scoped>
.text
  @include Roboto400
  font-size: 10px
  line-height: 15px
  letter-spacing: 0.4px
  text-transform: uppercase
  color: $color-primary-hc-navy-100


</style>
