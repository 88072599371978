<template>
  <popup-modal slot-class="edit" :model-value="modelValue" @update:model-value="close" use-platinum-background>
    <form class="modal-group" @submit.prevent="save">
      <h2 v-if="name" class="modal-header">
        {{ name }}
      </h2>
      <div v-if="description" class="description">
        {{ description }}
      </div>
      <div :class="containerClasses">
        <slot/>
      </div>
      <error-text :error="error"/>
      <div class="button-row" >
        <button-primary
          type="submit"
          :loading="loading"
          class="submit"
          :text="saveVerbiage"
        />
      </div>
    </form>
  </popup-modal>
</template>

<script lang="ts" setup>
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import {computed} from "vue";

const props = defineProps<{
  modelValue: boolean;
  error: string;
  name: string;
  description?: string;
  loading: boolean;
  showSuccess?: boolean;
  saveVerbiage?: string;
}>();

const emit = defineEmits(["update:modelValue", "save"]);

const containerClasses = computed((): string[] => {
  return ["container", props.description ? "margin16" : ""];
});

const saveVerbiage = computed((): string => {
  return props.saveVerbiage ? props.saveVerbiage : "Save Changes";
});

const close = (): void => {
  emit("update:modelValue", false);
};

const save = (): void => {
  emit("save");
};

</script>

<style lang="sass" scoped>
.container
  display: flex
  flex-wrap: wrap
  gap: 24px
  justify-content: space-around
  margin-top: 32px

.margin16
  margin-top: 16px

.success
  color: $color-status-success-green

.button-row
  display: flex
  flex-direction: row
  flex-wrap: wrap
  margin-top: 48px
  justify-content: space-between

.submit
  margin-left: auto

.modal-header
  @include Asap700
  margin: 0
  font-size: 28px
  line-height: 36px

.description
  @include Roboto400
  font-size: 16px
  line-height: 24px
  letter-spacing: 0.1px
  margin-top: 16px

</style>
