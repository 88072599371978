<template>
  <div class="section-list">
    <p class="title">
      Program
    </p>
    <div
      class="sections"
      :style="{flexDirection: sections.length > 3 ? 'column' : 'row'}"
    >
      <div
        v-for="(section,i) in sections"
        :key="section.id"
      >
        <img v-if="!finished" src="@/assets/img/check-mark-finished.svg"/> {{ i + 1 }}. {{ section.title }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import type {Section} from "@/ts/types/dto/pathway.dto";

@Component({
  name: 'PathwaySectionList',
})
export default class PathwaySectionList extends Vue {
  @Prop()
  sections!: Section[];

  @Prop()
  finished!: boolean;
}
</script>

<style lang="sass" scoped>
.title
  @include Asap700
  font-size: 24px
  color: $color-primary-hc-navy-100

.section-list
  display: flex
  flex-direction: column
  align-content: center
  justify-content: center
  align-items: center
  background-color: $color-neutral-platinum-40
  padding: 1rem
  padding-bottom: 2.5rem

.sections
  display: flex
  flex-direction: row
  @include Roboto400
  color: $color-primary-hc-navy-100
  font-size: 16px

  img
    margin-left: 30px
    margin-right: 5px


</style>
