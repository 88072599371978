<template>
  <li class="history-item">
    <div :class="iconClass">
      <protected-svg 
        v-if="item.path"
        :src="item.path"
        size="small"
        :ariaHidden="false"
        alt="History Item Icon"
      />
      <squared-icon v-else :icon-type="iconType"/>
    </div>
    <div>
      <h3>
        {{ item.displayText }}
      </h3>
      <div class="date">
        {{ date }}
      </div>
    </div>
    <div :class="rewardClass">
      {{ rewardAmount }}
    </div>
  </li>
</template>

<script lang="ts" setup>
import {getMonthDay} from "@/ts/utils/date-pure-functions";
import type {RewardHistoryItem} from "@/ts/types/dto/reward.dto";
import {RewardHistoryContentType, RewardType} from "@/ts/types/dto/reward.dto";
import type {ComputedRef} from "vue";
import {computed} from "vue";
import type {IconType} from "@/ts/types/component/transaction-history";
import SquaredIcon from "@/vue/atoms/squared-icon.vue";
import {getRewardAmountFormatted} from "@/ts/utils/pure-functions";
import ProtectedSvg from "@/vue/atoms/protected-svg.vue";

const props = defineProps<{
  item: RewardHistoryItem;
}>();

const rewardAmount = computed(() => `
  ${(props.item.amount >= 0) ? "+" : ""}${getRewardAmountFormatted({type: props.item.rewardType,
amount: props.item.amount})}
`);

const date = computed(() => {
  const dateObj = new Date(props.item.date);
  const localDate = new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000);
  return getMonthDay(new Date(localDate));
});

const rewardHistoryContentTypeToIconType = {
  [RewardHistoryContentType.reward_history]: "generic",
  [RewardHistoryContentType.activity]: props.item.taskType || "generic",
  [RewardHistoryContentType.platform_activity]: "platform",
  [RewardHistoryContentType.pathway]: "book",
  [RewardHistoryContentType.challenge_leaderboard]: props.item.taskType || "meditation",
  [RewardHistoryContentType.challenge_target]: props.item.taskType || "meditation",
  [RewardHistoryContentType.challenge_map_race]: props.item.taskType || "meditation",
  [RewardHistoryContentType.challenge_relay_race]: props.item.taskType || "meditation",
  [RewardHistoryContentType.bioscreening]: "bioscreening",
  [RewardHistoryContentType.hra]: "hra",
  [RewardHistoryContentType.coaching]: "coaching",
  [RewardHistoryContentType.redeem_reward]: "money",
};

const iconType: ComputedRef<IconType> = computed(() => {
  // This is just to fulfil the wrong design, ideally the icon type should represent the activity type
  if (props.item.isGatekeeper || props.item.isRecommended) {
    return "gatekeeper" as IconType;
  }

  return (rewardHistoryContentTypeToIconType[props.item.contentType] || "generic") as IconType;
});

const rewardHistoryContentTypeToIconClass = {
  [RewardHistoryContentType.reward_history]: "turquoise",
  [RewardHistoryContentType.activity]: "turquoise",
  [RewardHistoryContentType.platform_activity]: "turquoise",
  [RewardHistoryContentType.pathway]: "pink",
  [RewardHistoryContentType.challenge_leaderboard]: "orange",
  [RewardHistoryContentType.challenge_target]: "orange",
  [RewardHistoryContentType.challenge_map_race]: "orange",
  [RewardHistoryContentType.challenge_relay_race]: "orange",
  [RewardHistoryContentType.bioscreening]: "blue",
  [RewardHistoryContentType.hra]: "blue",
  [RewardHistoryContentType.coaching]: "blue",
  [RewardHistoryContentType.redeem_reward]: "white",
};

const iconClass = computed(() => {
  // This is just to fulfil the wrong design, ideally the icon class should match the activity type
  if (props.item.isGatekeeper || props.item.isRecommended) {
    return "icon-wrapper blue";
  }

  return `icon-wrapper ${rewardHistoryContentTypeToIconClass[props.item.contentType]}`;
});

const rewardClass = computed(() => (props.item.amount >= 0) ? "reward plus" : "reward minus");
</script>

<style lang="sass" scoped>
.history-item
  padding: 20px 32px
  display: flex
  flex-direction: row
  align-items: center
  background-color: $color-white

.reward
  margin-left: auto
  padding: 4px 12px
  border: 1px solid $color-neutral-platinum-100
  border-radius: 16px
  @include Roboto600
  font-size: 12px

.plus
  color: $color-status-success-green

.minus
  color: $color-status-error-red

h3
  color: $color-primary-hc-navy-100
  font-size: 16px
  letter-spacing: 0.15px
  @include Asap700
  margin: 0
  line-height: 22px

.icon-wrapper
  width: 48px
  height: 48px
  margin-right: 24px
  display: flex

  .wrapper
    width: 48px
    height: 48px

.turquoise
  color: $color-tertiary-turquoise-100

.pink
  color: $color-secondary-sea-foam-100

.orange
  color: $color-tertiary-orange-sunset-100

.blue
  color: $color-primary-hc-navy-100

.white
  color: $color-white


.date
  color: $color-primary-hc-navy-50
  font-size: 14px
  line-height: 22px
  @include Roboto400
  letter-spacing: 0.2px
</style>
