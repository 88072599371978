<template>
  <navbar-page title="My Path" :back-link="$pagesPath.profile.main" class="body">
    <div class="select">
      <tabs-navigation :items="tabs" :light-mode="true"/>
    </div>
    <router-view/>
  </navbar-page>
</template>

<script lang="ts" setup>
import NavbarPage from "@/vue/templates/navbar-page.vue";
import type {Tab} from "@/ts/types/component/tabs-navigation";
import {pagesPath} from "@/ts/router/pages-path";
import TabsNavigation from "@/vue/atoms/tabs-navigation.vue";

const tabs: Tab[] = [
  {
    link: pagesPath.profile.goals,
    title: "Goals",
  },
  {
    link: pagesPath.profile.myProgress,
    title: "My progress",
  },
];
</script>

<style lang="sass" scoped>
.body
  background-color: $color-neutral-platinum-40
  background-size: contain
  color: $color-primary-hc-navy-100
  @include Asap700
  width: 100%
  height: 100%
  min-height: 100vh
  padding-left: 100px
</style>
