<template>
  <section class="banner">
    <div class="rewards-content">
      <program-rewards-info
        v-if="rewardTracker?.currentProgramPointsInfo.isPointRewardsAvailable"
        :image="rewardsIconPoints"
        title="Points Earned"
        :numerator="rewardTracker.currentProgramPointsInfo.earnedPoints"
        :denominator="rewardTracker.currentProgramPointsInfo.maxPoints"
        isPoints="true"
      />
      <program-rewards-info
        v-if="rewardTracker?.currentProgramPointsInfo.isCashRewardsAvailable"
        :image="rewardsIconCash"
        title="Cash Earned"
        :numerator="rewardTracker.earnedCash"
        :denominator="rewardTracker.currentProgramPointsInfo.maxCash"
        :isCash="true"
      />
      <program-rewards-info 
        v-if="actionItems.length && showRequired"
        :image="rewardsIconRequired"
        title="Required Actions"
        :numerator="requiredActionsCompleted.length"
        :denominator="requiredActions.length"
      />
      <program-rewards-info 
        v-if="actionItems.length"
        :image="rewardsIconRecommended"
        title="Recommended Actions"
        :numerator="recommendedActionsCompleted.length"
        :denominator="recommendedActions.length"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { defineProps, computed } from "vue";
import type { RewardTrackerResponse } from "@/ts/types/dto/reward-tracker";
import ProgramRewardsInfo from "@/vue/molecules/program-rewards-info.vue";
import rewardsIconPoints from "@/assets/img/rewards-icon-points.svg";
import rewardsIconCash from "@/assets/img/rewards-icon-cash.svg";
import rewardsIconRequired from "/assets/img/rewards-icon-required.svg";
import rewardsIconRecommended from "/assets/img/rewards-icon-recommended.svg";
import { GatekeeperItem, GatekeeperStatus } from "@/ts/types/dto/program.dto";

const props = defineProps<{
  rewardTracker: RewardTrackerResponse | null;
  actionItems: GatekeeperItem[];
  showRequired: boolean;
}>();

const requiredActions = computed(() => props.actionItems.filter((i) => i.isRequired == true));
const requiredActionsCompleted = computed(() => requiredActions.value.filter((i) => i.status == GatekeeperStatus.completed));
const recommendedActions = computed(() => props.actionItems.filter((i) => i.isRequired == false));
const recommendedActionsCompleted = computed(() => recommendedActions.value.filter((i) => i.status == GatekeeperStatus.completed));

</script>

<style lang="sass" scoped>
.banner
  background-color: $color-neutral-platinum-100
  padding: 40px
  display: flex
  justify-content: center
  align-items: center
  opacity: 0
  transform: translateY(20px)
  animation: bounceIn 0.6s ease-out 0.2s forwards

@keyframes bounceIn
  0% 
    opacity: 0
    transform: translateY(20px)
  60% 
    opacity: 1
    transform: translateY(-5px)
  100% 
    opacity: 1
    transform: translateY(20px)

.rewards-content
  display: flex
  gap: 40px

@media (max-width: 1200px)
  .rewards-content
    flex-direction: column
</style>