<template>
  <div class="appointment-details">
    <div class="location">
      <span class="name">{{ locationName }}: </span>{{ locationAddress }}
    </div>
    <div class="date">
      {{ fullDate }}
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Prop} from "vue-property-decorator";
import {
  dateToFullFormat,
  parseDate,
} from "@/ts/utils/date-pure-functions";
import type {BiometricScreeningLocation} from "@/ts/types/dto/biometric-screening.dto";

@Component({
  name: "BiometricAppointmentDetails",
  components: {},
})
export default class BiometricAppointmentDetails extends Vue {
  @Prop()
  timestamp!: Date | string;

  @Prop()
  location!: BiometricScreeningLocation;

  get fullDate(): string | null {
    return dateToFullFormat(this.timestamp!);
  }

  get locationName(): string | null {
    return this.location.name ?? null;
  }

  get locationAddress(): string | null {
    const {room, address1, address2, city, stateCode, zipCode} = this.location;
    return [
      room ? `Room ${room}` : null,
      address1,
      address2,
      city,
      stateCode ?? zipCode ? `${stateCode ?? ""} ${zipCode ?? ""}` : null,
    ].filter(Boolean).join(", ");
  }
}
</script>

<style lang="sass" scoped>
.appointment-details
  display: flex
  flex-direction: column
  row-gap: 8px

.location
  @include Asap400

.name
  font-weight: 700

.date
  @include Asap700

.location,
.date
  font-size: 16px
  line-height: 22px
  letter-spacing: 0.15px
  color: $color-primary-hc-navy-100
</style>
