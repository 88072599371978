<template>
  <div class="tabs">
    <router-link
      v-for="item in items"
      :key="item.link"
      :class="tabClass"
      :to="item.link"
    >
      {{ item.title }}
    </router-link>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import type { Tab } from "@/ts/types/component/tabs-navigation";

const props = defineProps<{
  items: Tab[];
  lightMode?: boolean;
}>();

const tabClass = computed(() => ({
  tab: true,
  "tab--size-small": props.items.length > 4,
  "tab--size-default": props.items.length <= 4,
  "light-mode": props.lightMode ?? false,
}));
</script>

<style lang="sass" scoped>
.tabs
  display: flex
  flex-direction: row
  justify-content: center
  margin: 0 auto
  gap: 4rem
  z-index: 1100
  width: -webkit-fill-available

.tab
  &--size-default
    padding: 1rem
    font-size: 15px
  &--size-small
    padding: 1.3rem 0.2rem
    font-size: small
  width: fit-content
  display: flex
  justify-content: center
  text-transform: uppercase
  text-decoration: none
  @include Roboto600
  font-size: 15px
  color: $color-white 
  position: relative

  &.router-link-exact-active
    color: $color-secondary-sea-foam-100

    &::after
      content: ""
      background: $color-secondary-sea-foam-100
      height: 3px
      position: absolute
      bottom: 10px
      width: -webkit-fill-available

.light-mode
  color: $color-primary-hc-navy-100
</style>