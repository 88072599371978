import type {Pagination} from "@/ts/types/dto/pagination.dto";
import type {RewardValidation, RewardType} from "@/ts/types/dto/reward.dto";
import {ContentCategory, ContentCategoryIcon} from "@/ts/types/dto/program.dto";

/**
 * @deprecated
 */
export interface Task {
  type: TaskType;
  goal: number;
  progress: number;
}

export interface ActivityTask {
  type: TaskType;
  goals: ActivityTaskGoal[];
}

export interface ActivityTaskGoal {
  unit: TaskTargetValueStorageUnit;
  targetValue: number;
  progress: number;
}

export enum TaskTargetValueStorageUnit {
  Seconds = "seconds",
  Steps = "steps",
  Centimeter = "cm",
  Grams = "grams",
  Milliliters = "ml",
  Calories = "calories",
  Unspecific = "unspecific",
}

export enum TaskType {
  biking_distance = "biking_distance",
  biking_time = "biking_time",
  calorie_intake = "calorie_intake",
  dancing_time = "dancing_time",
  ergometer_time = "ergometer_time",
  fitness_time = "fitness_time",
  gardening_time = "gardening_time",
  general_physical_activity_time_or_steps = "seconds_or_steps",
  golf_time = "golf_time",
  gymnastics_time = "gymnastics_time",
  handbiking_time = "handbiking_time",
  heart_rate = "heart_rate",
  hiking_distance = "hiking_distance",
  hiking_time = "hiking_time",
  housework_time = "housework_time",
  jogging_distance = "jogging_distance",
  jogging_time = "jogging_time",
  meditation_time = "meditation_time",
  general_physical_activity_time = "seconds_activity",
  mood = "mood",
  nicotine_use = "nicotine_use",
  pilates_time = "pilates_time",
  rowing_time = "rowing_time",
  sleep_time = "sleep_time",
  steps = "steps",
  swimming_time = "swimming_time",
  team_sports_time = "team_sports_time",
  water_drinking_volume = "water_drinking_volume",
  weight = "weight",
  weightlifting_time = "weightlifting_time",
  wheelchair_time = "wheelchair_time",
  yoga_time = "yoga_time",
}

export enum ActivityStatus {
  active = "active",
  available = "available",
  completed = "completed",
  unknown = "unknown",
}

export enum SubmissionFrequency {
  once = "once",
  week_7_times = "week_7_times",
  week_3_times = "week_3_times",
  week_2_times = "week_2_times",
  unlimited = "unlimited",
}

export interface ActivityApprovalRequest {
  additionalInfo?: string;
  createdAt: string;
  denialReason?: string;
  id: string;
  proofPath: string;
  proofDescription: string;
  resolveDate: string;
  status: AdminRequestStatus;
  submitDate: string;
  updatedAt: string;
}

export enum AdminRequestStatus {
  approved = "approved",
  pending = "pending",
  declined = "declined",
}

export interface ActivityDTO {
  id: string;
  name: string;
  description: string;
  reward?: RewardValidation;
  submissionFrequency: SubmissionFrequency;
  status: ActivityStatus;
  endDate: string;
  isAGoal: boolean;
  canDo: boolean;
  task?: ActivityTask;
  proof: ActivityApprovalRequest[];
  proofDescriptionRequired: boolean;
  proofFileRequired: boolean;
  isGateKeeper?: boolean;
  isRecommended?: boolean;
  hasMaxPending: boolean;
  IsActivity: boolean;
  MaxSubmissions: number;
  SubmissionAvailableThisWeek: number;
  TotalsSubmissions: number;
  categories: ContentCategory[];
  primaryCategory?: ContentCategory;
  categoryIcon?: ContentCategoryIcon;
}

export interface GetActivitiesRequestDTO {
  limit?: number;
  offset?: number;
  onlyActive?: boolean;
  onlyAvailable?: boolean;
  onlyCompleted?: boolean;
  categoryIds?: string;
}

export interface GetActivitiesResponseDTO {
  pagination: Pagination;
  items: ActivityDTO[];
}

export interface GetUnifiedUserActivitiesResponseDTO {
  pagination: Pagination;
  items: UnifiedUserActivitiesResponseDTO[];
}

export interface UnifiedUserActivitiesResponseDTO {
  IsActivity: boolean;
  MaxSubmissions: number;
  SubmissionAvailableThisWeek: number;
  TotalsSubmissions: number;
  categories: ContentCategory[];
  id: string;
  name: string;
  description: string;
  reward?: RewardValidation;
  submissionFrequency: SubmissionFrequency;
  status: ActivityStatus;
  endDate: string;
  isAGoal: boolean;
  canDo: boolean;
  task?: ActivityTask;
  proof: ActivityApprovalRequest[];
  proofDescriptionRequired: boolean;
  proofFileRequired: boolean;
  isGateKeeper?: boolean;
  isRecommended?: boolean;
  hasMaxPending: boolean; 
  primaryCategory?: ContentCategory;
  categoryIcon?: ContentCategoryIcon;
}

export interface PostActivityFinishProofRequestDTO {
  url: string;
}

export interface PostActivityFinishHonorRequestDTO {
  progressDelta: number;
}

export type PutActivityToTodoResponseDTO = ActivityDTO;

export interface PlatformActivityDTO {
  description: string;
  id: string;
  name: string;
  reward: RewardValidation;
  status: ActivityStatus;
  type: string;
  categoryIcon?: ContentCategoryIcon;
}

export interface GetPlatformActivitiesResponseDTO {
  pagination: Pagination;
  items: PlatformActivityDTO[];
}
export type PutPlatformActivityToTodoResponseDTO = PlatformActivityDTO;
