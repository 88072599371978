<template>
  <card-item class="card-item" @click="navigateToChallenge">
    <div class="image-frame">
      <position-medal :position="challenge.myLeaderboardPosition?.position ?? 0" />
    </div>
    <div class="content">
      <challenge-type :type="challenge.type" />
      <h1>{{ challenge.name }}</h1>
      <p class="duration">
        {{ endPosition }} {{ finishedDate }}
      </p>
      <div v-if="pointsEarned" class="reward"> 
        {{ pointsEarned }} PTS
      </div>
    </div>
  </card-item>
</template>

<script lang="ts" setup>
import { computed, inject } from "vue";
import { useRouter, type RouteLocationRaw } from "vue-router";
import type { ChallengeDTO } from "@/ts/types/dto/challenges.dto";
import { formatDateShortMonthName } from "@/ts/utils/date-pure-functions";
import CardItem from "@/vue/atoms/card-item.vue";
import PositionMedal from "@/vue/atoms/position-medal.vue";
import ChallengeType from "@/vue/molecules/challenge/challenge-type.vue";
import { PagesPath } from "@/ts/router/pages-path";

const props = defineProps<{
  challenge: ChallengeDTO;
}>();

const router = useRouter();
const $pagesPath = inject<PagesPath>("$pagesPath")!;

const pointsEarned = computed(() => {
  switch (props.challenge.myLeaderboardPosition?.position) {
    case 1:
      return props.challenge.pointsFirstPositionReward;
    case 2:
      return props.challenge.pointsSecondPositionReward;
    case 3:
      return props.challenge.pointsThirdPositionReward;
    default:
      return null;
  }
});

const to = computed<RouteLocationRaw>(() => ({
  path: $pagesPath.challenge.challenge.replace(":id", props.challenge.id),
  query: { type: props.challenge.type },
}));

const navigateToChallenge = () => {
  router.push(to.value);
};

const finishedDate = computed(() => formatDateShortMonthName(props.challenge.endDate));

const endPosition = computed(() => {
  switch (props.challenge.myLeaderboardPosition?.position) {
    case 1:
      return "1st place on ";
    case 2:
      return "2nd place on ";
    case 3:
      return "3rd place on ";
    default:
      return "Ended on ";
  }
});
</script>

<style lang="sass" scoped>
.card-item
  cursor: pointer

.content
  display: flex
  flex-direction: column
  margin-bottom: 12px
  position: relative
  align-items: center
  text-align: center

h1
  @include Asap700
  color: $color-primary-hc-navy-100
  font-size: 1rem
  margin: 0
  /* stylelint-disable */
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: 2
  overflow: hidden

.duration
  @include Roboto400
  font-size: 12px
  line-height: 16px
  color: $color-primary-hc-navy-50
  margin-top: 8px

.footer
  margin-top: 10px
</style>