<template>
  <div v-if="modelValue" class="modal" @click="close">
    <div class="content" @click.stop>
      <img
        v-if="!hideCloseButton"
        src="@/assets/img/x-mark.svg"
        alt="close"
        class="close-button"
        @click="close"
      />
      <div :class="slotClasses">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, defineEmits } from "vue";

const props = defineProps({
  modelValue: Boolean,
  slotClass: String,
  hideCloseButton: Boolean,
  usePlatinumBackground: Boolean,
});

const emit = defineEmits(["update:modelValue"]);

const close = () => {
  emit("update:modelValue", false);
};

const slotClasses = computed(() => [
  "slot",
  props.slotClass ?? "",
  props.usePlatinumBackground ? "bg-platinum" : "",
]);
</script>

<style lang="sass" scoped>
.slot
  position: relative
  background: $color-white
  padding: 64px 40px 40px
  background-clip: padding-box
  border-radius: 24px

  @include max-device(md)
    height: 100vh
    overflow: auto

.bg-platinum
  background: $color-neutral-platinum-100

.modal
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba($color-primary-hc-navy-100, 0.5)
  display: flex
  justify-content: center
  align-items: center
  z-index: 1500
  overflow: hidden

.close-button
  position: absolute
  right: 24px
  top: 24px
  cursor: pointer
  display: block
  margin-left: auto
  z-index: 1

.content
  position: relative
  margin: 30rem
</style>