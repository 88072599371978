<template>
  <div class="biometric-screening-test-kit">
    <title-icon
      :icon="medicalKitIcon"
      text="Request At-Home Test Kit"
    />
    <p class="text">
      If you have questions about requesting an At-Home Test Kit,
      please contact HealthCheck360 at: <span class="phone">1-866-511-0360</span> or <span class="bold">support@healthcheck360.com</span>
    </p>
    <button-primary :disabled="isRequestButtonDisabled" @click="onClickRequest">
      Request At-Home Test Kit
    </button-primary>
  </div>

  <popup-modal :model-value="isConsentModalVisible" @update:model-value="onCloseModal">
    <div class="inner-modal">
      <h2>Request At-Home Test Kit</h2>
      <markdown-it :text="biometricScreeningTestKit?.consentText"/>
      <input-check
        id="hasGivenConsent"
        v-model="hasGivenConsent"
        class="checkbox"
        required
      >
        {{ biometricScreeningTestKit?.consentGivenText }}
      </input-check>
      <div class="button-wrapper">
        <a :href="biometricScreeningTestKit?.url" target="_blank">
          <button-primary :disabled="!hasGivenConsent">
            Request At-Home Test Kit
          </button-primary>
        </a>
      </div>
    </div>
  </popup-modal>
</template>

<script lang="ts" setup>
import TitleIcon from "@/vue/atoms/title-icon.vue";
import medicalKitIcon from "@/assets/img/icons/my-health/medical_kit.svg";
import type {BiometricScreeningTestKitDTO} from "@/ts/types/dto/biometric-screening.dto";
import {TestKitStatus} from "@/ts/types/dto/biometric-screening.dto";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import {computed, inject, ref} from "vue";
import InputCheck from "@/vue/atoms/input-check.vue";
import MarkdownIt from "@/vue/atoms/markdown-it.vue";
import type {Api} from "@/ts/classes/api";

const props = defineProps<{
  biometricScreeningTestKit: BiometricScreeningTestKitDTO | null;
}>();

const isConsentModalVisible = ref<boolean>(false);
const hasGivenConsent = ref<boolean>(false);

const $api = inject("$api")! as Api;

const isRequestButtonDisabled = computed(() => props.biometricScreeningTestKit?.status === TestKitStatus.unavailable);

const onClickRequest = () => {
  if (props.biometricScreeningTestKit?.status === TestKitStatus.available && Boolean(props.biometricScreeningTestKit.url)) {
    window.open(props.biometricScreeningTestKit.url, "_blank");
    $api.setBiometricScreeningTestKitVisited()
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          console.error("Failed to set biometric screening test kit as visited:", error);
        });
  } else if (props.biometricScreeningTestKit?.status === TestKitStatus.processing) {
    isConsentModalVisible.value = true;
  }
};

const onCloseModal = () => isConsentModalVisible.value = false;
</script>

<style lang="sass" scoped>
.biometric-screening-test-kit
  background-color: $color-white
  border-radius: 16px
  color: $color-primary-hc-navy-100
  display: flex
  flex-direction: column
  padding: 24px
  row-gap: 24px
  width: 447px

.title
  margin-bottom: 0

.text
  @include Roboto400
  color: $color-primary-hc-navy-100
  font-size: 16px
  letter-spacing: 0.1px
  line-height: 24px
  margin: 0

.phone
  white-space: nowrap

.bold
  font-weight: 700

.inner-modal
  max-width: 600px

.inner-modal > h2
  margin-top: 0

.button-wrapper
  margin-top: 16px
  display: flex
  justify-content: flex-end
</style>
