<template>
  <div :class="{'big-icon': big}">
    <squared-icon :icon-type="type"/>
  </div>
</template>

<script lang="ts" setup>
import SquaredIcon from "@/vue/atoms/squared-icon.vue";
import {computed} from "vue";
import type {ActivityIconType} from "@/ts/types/component/activity-card";

const props = defineProps<{
    type: ActivityIconType;
    big?: boolean;
}>();

const type = computed((): ActivityIconType => {
    return props.type ?? "generic";
});

</script>

<style lang="sass" scoped>
div
    color: $color-secondary-sea-foam-100
    width: 48px
    height: 48px

.big-icon
    width: 60px
    height: 60px
</style>
