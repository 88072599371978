<!-- eslint-disable max-len -->
<template>
  <hra-page :step="0">
    <div class="container">
      <img src="@/assets/img/info-icon.svg"/>
      <ul class="list">
        <li>This process will take about <span>3-5 minutes</span></li>
        <li>Progress <span>will not be saved</span> if you abandon it midway</li>
      </ul>
    </div>
    <div class="background">
      <div class="description">
        <div>
          This <span>Health Risk Assessment (HRA)</span> survey will ask you about your health and lifestyle habits.
          Please answer honestly. Your responses will help HealthCheck360 educate you about your health risks.
          <ul>
            <li>Your responses on this survey do not impact your HealthCheck360 score.</li>
            <li>
              This HRA and the report you will receive from HealthCheck360 is not intended to diagnose any diseases,
              illness or health conditions; it is an educational tool to help you understand your personal health risks.
            </li>
            <li>
              You should use the results to work with your personal health care provider for medical advice and prior
              to engaging in health-related programs.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <button-link :to="startHraQuiz" theme="dark" class="button">
      Start HRA
    </button-link>
  </hra-page>
</template>

<script lang="ts">
import {Component, Vue, Prop} from "vue-property-decorator";
import HraPage from "@/vue/templates/hra-page.vue";
import ButtonLink from "@/vue/atoms/button-link.vue";
import type {HRASurvey} from "@/ts/types/dto/health-risk-assessment.dto";
import {getQuestionLink} from "@/ts/utils/hra/common";

@Component({
  name: "HraStartPage",
  components: {HraPage, ButtonLink},
})
export default class HraStartPage extends Vue {
  @Prop()
  hraResponse!: HRASurvey;

  get startHraQuiz(): string {
    const [{id: questionId, type: questionType}] = this.hraResponse.questions;
    return getQuestionLink({
      hraSurvey: this.hraResponse,
      questionId,
      questionType,
    });
  }
}
</script>

<style lang="sass" scoped>
div
  @include Roboto400
  color: $color-primary-hc-navy-100
  font-size: 16px

  span
    @include Roboto700

.background
  width: 100%

.container
  display: flex
  position: absolute
  flex-direction: row
  align-items: center
  font-size: 14px
  margin: 1rem
  background-color: $color-status-info-blue-10
  padding: 0 10px
  border-radius: 8px

  img
    width: 20px
    height: 20px

.description
  display: flex
  flex-direction: row
  justify-content: center
  background-color: $color-neutral-platinum-40
  padding: 2rem 15rem
  line-height: 24px

  img
    padding-right: 40px

  li
    margin-top: 1.5rem

.button
  display: flex
  justify-content: center
  margin-top: 10rem
</style>
