<template>
  <div class="container">
    <Tooltip
      v-if="doHra"
      text="Please complete your Health Risk Assessment before scheduling a screening."
      class="tip"
    />
    <button-link
      v-if="showButton"
      theme="dark"
      class="large"
      :disabled="buttonDisabled || doHra"
      :to="schedulerLink"
    >
      Schedule a Screening
    </button-link>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watchEffect } from "vue"
import ButtonLink from "@/vue/atoms/button-link.vue"
import Tooltip from "@/vue/molecules/tooltip.vue"
import type { MyProgramResponse } from "@/ts/types/dto/program.dto"
import type {
  BiometricScreeningAppointment,
  BiometricScreeningAvailable,
  BiometricScreeningScheduler,
  BiometricScreeningStatus,
} from "@/ts/types/dto/biometric-screening.dto"
import { hasProgramBioscreeningAvailable } from "@/ts/utils/my-health/program-bioscreening"
import {
  isBioscreeningUnavailable,
  isBioscreeningSubmitted,
  isAppointmentScheduled,
} from "@/ts/utils/my-health/bioscreening"
import type { Api } from "@/ts/classes/api"
import type { PagesPath } from "@/ts/router/pages-path"
import { inject } from "vue"

const $api = inject("$api") as Api
const $pagesPath = inject<PagesPath>("$pagesPath")!

const props = defineProps<{
  biometricStatus: BiometricScreeningStatus | null
  appointment: BiometricScreeningAppointment | null
  program: MyProgramResponse
  biometricAvailable: BiometricScreeningAvailable | null
  hraComplete?: boolean
}>()

const doHra = ref(false)
const schedulerLink = ref<string>("")

const buttonDisabled = computed((): boolean => {
  const unavailable = isBioscreeningUnavailable({
    biometricAvailable: props.biometricAvailable,
    biometricStatus: props.biometricStatus,
    appointment: props.appointment,
  })
  const hasProgramBioscreeningUnavailable = !hasProgramBioscreeningAvailable(props.program)
  return hasProgramBioscreeningUnavailable || unavailable || !schedulerLink.value
})

const showButton = computed((): boolean => {
  const isBioscreeningNotSubmitted = !isBioscreeningSubmitted(props.biometricStatus)
  const isNoAppointmentScheduled = !isAppointmentScheduled(props.appointment)
  return isBioscreeningNotSubmitted && isNoAppointmentScheduled
})

watchEffect(() => {
  if (showButton.value) {
    void (async () => {
      let schedulerInfo: BiometricScreeningScheduler = {
        SSOUrl: "",
        sourceType: "InternalRequest",
        enforceHra: false,
      }
      try {
        schedulerInfo = (await $api.getBiometricScreeningScheduleStart()) ?? schedulerInfo
      } finally {
        if (schedulerInfo.sourceType === "External" && schedulerInfo.url) {
          schedulerLink.value = schedulerInfo.url
        } else if (schedulerInfo.sourceType === "SSO") {
          schedulerLink.value = schedulerInfo.SSOUrl
        } else {
          schedulerLink.value = $pagesPath.biometricScheduling.personalInformation
        }
        doHra.value = schedulerInfo.enforceHra && !props.hraComplete
      }
    })()
  }
})
</script>

<style lang="sass" scoped>
.large
  font-size: 18px

.container
  display: inline-flex
  align-items: center

.tip
  margin-right: 10px
</style>
