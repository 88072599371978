<template>
  <card-item class="card-item" @click="navigateToPathway">
    <protected-svg
      v-if="pathway.categoryIcon && pathway.categoryIcon.path"
      :src="pathway.categoryIcon.path" 
      size="large"
      :ariaHidden="false"
      alt="Pathway Icon"
    />
    <avatar-frame
      v-else
      :image="pathway.coverPath"
      :size="100"
      :alt="'Pathway Cover Image'"
    />
    <div class="content">
      <h1>{{ pathway.title }}</h1>
      <p v-if="statusLogic" class="legend">
        <pathway-label-with-icon :value="statusLogic.value" :icon-type="statusLogic.iconType"/>
      </p>
      <content-badges :content="pathway" />
    </div>
  </card-item>
</template>

<script lang="ts" setup>
import { useRouter, useRoute, RouteLocationRaw } from "vue-router";
import { computed, inject, onMounted, onUnmounted, ref } from "vue";
import CardItem from "@/vue/atoms/card-item.vue";
import type { PathwayFullDto } from "@/ts/types/dto/pathway.dto";
import { TodoStatus } from "@/ts/types/dto/pathway.dto";
import AvatarFrame from "@/vue/atoms/avatar-frame.vue";
import PathwayLabelWithIcon from "@/vue/atoms/pathway-label-with-icon.vue";
import { formatDateShortMonthName, formatDuration } from "@/ts/utils/date-pure-functions";
import type { PagesPath } from "@/ts/router/pages-path";
import ContentBadges from "@/vue/atoms/content-badges.vue";
import ProtectedSvg from "@/vue/atoms/protected-svg.vue";

type IconType = "completed" | "pending" | "viewed" | "waiting";

interface IStatus {
  value: string;
  iconType?: IconType;
}

const props = defineProps<{
  pathway: PathwayFullDto;
}>();

const router = useRouter();
const route = useRoute();
const $pagesPath = inject<PagesPath>("$pagesPath")!;
const clickedPathway = ref(false);

const to = computed((): string => {
  return $pagesPath.pathway.pathwayStart.replace(":id", props.pathway.id);
});

const navigateToPathway = () => {
  router.push(to.value);
  clickedPathway.value = true;
};

const isCompleted = computed((): boolean => {
  return props.pathway.status === TodoStatus.completed;
});

const statusLogic = computed((): IStatus | null => {
  if (props.pathway.status === TodoStatus.available) {
    const duration = formatDuration(props.pathway.duration);
    return { value: duration, iconType: "waiting" };
  }
  if (props.pathway.status === TodoStatus.completed) {
    const formattedDate = formatDateShortMonthName(props.pathway.lastUpdateTimestamp.toString());
    return { value: `Completed on ${formattedDate}`, iconType: "completed" };
  }
  if (props.pathway.status === TodoStatus.active) {
    if (props.pathway.lastSectionRead <= props.pathway.numberOfSections && props.pathway.lastSectionRead > 0) {
      return { value: `${props.pathway.lastSectionRead}/${props.pathway.numberOfSections} viewed`, iconType: "viewed" };
    }
    return { value: "Test pending", iconType: "pending" };
  }
  return null;
});

onMounted(() => {
  const storedPreviousPage = sessionStorage.getItem("previousPage");

  if (!storedPreviousPage && route.fullPath !== storedPreviousPage) {
    sessionStorage.setItem("previousPage", route.fullPath);
  }
});

onUnmounted(() => {
  if (!clickedPathway.value) {
    sessionStorage.removeItem("previousPage");
  }
});
</script>

<style lang="sass" scoped>
.card-item
  cursor: pointer

.content
  display: flex
  flex-direction: column
  margin-top: 5px
  position: relative
  align-items: center
  text-align: center

  p
    @include Roboto400
    font-size: 0.75rem
    margin: 0.5rem 0
    text-decoration: none

  h1
    @include Asap700
    color: $color-primary-hc-navy-100
    font-size: 1rem
    max-width: 300px
    /* stylelint-disable */
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    margin-top: 0
    margin-bottom: 5px

.legend
  color: $color-neutral-platinum-100
</style>