<template>
  <div>
    <card-item v-if="isHighlighted" class="card-box highlighted">
      <div class="task-type" @click="isOpen = true">
        <div class="icon-container">
        <tracking-icon :type="type" :big="false"/>
        <icon-chameleon class="checkmark"
            :icon="CheckIcon"
            color="customColor"
            alt="check icon"
            aria-hidden="true"
        />
        </div>
        <div class="task-text">
          {{ typeString }}
        </div>
        <div class="amount">
          <strong>{{ convertedAmountValue }} {{ unit }}</strong> today
        </div>
      </div>
      <div>
        <track-activity-modal
          v-model="isOpen"
          @tracking-updated="trackingUpdated"
          :type="type"
          :track-activity="typeString"
        />
      </div>
    </card-item>
    <card-item v-else class="card-box">
      <div class="task-type" @click="isOpen = true">
        <tracking-icon :type="type" :big="false"/>
        <div class="task-text">
          {{ typeString }}
        </div>
      </div>
      <div>
        <track-activity-modal
            v-model="isOpen"
            @tracking-updated="trackingUpdated"
            :type="type"
            :track-activity="typeString"
        />
      </div>
    </card-item>
  </div>
</template>

<script lang="ts" setup>
import CardItem from "@/vue/atoms/card-item.vue";
import TrackingIcon from "@/vue/atoms/tracking-icon.vue";
import TrackActivityModal from "@/vue/organisms/tracking/track-activity-modal.vue";
import {TaskTypeNames} from "@/ts/types/component/tracking-list";
import {computed, ref} from "vue";
import CheckIcon from "@/assets/img/checkmark.svg";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";
import {TaskType} from "@/ts/types/dto/activity.dto";
import {calculateSecToMinutes, convertHoursToSec, convertMinToSec} from "@/ts/utils/date-pure-functions";
import {
  calculateCmToMiles,
  calculateFlOzToMilliliter, calculateGramToPound,
  calculateMilesToCm, calculateMilliliterToFlOz,
  calculatePoundToGram
} from "@/ts/utils/pure-functions";
import {Emit} from "vue-property-decorator";

const props = defineProps<{
  type: string;
  isHighlighted: boolean
  amountValue: number
}>();

const emits = defineEmits(['tracking-updated']);

const isOpen = ref<boolean>(false);

const typeString = computed((): string => {
  return TaskTypeNames[props.type as keyof typeof TaskTypeNames];
});

//function to get the appropriate unit
 const unit = computed((): string => {
  switch (props.type) {
    case TaskType.biking_distance:
    case TaskType.hiking_distance:
    case TaskType.jogging_distance:
      return "miles";
    case TaskType.steps:
      return "steps";
    case TaskType.sleep_time:
      return "hours";
    case TaskType.nicotine_use:
      return "units";
    case TaskType.heart_rate:
      return "beats per minute";
    case TaskType.calorie_intake:
      return "calories";
    case TaskType.weight:
      return "lbs.";
    case TaskType.water_drinking_volume:
      return "fl.oz.";
    case TaskType.mood:
      return "mood";
    default:
      return "minutes";
  }
})

const convertedAmountValue = computed(() => {
  switch (unit.value) {
    case "minutes":
      return calculateSecToMinutes(props.amountValue);
    case "hours":
      return calculateSecToMinutes(props.amountValue)/60;
    case "miles":
      const tempVar = calculateCmToMiles(props.amountValue).toFixed(2);
      if(tempVar.endsWith(".00")){
        return calculateCmToMiles(props.amountValue).toFixed(0)
      }
      return tempVar;
    case "lbs.":
      const tempVar1 = calculateGramToPound(props.amountValue).toFixed(1);
      if(tempVar1.endsWith(".00")){
        return calculateGramToPound(props.amountValue).toFixed(0);
      }
      return tempVar1;
    case "fl.oz.":
      return calculateMilliliterToFlOz(props.amountValue);
    default: return props.amountValue;
  }

})

const trackingUpdated = () => {
   emits('tracking-updated');
}

</script>

<style lang="sass" scoped>
.task-type
  display: flex
  flex-direction: column
  justify-content: space-between

.task-text
  @include Asap700
  margin-top: 8px
  color: $color-primary-hc-navy-100
  font-size: 16px

.card-box
  padding: 1rem
  width: 140px
  min-height: 140px
  background-color: $color-neutral-platinum-40
  border-radius: 8px
  margin: 8px 16px 8px 0
  position: relative

.highlighted
  background-color: $color-neutral-platinum-100
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3)

.icon-container
  display: flex
  align-items: center

.checkmark
  height: 48px
  width: 48px
  margin-left: 20px

.amount
  margin-top: 8px
  color: $color-secondary-sea-foam-100
</style>
