<template>
  <box-row>
    <template #left>
      <div class="container">
        <div v-if="!canDo" class="task">
          <img :src="CheckSvg" />
        </div>
        <div>
          <p class="title">
            {{ typeString }}
          </p>
          <p class="text">
            {{ frequency }}
          </p>
        </div>
      </div>
    </template>
    <template v-if="props.goal.targetValue !== undefined" #right>
      <p class="title align-right">
        Goal
      </p>
      <p class="text align-right">
        <span class="progress-value">{{ formattedProgressValue }}&nbsp;</span>
        <span class="goal-value">/&nbsp;</span>
        <span class="goal-value">{{ formattedTargetValue }}</span>
      </p>
    </template>
  </box-row>
</template>

<script lang="ts" setup>
import BoxRow from "@/vue/atoms/box-row.vue";
import {TaskTypeNames} from "@/ts/types/component/tracking-list";
import {
  formatTaskAmountByTypeUnit,
} from "@/ts/utils/pure-functions";
import {computed} from "vue";
import type {ActivityTaskGoal} from "@/ts/types/dto/activity.dto";
import {TaskTargetValueStorageUnit, TaskType} from "@/ts/types/dto/activity.dto";
import CheckSvg from "@/assets/img/icon-check-w.svg";

const props = defineProps<{
  taskType: TaskType;
  goal: ActivityTaskGoal;
  frequency: string;
  canDo: boolean;
}>();

const typeString = computed((): string => {
  if (props.taskType === TaskType.general_physical_activity_time_or_steps) {
    if (props.goal.unit === TaskTargetValueStorageUnit.Seconds) {
      return "Minutes of Activity";
    }
    if (props.goal.unit === TaskTargetValueStorageUnit.Steps) {
      return "Steps";
    }
  }

  return TaskTypeNames[props.taskType];
});

const formattedProgressValue = computed((): string => {
  const val = formatTaskAmountByTypeUnit(props.goal.progress, props.goal.unit);
  return val
      /*
       * remove units, but not for time!
       * it's because time is displayed like '1h 15m'
       */
      .replace(" mi.", "")
      .replace(" steps", "")
      .replace(" fl oz", "")
      .replace(" lbs.", "");
});

const formattedTargetValue = computed((): string => {
  return formatTaskAmountByTypeUnit(props.goal.targetValue, props.goal.unit);
});

</script>

<style lang="sass" scoped>
.title
  @include Roboto700
  font-size: 16px
  line-height: 24px
  color: $color-primary-hc-navy-100
  margin: 0

.text
  @include Roboto400
  font-size: 14px
  line-height: 22px
  color: $color-primary-hc-navy-50
  margin: 0

.progress-value
  @include Roboto700
  font-size: 14px
  line-height: 22px
  color: $color-primary-hc-navy-100

.goal-value
  @include Roboto400
  font-size: 14px
  line-height: 22px
  color: $color-primary-hc-navy-100


.align-right
  text-align: right

.container
  display: flex
  gap: 6px
  align-items: center

.task
  background: $color-secondary-sea-foam-100
  border-radius: 16px
  display: flex
  align-items: center
  justify-content: center
  width: 30px
  height: 30px

  img
    width: 20px
</style>
