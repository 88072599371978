<template>
  <div :class="{'activity-card': true, 'disable': isCompleted}">
    <div v-if="isPopup" role="button" @click="onClickItemTitle">
      <todo-item-title :todo="todo" :is-completed="isCompleted" @is-expiring="handleIsExpiring"/>
    </div>
    <router-link v-else :to="to">
      <todo-item-title :todo="todo" :is-completed="isCompleted" @is-expiring="handleIsExpiring"/>
    </router-link>

    <div v-if="isCompleted" class="detail-list">
      <badge-rounded
        v-if="todo.reward"
        :value="rewardType"
        theme="dark"
        size="small"
      />
    </div>
    <div v-else class="detail-list">
      <badge-rounded
        v-if="todo.reward"
        :value="rewardType"
        theme="grey"
        size="small"
      />
    </div>
  </div>

  <coaching-popup
    v-if="isCoaching"
    v-model="isCoachingPopupVisible"
    :to="to"
    :is-gate-keeper="todo.isGateKeeper"
    @skip="skipTodo"
  />
  <div v-if="isCompleted">
    <activity-modal-completed
      v-if="isActivity && activity"
      v-model="isActivityPopupVisible"
      :is-from-to-do-page="true"
      :activity="activity"
      :activity-icon="iconType"
      :frequency="durationType"
    />
  </div>
  <div v-else>
    <activity-detail-modal
      v-if="isActivity && activity"
      v-model="isActivityPopupVisible"
      :is-from-to-do-page="true"
      :activity="activity"
      :activity-icon="iconType"
      :past-day-selected="isDateInPast"
      :frequency="durationType"
      @change-item="changeItem"
      @proof-sent="proofSent"
      @skip="skipTodo"
    />
  </div>
</template>

<script lang="ts" setup>
import {computed, inject, ref} from "vue";
import ActivityModalActive from "@/vue/organisms/activity-modals/activity-modal-active.vue";
import ActivityDetailModal from "@/vue/organisms/activity-modals/activity-detail-modal.vue";
import ActivityModalCompleted from "@/vue/organisms/activity-modals/activity-modal-completed.vue";
import type {TodoItemDto} from "@/ts/types/dto/todo.dto";
import {TodoItemStatus} from "@/ts/types/dto/todo.dto";
import {getRewardAmountFormatted} from "@/ts/utils/pure-functions";
import {ContentReferenceType} from "@/ts/types/dto/content-reference.dto";
import {RewardCompletionStatus} from "@/ts/types/dto/reward.dto";
import BadgeRounded from "@/vue/atoms/badge-rounded.vue";
import type {RouteLocationRaw} from "vue-router";
import TodoItemTitle from "@/vue/molecules/todo-item-title.vue";
import CoachingPopup from "@/vue/molecules/coaching-popup.vue";
import {pagesPath} from "@/ts/router/pages-path";
import type {ActivityIconType} from "@/ts/types/component/activity-card";
import {ActivityCardModel, KindOfActivity} from "@/ts/types/component/activity-card";
import type {Api} from "@/ts/classes/api";
import type {ActivityDTO, PlatformActivityDTO} from "@/ts/types/dto/activity.dto";
import {SubmissionFrequency} from "@/ts/types/dto/activity.dto";
import {isSameDay, today} from "@/ts/utils/date-pure-functions";

const $api: Api = inject("$api")! as Api;

const props = defineProps<{
  todo: TodoItemDto;
  day?: Date;
}>();

const emit = defineEmits(["changeItem", "proofSent", "skip", "update:modelValue"]);

const loading = ref(false);
const isCoachingPopupVisible = ref(false);
const isActivityPopupVisible = ref(false);
const isExpiring = ref(false);

const activity = ref<ActivityCardModel>();

const getPlatformActivityFromTodo = async(id: string): Promise<void> => {
  try {
    loading.value = true;
    const platformActivityByIdResponse: PlatformActivityDTO = await $api.getPlatformActivityById(id);

    if (platformActivityByIdResponse !== null) {
      activity.value = ActivityCardModel.createPlatformActivity(platformActivityByIdResponse);
    } else {
      console.error("Getting null for getPlatformActivityFromTodo");
    }
  } catch (error) {
    console.error("Error fetching activity:", error);
  } finally {
    loading.value = false;
  }
};

const getActivityFromTodo = async(id: string): Promise<void> => {
  try {
    loading.value = true;
    const activityByIdResponse: ActivityDTO = await $api.getActivityById(id);

    if (activityByIdResponse !== null) {
      activity.value = ActivityCardModel.createActivity(activityByIdResponse);
    } else {
      console.error("Getting null for getActivityFromTodo");
    }
  } catch (error) {
    console.error("Error fetching activity:", error);
  } finally {
    loading.value = false;
  }
};

const durationType = computed((): string => {
  if (activity.value?.submissionFrequency === SubmissionFrequency.once) {
    return "One time";
  } else if (
      activity.value?.submissionFrequency === SubmissionFrequency.week_7_times
  ) {
    return "Daily";
  } else if (
      activity.value?.submissionFrequency === SubmissionFrequency.week_3_times
  ) {
    return "3x per week";
  } else if (
      activity.value?.submissionFrequency === SubmissionFrequency.week_2_times
  ) {
    return "2x per week";
  }

  return "";
});

const iconType = computed((): ActivityIconType => {
  if (activity.value?.kindOf === KindOfActivity.platform) {
    return "platform";
  }
  return activity.value?.task?.type ?? "generic";
});

const isCompleted = computed(() => {
  return props.todo.status === TodoItemStatus.completed ||
    props.todo.status === TodoItemStatus.completed_for_the_week ||
    props.todo.reward?.completionValidation?.status === RewardCompletionStatus.accepted;
});

const isActivity = computed(() => {
  return props.todo.contentReference.type === ContentReferenceType.Activity ||
   props.todo.contentReference.type === ContentReferenceType.PlatformActivity;
});

const isCoaching = computed(() => {
  return props.todo.contentReference.type === ContentReferenceType.Coaching;
});

const isPopup = computed(() => {
  return isCoaching.value || isActivity.value;
});

const rewardType = computed(() => getRewardAmountFormatted(props.todo.reward));

const to = computed((): RouteLocationRaw | string => {
  const contentReferenceType = props.todo.contentReference.type;
  const contentReferenceId = props.todo.contentReference.id;

  switch (contentReferenceType) {
    case ContentReferenceType.Pathway:
      return `${pagesPath.pathway.pathwayStart.replace(":id", contentReferenceId)}`;
    case ContentReferenceType.Activity:
      return `${pagesPath.program.programActivities}?id=${contentReferenceId}`;
    case ContentReferenceType.PlatformActivity:
      return "";
    case ContentReferenceType.ChallengeLeaderboard:
    case ContentReferenceType.ChallengeMapRace:
    case ContentReferenceType.ChallengeRelayRace:
      return {
        path: `${pagesPath.challenge.challenge.replace(":id", contentReferenceId)}`,
        query: {type: contentReferenceType},
      };
    case ContentReferenceType.BiometricScreening:
    case ContentReferenceType.HealthRiskAssessment:
      return pagesPath.myHealthPath.myhealthCheckScore;
    case ContentReferenceType.Coaching:
      return pagesPath.coaching.info;
    case ContentReferenceType.NicotineAttestation:
      return pagesPath.nicotineAttestation.start;
    default:
      throw new Error("Unsupported item");
  }
});

const isDateInPast = computed((): boolean | undefined => {
  return props?.day && !isSameDay(props.day,today())
})
const changeItem = (item: ActivityCardModel): void => {
  emit("changeItem", item);
  emit("update:modelValue", false);
};

const proofSent = (id: string): void => {
  emit("proofSent", id);
  emit("update:modelValue", false);
};

const skipTodo = (): void => {
  emit("skip");
};

const onClickItemTitle = async () => {
  if (isCoaching.value) {
    isCoachingPopupVisible.value = true;
  }
  if (isActivity.value) {
    const {id} = props.todo;
    if (props.todo.contentReference.type === ContentReferenceType.Activity) {
      await getActivityFromTodo(id);
    } else {
      await getPlatformActivityFromTodo(id);
    }
    isActivityPopupVisible.value = true;
  }
};

const handleIsExpiring = (value: boolean): void => {
  isExpiring.value = value;
}
</script>

<style lang="sass" scoped>
.activity-card
  align-items: center
  background-color: $color-white
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  justify-content: space-between
  padding: 20px 32px
  width: 100%
  margin-bottom: 12px
  text-decoration: none
  border-radius: 16px
  border-bottom: 1.5px solid #E6E6E6
  background: rgba(255, 255, 255, 0.75)

  > a
    text-decoration: none

.disable
  filter: opacity(0.5)

.complete-button
  background: none
  color: inherit
  border: none
  padding: 0
  font: inherit
  cursor: pointer
  outline: inherit

  &:hover
    filter: brightness(0) invert(0.2)

.points
  background: $color-neutral-platinum-40
  border-radius: 16px
  @include Roboto600
  font-size: 12px
  color: $color-secondary-sea-foam-100
  display: flex
  padding: 6px 8px
  justify-content: center
  width: 5rem

.detail-list
  display: flex
  gap: 24px
  justify-content: space-between
  align-items: center
</style>
