<template>
  <box-row>
    <template #left>
      <p class="title">Reward</p>
    </template>
    <template #right>
      <badged-rounded v-if="reward?.amount" :value="rewardFormatted" theme="navy"/>
    </template>
  </box-row>
</template>

<script lang="ts" setup>
import { computed, defineProps } from "vue";
import BoxRow from "@/vue/atoms/box-row.vue";
import BadgedRounded from "@/vue/atoms/badge-rounded.vue";
import type { Reward, RewardValidation } from "@/ts/types/dto/reward.dto";
import { getRewardAmountFormatted } from "@/ts/utils/pure-functions";

const props = defineProps<{
  reward: Reward | RewardValidation;
}>();

const rewardFormatted = computed(() => 
  getRewardAmountFormatted(props.reward ? { amount: props.reward.amount, type: props.reward.type } : null)
);
</script>

<style lang="sass" scoped>
.title
  @include Roboto700
  font-size: 16px
  line-height: 24px
  color: $color-primary-hc-navy-100
  margin: 0
</style>