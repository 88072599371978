<template>
  <box-row>
    <template #left>
      <div class="left">
        <img src="@/assets/img/check-mark-finished.svg"/>
        <p>
          {{ providerLabel }}
        </p>
      </div>
    </template>
    <template #right>
      <p class="text align-right">
        <b>{{ amount }}</b>
      </p>
    </template>
  </box-row>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import BoxRow from "@/vue/atoms/box-row.vue";
import {TrackingProvider} from "@/ts/types/dto/tracking.dto";
import {formatTaskAmountByType} from "@/ts/utils/pure-functions";

@Component({
  name: "ActivityLoggingHistory",
  components: {
    BoxRow,
  },
})

export default class ActivityLoggingHistory extends Vue {
  @Prop() provider!: TrackingProvider;

  @Prop() amount!: string;

  get providerLabel(): string {
    let label;
    switch (this.provider) {
      case TrackingProvider.manual:
        label = "Manual";
        break;
      case TrackingProvider.appleHealth:
        label = "Apple Health";
        break;
      case TrackingProvider.googleFit:
        label = "Google Fit";
        break;
      case TrackingProvider.fitbit:
        label = "Fitbit";
        break;
        case TrackingProvider.garmin:
        label = "Garmin";
        break;
      default:
        label = "Unknown source";
    }
    return label;
  }
}
</script>

<style lang="sass" scoped>

.left
  display: flex
  flex-direction: row
  gap: 10px

.history
  @include Asap700
  font-size: 20px
  color: $color-primary-hc-navy-100
  border-bottom: 1px solid $color-neutral-platinum-40
</style>
