<template>
  <popup-modal :model-value="displayed" @update:model-value="closePopup">
    <div class="content">
      <h3 class="title">
        Welcome!
      </h3>
      <p class="text">
        Please enter your <span>company code</span>{{ props.from == 'sso' ? " or" : "" }} <span>{{ props.from == 'sso' ? "email" : "" }}</span> to begin your registration process.
      </p>
      <form @submit.prevent="checkCompanyCode">
        <field-set id="companyCode" class="label" :label="popupLabel">
          <edit-company-code-input v-if="props.from != 'sso'" v-model="companyCode"/>
          <input-text
            v-else
            id="company-code"
            :model-value="inputForSso"
            placeholder="Enter your company code or email"
            :validity="validity"
            :error-line="validity"
            @input="handleInput"
          />
        </field-set>
        <error-text :error="error"/>
        <p class="forgot">
          Forgot your company code?
          <span @click="showForgotPopup">Contact support here.</span>
        </p>
        <button-primary
          class="button"
          :loading="loading"
          type="submit"
          :text="from == 'sso' ? 'Continue' :'Begin Registration' "
          theme="dark"
          @click="checkCompanyCode"
        />
      </form>
    </div>
  </popup-modal>

  <static-forgot-popup :displayed="isForgotPopupDisplayed" forgot-company-code="true" @close-popup="hideForgotPopup"/>
</template>

<script lang="ts" setup>
import {ref, inject, watch, onMounted} from "vue";
import {useRouter} from "vue-router";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import FieldSet from "@/vue/molecules/field-set.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import EditCompanyCodeInput from "@/vue/molecules/edit-field/edit-company-code-input.vue";
import StaticForgotPopup from "@/vue/organisms/static-forgot-popup.vue";
import type {Api} from "@/ts/classes/api";
import type {CompanyCodeDTO, CompanySSODTO} from "@/ts/types/dto/company-code.dto";
import InputText from "@/vue/atoms/input-text.vue";
import debounce from "lodash.debounce";

const $api = inject("$api")! as Api;

const props = defineProps({
  displayed: Boolean,
  from: String,
});

const emit = defineEmits(["closePopup"]);

const companyCode = ref("");
const hideGender = ref(false);
const email = ref("");
const inputForSso = ref(""); // this is pretty much just used as a holder
const loading = ref(false);
const error = ref("");
const isForgotPopupDisplayed = ref(false);
const validity = ref("");
const popupLabel = ref("My Company Code");
const router = useRouter();

onMounted(() => {
  if (props.from == "sso") {
    popupLabel.value = "My Company Code or Email";
  }
});

// Watch the 'displayed' prop for changes
watch(() => props.displayed, (newVal, oldVal) => {
  if (!newVal) {
    companyCode.value = "";
    error.value = "";
  }
});

const showForgotPopup = () => {
  isForgotPopupDisplayed.value = true;
};

const hideForgotPopup = () => {
  isForgotPopupDisplayed.value = false;
};

const closePopup = () => {
  emit("closePopup");
};
const validateSso = (input: string) => {
  inputForSso.value = input;
  if (input.indexOf("@") > 0) {
    email.value = input;
    inputForSso.value = email.value;
    companyCode.value = "";
    validity.value = "";
  } else {
    if (input.length > 5) {
      validity.value = "Your company code must be 5 characters!";
      return;
    }
    input = input.toUpperCase();
    companyCode.value = input;
    inputForSso.value = companyCode.value;
    email.value = "";
    validity.value = "";
  }
};
const handleInput = debounce(async(event: Event) => {
  const input = event.target as HTMLInputElement;
  validateSso(input.value);
}, 2000);
const checkCompanyCode = async() => {
  error.value = "";
  if (props.from == "sso") {
    /*
     * this makes sure that the debounce fn runs immediately if it was waiting
     * i.e the user pasted their email and quickly clicked enter
     */
    handleInput.flush();
    validateSso(inputForSso.value);
    if (validity.value.length != 0) {
      return;
    }
  }

  loading.value = true;
  try {
    let route = "/auth/sign-up/verification";
    if (props.from == "sso") {
      const payload: CompanySSODTO = {
        companyCode: companyCode.value,
        email: email.value,
      };
      const res = await $api.checkCompanySso(payload);
      if (typeof res === "number") {
        error.value = "Your company does not use SSO. Please login with your username and password you made upon registration, or register an account.";
        return;
      }
      route = `/auth/redirect?provider=${res.companyCode}`;
      hideGender.value = res.hideGender == undefined || res.hideGender == false   ? false : true;
    } else {
      const payload: CompanyCodeDTO = {
        CompanyCode: companyCode.value,
      };
      const res = await $api.checkCompanyCode(payload);
      if (typeof res === "number") {
        error.value = "Your company uses SSO. Please return back to the login page and select 'Login With Company SSO'";
        return;
      }
      hideGender.value = res.hideGender == undefined || res.hideGender == false   ? false : true;
    }
    localStorage.setItem("companyCode", companyCode.value); // todo, might need this if the user used email
    localStorage.setItem("hideGender", `${hideGender.value}`);
    router.push(route);
  } catch (e: any) {
    error.value = e.message;
  } finally {
    loading.value = false;
  }
};
</script>

<style lang="sass" scoped>
.forgot
  @include Roboto400
  color: $color-primary-hc-navy-100
  font-size: 14px
  line-height: 22px
  letter-spacing: 0.2px

  span
    @include Roboto600
    color: $color-secondary-sea-foam-100

    &:hover
      text-decoration: underline
      cursor: pointer

.content
    display: flex
    flex-direction: column
    max-width: 652px

.title
    @include Asap700
    color: $color-primary-hc-navy-100
    font-size: 28px
    line-height: 36px
    margin-top: 0
    margin-bottom: 16px

.text
    @include Roboto400
    color: $color-primary-hc-navy-100
    font-size: 16px
    line-height: 24px
    letter-spacing: 0.1px

span
    @include Roboto700

form
    display: flex
    flex-direction: column
    margin-top: 40px

.label
    font-size: 16px
    line-height: 22px
    letter-spacing: 0.15px

.button
    margin-top: 48px
    align-self: flex-end

img
    align-self: center
    height: 132px
    width: 183px
</style>
