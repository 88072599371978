<template>
  <form class="form" @submit.prevent="createPost">
    <h2>Create post</h2>

    <h4>Description</h4>
    <input-textarea
      id="message"
      v-model="post.message"
      name="message"
      class="textarea"
      placeholder="Message"
      :height="100"
      required
    />

    <h4 class="featured-image">Featured image <span>(optional)</span></h4>
    <div class="image-container">
      <div v-if="!isStep2">
        <div class="placeholder-img">
          <img src="@/assets/img/profile.svg" aria-hidden="true"/>
        </div>

        <input
          id="upload-file"
          type="file"
          name="upload-file"
          accept="image/*"
          hidden
          :disabled="Boolean(file)"
          @change="onFileChanged"
        />
        <label for="upload-file" refs="upload-file" class="file-picker-btn">
          <img src="@/assets/img/upload-icon.svg" aria-hidden="true"/> Choose a Photo
        </label>
      </div>

      <div v-else>
        <avatar-frame
          :fallback-image="fileSrc"
          :size="100"
        />
      </div>
    </div>

    <div class="submit-button">
      <button-primary
        type="submit"
        text="Create"
        :loading="isPostCreationInFlight"
        flexibleWidth
      />
    </div>
  </form>
</template>

<script lang="ts" setup>
import {computed, inject, ref} from "vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import {GroupPostCreate} from "@/ts/types/dto/group.dto";
import {Api} from "@/ts/classes/api";
import AvatarFrame from "@/vue/atoms/avatar-frame.vue";
import InputTextarea from "@/vue/atoms/input-textarea.vue";

const $api: Api = inject("$api")! as Api

const file = ref<File>();
const fileSrc = ref<string>();

const uploadInProgress = ref<boolean>(false);

const props = defineProps<{
  userGroupId: string;
}>();

const emit = defineEmits(["postCreated"]);

const post = ref<GroupPostCreate>({imagePath: '', message: ''})

const isPostCreationInFlight = ref(false)

const createPost = (async () => {
  isPostCreationInFlight.value = true
  try {
    if (!!fileSrc.value) {
      const {path} = await $api.uploadGroupImage(file.value!);
      post.value.imagePath = path
    }
    await $api.createGroupPost(props.userGroupId, post.value)
  } catch (e) {
    console.error(e)
  } finally {
    isPostCreationInFlight.value = false
    emit("postCreated")
  }
})

// eslint-disable-next-line id-length, @typescript-eslint/no-unsafe-member-access
const onFileChanged = (e: any): void => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const {files} = e.target as HTMLInputElement;

  // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
  file.value = files?.[0] as File;
  const fr = new FileReader();
  fr.onload = () => {
    fileSrc.value = fr.result as string;
  };
  fr.readAsDataURL(file.value);
};

const onCloseUploadDialog = (): void => {
  //isUploadDialogOpened.value = false;
  // eslint-disable-next-line no-undefined
  file.value = undefined;
  // eslint-disable-next-line no-undefined
  fileSrc.value = undefined;
};

const isStep2 = computed(() => {
  return Boolean(fileSrc.value);
});

</script>

<style lang="sass" scoped>
.form
  min-width: 500px

  h2
    margin-top: 0

.featured-image
  span
    color: $color-primary-hc-navy-50

.image-container, .placeholder-img
  display: flex
  justify-content: center

.file-picker-btn
  border-radius: 8px
  cursor: pointer
  display: flex
  justify-content: center
  align-items: center
  @include Asap600
  font-size: 17px
  font-style: normal
  font-weight: 600
  gap: 8px
  width: 260px
  height: 45px
  line-height: 3px
  padding: 12px 0
  border: none
  background-color: $color-primary-hc-navy-100
  color: $color-white

.submit-button
  display: flex
  justify-content: end
  padding-top: 24px

</style>


