<template>
  <div class="select">
    <div :class="['tabs-wrapper', { shrink: isShrunk }]">
      <tabs-navigation :items="links"/>
    </div>
  </div>
  <router-view/>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import TabsNavigation from "@/vue/atoms/tabs-navigation.vue";
import type {Tab} from "@/ts/types/component/tabs-navigation";
import {UserPointCapTotalsDTO} from "@/ts/types/dto/reward.dto";

@Component({
  name: "PointCapPageSelect",
  components: {
    TabsNavigation,
  },
})
export default class PointCapPageSelect extends Vue {
  @Prop({ type: Array, required: true }) readonly ids!: UserPointCapTotalsDTO[];

  isShrunk = false;

  get links(): Tab[] {
    return this.ids.map(item => ({
      link: `${this.$pagesPath.pointCap.pointCapItems.replace(':id', item.pointCapRuleId)}`,
      title: `${item.pointCapRule.name}`,
    })).sort((a, b) => a.title.localeCompare(b.title));
  }

  handleScroll() {
    this.isShrunk = window.pageYOffset > 80;
  }

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  }

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
</script>

<style lang="sass" scoped>
.tabs-wrapper
  display: flex
  overflow-x: auto
  white-space: nowrap
  width: 100%
  max-width: calc(100vw - var(--sidebar-width, 255px))
  justify-content: flex-start
  scroll-behavior: smooth
  padding-bottom: 0.5rem
  position: relative
  z-index: 1
  margin-left: 70px
  &::-webkit-scrollbar
    height: 6px
  &::-webkit-scrollbar-thumb
    background: $color-primary-hc-blue-50
    border-radius: 10px
    transition: background 0.3s ease, height 0.2s ease
  &::-webkit-scrollbar-thumb:hover
    background: darken($color-primary-hc-blue-50, 15%)
  &::-webkit-scrollbar-track
    background: rgba(0, 0, 0, 0.1)

.tabs
  display: flex
  gap: 2rem
  border-bottom: none
  width: max-content
  align-items: center
  flex-shrink: 0

@media (min-width: 1800px)
  .tabs-wrapper
    margin-left: 150px
    max-width: calc(100vw - 255px)

  .tabs-wrapper.shrink
    margin-left: 0px

</style>
