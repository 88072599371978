<template>
  <section class="main">
    <div class="content">
      <div class="details">
        <div class="title">
          <markdown-it :text="program.name"/>
        </div>
        <program-details :program="program" :points-info="rewardTracker?.currentProgramPointsInfo"/>
      </div>
      <div v-if="coverPath" class="image-container">
        <protected-image 
            :src="coverPath"
            :ariaHidden="false"
            alt="Program Cover"
            :size-width="600"
            :height="450"
            :resize="true"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { MyProgramResponse } from "@/ts/types/dto/program.dto";
import type { RewardTrackerResponse } from "@/ts/types/dto/reward-tracker";
import ProgramDetails from "@/vue/molecules/program-details.vue";
import MarkdownIt from "@/vue/atoms/markdown-it.vue";
import ProtectedImage from "@/vue/atoms/protected-image.vue";
import { computed, defineProps } from "vue";

const props = defineProps<{
  program: MyProgramResponse;
  rewardTracker?: RewardTrackerResponse | null;
}>();

const coverPath = computed(() => props.program.coverPath);
</script>

<style lang="sass" scoped>
.main
  background: white
  border-radius: 16px
  box-shadow: $content-shadow
  padding-left: 24px
  display: flex
  margin: 58px auto
  max-width: 1320px
  min-width: 300px
  opacity: 0
  transform: scale(0.95)
  animation: fadeScaleIn 0.5s ease-out forwards

@keyframes fadeScaleIn
  from
    opacity: 0
    transform: scale(0.95)
  to
    opacity: 1
    transform: scale(1)

.content
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%

.details
  display: flex
  flex-direction: column
  align-self: flex-start
  margin-bottom: 24px

.title
  font-size: 24px
  font-weight: 700
  color: $color-primary-hc-navy-100

.image-container
  flex-shrink: 0
  padding: 20px
  transition: transform 0.3s ease
  &:hover
    transform: rotateX(5deg) rotateY(5deg) scale(1.02)

.cover-image
  max-width: 180px
  border-radius: 8px

@media (max-width: 1500px)
    .main
        margin: 58px

    .image-container
        transform: scale(0.8)

@media (max-width: 1200px)
  .content
    flex-direction: column

  .details
    align-self: center

</style>