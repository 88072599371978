<template>
  <div>
    <div :class="['nav-bar', { shrink: isShrunk }]" ref="navbar">
      <side-bar-menu />
      <div class="nav-content">
        <div v-if="!(isShrunk && hideLeftSection)" class="left-section">
          <router-link v-if="backLink" :to="backLink" class="button-go-back">
            <img src="@/assets/img/left-white.svg" alt="Go back" />
          </router-link>
          <reward-points v-if="rewardPoints" class="rewards-points" />
        </div>
        <div class="center-section" :class="{ hidden: isShrunk && hasTabs }">
          <h2 v-if="subtitle" :class="subtitleClass">{{ subtitle }}</h2>
          <h1 v-if="title">
            <markdown-it :text="title" />
          </h1>
        </div>
        <div v-if="hasTabs" class="tabs-inside">
          <slot name="tabs" />
        </div>
        <div v-if="!(isShrunk && hideRightOnScroll)" class="right-section">
          <profile-and-notifications v-if="profileSection" class="notifications" />
          <router-link v-if="closeLink" :to="$pagesPath.main.home" class="close-btn">
            <img src="@/assets/img/x-mark.svg" alt="close" />
          </router-link>
        </div>
      </div>

    </div>
    <div v-if="hasTabs" class="tabs-outside" :class="{ hidden: isShrunk }">
      <slot name="tabs" />
    </div>
    <div :class="slotClass">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator"
import SideBarMenu from "@/vue/organisms/side-bar-menu.vue"
import ProfileAndNotifications from "@/vue/molecules/profile-and-notifications.vue"
import RewardPoints from "@/vue/molecules/reward-points.vue"
import MarkdownIt from "@/vue/atoms/markdown-it.vue"

@Component({
  components: { SideBarMenu, ProfileAndNotifications, RewardPoints, MarkdownIt }
})
export default class NavbarMain extends Vue {
  @Prop() title!: string
  @Prop() subtitle!: string
  @Prop({ default: "" }) backLink!: string
  @Prop({ default: false }) closeLink!: boolean
  @Prop({ default: false }) profileSection!: boolean
  @Prop({ default: false }) rewardPoints!: boolean
  @Prop({ default: "pink" }) theme!: "orange" | "pink"
  @Prop({ default: "" }) slotCustom!: string
  @Prop({ default: false }) hasTabs!: boolean
  @Prop({ default: false }) hideLeftSection!: boolean;
  @Prop({ default: false }) hideRightOnScroll!: boolean;

  isShrunk = false

  mounted() {
    window.addEventListener("scroll", this.handleScroll)
  }

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll() {
    this.isShrunk = window.pageYOffset > 80
  }

  get subtitleClass(): string {
    return `subtitle ${this.theme}`
  }

  get slotClass(): string {
    return `slot ${this.slotCustom}`
  }
}
</script>

<style lang="sass" scoped>
.nav-bar
  display: flex
  width: 100%
  justify-content: space-between
  align-items: center
  position: fixed
  top: 0
  left: 0
  right: 0
  transition: all 0.3s ease-in-out
  height: 100px
  z-index: 1000
  padding: 0 20px
  padding-left: 120px
  background-color: #1b2d3b

.nav-bar.shrink
  height: 60px

.nav-content
  display: flex
  width: 100%
  justify-content: space-between
  align-items: center

.left-section
  display: flex
  align-items: center
  min-width: 160px
  gap: 15px

.center-section
  flex-grow: 1
  text-align: center

h1
  font-size: 28px
  margin: 0
  @include Asap700
  color: $color-white
  transition: font-size 0.3s ease-in-out

h2
  font-size: 16px
  text-transform: uppercase
  @include Roboto600
  transition: font-size 0.3s ease-in-out

.nav-bar.shrink h1
  font-size: 22px

.nav-bar.shrink h2
  font-size: 14px

.right-section
  display: flex
  align-items: center
  gap: 15px

.close-btn
  cursor: pointer

.rewards-points
  display: flex
  align-items: center
  gap: 5px
  transition: transform 0.3s ease-in-out

.button-go-back
  display: flex
  align-items: center
  transition: transform 0.3s ease-in-out

.notifications
  display: flex
  align-items: center

.slot
  transition: margin-left 0.1s ease-in-out
  margin-top: 100px

.nav-bar.shrink + .slot
  margin-top: 60px

.hidden
  display: none

.tabs-inside
  display: none
  width: 100%
  transition: all 0.3s ease-in-out

.nav-bar.shrink .tabs-inside
  display: block

.tabs-outside
  display: block
  width: 100%
  transition: all 0.3s ease-in-out
  margin-top: 100px

@media (min-width: 1800px)
  .nav-bar
    padding-left: 310px
</style>