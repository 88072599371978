<template>
  <card-row>
    <template #header>
      <headline-with-counter :title="title" :amount="pathways.length"/>
    </template>

    <template #filter>
      <div v-if="categories && categories.length > 0 && filterKey">
        <program-filter :categories="categories" @applyFilter="applyFilter" :filterKey="filterKey">
          <template #trigger>
            <button class="icon">
              <icon-chameleon color="primary100" hover-color="primary100" :icon="SvgFilter" />
            </button>
          </template>
        </program-filter>
      </div>
    </template>

    <template #extraButton>
      <button class="icon" @click="togglePanel">
        <icon-chameleon
          color="primary100"
          hover-color="primary100"
          :icon="(showPanel ? DownChevron : RightChevron)"
        />
      </button>
    </template>

    <template #selectedFilters>
      <div v-for="filter in selectedFilters" @click="removeFilter(filter)">
        <category-badge
          :value="filter.name"
          :selected="true"
          :color="filter.color"
        />
      </div>
    </template>

    <expansion-panel :expanded="showPanel"> 
      <template #body>
        <pathway-card
          v-for="pathway in pathways"
          :key="pathway.id"
          :pathway="pathway"
        />
      </template>
    </expansion-panel>
  </card-row>
</template>
<script lang="ts" setup>
import {ref} from "vue";
import CardRow from "@/vue/molecules/card-row.vue";
import HeadlineWithCounter from "@/vue/atoms/headline-with-counter.vue";
import ProgramFilter from "@/vue/molecules/program-filter.vue";
import CategoryBadge from "@/vue/atoms/category-badge.vue";
import PathwayCard from "@/vue/molecules/pathway-card.vue";
import type {PathwayFullDto} from "@/ts/types/dto/pathway.dto";
import type {ContentCategory} from "@/ts/types/dto/program.dto";
import ExpansionPanel from "@/vue/atoms/expansion-panel.vue";
import RightChevron from "@/assets/img/right-chevron.svg";
import DownChevron from "@/assets/img/down-chevron.svg";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";
import SvgFilter from "@/assets/img/filter.svg";
const props = defineProps<{
  title: string;
  pathways: PathwayFullDto[];
  categories?: ContentCategory[];
  filterKey?: string;
}>();

const isFilterModalOpen = ref(false);
const selectedFilters = ref<ContentCategory[]>([]);
const showPanel = ref<boolean>(true);

const emit = defineEmits(["applyFilter"]);

const togglePanel = () => {
      showPanel.value = !showPanel.value;
};

const applyFilter = (selectedCategories: ContentCategory[]): void => {
  emit("applyFilter", selectedCategories.map(item => item.id));
  selectedFilters.value = selectedCategories;
  isFilterModalOpen.value = false;
};

const removeFilter = (filter: ContentCategory): void => {
  selectedFilters.value = selectedFilters.value.filter(item => item.id !== filter.id);
  if (props.filterKey) {
    localStorage.setItem(`selectedCategories_${props.filterKey}`, JSON.stringify(selectedFilters.value));
  }
  applyFilter(selectedFilters.value);
};

</script>

<style lang="sass" scoped>
.icon
  margin-bottom: 4px
  cursor: pointer
  border: none
  background: none
</style>
