<template>
  <div class="action-card">
    <div class="info">
      <div class="header">
        <h4>{{ title }}</h4>
        <tooltip v-if="tooltipText" :text="tooltipText"/>
      </div>
      <p>
        {{ description }}
      </p>
    </div>
    <div class="cards">
      <div v-for="action in actions" :key="action.name">
        <button v-if="action.isHealthContent" :class="['item', { 'success': action.gatekeeperItem?.status == 'completed'}]"  @click="action.handleClick">
          <squared-icon v-if="action.gatekeeperItem?.status == 'completed'" class="icon" icon-type="successWhite" />
          <nicotine-attestation-icon v-else-if="action.gatekeeperItem?.contentReference.type == ContentReferenceType.NicotineAttestation" class="icon"/>
          <squared-icon v-else class="icon" :image="action.icon" icon-type="generic"/>
          <label class="label">
            <span class="title">{{ action.name }}</span>
            <span class="text">{{ text(action) }}</span>
          </label>
        </button>

        <button v-else :class="['item', { 'success': action.gatekeeperItem?.status == 'completed'}]"  @click="action.handleClick(action.gatekeeperItem)">
          <squared-icon v-if="action.gatekeeperItem?.status == 'completed'" class="icon" icon-type="successWhite" />
          <squared-icon v-else class="icon" icon-type="gatekeeper"/>
          <nicotine-attestation-icon class="todo-icon"/>

          <label class="label">
            <span class="name">{{ action.name }}</span>
            <span class="end-date">{{ text(action) }}</span>
          </label>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import IconCard from "@/vue/atoms/icon-card.vue";
import type {Action} from "@/ts/types/component/action-card";
import {formatDateShortMonthName, formatDate, formatDateLongMonthName} from "@/ts/utils/date-pure-functions";
import Tooltip from "@/vue/molecules/tooltip.vue";
import SquaredIcon from "@/vue/atoms/squared-icon.vue";
import type {GatekeeperItem} from "@/ts/types/dto/program.dto";
import NicotineAttestationIcon from "@/vue/atoms/nicotine-attestation-icon.vue";
import {ContentReferenceType} from "@/ts/types/dto/content-reference.dto";

const props = defineProps<{
  title: string;
  description: string;
  actions: Action[];
  tooltipText?: string;
}>();

const text = (action: Action): string => {
  const {startDate, endDate} = action;

  if (action.gatekeeperItem?.completedDate && action.gatekeeperItem?.status === "completed") {
    return `Completed on: ${formatDateShortMonthName(action.gatekeeperItem.completedDate)}`;
  }

  if (startDate && endDate) {
    return `${formatDate(startDate)} - ${formatDate(endDate)}`;
  }
  if (startDate) {
    return `Start date: ${formatDateShortMonthName(startDate)}`;
  }
  if (endDate) {
    return `End date: ${formatDateShortMonthName(endDate)}`;
  }

  return "";
};
</script>

<style lang="sass" scoped>
.action-card
  background-color: $color-neutral-platinum-40
  display: flex
  flex-direction: column
  flex: 1

.info
  color: $color-primary-hc-navy-100

.header
  display: flex
  justify-content: space-between

h4
  @include Asap700
  font-size: 16px
  text-transform: capitalize
  margin: 0

p
  @include Roboto400
  font-size: 14px
  letter-spacing: 0.2px
  line-height: 22px
  margin-top: 8px

.cards
  display: flex
  flex-direction: column
  flex: 1
  gap: 8px

.item
  background-color: $color-white
  border-radius: 16px
  display: flex
  padding: 12px
  width: 100%
  border: none
  color: inherit
  cursor: pointer
  &.success
    background-color: $color-status-success-green-10

.icon
  width: 48px
  height: 48px

  &.activity
    color: $color-tertiary-turquoise-100

.label
  display: flex
  flex-direction: column
  justify-content: space-between
  margin-left: 10px
  align-items: baseline
  height: 42px
  cursor: inherit

.name
  grid-row: 1
  grid-column: 2
  @include Asap700
  color: $color-primary-hc-navy-100
  font-size: 16px
  line-height: 22px
  margin: 0

.title
  @include Asap700
  color: $color-primary-hc-navy-100
  font-size: 16px
  line-height: 22px
  margin: 4px 0

.text
  @include Roboto400
  color: $color-primary-hc-navy-50
  font-size: 12px
  line-height: 16px
  margin: 0 0 4px


.end-date
  grid-row: 2
  grid-column: 2
  @include Roboto400
  color: $color-primary-hc-navy-50
  font-size: 12px
  line-height: 16px
  margin: 0
</style>
