<template>
  <div class="position">
    <div>
      <position-medal :position="user.position"/>
    </div>
    <div class="avatar">
      <user-avatar
        :avatar="user.challengeParticipant.avatarPath"
        :is-me="userIsYou"
        :firstname="user.challengeParticipant.firstName"
        :lastname="user.challengeParticipant.lastName"
        :size="user.position !== 1 ? 'large' : 'xlarge'"
        :user-status="user.challengeParticipant.status"
      />
      <badge-rounded
        theme="dark"
        size="large"
        :value="rankingPosition(user.position)"
        class="position-badge"
      />
    </div>
    <div :class="{user: true, first: user.position === 1}">
      <p :class="{name: true, orange: userIsYou}">
        {{ userName }}
      </p>
      <p class="progress">
        {{ progress }}
      </p>
      <badge-rounded v-if="user.points > 0" theme="white" size="large" :value="`${user.points} PTS`"/>
    </div>
  </div>
</template>

<script lang="ts">
import {DefaultState} from "@/ts/store/default/default-store-instance";
import type {ChallengeWinner} from "@/ts/types/component/challenges";
import type {LeaderboardChallengeDTO} from "@/ts/types/dto/leaderboard-challenge.dto";
import {TaskTypeNames} from "@/ts/types/dto/leaderboard-challenge.dto";
import type {Profile} from "@/ts/types/store/default-store-types";
import {calculateSecToMinutes, suffix} from "@/ts/utils/date-pure-functions";
import BadgeRounded from "@/vue/atoms/badge-rounded.vue";
import PositionMedal from "@/vue/atoms/position-medal.vue";
import UserAvatar from "@/vue/atoms/user-avatar.vue";
import {Component, Prop, Vue} from "vue-property-decorator";
import {UserStatus} from "@/ts/types/dto/profile.dto";
import {formatTaskAmountByType} from "@/ts/utils/pure-functions";

@Component({
  name: "ChallengeWinnerPodium",
  components: {
    PositionMedal,
    UserAvatar,
    BadgeRounded,
  },
})
export default class ChallengeWinnerPodium extends Vue {
  @Prop() user!: ChallengeWinner;

  @DefaultState
  public profile!: Profile;

  @DefaultState
  leaderboardChallenge!: LeaderboardChallengeDTO;

  get userIsYou(): boolean {
    return this.user.challengeParticipant.id === this.profile.id;
  }

  get userName(): string {
    if (this.user.challengeParticipant.id === this.profile.id) {
      return "You";
    }

    if (this.user.challengeParticipant.status === UserStatus.anonymized) {
      return "Deleted Acct.";
    }

    return `${
      this.user.challengeParticipant.firstName
    } ${this.user.challengeParticipant.lastName.charAt(0)}.`;
  }

  get progress(): string {
    return formatTaskAmountByType(this.user.progress, this.leaderboardChallenge.taskType);
  }

  rankingPosition(position: number): string {
    return `${position}${suffix(Number(position))}`;
  }
}
</script>

<style lang="sass" scoped>

.position
  display: flex
  gap: 9px

.avatar
  display: flex
  flex-direction: column
  align-items: center

.position-badge
  position: relative
  bottom: 20px

.user
  display: flex
  flex-direction: column
  @include Roboto700
  align-items: center

.first
  margin: 26px 0 0

.name
  margin: 0
  font-size: 16px
  line-height: 24px
  color: $color-primary-hc-navy-100

.orange
  color: $color-tertiary-orange-sunset-100


.progress
  margin: 4px 0
  font-size: 14px
  line-height: 22px
  color: $color-secondary-sea-foam-100
</style>
