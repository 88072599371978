<template>
  <div class="fax-results">
    <header>
      <h1>Fax Bio-Screening to Physician</h1>
      <button class="button-back" @click="onClickClose">
        <img src="@/assets/img/x-mark.svg" alt="close"/>
      </button>
    </header>

    <loading-bar :steps="1" :current="0"/>

    <form class="fax-form" @submit.prevent="onSubmit">
      <div class="personal-information">
        <h3>Your Contact Information</h3>
        <field-set label="Phone Number">
          <input-text
            id="user-phone-number"
            v-model="bioScreeningFaxRequest.userPhoneNumber"
            placeholder="Phone Number"
            required
          />
        </field-set>
        <field-set label="Email">
          <input-text
            id="user-email"
            v-model="bioScreeningFaxRequest.userEmail"
            placeholder="Email"
          />
        </field-set>
        <field-set>
          <input-check
            id="include-email"
            v-model="bioScreeningFaxRequest.includeEmail"
          >
            <b>Include on Fax cover Sheet</b>
          </input-check>
        </field-set>
      </div>
      <div class="physician-information">
        <h3>Your Physician Information</h3>
        <field-set label="Physician or Primary Care Provider Name">
          <input-text
            id="physician-name"
            v-model="bioScreeningFaxRequest.physicianName"
            placeholder="Physician Name"
            required
          />
        </field-set>
        <field-set label="Physician Clinic">
          <input-text
            id="physician-clinic"
            v-model="bioScreeningFaxRequest.physicianClinic"
            placeholder="Physician Clinic"
            required
          />
        </field-set>
        <field-set label="Fax Number">
          <input-text
            id="physician-fax"
            v-model="bioScreeningFaxRequest.physicianFax"
            placeholder="Physician Fax"
            required
          />
        </field-set>
        <field-set label="Phone Number">
          <input-text
            id="physician-phone"
            v-model="bioScreeningFaxRequest.physicianPhone"
            placeholder="Physician Phone"
          />
        </field-set>
        <field-set>
          <input-check
            id="is-consent-given"
            v-model="bioScreeningFaxRequest.isConsentGiven"
          >
            <b>Be aware of privacy issues!</b>
          </input-check>
          <p>
            I have verified the fax number entered is accurate and acknowledge that my health
            screening results are being sent to an external third party.
          </p>
        </field-set>
      </div>
      <div class="status">
        <alert-icon-card
          class="info"
          :theme="statusTheme"
          :title="statusTitle"
          :text="bioScreeningFaxDetails?.faxErrorMessage"
        >
          <p v-if="bioScreeningFaxDetails?.faxStatusDate">
            <b>Updated: </b>{{ dateToFullFormat(bioScreeningFaxDetails.faxStatusDate) }}
          </p>
          <p v-if="bioScreeningFaxDetails?.requestDate">
            <b>Requested: </b>{{ dateToFullFormat(bioScreeningFaxDetails.requestDate) }}
          </p>
        </alert-icon-card>
      </div>
      <div class="actions">
        <div class="buttons">
          <button-primary v-if="showNewRequestButton" theme="white" @click="onClickNewRequest">
            <img src="@/assets/img/icons/test.svg" alt="fax results"/> New request
          </button-primary>
          <button-primary type="submit" :loading="loading">
            <img v-if="submitButton.icon" :src="submitButton.icon" alt="submit"/>
            {{ submitButton.label }}
          </button-primary>
        </div>
        <error-text :error="error"/>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import {computed, inject, onMounted, ref} from "vue";
import type {Api} from "@/ts/classes/api";
import LoadingBar from "@/vue/atoms/loading-bar.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import type {BiometricScreeningFaxDetail, BiometricScreeningFaxRequest} from "@/ts/types/dto/biometric-screening.dto";
import InputText from "@/vue/atoms/input-text.vue";
import FieldSet from "@/vue/molecules/field-set.vue";
import InputCheck from "@/vue/atoms/input-check.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import AlertIconCard from "@/vue/atoms/alert-icon-card.vue";
import {Theme} from "@/ts/types/component/alert-icon-card";
import {dateToFullFormat} from "@/ts/utils/date-pure-functions";
import reloadIcon from "@/assets/img/icons/reload.svg";

const $api = inject("$api")! as Api;

const loading = ref<boolean>(false);
const error = ref<string | null>(null);
let bioScreeningId = 0;
const bioScreeningFaxDetails = ref<BiometricScreeningFaxDetail | null>(null);
const bioScreeningFaxRequest = ref<BiometricScreeningFaxRequest>({
  includeEmail: false,
  isConsentGiven: true,
  physicianClinic: "",
  physicianFax: "",
  physicianName: "",
  physicianPhone: "",
  userEmail: "",
  userPhoneNumber: "",
});

onMounted(async() => {
  bioScreeningId = parseInt(window.router.currentRoute.value.params.id as string, 10);
  bioScreeningFaxDetails.value = await $api.getBiometricScreeningFaxDetails(bioScreeningId);
  bioScreeningFaxRequest.value = bioScreeningFaxDetails.value;
});

const fetchFaxDetails = async(): Promise<void> => {
  loading.value = true;
  error.value = "";
  try {
    bioScreeningFaxDetails.value = await $api.getBiometricScreeningFaxDetails(bioScreeningId);
  } catch (err) {
    error.value = err as string;
  } finally {
    loading.value = false;
  }
};

const onSubmit = async(): Promise<void> => {
  if (bioScreeningFaxDetails.value?.faxRequestLogId && !bioScreeningFaxDetails.value.isCompleted) {
    await fetchFaxDetails();
  } else {
    await postFaxRequest();
  }
};

const postFaxRequest = async(): Promise<void> => {
  loading.value = true;
  error.value = "";
  try {
    await $api.faxBiometricScreening(bioScreeningId, bioScreeningFaxRequest.value);
    bioScreeningFaxDetails.value = await $api.getBiometricScreeningFaxDetails(bioScreeningId);
    window.router.back();
  } catch (err) {
    error.value = err as string;
  } finally {
    loading.value = false;
  }
};

const onClickClose = (): void => {
  window.router.back();
};

const statusTheme = computed(() => {
  if (bioScreeningFaxDetails.value?.isError) {
    return Theme.error;
  }
  if (bioScreeningFaxDetails.value?.isCompleted) {
    return Theme.success;
  }
  return Theme.info;
});

const statusTitle = computed(() => `Status: ${bioScreeningFaxDetails.value ? bioScreeningFaxDetails.value.faxStatus : "n/a"}`);

const submitButton = computed((): {icon?: any; label: string} => {
  if (bioScreeningFaxDetails.value?.faxRequestLogId && !bioScreeningFaxDetails.value.isCompleted) {
    return {icon: reloadIcon,
label: "Refresh status"};
  }
  if (bioScreeningFaxDetails.value?.faxRequestLogId && bioScreeningFaxDetails.value.isCompleted) {
    return {icon: reloadIcon,
label: "Resubmit"};
  }
  return {label: "Submit"};
});

const showNewRequestButton = computed(() => bioScreeningFaxDetails.value?.faxRequestLogId && bioScreeningFaxDetails.value.isCompleted);

const onClickNewRequest = (): void => {
  bioScreeningFaxDetails.value = null;
  bioScreeningFaxRequest.value = {
    includeEmail: false,
    isConsentGiven: true,
    physicianClinic: "",
    physicianFax: "",
    physicianName: "",
    physicianPhone: "",
    userEmail: "",
    userPhoneNumber: "",
  };
};
</script>

<style lang="sass" scoped>
header
  @include Asap700
  color: $color-primary-hc-navy-100
  padding: 20px 70px
  display: flex
  justify-content: center
  align-items: center

h1,
h3
  text-align: center

.button-back
  cursor: pointer
  position: absolute
  right: 70px
  border: none
  background: none

.fax-form
  max-width: 920px
  margin: auto
  padding: 0 70px
  display: grid
  grid-template-columns: 1fr 1fr

.personal-information,
.physician-information
  padding: 22px
  display: flex
  flex-direction: column
  gap: 22px

.status,
.actions
  grid-column: span 2
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: 22px

.buttons
  display: flex

  > button:nth-child(2)
    margin-left: 22px

.info
  min-width: 260px
</style>
