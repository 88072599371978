import type {Pagination} from "@/ts/types/dto/pagination.dto";
import type {Department,
  Location,
UserStatus} from "@/ts/types/dto/profile.dto";
import type {Reward} from "@/ts/types/dto/reward.dto";
import {ContentCategoryIcon} from "@/ts/types/dto/program.dto";


export enum QuizStatus {
  completed = "completed",
  pending = "pending",
  none = "none",
}

export enum TodoStatus {
  available = "available",
  active = "active",
  completed = "completed",
}

export enum PathwayStatus {
  completed = "completed",
  pending = "pending",
  viewed = "viewed",
  waiting = "waiting",
}

export interface PathwayDTO {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  numberOfSections: number;
  lastSectionRead: number;
  quizStatus: QuizStatus;
  isEnabled: boolean;
  title: string;
  description: string;
  coverPath: string;
  status: TodoStatus;
}

export interface GetPathwaysRequestDTO {
  limit?: number;
  offset?: number;
  onlyActive?: boolean;
  onlyAvailable?: boolean;
  onlyCompleted?: boolean;
  categoryIds?: string;
}

export interface GetPathwaysResponseDTO {
  pagination: Pagination;
  items: PathwayFullDto[];
}

export interface GetPathwayRequestDTO {
  id: string;
}

export enum MediaProvider {
  image = "image",
  youtube = "youtube_video",
}

export interface PathwayMedia {
  mediaReference: string;
  mediaProvider: MediaProvider;
}

export interface Section {
  id: string;
  title: string;
  description: string;
  pathwayMedia: PathwayMedia;
}

export interface Option {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  quizQuestionId: string;
  text: string;
  isCorrectAnswer: boolean;
}

export enum QuestionType {
  single = "single",
  multiple = "multiple_choice",
}

export interface Question {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  stem: string;
  minOptions: number;
  maxOptions: number;
  questionType: QuestionType;
  quizId: string;
  options: Option[];
}

export interface SelectionOption {
  id: string;
}

export interface PostQuizRequestItemDTO {
  questionId: string;
  selectedOptions: SelectionOption[];
}

export type PostQuizRequestRequestDTO = PostQuizRequestItemDTO[];

export interface PostQuizResponse {
  passed: boolean;
}

export interface PutLastSectionReadRequestDTO {
  page: number;
}

export interface Quiz {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  pathwayId: string;
  rewardQualification: number;
  questions: Question[];
}

export interface Category {
  id: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}


export interface FinishedFriend {
  id: string;
  firstName: string;
  lastName: string;
  avatarPath: string;
  department: Department;
  location: Location;
  status: UserStatus;
}

export interface PathwayFullDto extends PathwayDTO {
  duration: number;
  startedTimestamp: Date;
  lastUpdateTimestamp: Date;
  sections: Section[];
  quiz?: Quiz;
  categories: Category[];
  finishedFriends: FinishedFriend[];
  reward?: Reward;
  primaryCategory: Category;
  categoryIcon?: ContentCategoryIcon;
}

export type GetPathwayResponseDTO = PathwayFullDto;

export type PutPathwayToTodoResponseDTO = PathwayFullDto;
