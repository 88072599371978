<template>
  <section :class="directionClass">
    <div class="info">
      <div class="item">
        <user-avatar
          v-if="createdByYou"
          :avatar="avatar"
          :is-me="createdByYou"
          :firstname="leaderboardChallenge.author?.firstName"
          :lastname="leaderboardChallenge.author?.lastName"
          size="mid"
          :user-status="leaderboardChallenge.author?.status"
        />
        <company-default-avatar v-if="!createdByYou"/>
        <div>
          <p class="subtext">
            Created By
          </p>
          <p class="title">
            {{ fullname }}
          </p>
        </div>
      </div>
    </div>
    <markdown-it :text="leaderboardChallenge.description" class="description"/>
  </section>
</template>
<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import UserAvatar from "@/vue/atoms/user-avatar.vue";
import MarkdownIt from "@/vue/atoms/markdown-it.vue";
import type {Profile} from "@/ts/types/store/default-store-types";
import type {LeaderboardChallengeDTO} from "@/ts/types/dto/leaderboard-challenge.dto";
import CompanyDefaultAvatar from "@/vue/atoms/company-default-avatar.vue";
import {DefaultState} from "@/ts/store/default/default-store-instance";

@Component({
  name: "ChallengeCreatedByLabel",
  components: {CompanyDefaultAvatar, UserAvatar, MarkdownIt},
})
export default class ChallengeCreatedByLabel extends Vue {
  @DefaultState
  leaderboardChallenge!: LeaderboardChallengeDTO;

  @Prop({default: "vertical"})
  direction!: "horizontal" | "vertical";

  @DefaultState
  public profile!: Profile;

  get createdByYou(): boolean {
    return this.leaderboardChallenge.author?.id === this.profile.id;
  }

  get fullname(): string {
    if (this.createdByYou) {
      return "by you";
    }
    return this.leaderboardChallenge.author
      ? `${this.leaderboardChallenge.author?.firstName} ${this.leaderboardChallenge.author?.lastName}`
      : "";
  }

  get avatar(): string {
    if (this.createdByYou) {
      return this.leaderboardChallenge.author?.avatarPath;
    }
    return "";
  }

  get directionClass(): string {
    return `heading ${this.direction}`;
  }
}
</script>

<style lang="sass" scoped>

.heading
  display: flex
  flex-direction: column
  gap: 16px
  max-width: 686px
  margin: 0 auto
  padding: 2.5rem 1rem

.horizontal
  flex-direction: row
  gap: 24px
  max-width: 920px

  .item::after
    content: ""
    border-right: 1.5px solid $color-neutral-platinum-100
    padding-right: 8px

.info
  display: flex
  flex-direction: column
  gap: 16px

.item
  display: flex
  gap: 16px
  color: $color-primary-hc-navy-100

.title
  @include Asap700
  margin: 0
  font-size: 14px
  line-height: 22px
  text-align: left

.subtext
  @include Roboto400
  margin: 0
  font-size: 14px
  line-height: 22px
  text-align: left


.description
  font-size: 16px
  line-height: 24px
  color: $color-primary-hc-navy-100
  @include Roboto400
  max-width: 652px
</style>
