<template>
  <div>
    <div class="avatar-header">
      <user-avatar
        size="large"
        :firstname="profile.firstName"
        :lastname="profile.lastName"
        :avatar="profile.avatarPath"
      />
    </div>
    <div class="profile-header">
      <h2 class="header-item">
        {{ profile.firstName }} {{ profile.lastName }}
      </h2>
      <br>
    </div>
    <div class="profile-header">
      <p class="header-subitem">
        {{ '@' + profile.username }}
      </p>
    </div>
    <div class="motivation-quote">
      <h4>My Motivation:</h4>
      <h3> {{ profile.motivationQuote }} </h3>
    </div>
  </div>
</template>

<script lang="ts" setup>
import UserAvatar from "@/vue/atoms/user-avatar.vue";
import {DefaultStore} from "@/ts/store/default/default-store";
import {getModule} from "vuex-module-decorators";
import {vueStore} from "@/ts/store/vue-store";
import {computed} from "vue";
import type {Profile} from "@/ts/types/store/default-store-types";

// TODO refactor markup and alignment of html-elements, ticket: HVV-2856

const defaultStore: DefaultStore = getModule(DefaultStore, vueStore);
const profile = computed((): Profile => {
  return defaultStore.profile!;
});

</script>

<style lang="sass" scoped>
.profile-header
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center

.header-item
  font-size: 1.75rem
  margin-block-end: .5em

.header-subitem
  font-size: .90rem
  margin-block-start: 0em
  color: $color-secondary-sea-foam-100

.motivation-quote
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column

.avatar-header
  display: flex
  justify-content: center
</style>
