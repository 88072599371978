<template>
  <div class="section-list">
    <p class="title">
      Final Standings
    </p>
    <div class="sections">
      <challenge-winner-podium :user="secondPlaceWinner"/>
      <challenge-winner-podium :user="firstPlaceWinner"/>
      <challenge-winner-podium :user="thirdPlaceWinner"/>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import type {
  LeaderboardChallengeDTO,
  RankingPosition,
} from "@/ts/types/dto/leaderboard-challenge.dto";
import ChallengeWinnerPodium from "@/vue/molecules/challenge/challenge-winner-podium.vue";
import type {ChallengeWinner} from "@/ts/types/component/challenges";
import {mixins} from "vue-class-component";
import {DefaultState, DefaultStoreMixin} from "@/ts/store/default/default-store-instance";

@Component({
  name: "ChallengeWinnersSection",
  components: {
    ChallengeWinnerPodium,
  },
})
export default class ChallengeWinnersSection extends mixins(DefaultStoreMixin) {
  @DefaultState
  leaderboardChallenge!: LeaderboardChallengeDTO;

  @Prop()
  ranking!: RankingPosition[];

  get firstPlaceWinner(): ChallengeWinner {
    const [ranking] = this.ranking.filter((rank) => rank.position === 1);

    return {
      ...ranking,
      points: this.leaderboardChallenge.pointsFirstPositionReward,
    };
  }

  get secondPlaceWinner(): ChallengeWinner {
    const [ranking] = this.ranking.filter((rank) => rank.position === 2);
    return {
      ...ranking,
      points: this.leaderboardChallenge.pointsSecondPositionReward,
    };
  }

  get thirdPlaceWinner(): ChallengeWinner {
    const [ranking] = this.ranking.filter((rank) => rank.position === 3);
    return {
      ...ranking,
      points: this.leaderboardChallenge.pointsThirdPositionReward,
    };
  }
}
</script>

<style lang="sass" scoped>
.title
  @include Asap700
  font-size: 24px
  color: $color-primary-hc-navy-100
  margin-top: 4px

.section-list
  display: flex
  flex-direction: column
  align-items: center
  background-color: $color-neutral-platinum-40
  padding: 1rem

.sections
  display: flex
  align-items: flex-end
  gap: 32px
</style>
