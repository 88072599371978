<template>
  <box-row>
    <template #left>
      <p class="title">Submissions</p>
    </template>
    <template #right>
      <p class="title">
        {{ totalSubmissions }} / {{ submissionLimitBasedOnFrequency }}
      </p>
    </template>
  </box-row>
</template>

<script lang="ts" setup>
import { computed, defineProps } from "vue";
import BoxRow from "@/vue/atoms/box-row.vue";

const props = defineProps<{
  submissionFrequency: string;
  maxSubmissions: number;
  totalSubmissions: number;
  submissionsAvailableThisWeek: number; 
}>();

const submissionLimitBasedOnFrequency = computed((): number => {
  if (props.submissionFrequency == "One time") return props.maxSubmissions;
  if (props.submissionFrequency == "Daily") return props.maxSubmissions;
  if (props.submissionFrequency == "3x per week") return props.submissionsAvailableThisWeek;
  if (props.submissionFrequency == "2x per week") return props.submissionsAvailableThisWeek;
  if (props.submissionFrequency == "Unlimited") return props.maxSubmissions;
  return 0;
});

</script>

<style lang="sass" scoped>
.title
  @include Roboto700
  font-size: 16px
  line-height: 24px
  color: $color-primary-hc-navy-100
  margin: 0
</style>