<template>
  <div class="item">
    <img :src="image" :alt="title"/>
    <div>
      <p class="title">
        {{ title }}
      </p>
      <div class="fraction">
        <p class="numerator">
          {{ formattedNumerator }}
        </p>
        <p class="denominator">
          / {{ formattedDenominator }}
        </p>
      </div>
      <loading-bar 
        :steps="denominator"
        :current="numerator"
        class="loading-bar"
        theme="blue"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import LoadingBar from "@/vue/atoms/loading-bar.vue";

@Component({
  name: "ProgramRewardsInfo",
  components: {
    LoadingBar,
  },
})
export default class ProgramRewardsInfo extends Vue {
  @Prop()
  title!: string;

  @Prop()
  image!: string;

  @Prop()
  numerator!: number;

  @Prop()
  denominator!: number;

  @Prop({ default: false })
  isPoints!: boolean;

  @Prop({ default: false })
  isCash!: boolean;

  get formattedNumerator(): string {
    return this.isCash ? `$${this.numerator}` : `${this.numerator}`;
  }

  get formattedDenominator(): string {
    return this.isPoints ? `${this.denominator} PTS` : `${this.denominator}`;
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.item
  display: flex
  gap: 12px
  transition: transform 0.3s ease
  &:hover
    transform: rotateX(5deg) rotateY(5deg) scale(1.02)

.title
  @include Roboto600
  color: $color-primary-hc-navy-100
  margin-bottom: 8px
  margin-top: 0
  font-size: 20px
  line-height: 22px
  text-align: left

.fraction
  display: flex
  flex-direction: row

.numerator
  @include Roboto700
  color: $color-secondary-sea-foam-100
  margin: 4px 0
  font-size: 32px
  line-height: 22px
  text-align: left

.denominator
  @include Roboto400
  color: $color-primary-hc-navy-100
  margin: 4px
  margin-top: 10px
  font-size: 16px
  line-height: 22px
</style>
