<template>
  <div class="wrapper">
    <img src="@/assets/img/illustrations/no-activities-available-1.svg" alt="no data"/>
    <h3 class="text-h3">
      No available benefits yet.
    </h3>
  </div>
</template>

<script setup lang="ts">

</script>

<style lang="sass" scoped>
.wrapper
  display: flex
  justify-content: center
  flex-direction: column
  margin: 2rem auto
  align-items: center

  img
    height: 250px
    width: 250px

</style>
