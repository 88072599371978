<template>
  <div class="wrapper">
    <img src="@/assets/img/illustrations/no-progress.svg" alt="no data"/>
    <h3 class="text-h3">
      No available data yet.
    </h3>
    <p class="text-body text-primary-blue-100 description">
      Start tracking to get statistics about your activities.
    </p>
  </div>
</template>

<script setup lang="ts">

</script>

<style lang="sass" scoped>
.wrapper
  display: flex
  justify-content: center
  flex-direction: column
  margin: 2rem auto
  align-items: center

  img
    height: 150px
    width: 150px

.description
  margin-top: 0
  text-align: center
</style>
