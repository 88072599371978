<template>
  <div>
    <rounded-icons :icon-type="type"/>
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import RoundedIcons from "@/vue/atoms/rounded-icons.vue";
import type {TaskType} from "@/ts/types/dto/activity.dto";

type IconType = TaskType | "duration" | "generic";

@Component({
  name: "ChallengeIcon",
  components: {
    RoundedIcons,
  },
})
export default class ChallengeIcon extends Vue {
  @Prop({default: "generic"}) type!: IconType;
}
</script>
<style lang="sass" scoped>
div
  color: $color-primary-hc-navy-100
  width: 40px
  height: 40px

</style>
