<template>
  <popup-modal :model-value="modelValue" @update:model-value="close" use-platinum-background>
    <popup-modal :model-value="isSendProofModalOpen" @update:model-value="toggleSendProofModal">
      <p class="title">Send Submission?</p>
      <p class="description">
        You have reached the submission limit. Any submissions sent beyond this limit will not earn additional points.
      </p>
      <div class="buttons">
        <button-primary theme="white" @click="toggleSendProofModal">Cancel</button-primary>
        <button-primary @click="saveProofFile">Confirm</button-primary>
      </div>
    </popup-modal>
    <popup-modal v-if="isActive" :model-value="isRemoveModalOpen" @update:model-value="toggleDeleteModal">
      <p class="delete-title">Remove activity?</p>
      <p class="delete-description">
        Clicking 'confirm' will remove the activity from your to-do list. If you would like to view the activity or add it back to your to-do list, you can view and manage the activity under the 'Available Activities' section on your My Program page.
      </p>
      <div class="buttons">
        <button-primary theme="white" @click="toggleDeleteModal">Cancel</button-primary>
        <button-primary @click="deleteActivity">Confirm</button-primary>
      </div>
    </popup-modal>
    <div class="wrap">
      <activity-title-heading
        :title="activity.name"
        :subtext="subText"
        :description="activity.description"
        :activity-type="activityIcon"
        :is-gatekeeper="activity.isGateKeeper || activity.isRecommended"
        :categories="activity.categories"
        :category-icon="activity.categoryIcon"
      />
      <activity-info-box
        :reward="activity.reward"
        :max-submissions="activity.maxSubmissions"
        :total-submissions="activity.totalSubmissions"
        :submissions-available-this-week="activity.submissionsAvailableThisWeek"
        :frequency="frequency"
        :task="activity.task"
        :available="available"
        :status="activity.status"
        :can-do="activity.canDo"
      />
      <div v-if="proofTypeStatusSubmitted" class="available">
        <p>You have submitted proof for this activity.</p>
      </div>
      <div v-if="activityNeedsProof && proofActivityRequiresDescription">
        <input-textarea
          v-model="proofDescription"
          name="proof-description"
          placeholder="Add a description for your proof"
        />
      </div>
      <activity-proof-display
        v-if="activityNeedsProof"
        :proof-image="imageUpload"
        :proof-file-name="proofFileName"
      />
      <activity-skip-done-delete-buttons
        v-if="!activityNeedsProof || proofTypeStatusAccepted"
        v-bind="skipDoneButtonsProps"
        v-on="skipDoneButtonsEvents"
      />
    </div>
    <div class="proof-btns">
      <button-primary
        v-if="activityNeedsProof && !imageUpload && (isActive ? !isCompleted : !proofTypeStatusAccepted)"
        :class="isActive ? { 'remove-from-todo': !proofActivityRequiresFile } : { 'add-to-todo': !proofActivityRequiresFile }"
        :theme="isActive ? 'white' : undefined"
        :disabled="isActive ? (activity.isGateKeeper || activity.isRecommended) : false"
        @click="isActive ? toggleDeleteModal() : addActivityToTodo()"
      >
        <icon-chameleon
          :icon="isActive ? SvgTrashIcon : SvgPlusIcon"
          :color="isActive ? 'primary100' : 'white'"
          aria-hidden="true"
          :alt="isActive ? 'Trash icon' : 'plus icon'"
        />
        {{ isActive ? "Remove from To-Do's" : "Add to My To-Do's" }}
      </button-primary>
      <activity-proof-upload
        v-if="proofStatusAllowToSend && proofActivityRequiresFile"
        :is-uploaded="!!proofImageBlob"
        @file-uploaded="uploadProof"
        @editProofFile="uploadProof"
      />
    </div>
    <div v-if="activityNeedsProof" class="proof-actions">
      <proof-history-button :is-proof-activity="isProofActivity" @open="openProofHistory" />
      <button-primary class="large" @click="validateSubmissionLimit">
        <img src="@/assets/img/send-icon.svg"/>
        Submit
      </button-primary>
    </div>
    <error-text class="error-text" :error="error"/>
  </popup-modal>
  <activity-proof-modal
    :show-modal="showProofHistoryModal"
    :activity="activity"
    @closeModal="closeProofHistory"
  />
</template>

<script lang="ts" setup>
import { computed, inject, ref, onMounted } from "vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";
import ActivityTitleHeading from "@/vue/molecules/action-title-heading.vue";
import ActivityInfoBox from "@/vue/molecules/activities/activity-info-box.vue";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import ActivitySkipDoneDeleteButtons from "@/vue/molecules/activities/activity-skip-done-delete-buttons.vue";
import ActivityProofDisplay from "@/vue/molecules/activities/activity-proof-display.vue";
import ActivityProofUpload from "@/vue/molecules/activities/activity-proof-upload.vue";
import ActivityProofModal from "@/vue/organisms/activity-modals/activity-proof-modal.vue";
import ProofHistoryButton from "@/vue/atoms/proof/proof-history-button.vue";
import InputTextarea from "@/vue/atoms/input-textarea.vue";
import SvgTrashIcon from "@/assets/img/delete-trash-icon.svg";
import SvgPlusIcon from "@/assets/img/plus-icon.svg";
import type { ActivityCardModel, ActivityIconType } from "@/ts/types/component/activity-card";
import { ActivityStatus, SubmissionFrequency } from "@/ts/types/dto/activity.dto";
import { RewardCompletionStatus, RewardValidationType } from "@/ts/types/dto/reward.dto";
import { KindOfActivity } from "@/ts/types/component/activity-card";
import type { Api } from "@/ts/classes/api";
import { GrowslStore } from "@/ts/store/growl/growl-store";
import { getModule } from "vuex-module-decorators";
import { vueStore } from "@/ts/store/vue-store";
import type { ActivityApprovalRequest } from "@/ts/types/dto/activity.dto";

const props = defineProps<{
  activity: ActivityCardModel;
  modelValue: boolean;
  activityIcon: ActivityIconType;
  frequency: string;
  isFromToDoPage?: boolean;
  proof?: ActivityApprovalRequest[];
  pastDaySelected?: boolean;
}>();

const emit = defineEmits<{
  (e: "changeItem", item: ActivityCardModel): void;
  (e: "proofSent", id: string): void;
  (e: "update:modelValue", value: boolean): void;
  (e: "skip"): void;
}>();

const $api = inject("$api") as Api;
const growlsStore = getModule(GrowslStore, vueStore);

const error = ref<string>();
const proofImageBlob = ref<string | undefined>("");
let proofImageFile: File;
const proofFileName = ref<string | undefined>("");
const proofDescription = ref<string>("");
const showProofHistoryModal = ref(false);
const isSendProofModalOpen = ref(false);
const isRemoveModalOpen = ref(false);
const submittingData = ref<boolean>(false);

const activityNeedsProof = computed((): boolean => {
  return props.activity.rewardCompletionValidation?.type === RewardValidationType.AdminApproval ||
    props.activity.rewardCompletionValidation?.type === RewardValidationType.AutoApproval;
});
const proofActivityRequiresDescription = computed((): boolean => props.activity.proofDescriptionRequired);
const proofActivityRequiresFile = computed((): boolean => props.activity.proofFileRequired);
const proofStatusAllowToSend = computed((): boolean => {
  return activityNeedsProof.value &&
    (props.activity.rewardCompletionValidation?.status === RewardCompletionStatus.not_submitted ||
      props.activity.rewardCompletionValidation?.status === RewardCompletionStatus.declined);
});
const proofTypeStatusAccepted = computed((): boolean => {
  return activityNeedsProof.value && props.activity.rewardCompletionValidation?.status === RewardCompletionStatus.accepted;
});
const proofTypeStatusSubmitted = computed((): boolean => {
  return activityNeedsProof.value && props.activity.rewardCompletionValidation?.status === RewardCompletionStatus.submitted;
});
const available = computed((): boolean => props.activity.canDo);
const hideDoneBtn = computed((): boolean => {
  const { kindOf, rewardCompletionValidation, task } = props.activity;
  const isStandardActivity = kindOf !== KindOfActivity.standard;
  const isAdminUploadValidation = rewardCompletionValidation?.type === RewardValidationType.AdminUpload;
  const hasTaskType = task?.type != null;
  return isStandardActivity || isAdminUploadValidation || hasTaskType;
});
const isCompleted = computed((): boolean => props.activity.status === ActivityStatus.completed);
const imageUpload = computed((): string | undefined => proofImageBlob.value);
const isProofActivity = computed((): boolean => {
  return props.activity.rewardCompletionValidation?.type === RewardValidationType.AdminApproval ||
    props.activity.rewardCompletionValidation?.type === RewardValidationType.AutoApproval ||
    props.activity.rewardCompletionValidation?.type === RewardValidationType.AdminUpload;
});
const subText = computed((): string => {
  return props.activity.isGateKeeper ? "Required Activity" : props.activity.isRecommended ? "Recommended Activity" : "";
});
const isActive = computed((): boolean => props.activity.status === ActivityStatus.active);

const skipDoneButtonsProps = computed(() => {
  if (isActive.value) {
    return {
      hasDelete: true,
      hideSkipBtn: !props.isFromToDoPage || !props.activity.canDo || (props.pastDaySelected && !props.activity.canDo),
      hideDoneBtn: hideDoneBtn.value,
      doneDisabled: !props.activity.canDo,
      skipDisabled: props.activity.isGateKeeper || props.activity.isRecommended,
      todoAdjustmentDisabled: props.activity.isGateKeeper || props.activity.isRecommended,
    };
  }
  return {
    hasDelete: false,
    hideSkipBtn: true,
    hideDoneBtn: hideDoneBtn.value,
    doneDisabled: !props.activity.canDo,
    skipDisabled: true,
    submittingData: submittingData.value,
  };
});
const skipDoneButtonsEvents = computed(() => {
  if (isActive.value) {
      return {
      deleteTask: toggleDeleteModal,
      taskDone: complete,
      skipTask: skipTodo,
      };
  }
  return {
      deleteTask: deleteActivity,
      taskDone: complete,
      addToToDo: addActivityToTodo,
  };
});

const openProofHistory = () => {
  showProofHistoryModal.value = true;
};
const closeProofHistory = () => {
  showProofHistoryModal.value = false;
};
const toggleDeleteModal = (): void => {
  isRemoveModalOpen.value = !isRemoveModalOpen.value;
};
const toggleSendProofModal = (): void => {
  isSendProofModalOpen.value = !isSendProofModalOpen.value;
};
const deleteActivity = async (): Promise<void> => {
  try {
    if (props.activity.kindOf === KindOfActivity.standard) {
      await $api.deleteActivity(props.activity.id);
    }
    if (props.activity.kindOf === KindOfActivity.platform) {
      await $api.deletePlatformActivity(props.activity.id);
    }
  } catch (err) {
    await growlsStore.growlError(err as string);
  }
  const updatedItem = props.activity.instanceClone;
  updatedItem.status = ActivityStatus.available;
  changeItem(updatedItem);
  await growlsStore.growlInfo(`Activity ${props.activity.name} has been removed from your to-do list`);
};
const complete = async (): Promise<void> => {
  close();
  try {
    await $api.submitHonorActivity(props.activity.id, { progressDelta: 0 });
  } catch (err) {
    await growlsStore.growlError(err as string);
  }
  const updatedItem = props.activity.instanceClone;
  if (updatedItem.submissionFrequency === SubmissionFrequency.once ||
      updatedItem.submissionFrequency === SubmissionFrequency.unlimited) {
    updatedItem.status = ActivityStatus.completed;
  }
  changeItem(updatedItem);
  await growlsStore.growlInfo("Activity completed.");
};
const validateSubmissionLimit = async (): Promise<void> => {
  if (props.activity.hasMaxPending) {
    toggleSendProofModal();
    return;
  }
  await saveProofFile();
};
const saveProofFile = async (): Promise<void> => {
  isSendProofModalOpen.value = false;
  if (!proofImageFile && props.activity.proofFileRequired) {
    error.value = "Please upload a proof attachment.";
    return;
  }
  if (proofDescription.value === "" && props.activity.proofDescriptionRequired) {
    error.value = "Please add a description for your proof.";
    return;
  }
  try {
    await $api.submitProofActivity(props.activity.id, proofImageFile, proofDescription.value);
    close();
    await growlsStore.growlInfo("Proof sent. Your proof will be validated soon.");
    await growlsStore.growlInfo("Your submission has been received, you will receive a notification once it has been reviewed.");
    proofSent(props.activity.id);
  } catch (err) {
    await growlsStore.growlError(err as string);
  }
};
const uploadProof = (value: File | undefined): void => {
  if (value) {
    proofImageBlob.value = URL.createObjectURL(value);
    proofImageFile = value;
    proofFileName.value = value.name;
  }
};
const addActivityToTodo = async (): Promise<void> => {
  try {
    if (props.activity.kindOf === KindOfActivity.standard) {
      await $api.addActivityToTodo(props.activity.id);
    } else if (props.activity.kindOf === KindOfActivity.platform) {
      await $api.addPlatformActivityToTodo(props.activity.id);
    }
  } catch (err) {
    await growlsStore.growlError(err as string);
  }
  const updatedActivity = props.activity.instanceClone;
  updatedActivity.status = ActivityStatus.active;
  changeItem(updatedActivity);
  await growlsStore.growlInfo("Activity added to the To-Do's list.");
};
const close = (): void => {
  emit("update:modelValue", false);
};
const changeItem = (item: ActivityCardModel): void => {
  emit("changeItem", item);
};
const proofSent = (id: string): void => {
  proofImageBlob.value = "";
  emit("proofSent", id);
};
const skipTodo = (): void => {
  emit("skip");
};
const created = async (): Promise<void> => {
  if (activityNeedsProof.value && props.activity.rewardCompletionValidation?.status !== RewardCompletionStatus.not_submitted) {
    try {
      const { url } = await $api.getActivityCompletionProof(props.activity.id);
      proofImageBlob.value = url.includes("https") ? url : "";
    } catch (err) {
      await growlsStore.growlError(err as string);
    }
  }
};
onMounted(created);
</script>

<style lang="sass" scoped>
.remove-from-todo,
.add-to-todo
  margin-bottom: 12px

.wrap
  display: flex
  flex-direction: column
  gap: 24px
  width: 600px
  overflow-y: auto
  max-height: 600px

h1
  text-align: center

.proof-btns
  display: flex
  gap: 18px
  align-items: center
  padding-top: 1rem

.proof-actions
  display: flex
  justify-content: space-between
  align-items: center

.available
  font-size: 16px
  line-height: 24px
  color: $color-primary-hc-navy-100
  @include Roboto400
  max-width: 652px

.title
  @include Asap700
  margin: 0
  font-size: 32px
  line-height: 40px
  text-align: left

.description
  font-size: 16px
  line-height: 24px
  color: $color-primary-hc-navy-100
  @include Roboto400
  max-width: 652px

.buttons
  display: flex
  gap: 16px
  justify-content: center
  margin-top: 8px

.error-text
  text-align: right
  padding-top: 8px
</style>