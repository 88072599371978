<template>
  <popup-modal :model-value="displayed" @update:model-value="closePopup">
    <div class="content">
      <h3 class="title">
        {{ title }}
      </h3>
      <div>
        <img
          src="@/assets/img/oops.svg"
          aria-hidden="true"
          class="oops-icon"
        />
      </div>
      <p v-if="forgotUsername" class="text">
        Oops! We are sorry you forgot your username.
        Our support center will love to help you recover it.
        Please get in contact with us.
      </p>
      <p v-if="forgotCompanyCode" class="text">
        No worries! Your company code is a 5 character code you can find on most communications from your company.
        If you need help locating your information, our support team would be happy to help!
      </p>
      <p v-if="forgotUniqueId" class="text">
        No worries! Your unique ID is usually your last 4 SSN or your employee ID.
        If you need help locating your information, our support team would be happy to help!
      </p>
      <div class="contact">
        <div class="contact__entry">
          <p class="field">
            Email
          </p>
          <p>support@healthcheck360.com</p>
        </div>
        <div class="contact__entry">
          <p class="field">
            Phone
          </p>
          <p>866-511-0360</p>
        </div>
      </div>
    </div>
  </popup-modal>
</template>

<script lang="ts">
import {Component, Vue, Prop, Emit} from "vue-property-decorator";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";

@Component({
  name: "StaticForgotPopup",
  components: {
    ButtonPrimary,
    PopupModal,
  }
})
export default class StaticForgotPopup extends Vue {
  @Prop()
  displayed!: boolean;

  @Prop()
  forgotUsername!: boolean;

  @Prop()
  forgotCompanyCode!: boolean;

  @Prop()
  forgotUniqueId!: boolean;

  get title(): string {
    let title = "";
    if (this.forgotCompanyCode) {
      title = "Forgot Company Code";
    }
    if (this.forgotUniqueId) {
      title = "Forgot Unique ID";
    }
    if (this.forgotUsername) {
      title = "Forgot my username";
    }
    return title;
  }

  @Emit('closePopup')
  closePopup(): boolean {
    return false;
  }
}
</script>

<style lang="sass" scoped>
.content
  display: flex
  flex-direction: column
  max-width: 652px

.title
  @include Asap700
  color: $color-primary-hc-navy-100
  font-size: 28px
  line-height: 36px
  margin-top: 0
  margin-bottom: 16px

p
  @include Roboto400
  color: $color-primary-hc-navy-100
  font-size: 16px
  line-height: 24px
  letter-spacing: 0.1px
  margin: 0

.field
  @include Roboto700
  margin-top: 20px

.button
  margin-top: 48px
  align-self: flex-end

.oops-icon
  display: block
  width: 137px
  margin: 10px auto 30px auto

.contact
  display: flex

.contact__entry
  width: 50%


</style>
