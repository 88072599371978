<template>
  <popup-modal :model-value="isOpen" @update:model-value="close">
    <loading-suspense :error="error" :loading="loading">
      <div v-if="!!activity" class="container">
        <header>
          <activity-icon v-if="activity.task" :type="activity.task.type"/>
          <h1 class="title">
            {{ activity.name }}
          </h1>
        </header>
        <markdown-it :text="activity.description"/>
        <activity-info-box
          :reward="activity.reward"
          :max-submissions="activity.maxSubmissions"
          :total-submissions="activity.totalSubmissions"
          :submissions-available-this-week="activity.submissionsAvailableThisWeek"
          :frequency="activity?.frequency"
          :task="activity.task"
          :status="activity.status"
          :can-do="activity.canDo"
        />
        <activity-end-date-box :date="activity.endDate"/>
      </div>
    </loading-suspense>
  </popup-modal>
</template>

<script lang="ts" setup>
import type {GatekeeperItem} from "@/ts/types/dto/program.dto";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import {inject, ref, watch} from "vue";
import type {Api} from "@/ts/classes/api";
import type {ActivityDTO} from "@/ts/types/dto/activity.dto";
import MarkdownIt from "@/vue/atoms/markdown-it.vue";
import ActivityInfoBox from "@/vue/molecules/activities/activity-info-box.vue";
import {ActivityCardModel} from "@/ts/types/component/activity-card";
import ActivityEndDateBox from "@/vue/molecules/activities/activity-end-date-box.vue";
import ActivityIcon from "@/vue/atoms/activity-icon.vue";

const props = defineProps<{
  isOpen: boolean;
  gatekeeperItem: GatekeeperItem | null;
}>();

const $api = inject("$api")! as Api;
const loading = ref<boolean>(true);
const error = ref<string>("");
const activity = ref<ActivityCardModel | null>(null);

const emit = defineEmits(["update:isOpen"]);

watch(() => props.gatekeeperItem, (newValue, oldValue) => {
  if (newValue) {
    fetchActivity(newValue.id);
  }
});

const fetchActivity = async(activityId: string) => {
  loading.value = true;
  try {
    const resp: ActivityDTO = await $api.getActivityById(activityId);
    activity.value = ActivityCardModel.createActivity(resp);
  } catch (err) {
    error.value = err as string;
  } finally {
    loading.value = false;
  }
};

const close = (): void => {
 emit("update:isOpen", false);
};
</script>

<style lang="sass" scoped>
.container
  display: flex
  flex-direction: column
  gap: 24px
  width: 600px
  overflow-y: auto
  max-height: 600px

header
  display: flex
  align-items: center
  gap: 24px

.title
  @include Asap700
  margin: 0
  font-size: 32px
  line-height: 40px
</style>
