<template>
  <box-row class="buttons-row">
    <template #right>
      <div class="right">
        <span v-if="isUploaded">
          <input
            id="upload-file"
            type="file"
            name="upload-file"
            accept="image/*,application/pdf"
            hidden
            @change="fileUploaded"
          />
          <label for="upload-file" refs="upload-file" class="btn-basic edit-btn">
            <img src="@/assets/img/profile-icons/edit-profile.svg"/>
            Edit Image/File</label>
        </span>

        <span v-if="!isUploaded">
          <input
            id="upload-file"
            type="file"
            name="upload-file"
            accept="image/*,application/pdf"
            hidden
            @change="fileUploaded"
          />
          <label for="upload-file" refs="upload-file" class="btn-basic upload-btn">
            <img src="@/assets/img/upload-icon.svg"/>
            Upload Attachment
          </label>
        </span>
      </div>
    </template>
  </box-row>
</template>

<script lang="ts" setup>
import BoxRow from "@/vue/atoms/box-row.vue";

const props = defineProps<{
  isUploaded: boolean;
}>();

const emit = defineEmits(["fileUploaded"]);

const fileUploaded = (event: Event): void => {
  const {files} = event.target as HTMLInputElement;

  // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
  emit("fileUploaded", files?.[0] as File);
};
</script>

<style lang="sass" scoped>

.btn-basic
  border-radius: 8px
  cursor: pointer
  display: flex
  justify-content: center
  align-items: center
  @include Asap600
  font-size: 17px
  font-style: normal
  font-weight: 600
  gap: 8px
  width: 260px
  height: 45px
  line-height: 3px
  padding: 12px 0

.upload-btn
  border: none
  background-color: $color-primary-hc-navy-100
  color: $color-white
  align-self: flex-end
  margin-left: 4rem

.edit-btn
  background-color: $color-white
  color: $color-primary-hc-navy-100
  border: 2px solid $color-primary-hc-navy-50

.buttons-row
  padding: 12px 0

.small
  width: 130px

.right
  display: flex
  gap: 24px
</style>
