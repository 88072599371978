<template>
  <loading-suspense :error="error" :loading="loading">
    <main v-if="dataFound" class="content">
      <div class="point-cap-tracker">
        <point-cap-tracker
          v-if="pathwayCapRule !== null && showCapTracker"
          :rule="pathwayCapRule"
        />
      </div>
      <pathway-card-row
        title="Available"
        :pathways="availablePathways"
        :categories="availableCategories"
        :filter-key="'availablePathways'"
        @apply-filter="applyAvailableFilter"
      />
      <pathway-card-row
        title="Completed"
        :pathways="completedPathways"
        :categories="completedCategories"
        :filter-key="'completedPathways'"
        @apply-filter="applyCompletedFilter"
      />
    </main>
    <empty-state
      v-else
      :image="noPathwaysImage"
      title="No Pathways Available"
      description="Please contact your admin."
    />
  </loading-suspense>
  <add-tracking-button/>
</template>

<script lang="ts" setup>
import type { PathwayFullDto } from "@/ts/types/dto/pathway.dto";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import PathwayCardRow from "@/vue/molecules/pathway-card-row.vue";
import EmptyState from "@/vue/atoms/empty-state.vue";
import AddTrackingButton from "@/vue/atoms/add-tracking-button.vue";

import { computed, inject, onMounted, ref, watch } from "vue";
import type { Api } from "@/ts/classes/api";
import type { ContentCategory, GetProgramResponseDTO } from "@/ts/types/dto/program.dto";
import noPathwayImage from "@/assets/img/illustrations/no-activities-available-3.svg";
import PointCapTracker from "@/vue/molecules/point-cap-tracker.vue";
import { PointCapUISelect, UserPointCapTotalsDTO } from "@/ts/types/dto/reward.dto";

const $api: Api = inject("$api")! as Api;

const availableCategories = ref<ContentCategory[]>([]);
const completedCategories = ref<ContentCategory[]>([]);

const error = ref("");
const loading = ref(true);
const dataFound = ref(true);

const availablePathways = ref<PathwayFullDto[]>([]);
const completedPathways = ref<PathwayFullDto[]>([]);
const userPointTotals = ref<UserPointCapTotalsDTO[]>([]);
const showCapTracker = ref(false);
const program = ref<GetProgramResponseDTO>();
const pathwayCapRule = ref<UserPointCapTotalsDTO | null>(null);

const noPathwaysImage = computed((): string => noPathwayImage);

const applyAvailableFilter = async (categoryIds: string[]): Promise<void> => {
  try {
    const filteredPathways = await $api.getPathways({
      onlyActive: true,
      onlyAvailable: true,
      categoryIds: categoryIds.join(","),
      limit: 100,
    });
    availablePathways.value = filteredPathways?.items ?? [];
  } catch (err) {
    error.value = err as string;
  }
};

const applyCompletedFilter = async (categoryIds: string[]): Promise<void> => {
  try {
    const filteredPathways = await $api.getPathways({
      onlyCompleted: true,
      categoryIds: categoryIds.join(","),
      limit: 100,
    });
    completedPathways.value = filteredPathways?.items ?? [];
  } catch (err) {
    error.value = err as string;
  }
};

const loadUserPointCaps = async (): Promise<void> => {
  try {
    program.value = await $api.getProgram(true);
    const response = await $api.getUserPointCapTotals(program.value.id);
    userPointTotals.value = response?.userPointCapTotal ?? [];
    showCapTracker.value = response?.uiSelection === PointCapUISelect.content_page;
  } catch (err) {
    console.error(err);
  }
};

const loadCategories = async (): Promise<void> => {
  const [availableCatRes, completedCatRes] = await Promise.allSettled([
    $api.getPathwayCategories({ onlyActive: true, onlyAvailable: true }),
    $api.getPathwayCategories({ onlyCompleted: true }),
  ]);

  if (availableCatRes.status === "fulfilled") {
    availableCategories.value = availableCatRes.value
      .sort((a, b) => b.count - a.count)
      .map((category) => ({
        id: category.id,
        name: `${category.name} (${category.count})`,
        color: category.color,
      }));
  }
  if (completedCatRes.status === "fulfilled") {
    completedCategories.value = completedCatRes.value
      .sort((a, b) => b.count - a.count)
      .map((category) => ({
        id: category.id,
        name: `${category.name} (${category.count})`,
        color: category.color,
      }));
  }
};

onMounted(async () => {
  try {
    await loadUserPointCaps();
    await loadCategories();

    const [combinedResponse, completedResponse] = await Promise.allSettled([
      $api.getPathways({
        onlyActive: true,
        onlyAvailable: true,
        limit: 100,
      }),
      $api.getPathways({
        onlyCompleted: true,
        limit: 100,
      }),
    ]);

    if (combinedResponse.status === "fulfilled") {
      availablePathways.value = combinedResponse.value.items ?? [];
    }
    if (completedResponse.status === "fulfilled") {
      completedPathways.value = completedResponse.value.items ?? [];
    }
  } catch (err) {
    error.value = err as string;
  } finally {
    loading.value = false;
    dataFound.value =
      Boolean(availablePathways.value?.length) || Boolean(completedPathways.value?.length);
  }
});

watch(userPointTotals, (newVal) => {
  pathwayCapRule.value =
    newVal.find(
      (rule) =>
        rule.pointCapRule.ruleType === "content" &&
        rule.pointCapRule.contentType === "pathway"
    ) || null;
});
</script>

<style lang="sass" scoped>
main
  margin-top: 8rem

.point-cap-tracker
  display: flex
  justify-content: flex-start
  margin-left: 4rem
  margin-bottom: 1rem

  @media (min-width: 1800px)
    margin-right: 5rem
</style>