<template>
  <div class="group-parent">
    <div class="rectangle-parent">
      <protected-image
        class="background-image"
        :src="backgroundImage"
        alt="Progress background image"
        :ariaHidden="true"
        :load-raw-image="true"
      />
      <div class="overlay" :style="{ backgroundColor }"></div>
      <div class="rectangle-group">
        <div class="group-item">
          <div class="value">
            <span>{{ value }} </span>
            <span v-if="unit" class="unit">{{ unit }}</span>
          </div>
        </div>
      </div>
      <div class="title">{{ title }}</div>
      <img class="chevron" alt="chevron" src="@/assets/img/right-arrow.svg" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import ProtectedImage from "@/vue/atoms/protected-image.vue";

const props = defineProps<{
  type: "steps" | "activity" | "sleep";
  value: number;
}>();

const title = computed(() => ({
  steps: "Daily Steps",
  activity: "Daily Activity",
  sleep: "Daily Sleep",
}[props.type] || ""));

const unit = computed(() => ({
  steps: "",
  activity: "minutes",
  sleep: "hours",
}[props.type] || ""));

const backgroundColor = computed(() => ({
  steps: "rgba(233, 170, 66, 0.70)",
  activity: "rgba(27, 45, 59, 0.70)",
  sleep: "rgba(133, 197, 174, 0.70)",
}[props.type] || ""));

const backgroundImage = computed(() => ({
  steps: "147f9b80-6eca-4c15-911c-3db9ab767505-static/photos/GettyImages-1482883834.jpg",
  activity: "147f9b80-6eca-4c15-911c-3db9ab767505-static/photos/GettyImages-1459345882.jpg",
  sleep: "147f9b80-6eca-4c15-911c-3db9ab767505-static/photos/GettyImages-1969842239.jpg",
}[props.type] || ""));
</script>

<style lang="sass" scoped>
.group-parent
  width: 100%
  position: relative
  height: 131px
  text-align: center
  font-family: Asap

.rectangle-parent
  position: relative
  width: 230px
  height: 131px
  border-radius: 16px
  transition: box-shadow 0.3s ease
  box-shadow: $content-shadow
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  overflow: hidden
  flex-direction: column
  &:hover
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2)
    .chevron
      opacity: 1

.background-image
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  object-fit: cover
  border-radius: 16px

.overlay
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  border-radius: 16px

.title
  position: absolute
  bottom: 8px
  left: 50%
  transform: translateX(-50%)
  font-size: 30px
  color: $color-white
  font-weight: bold
  text-align: center
  width: 100%

.rectangle-group
  position: absolute
  bottom: 40px
  left: 50%
  transform: translateX(-50%)
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  width: auto
  padding: 2px 14px
  border-radius: 16px
  background-color: $color-white
  margin-bottom: 4px

.group-item
  width: 100%
  height: auto
  border-radius: 16px

.value
  font-size: 24px
  display: flex
  justify-content: center
  align-items: baseline
  white-space: nowrap

.unit
  font-size: 16px
  font-weight: bold
  padding-left: 5px
  font-weight: bold

.chevron
  position: absolute
  top: 10px
  right: 10px
  width: 20px
  height: 20px
  opacity: 0
  transition: opacity 0.3s ease
</style>