<template>
  <card-item class="card-item" @click="navigateToChallenge">
    <div class="image-frame">
      <protected-svg
        v-if="challenge.categoryIcon && challenge.categoryIcon.path"
        :src="challenge.categoryIcon.path"
        size="large"
        :ariaHidden="false"
        alt="Challenge Icon"
      />
      <avatar-frame v-else :image="cover" :fallback-image="coverFallback" :size="100"/>
    </div>
    <div class="content">
      <challenge-type :type="challenge.type"/>
      <h1>{{ challenge.name }}</h1>
      <div class="duration">
        <img :src="iconTime" alt="time remaining"/>
        <p>{{ duration }}</p>
      </div>
      <div class="badges">
        <div v-if="!creatorTypeAuthor && challengePoints" class="reward">
          {{ challengePoints }}
        </div>
        <div v-if="challenge.challengeStatus == 'active'" class="todo">
          <img :src="CalendarSvg" />
        </div>
      </div>
    </div>
  </card-item>
</template>

<script lang="ts" setup>
import { computed, inject, onMounted, onUnmounted, ref } from "vue";
import { useRouter, useRoute, RouteLocationRaw } from "vue-router";
import type { ChallengeDTO } from "@/ts/types/dto/challenges.dto";
import { formatDateShortMonthName, getRemainNumberOfDays } from "@/ts/utils/date-pure-functions";
import { determineChallengeStatus } from "@/ts/utils/pure-functions";
import type { PagesPath } from "@/ts/router/pages-path";
import { ChallengeStatus } from "@/ts/types/component/challenges";
import AvatarFrame from "@/vue/atoms/avatar-frame.vue";
import ProtectedSvg from "@/vue/atoms/protected-svg.vue";
import CalendarSvg from "@/assets/img/calendar-white-icon.svg";
import CardItem from "@/vue/atoms/card-item.vue";
import ChallengeType from "@/vue/molecules/challenge/challenge-type.vue";
import clockIcon from "@/assets/img/challenge-card-icons/clock-icon.svg";
import calendarIcon from "@/assets/img/challenge-card-icons/calendar-clean-icon.svg";
import challengeDefaultImage from "@/assets/img/challenge-card-icons/challenge-default-image.svg";
import {DefaultStore} from "@/ts/store/default/default-store";
import {getModule} from "vuex-module-decorators";
import {vueStore} from "@/ts/store/vue-store";
import type { Profile } from "@/ts/types/store/default-store-types";
import { checkPrime } from "crypto";

const props = defineProps<{ challenge: ChallengeDTO }>();

const router = useRouter();
const route = useRoute();
const $pagesPath = inject<PagesPath>("$pagesPath")!;
const clickedChallenge = ref(false);

const defaultStore: DefaultStore = getModule(DefaultStore, vueStore);
const profile = computed((): Profile => {
  return defaultStore.profile!;
});

const challengeStatus = computed(() => determineChallengeStatus(props.challenge));

const duration = computed(() => {
  const daysUntilStart = Math.max(getRemainNumberOfDays(props.challenge.startDate), 0);
  const duration = Math.max(getRemainNumberOfDays(props.challenge.endDate), 0);

  switch (challengeStatus.value) {
    case ChallengeStatus.Active:
      return duration === 0 ? "Ends Today" : `Ends in ${duration} ${duration > 1 ? "days" : "day"}`;
    case ChallengeStatus.Available:
      return daysUntilStart > 0
        ? `Starts in ${daysUntilStart} ${daysUntilStart > 1 ? "days" : "day"}`
        : duration === 0
          ? "Ends Today"
          : `Ends in ${duration} ${duration > 1 ? "days" : "day"}`;
    case ChallengeStatus.Joined:
      return `Starts in ${daysUntilStart} ${daysUntilStart > 1 ? "days" : "day"}`;
    case ChallengeStatus.Completed:
      return `Ended on ${formatDateShortMonthName(props.challenge.endDate)}`;
    default:
      return "";
  }
});

const iconTime = computed(() => (challengeStatus.value === ChallengeStatus.Active ? clockIcon : calendarIcon));

const cover = computed(() => props.challenge.coverPath);
const coverFallback = computed(() => challengeDefaultImage);

const challengePoints = computed(() => {
  if (!props.challenge.pointsThirdPositionReward && !props.challenge.pointsFirstPositionReward) return null;
  return `${props.challenge.pointsThirdPositionReward}-${props.challenge.pointsFirstPositionReward} PTS`;
});

const creatorTypeAuthor = computed(() => props.challenge.author?.id === profile.value.id);

const to = computed(() => ({
  path: $pagesPath.challenge.challenge.replace(":id", props.challenge.id),
  query: { type: props.challenge.type },
}));

const navigateToChallenge = () => {
  router.push(to.value);
  clickedChallenge.value = true;
};

const hostBorderClass = computed(() => (props.challenge.author?.id === profile.value.id ? "orange" : "none"));

onMounted(() => {
  const storedPreviousPage = sessionStorage.getItem("challengePreviousPage");

  if (!storedPreviousPage && route.fullPath !== storedPreviousPage) {
    sessionStorage.setItem("challengePreviousPage", route.fullPath);
  }
});

onUnmounted(() => {
  if (!clickedChallenge.value) {
    sessionStorage.removeItem("challengePreviousPage");
  }
});
</script>

<style lang="sass" scoped>
.card-item
  cursor: pointer

.content
  display: flex
  flex-direction: column
  margin-bottom: 12px
  position: relative
  align-items: center
  text-align: center

.badges
  display: flex
  flex-direction: row
  gap: 6px
  margin-bottom: 8px

.todo
  background: $color-tertiary-orange-sunset-100
  border-radius: 16px
  display: flex
  align-items: center
  justify-content: center
  width: 30px

  img
    width: 16px

h1
  @include Asap700
  color: $color-primary-hc-navy-100
  font-size: 1rem
  margin: 0
  line-height: 22px
  /* stylelint-disable */
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  overflow: hidden

.sub-text
  @include Roboto400
  font-size: 10px
  line-height: 15px
  letter-spacing: 0.4px
  text-transform: uppercase
  color: $color-primary-hc-navy-100

.duration
  display: flex
  align-items: center
  gap: 4px

  p
    @include Roboto400
    font-size: 12px
    line-height: 16px
    color: $color-primary-hc-navy-50
    margin: 8px 0
    
.reward
  background: $color-primary-hc-navy-100
  border-radius: 16px
  @include Roboto600
  font-size: 12px
  color: $color-white
  display: flex
  padding: 8px 10px
  align-items: center
  width: fit-content
</style>