<template>
  <section class="row-section">
    <div class="header">
      <slot name="header"/>
      <slot name="filter"/>
      <slot name="extraButton"/>
      <slot name="selectedFilters"/>
    </div>
    <div class="content">
      <slot/>
    </div>
  </section>
</template>

<script lang="ts" setup>
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.row-section
  display: flex
  flex-direction: column
  margin-left: 8rem
  margin-bottom: 24px

.header
  display: flex
  gap: 16px
  @include Asap700
  font-size: 20px
  background-color: $color-white
  border-radius: 16px
  padding: 6px
  width: fit-content
  flex-wrap: wrap

.content
  display: flex
  flex-wrap: wrap
  gap: 4px
</style>
