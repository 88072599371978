<template>
  <popup-modal :model-value="modelValue" @update:model-value="close">
    <div>
      <p class="title">
        Completed this pathway
      </p>
      <div class="container">
        <colleague-display
          v-for="friend in friends"
          :key="friend.id"
          :avatar="friend.avatarPath"
          :first-name="friend.firstName"
          :last-name="friend.lastName"
          :user-status="friend.status"
        />
      </div>
    </div>
  </popup-modal>
</template>

<script lang="ts" setup>
import PopupModal from "@/vue/atoms/popup-modal.vue";
import type {FinishedFriend} from "@/ts/types/dto/pathway.dto";
import ColleagueDisplay from "@/vue/atoms/colleague-display.vue";

const props = defineProps<{
  modelValue: boolean;
  friends: FinishedFriend[];
}>();

const emit = defineEmits(["update:modelValue"]);

const close = (): void => {
  emit("update:modelValue", false);
};

</script>

<style lang="sass" scoped>
.title
  @include Asap700
  color: $color-primary-hc-navy-100
  text-align: start
  font-size: 36px

.container
  max-width: max-container-width(24px, $container-width, 2)
  @include flex-container(24px, $container-width, 2)
  justify-content: space-around

</style>
