<template>
  <div class="badges">
    <div v-if="reward" class="reward">
      {{ reward }}
    </div>
    <div v-if="content.status == 'active'" class="todo">
      <img :src="CalendarSvg" />
    </div>
    <div v-if="isActivityTaskComplete" class="task">
      <img :src="CheckSvg" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import CalendarSvg from "@/assets/img/calendar-white-icon.svg";
import CheckSvg from "@/assets/img/icon-check-w.svg";
import { getRewardAmountFormatted } from "@/ts/utils/pure-functions";
import type { ActivityCardModel } from "@/ts/types/component/activity-card";
import { PathwayFullDto } from "@/ts/types/dto/pathway.dto";

const props = defineProps<{ content: ActivityCardModel | PathwayFullDto }>();

const reward = computed((): string | null => {
  return props.content.reward ? getRewardAmountFormatted(props.content.reward) : null;
});

const isActivityTaskComplete = computed((): boolean => {
  return (props.content as ActivityCardModel)?.canDo === false;
});
</script>

<style lang="sass" scoped>
.badges
  display: flex
  flex-direction: row
  gap: 6px
  margin-bottom: 8px

.reward
  background: $color-primary-hc-navy-100
  border-radius: 16px
  @include Roboto600
  font-size: 12px
  color: $color-white
  display: flex
  padding: 8px 10px
  align-items: center
  width: fit-content

.todo
  background: $color-tertiary-orange-sunset-100
  border-radius: 16px
  display: flex
  align-items: center
  justify-content: center
  width: 30px
  height: 30px

  img
    width: 16px

.task
  background: $color-secondary-sea-foam-100
  border-radius: 16px
  display: flex
  align-items: center
  justify-content: center
  width: 30px

  img
    width: 20px
</style>