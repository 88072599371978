<template>
  <div>
    <div
      v-if="loading"
      :class="[sizeClass, 'skeleton']"
      role="presentation"
      aria-hidden="true"
    />
    <img
      v-else-if="imgSrc"
      :src="imgSrc"
      :class="sizeClass"
      :aria-hidden="ariaHidden"
      :alt="alt"
      role="img"
    />
    <img
      v-else
      :src="noImageSvg"
      :class="sizeClass"
      aria-hidden="true"
      alt="No activity available"
      role="img"
    />
  </div>
</template>

<script lang="ts" setup>
import { inject, onMounted, onUnmounted, ref, watch } from "vue";
import type { Api } from "@/ts/classes/api";
import noImageSvg from "@/assets/img/icons/generic_black.svg";

const $api: Api = inject("$api")!;

const error = ref("");
const loading = ref(true);
const imgSrc = ref<string | null>(null);

const props = defineProps<{
  src: string;
  ariaHidden: boolean;
  alt: string;
  size: "small" | "large";
}>();

const sizeClass = ref<string>("");

const fetchSvg = async (): Promise<void> => {
  if (!props.src) return;
  loading.value = true;

  try {
    const response = await $api.getProtectedImage(props.src);
    imgSrc.value = response.imageObjectUrl;
    error.value = "";
  } catch (err) {
    imgSrc.value = null;
    error.value = (err as Error).message;
  } finally {
    loading.value = false;
  }
};

watch(() => props.src, fetchSvg);

onMounted(() => {
  fetchSvg();
  sizeClass.value = props.size === "small" ? "svg-small" : "svg-large";
});

onUnmounted(() => {
  if (imgSrc.value) {
    URL.revokeObjectURL(imgSrc.value);
    imgSrc.value = null;
  }
});
</script>

<style lang="sass" scoped>
.svg-small
  width: 48px
  height: 48px
  display: inline-block

.svg-large
  width: 100px
  height: 100px
  display: inline-block

.skeleton
  background-color: $color-neutral-platinum-40
  border-radius: 16px
  animation: pulse 1s infinite ease-in-out

@keyframes pulse
  0%
    opacity: 1
  50%
    opacity: 0.4
  100%
    opacity: 1
</style>