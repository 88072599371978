<template>
  <div
    v-for="question in questions"
    :key="question.id"
  >
    <div class="answers">
      <div class="question">
        {{ question.question }}
        <p class="answer">
          {{ question.selections?.find((se) => se.selected)?.selection }}
        </p>
      </div>
    </div>
    <div class="answers">
      <div class="question">
        {{ question.selections?.find((se) => se.selected)?.takingMedicationsQuestion?.question }}
      </div>
      <p class="answer-bottom">
        {{
          question.selections?.find((se) => se.selected)?.takingMedicationsQuestion?.selections?.find((se) => se.selected)?.selection
        }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import {Prop, Vue} from "vue-property-decorator";
import type {HRASurveyQuestionDetail} from "@/ts/types/dto/health-risk-assessment.dto";


export default class HraAnswers extends Vue {
  @Prop()
  questions!: HRASurveyQuestionDetail[];
}
</script>

<style lang="sass" scoped>
.answers
  display: flex
  flex-direction: column
  width: 50%
  margin: 0 auto

.question
  @include Roboto700
  font-size: 16px
  color: $color-primary-hc-navy-100

.answer
  @include Roboto400
  font-size: 16px
  color: $color-primary-hc-navy-100
  padding-bottom: 10px

.answer-bottom
  @include Roboto400
  font-size: 16px
  color: $color-primary-hc-navy-100
  padding-bottom: 10px
  border-bottom: 1px solid $color-neutral-platinum-40
</style>
