<template>
  <div class="onboarding-card-list">
    <onboarding-card
      v-for="(card, index) in onboardingCards"
      :key="index"
      :title="card.title"
      :text="card.text"
      :image="card.image"
      :class="card.class"
    />
  </div>
</template>

<script lang="ts" setup>
import OnboardingCard from "@/vue/molecules/onboarding-card.vue";
import trackYourProgress from "@/assets/img/onboarding-photos/track-your-progress.svg";
import receiveSupport from "@/assets/img/onboarding-photos/receive-support.svg";
import findInspiration from "@/assets/img/onboarding-photos/find-inspiration.svg";
import buildACommunity from "@/assets/img/onboarding-photos/build-a-community.svg";
import earnRewards from "@/assets/img/onboarding-photos/earn-rewards.svg";
import stayConnected from "@/assets/img/onboarding-photos/stay-connected.svg";

type OnboardingCardType = {
  title: string;
  text: string;
  image: string;
  class: string;
};

const onboardingCards: OnboardingCardType[] = [
  {
    title: "Track Your Progress",
    text: "Monitor steps, activity, nutrition, sleep, and more for valuable health insights.",
    image: trackYourProgress,
    class: "precise",
  },
  {
    title: "Receive Support",
    text: "Get the right support at the right time with our team of health coaches.",
    image: receiveSupport,
    class: "precise",
  },
  {
    title: "Find Inspiration",
    text: "Explore learning pathways, engaging activities, and exciting challenges to fuel your health journey.",
    image: findInspiration,
    class: "precise",
  },
  {
    title: "Build a Community",
    text: "Grow with friends as you accomplish your wellness goals.",
    image: buildACommunity,
    class: "precise",
  },
  {
    title: "Earn Rewards",
    text: "Get rewarded for completing activities and improving your health.",
    image: earnRewards,
    class: "precise",
  },
  {
    title: "Stay Connected",
    text: "Download the app to access your health information at the tap of a finger.",
    image: stayConnected,
    class: "precise",
  },
];
</script>

<style lang="sass" scoped>
.onboarding-card-list
  padding-left: 2rem
  padding-right: 2rem
  display: flex
  flex-direction: column
  max-width: 1156px
  margin: 104px auto auto
  gap: 96px

.precise :deep(img)
  bottom: -25px
</style>
