<template>
  <div :class="{['big-icon']: big}">
    <squared-icon :icon-type="type"/>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import SquaredIcon from "@/vue/atoms/squared-icon.vue";
import type {TaskType} from "@/ts/types/dto/activity.dto";

type IconType = TaskType | "generic";

@Component({
  name: "ChallengeIcon",
  components: {
    SquaredIcon,
  },
})

export default class ChallengeIcon extends Vue {
  @Prop({default: "generic"}) type!: IconType;

  @Prop({default: false}) big?: boolean;
}
</script>

<style lang="sass" scoped>
div
  color: $color-tertiary-orange-sunset-100
  width: 48px
  height: 48px

  .big-icon
    width: 60px
    height: 60px
</style>
