<template>
  <div class="activity">
    <img :src="icon" alt="reward icon"/>
    <div class="activity-description">
      <span>{{ name }}</span>
      <span class="activity-detail">{{ details }} {{ label || '' }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";

@Component({
  name: 'PathwayInfo'
})
export default class PathwayInfo extends Vue {
  @Prop()
  name!: string;

  @Prop()
  details!: number;

  @Prop()
  icon!: string;

  @Prop({ default: '' })
  label!: string;
}
</script>

<style lang="sass" scoped>
.activity
  display: flex
  align-items: center
  margin-right: 20px
  flex-direction: row

.activity-description
  @include Roboto400
  display: flex
  font-size: 14px
  align-items: flex-start
  margin-bottom: 10px
  flex-direction: column
  margin-left: 0.5rem
  color: $color-primary-hc-navy-100

.activity-detail
  @include Roboto600
  color: $color-secondary-sea-foam-100
</style>
