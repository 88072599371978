<template>
  <div class="avatar">
    <user-avatar
      :avatar="avatar"
      :is-me="joinedByYou"
      :firstname="firstName"
      :lastname="lastName"
      size="small"
      :user-status="userStatus"
    />

    <p :class="joinedByYou ? 'you-color' : 'user-color'">
      {{ fullName }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import UserAvatar from "@/vue/atoms/user-avatar.vue";
import {computed} from "vue";
import {UserStatus} from "@/ts/types/dto/profile.dto";

const props = defineProps<{
  avatar: string;
  firstName: string;
  lastName: string;
  joinedByYou?: boolean;
  userStatus?: UserStatus;
}>();

const fullName = computed((): string => {
  if (props.joinedByYou) {
    return "You";
  }

  if (props.userStatus === UserStatus.anonymized) {
    return "Deleted Account";
  }

  return `${props.firstName} ${props.lastName}`;
});

</script>

<style lang="sass" scoped>
.avatar
  display: flex
  flex-direction: row
  align-items: center
  margin: 1rem 4rem 1rem 0
  gap: 16px

  p
    @include Roboto700
    font-size: 14px
    line-height: 22px
    text-transform: capitalize

.user-color
  color: $color-primary-hc-navy-100

.you-color
  color: $color-tertiary-orange-sunset-100
</style>
