<template>
  <navbar-main
    title="My Health"
    :profile-section="true"
    :reward-points="true"
    :hasTabs="true"
    class="custom body"
    slot-custom="custom"
  >
    <template #tabs>
      <tabs-navigation :items="healthTabs" />
    </template>
    <router-view></router-view>  
  </navbar-main>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import NavbarMain from "@/vue/templates/navbar-main.vue";
import TabsNavigation from "@/vue/atoms/tabs-navigation.vue";
import type {Tab} from "@/ts/types/component/tabs-navigation";
import {pagesPath} from "@/ts/router/pages-path";
import {getModule} from "vuex-module-decorators";
import {DefaultStore} from "@/ts/store/default/default-store";
import {vueStore} from "@/ts/store/vue-store";

const defaultStore = getModule(DefaultStore, vueStore);

@Component({
  name: "MyHealthPage",
  components: {
    NavbarMain,
    TabsNavigation
  },
})
export default class MyHealthPage extends Vue {
  get healthTabs(): Tab[] {
  if (defaultStore.profile?.myCareEnabled) {
    return [
      {
        link: pagesPath.myHealthPath.myhealthCheckScore,
        title: "My healthcheck score",
      },
      {
        link: pagesPath.myHealthPath.myhealthCare360,
        title: "MYCARE360",
      },
      {
        link: pagesPath.myHealthPath.myhealthPastResults,
        title: "past results",
      },
    ];
  }

  return [
    {
      link: pagesPath.myHealthPath.myhealthCheckScore,
      title: "My healthcheck score",
    },
    {
      link: pagesPath.myHealthPath.myhealthPastResults,
      title: "past results",
    },
  ];
}
}
</script>

<style lang="sass" scoped>
.custom
  min-height: 100vh
  display: flex
  flex-direction: column

.body
  background-image: url("@/assets/img/header-wave-background-2.svg")
  background-position-y: -3rem
  background-repeat: no-repeat
  background-size: contain
  color: $color-primary-hc-navy-100
  @include Asap700
  position: absolute
  height: 100%
  width: 100%  
  padding-left: 100px

@media (min-width: 1800px)

  .body
    padding-left: 280px

@media (min-width: 1500px)

  .body
    background-position-y: -5rem

@media (max-width: 1300px)

  .body
    background-position-y: 0rem

@media (max-width: 1000px)

  .body
    background-position-y: 2rem
</style>
