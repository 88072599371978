<template>
  <div class="description-finish">
    <div class="completed">
      <img src="@/assets/img/illustrations/completed-illustration.svg" />
    </div>
    <h1>Congratulations!</h1>
    <p>You have completed the HRA.</p>
  </div>
  <button-link :to="$pagesPath.main.home" theme="white" class="button">
    Got it!
  </button-link>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import ButtonLink from "@/vue/atoms/button-link.vue";

@Component({
  name: "HraFinishPage",
  components: {
    ButtonLink,
  },
})
export default class HraFinishPage extends Vue {
}
</script>

<style lang="sass" scoped>
.description-finish
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 10rem

.completed
  height: 200px
  width: 200px

h1
  @include Asap700
  color: $color-primary-hc-navy-100
  font-size: 28px

p
  @include Roboto400
  font-size: 16px
  color: $color-primary-hc-navy-100

.button
  display: flex
  align-items: center
  justify-content: center
  margin-top: 2rem
</style>
