<template>
  <navbar-page
    title="Settings"
    class="body"
    :display-right-nav="false"
    :back-link="$pagesPath.profile.settings"
  >
    <div class="content">
      <h2>Change Password</h2>
      <password-rules class="password-rules"/>
      <form class="form" @submit.prevent="changePassword">
        <field-set id="current-password" label="Current Password">
          <input-password
            id="current-password"
            v-model="currentPassword"
            autocomplete="current-password"
          />
        </field-set>

        <password-change
          @password-has-error="onPasswordHasError"
          @password-changed="onPasswordChanged"
          @confirm-password-changed="onConfirmPasswordChanged"
        />
        <error-text :error="error"/>
        <div class="button-holder">
          <button-primary
            text="Submit"
            type="submit"
            :loading="loading"
          />
        </div>
      </form>
    </div>
  </navbar-page>
</template>
<script lang="ts">
import {Component} from "vue-property-decorator";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import {mixins} from "vue-class-component";
import InputPassword from "@/vue/atoms/input-password.vue";
import FieldSet from "@/vue/molecules/field-set.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {GrowlsStoreMixin} from "@/ts/store/growl/growl-store-instance";
import ErrorText from "@/vue/atoms/error-text.vue";
import PasswordRules from "@/vue/molecules/password-rules.vue";
import PasswordChange from "@/vue/molecules/password-change.vue";

@Component({
  name: "ChangePasswordPage",
  components: {
    ErrorText,
    ButtonPrimary,
    FieldSet,
    InputPassword,
    NavbarPage,
    PasswordRules,
    PasswordChange,
  },
})
export default class ChangePasswordPage extends mixins(LoadingMixin, GrowlsStoreMixin) {
  confirmPassword: string = "";

  currentPassword: string = "";

  newPassword: string = "";

  isButtonDisabled: boolean = true;

  @DefaultGrowlError
  async changePassword(): Promise<void> {
    await this.$api.changePassword({
      new: this.newPassword,
      current: this.currentPassword,
      newConfirmation: this.confirmPassword,
    });
    void this.growlsStore.growlInfo("Password has been updated");
  }

  onPasswordHasError(passwordHasError: boolean): void {
    this.isButtonDisabled = Boolean(!this.currentPassword || passwordHasError);
  }

  onPasswordChanged(password: string): void {
    this.newPassword = password;
  }

  onConfirmPasswordChanged(confirmPassword: string): void {
    this.confirmPassword = confirmPassword;
  }
}
</script>
<style lang="sass" scoped>
.body
  padding-left: 100px

h2
  @include Asap700
  font-size: 20px
  color: $color-primary-hc-navy-100
  margin-top: 0

.content
  padding: 0 20px 20px
  display: flex
  flex-direction: column

  margin: auto
  align-items: center
  gap: 24px

  > *
    max-width: 448px
    width: 100%

.form
  display: flex
  flex-direction: column
  gap: 24px

.button-holder
  display: flex
  justify-content: center
  margin-top: 80px
</style>
