<template>
  <empty-state
    :image="emptyStateImage"
    title=""
    description="You will find this Feature in our mobile App."
  />
</template>
<script lang="ts" setup>
import EmptyState from "@/vue/atoms/empty-state.vue";
import useAppImage from "@/assets/img/illustrations/connected-devices.svg";
import {computed} from "vue";

const emptyStateImage = computed((): string => {
  return useAppImage;
});

</script>
