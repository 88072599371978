<!-- eslint-disable max-lines -->
<template>
  <div class="wrapper">
    <div class="header">
      <div>Health Risk Assessment</div>
      <router-link :to="$pagesPath.myHealthPath.myhealth" class="button-back">
        <img src="@/assets/img/x-mark.svg" alt="close"/>
      </router-link>
    </div>
    <loading-bar :steps="orderedQuestionsToDisplay.length" :current="indexOfCurrentQuestionInOrderedList"/>
    <div class="title-wrapper">
      <h1 class="title">
        <span v-if="question?.isRequired">*</span> {{ question?.question }}
      </h1>
      <h3 v-if="isMultiple" class="description">
        You may select one or more answers
      </h3>
    </div>
    <div class="option">
      <multiple-checkbox
        v-if="selected"
        v-model="selected"
        :allow-multiple="isMultiple"
        :values="optionList"
        class="multiple-checkbox"
        theme="purple"
      />
      <input-text
        v-if="isAnswerText"
        :model-value="textAnswer"
        class="input-text"
        :required="true"
        @update:model-value="textAnswerUpdate"
      />
    </div>
    <hra-followup-modal
      v-if="isOpen"
      :question="medicationQuestion"
      :already-selected="medicationQuestionAnswerSelected"
      @selected="selectMedicationAnswer"
    />
    <div class="buttons">
      <button-primary
        v-if="prevPath"
        class="button-next"
        theme="white"
        @click="back"
      >
        Back
      </button-primary>
      <button-primary
        v-if="nextPath"
        :disabled="nextBtnDisabled"
        class="button-next"
        :loading="loading"
        @click="next"
      >
        Next
      </button-primary>
    </div>
    <error-text :error="error"/>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import LoadingBar from "@/vue/atoms/loading-bar.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import MultipleCheckbox from "@/vue/molecules/multiple-checkbox.vue";
import HraFollowupModal from "@/vue/organisms/hra-followup-question.vue";
import type {MultipleCheckboxItem} from "@/ts/types/component/input.select";
import ErrorText from "@/vue/atoms/error-text.vue";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {
  HRASurveyAnswerType,
  HRASurveyAnswerSelectionType,
} from "@/ts/types/dto/health-risk-assessment.dto";
import type {
  HRASurvey,
  HRASurveyQuestionDetail,
  HRASurveyAnswer,
  HRAQuestionChoiceDetail,
  HRATakingMedicationsQuestion,
  HRATakingMedicationsQuestionChoiceDetail,
} from "@/ts/types/dto/health-risk-assessment.dto";
import InputText from "@/vue/atoms/input-text.vue";
import {orderQuestionsToDisplay, isValid} from "@/ts/utils/hra/hra-quiz";
import {buildQuestionOption, getQuestionPath, buildMedicationQuestionOption, convertFromStringToBoolean} from "@/ts/utils/hra/common";

@Component({
  name: "HraQuizPage",
  components: {
    ErrorText,
    MultipleCheckbox,
    ButtonPrimary,
    LoadingBar,
    HraFollowupModal,
    InputText,
  },
})
export default class HraQuizPage extends mixins(LoadingMixin) {
  isOpen: boolean = false;

  hraResponse: HRASurvey | null = null;

  selected: MultipleCheckboxItem<HRAQuestionChoiceDetail>[] = [];

  textAnswer: string | null = null;

  nextBtnDisabled: boolean = true;

  medicationQuestionAnswerSelected: MultipleCheckboxItem<HRATakingMedicationsQuestionChoiceDetail>[] = [];

  get questionId(): number {
    return parseInt(this.$route.query.id as string, 10);
  }

  get questionType(): string {
    return this.$route.query.type as string;
  }

  get answerType(): HRASurveyAnswerType | undefined {
    return this.question?.answerType;
  }

  get isAnswerText(): boolean {
    return this.answerType === HRASurveyAnswerType.text;
  }

  get isMultiple(): boolean {
    return this.question?.selectionType === HRASurveyAnswerSelectionType.multiple;
  }

  get questionIndex(): number | null {
    const index = this.hraResponse?.questions
      .findIndex((question) => question.id === this.questionId && question.type === this.questionType) ?? null;

    return index === -1 ? null : index;
  }

  get question(): HRASurveyQuestionDetail | null {
    return this.hraResponse?.questions[this.questionIndex!] ?? null;
  }

  get optionList(): MultipleCheckboxItem<HRAQuestionChoiceDetail>[] {
    return this.question?.selections?.map(buildQuestionOption) ?? [];
  }

  get medicationQuestion(): HRATakingMedicationsQuestion | null {
    if (!this.hraResponse || !this.question) {
      return null;
    }

    const idsOfSelected = this.selected.map((sel) => sel.id);

    function isOptionSelected(option: HRAQuestionChoiceDetail): boolean {
      return idsOfSelected.includes(option.value);
    }

    const currentQuestionSelectedOptions = this.question.selections?.filter(isOptionSelected);

    function hasOptionTakingMedicationsQuestion(option: HRAQuestionChoiceDetail): boolean {
      return option.takingMedicationsQuestion?.answerType === HRASurveyAnswerType.selection;
    }

    const takingMedicationsQuestion = currentQuestionSelectedOptions?.find(hasOptionTakingMedicationsQuestion)?.takingMedicationsQuestion;

    return takingMedicationsQuestion ?? null;
  }

  get indexOfCurrentQuestionInOrderedList(): number {
    return this.orderedQuestionsToDisplay.findIndex((el) => el === this.questionIndex);
  }

  get nextPath(): string {
    const nextQuestionPath = getQuestionPath({
      orderedQuestionIndex: this.indexOfCurrentQuestionInOrderedList + 1,
      orderedQuestionsToDisplay: this.orderedQuestionsToDisplay,
      hraSurvey: this.hraResponse!,
    });

    return nextQuestionPath ?? this.$pagesPath.healthRiskAssessment.quizOverviewHra;
  }

  get prevPath(): string | null {
    return getQuestionPath({
      orderedQuestionIndex: this.indexOfCurrentQuestionInOrderedList - 1,
      orderedQuestionsToDisplay: this.orderedQuestionsToDisplay,
      hraSurvey: this.hraResponse!,
    });
  }

  get orderedQuestionsToDisplay(): number[] {
    return orderQuestionsToDisplay({
      hraResponse: this.hraResponse!,
      currentQuestionIndex: this.questionIndex!,
      currentQuestionSelectedOptions: this.selected,
    });
  }

  updateNextBtnDisabled(): void {
    if (!this.question) {
      return;
    }
    this.nextBtnDisabled = Boolean(!isValid({
      question: this.question,
      selected: this.selected,
      textAnswer: this.textAnswer,
      medicationQuestion: this.medicationQuestion,
      medicationQuestionAnswerSelected: this.medicationQuestionAnswerSelected,
    }));
  }

  @DefaultGrowlError
  back(): void {
    void this.$router.push(this.prevPath!);
  }

  @DefaultGrowlError
  async next(): Promise<void> {
    if (this.nextBtnDisabled) {
      return;
    }

    const isTakingMedications = this.medicationQuestion && this.medicationQuestionAnswerSelected[0]
      ? Boolean(convertFromStringToBoolean(this.medicationQuestionAnswerSelected[0].id))
      : false;

    const data: HRASurveyAnswer = {
      surveySessionId: this.hraResponse!.id,
      questionType: this.question!.type,
      questionId: this.question!.id,
      answers: this.answerType === HRASurveyAnswerType.selection ? this.selected!.map((el) => el.id) : [this.textAnswer!],
      isTakingMedications,
    };

    await this.$api.postHRASurveyAnswer(data);
    void this.$router.push(this.nextPath);
  }

  @Watch("medicationQuestion")
  medicationQuestionChanged(): void {
    this.medicationQuestionAnswerSelected = this.medicationQuestion?.selections
      ? this.medicationQuestion.selections?.filter((sel) => sel.selected)
        .map(buildMedicationQuestionOption)
      : [];
  }

  @Watch("medicationQuestionAnswerSelected")
  medicationQuestionAnswerSelectedChanged(): void {
    this.updateNextBtnDisabled();
  }

  async setup(): Promise<void> {
    if (!this.$route.params.id) {
      return;
    }

    this.isOpen = false;
    this.nextBtnDisabled = true;
    this.selected = [];
    this.textAnswer = null;
    this.medicationQuestionAnswerSelected = [];

    this.hraResponse = await this.$api.getHRASurvey(this.$route.params.id as string);

    if (!this.question) {
      return;
    }

    if (this.answerType === HRASurveyAnswerType.selection) {
      this.selected = this.question.selections
        .filter((se) => se.selected)
        .map(buildQuestionOption);
    } else {
      this.textAnswer = this.question.textValue;
    }
  }

  @Watch("$route")
  async routeChanged(): Promise<void> {
    await this.setup();
  }

  @Watch("selected")
  selectedChanged(): void {
    this.isOpen = Boolean(this.medicationQuestion);
    this.updateNextBtnDisabled();
  }

  selectMedicationAnswer(selected: MultipleCheckboxItem<HRATakingMedicationsQuestionChoiceDetail>[]): void {
    this.medicationQuestionAnswerSelected = selected;
  }

  textAnswerUpdate(text: string): void {
    this.textAnswer = text;
  }

  async created(): Promise<void> {
    await this.setup();
  }
}
</script>

<style lang="sass" scoped>

.container
  width: auto


.header
  @include Asap700
  font-size: 20px
  line-height: 28px
  text-align: center
  color: $color-primary-hc-navy-100

  display: grid
  grid-template-columns: 1fr repeat(1, auto) 1fr
  grid-column-gap: 5px
  justify-items: center
  align-items: center
  padding: 60px 70px 27px

  > div
    grid-column-start: 2

.button-back
  margin-left: auto
  cursor: pointer

.title-wrapper
  @include Asap700
  color: $color-primary-hc-navy-100
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  text-align: center

.required-error
  @include Roboto400
  text-align: center
  color: $color-status-error-red
  letter-spacing: 0.4px
  font-size: 14px

.option
  display: grid
  justify-content: center
  margin-top: 3rem

.buttons
  display: flex
  justify-content: space-evenly
  margin-top: 15rem
  margin-bottom: 20px

.input-text
  width: 600px
</style>
