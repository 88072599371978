<template>
  <div class="title">
    <img :src="iconImage"/>
    <p>{{ title }}</p>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import IconTrophy from "@/assets/img/trophy-icon.svg";
import IconCalendar from "@/assets/img/calendar-end-date-icon.svg";
import IconCompleted from "@/assets/img/check-mark-finished.svg";

@Component({
  name: "IconTitle",
  components: {},
})
export default class IconTitle extends Vue {
  @Prop() title!: string;

  @Prop() icon!: "calendar" | "completed" | "reward";

  get iconImage(): string {
    switch (this.icon) {
      case "calendar":
        return IconCalendar;
      case "reward":
        return IconTrophy;
      case "completed":
        return IconCompleted;

      default:
        return "";
    }
  }
}
</script>

<style lang="sass" scoped>
.title
  display: flex
  align-items: center
  gap: 8px
  color: $color-primary-hc-navy-100

  p
    @include Roboto400
    font-size: 14px
    line-height: 22px
    color: $color-primary-hc-navy-100
    margin: 0
</style>
