<template>
  <navbar-page
    title="Notifications"
    class="body"
    :display-right-nav="false"
    :back-link="$pagesPath.main.home"
  >
    <loading-suspense :loading="loading && !notifications.length" :error="error">
      <div class="container">
        <empty-state
          v-if="!notificationClusters.length"
          :image="SvgPersonPracticingYoga"
          title="You're all caught up!"
          description="No new notifications at this time."
          description-max-width="440px"
        />
        <div
          class="cluster"
          v-for="(notificationCluster, clusterIndex) in notificationClusters"
          :key="clusterIndex"
        >
          <h2 :aria-label="notificationCluster.ariaHeadline">
            <display-day :date="notificationCluster.date"/>
          </h2>
          <ul class="notifications">
            <li
              v-for="notification in notificationCluster.items"
              :key="notification.id"
            >
              <template v-if="!!notificationPath(notification.target)">
                <router-link class="link" :to="notificationPath(notification.target)">
                  <notification :notification="notification" @read="markNotificationAsRead"/>
                </router-link>
              </template>
              <template v-else>
                <notification :notification="notification" @read="markNotificationAsRead"/>
              </template>
            </li>
          </ul>
        </div>

        <div class="more-btn-container">
          <button-primary
            v-if="numTotalNotifications > notifications.length"
            text="Load more notifications"
            :loading="loading"
            @click="fetchNotifications"
          />
        </div>
      </div>
    </loading-suspense>
  </navbar-page>
</template>

<script lang="ts" setup>
import NavbarPage from "@/vue/templates/navbar-page.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import SvgPersonPracticingYoga from "@/assets/img/illustrations/no-activities-available-1.svg";
import Notification from "@/vue/molecules/notification.vue";
import {computed, inject, onMounted, Ref, ref} from "vue";
import {Api} from "@/ts/classes/api";
import {formatDateShortMonthName, getStartOfTheDay, isSameDay, parseDate} from "@/ts/utils/date-pure-functions";
import type {NotificationDTO, NotificationClusterDTO} from "@/ts/types/dto/notification.dto";
import EmptyState from "@/vue/atoms/empty-state.vue";
import DisplayDay from "@/vue/atoms/display-day.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import {NotificationTarget} from "@/ts/types/dto/notification.dto";
import {pagesPath} from "@/ts/router/pages-path";

const $api = inject("$api")! as Api;

const notifications: Ref<NotificationDTO[]> = ref<NotificationDTO[]>([]);
const numTotalNotifications: Ref<number> = ref(0);
const loading: Ref<boolean> = ref(false);
const error: Ref<string> = ref('')

const notificationClusters = computed((): NotificationClusterDTO[] => {
  const clusters: NotificationClusterDTO[] = [];
  notifications.value.forEach((item, index) => {
    if (clusters.length > 0 && isSameDay(clusters[clusters.length - 1].date, item.createdAt)) {
      // add item to existing cluster
      clusters[clusters.length - 1].items.push(item);
    } else {
      // create new cluster
      const date = getStartOfTheDay(parseDate(item.createdAt));
      clusters.push({
        items: [item],
        date,
        ariaHeadline: `All posts from ${formatDateShortMonthName(date)}`,
        firstItemIndex: index,
      });
    }
  });
  return clusters;
});

const fetchNotifications = async(): Promise<void> => {
  loading.value = true;
  // load 10 items first time and 100 more on demand
  const limit = notifications.value.length ? 100 : 10; // eslint-disable-line @typescript-eslint/no-magic-numbers
  const offset = notifications.value.length;
  const paginatedNotifications = await $api.getNotifications(limit, offset);

  const existingItems = notifications.value;
  const newItems: NotificationDTO[] = paginatedNotifications.items.map((newItem) => {
    /*
     * remove doubled items with same ID
     * doubled items will occur if somebody creates a new post after loading the first bunch of items and
     * before clicking the "more" button
     */
    const alreadyExist = Boolean(existingItems.find((existingItem) => existingItem.id === newItem.id));
    if (alreadyExist) {
      return null;
    }
    return newItem;
  }).filter((item) => Boolean(item)) as NotificationDTO[];

  notifications.value = [
    ...existingItems,
    ...newItems,
  ];

  numTotalNotifications.value = paginatedNotifications.pagination.total;
  loading.value = false;
};

onMounted(() => { fetchNotifications() })

const notificationPath = (target: NotificationTarget):string => {
  const targetToPath: any = {
    [NotificationTarget.activity_starts_today]: pagesPath.program.programActivities,
    [NotificationTarget.activity_end_soon]: pagesPath.program.programActivities,
  }

  return targetToPath[target] || ''
}

const markNotificationAsRead = async (notification: NotificationDTO) => {
  try {
    await $api.markNotificationsAsRead([notification.id])
    notification.isRead = true
  } catch (e) {
    console.error(e)
  }
}
</script>

<style lang="sass" scoped>
.body
  background-color: $color-neutral-platinum-40
  background-size: contain
  color: $color-primary-hc-navy-100
  @include Asap700
  width: 100%
  height: 100%
  min-height: 100vh
  padding-left: 100px

.container
  @include container

.cluster
  max-width: 685px
  margin: 0 auto

h4
  margin: 0
  color: $color-primary-hc-navy-100
  text-align: center
  @include Roboto700
  font-size: 16px
  letter-spacing: 0.1px

  span
    @include Roboto400

h2
  color: $color-primary-hc-navy-100
  @include Roboto700
  font-size: 20px

.notifications
  margin: 0
  padding: 0 0 20px 0
  display: flex
  flex-direction: column
  gap: 2px
  list-style: none

.more-btn-container
  display: flex
  justify-content: center
  padding: 32px

.link
  text-decoration: none
</style>
