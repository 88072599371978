<template>
  <div :class="className">
    <slot/>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";

@Component({
  name: "BorderedBox",
  components: {
  },
})
export default class BorderedBox extends Vue {
  @Prop({default: "bordered"}) theme!: "bordered" | "light-gray" | "white";

  get className(): string {
    return `box ${this.theme}`;
  }
}
</script>
<style lang="sass" scoped>
.box
  display: flex
  flex-direction: column
  border-radius: 8px
  padding: 0

.bordered
  border: 1.5px solid $color-neutral-platinum-100
  background-color: $color-white

.white
  background-color: $color-white
  border: none

.light-gray
  background-color: $color-neutral-platinum-40
  border: none
</style>
