<template>
  <loading-suspense :error="error" :loading="loading">
    <empty-state
      v-if="!anyChallenges"
      :image="noChallengeImage"
      title="No Challenges Available"
      description="Please check back later!"
    />
    <main v-if="anyChallenges" class="content">
      <div class="point-cap-tracker">
        <point-cap-tracker
          v-if="challengeCapRule !== null && showCapTracker"
          :rule="challengeCapRule"
        />
      </div>
      <challenge-card-row title="Active" :challenges="activeChallenges"/>
      <challenge-card-row title="Joined & Active Soon" :challenges="joinedChallenges"/>
      <challenge-card-row title="Available" :challenges="availableChallenges"/>
      <challenge-card-row title="Completed" :challenges="completedChallenges"/>
    </main>
  </loading-suspense>
  <add-tracking-button/>
</template>

<script lang="ts">
import {Component, Watch} from "vue-property-decorator";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import ChallengeCardRow from "@/vue/molecules/challenge-card-row.vue";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import type {ChallengeDTO, PaginatedChallengeDTO} from "@/ts/types/dto/challenges.dto";
import EmptyState from "@/vue/atoms/empty-state.vue";
import noChallengeImage from "@/assets/img/illustrations/no-activities-available-2.svg";
import {ChallengeStatus} from "@/ts/types/component/challenges";
import {determineChallengeStatus} from "@/ts/utils/pure-functions";
import AddTrackingButton from "@/vue/atoms/add-tracking-button.vue";
import { ActivityCardModel } from "@/ts/types/component/activity-card";
import { ActivityDTO } from "@/ts/types/dto/activity.dto";
import PointCapTracker from "@/vue/molecules/point-cap-tracker.vue";
import {PointCapUISelect, UserPointCapTotalsDTO} from "@/ts/types/dto/reward.dto";
import {GetProgramResponseDTO} from "@/ts/types/dto/program.dto";

@Component({
  name: "ChallengeProgramPage",
  components: {
    PointCapTracker,
    AddTrackingButton,
    ChallengeCardRow,
    LoadingSuspense,
    EmptyState,
  },
})
export default class ChallengeProgramPage extends mixins(LoadingMixin) {
  challenges: ChallengeDTO[] = [];
  activeChallenges?: PaginatedChallengeDTO;
  completedChallenges?: PaginatedChallengeDTO;
  availableChallenges?: PaginatedChallengeDTO;
  joinedChallenges?: PaginatedChallengeDTO;
  gracePeriodChallenges?: PaginatedChallengeDTO;
  anyChallenges: boolean = false;
  userPointTotals: UserPointCapTotalsDTO[] = [];
  program?: GetProgramResponseDTO;
  challengeCapRule: UserPointCapTotalsDTO | null = null;
  showCapTracker: boolean = false;

   getAvailableChallenges(): Promise<PaginatedChallengeDTO> {
    return this.$api.getChallenges({
        onlyAvailable: true,
        limit: 100,
      });
  }

     getActiveChallenge()  :  Promise<PaginatedChallengeDTO> {
    return this.$api.getChallenges({
        onlyActive: true,
        limit: 100,
      });
      
    
  }

   getCompletedChallenges(): Promise<PaginatedChallengeDTO> {
    return this.$api.getChallenges({
        onlyCompleted: true,
        limit: 100,
      });
  }

  getjoinedChallenges():  Promise<PaginatedChallengeDTO> {
    return this.$api.getChallenges({
        onlyJoined: true,
        limit: 100,
      });
  }
  getGracePeriodChallenges():  Promise<PaginatedChallengeDTO> {
    return this.$api.getChallenges({
        onlyGrace: true,
        limit: 100,
      });
  }
  get noChallengeImage(): string {
    return noChallengeImage;
  }

  @DefaultGrowlError
  async created(): Promise<void> {

    
    const [activeResponse, availableResponse, completedResponse,joinedResponse,graceResponse] = await Promise.allSettled([
    this.getActiveChallenge(),
    this.getAvailableChallenges(),
    this.getCompletedChallenges(),
    this.getjoinedChallenges(),
    this.getGracePeriodChallenges(),
    ]);
    if (activeResponse.status === "fulfilled") {
      this.activeChallenges = activeResponse?.value
    }

    if (availableResponse.status === "fulfilled") {
      this.availableChallenges = availableResponse?.value;

    }

    if (completedResponse.status === "fulfilled") {
      this.completedChallenges = completedResponse?.value;

    }
    if (joinedResponse.status === "fulfilled") {
      this.joinedChallenges = joinedResponse?.value;

    }
    if (graceResponse.status === "fulfilled") {
      this.gracePeriodChallenges = graceResponse?.value;

      if(this.completedChallenges?.items != undefined){
        this.completedChallenges.items = this.completedChallenges?.items.concat(this.gracePeriodChallenges.items);
        this.completedChallenges.pagination.total += this.gracePeriodChallenges.pagination.total
      }
      //TODO: maybe handle this combination in terms of the pagination params

    }
    // TODO: handle pagination and offset with slider/scroll

    this.anyChallenges = Boolean(
      (this.activeChallenges?.items?.length || 0) +
      (this.availableChallenges?.items?.length || 0) +
      (this.completedChallenges?.items?.length || 0) +
      (this.joinedChallenges?.items?.length || 0) +
      (this.gracePeriodChallenges?.items?.length || 0)
    );
    this.program = await this.$api.getProgram(true);

    const response = await this.$api.getUserPointCapTotals(this.program.id);
    this.userPointTotals = response?.userPointCapTotal ?? [];
    this.showCapTracker = response?.uiSelection === PointCapUISelect.content_page;


  }

  @Watch("userPointTotals", { deep: true, immediate: true })
  computeChallengeCapRule(): void {
     this.challengeCapRule =
         this.userPointTotals.find((rule) => rule.pointCapRule.ruleType === "content" &&
             rule.pointCapRule.contentType === "leaderboard_challenge") || null;
  }
}
</script>

<style lang="sass" scoped>
.content
  margin-top: 4rem

.point-cap-tracker
  display: flex
  justify-content: flex-start
  margin-left: 4rem
  margin-bottom: 1rem

  @media (min-width: 1800px)
    margin-right: 5rem
</style>
