<template>
  <div class="card-box">
    <div class="text">
      <h3>{{ text.title }}</h3>
      <p>{{ text.description }}</p>
    </div>
    <div>
      <input
        id="upload-file"
        type="file"
        name="upload-file"
        accept="application/pdf"
        required
        :disabled="disabled"
        class="upload-file"
        @change="fileUploaded"
      />
      <label ref="upload-file" for="upload-file" :class="`upload-btn ${disabled ? 'disabled' : ''}`">
        <img src="@/assets/img/file-download-icon.svg" alt="upload"/>{{
          uploadButtonText
        }}</label>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Prop, Emit} from "vue-property-decorator";
import ButtonLink from "@/vue/atoms/button-link.vue";

@Component({components: {ButtonLink}})
export default class PhysicianFormCardButton extends Vue {
  @Prop() disabled!: boolean;

  isUploaded: boolean = false;

  get text(): any {
    return this.isUploaded
      ? {title: "File Uploaded"}
      : {
        title: "Upload file form",
        description:
          "Please upload the medical results, signed by your physician.",
      };
  }

  get uploadButtonText(): string {
    return this.isUploaded ? "Change file" : "Upload file";
  }

  @Emit("fileUploaded")
  fileUploaded(event: Event): File {
    const {files} = event.target as HTMLInputElement;

    if (files?.length) {
      this.isUploaded = true;
    }
    // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
    return files?.[0] as File;
  }
}
</script>

<style lang="sass" scoped>
.card-box
  padding: 24px
  width: 681px
  background-color: $color-neutral-platinum-40
  border-radius: 16px
  margin: 1rem 0
  display: flex
  flex-direction: column
  gap: 40px

  @include max-device(md)
    width: min-content

.text
  h3
    @include Asap700
    font-size: 20px
    line-height: 28px
    color: $color-primary-hc-navy-100
    margin: 0

  p
    @include Roboto400
    font-size: 16px
    line-height: 24px
    color: $color-primary-hc-navy-100
    margin: 8px 0

.upload-btn
  border-radius: 8px
  border: none
  cursor: pointer
  display: flex
  justify-content: center
  align-items: center
  @include Asap600
  font-size: 17px
  gap: 8px
  width: 260px
  height: 45px
  line-height: 20px
  padding: 12px 0
  background-color: $color-primary-hc-navy-100
  color: $color-white

  &.disabled
    background-color: $color-primary-hc-navy-50
    border: 1px solid $color-primary-hc-navy-50
    opacity: 0.2
    cursor: not-allowed

.upload-file
  color: transparent
  background-color: transparent
  position: absolute
  z-index: -1

.upload-file:focus + .upload-btn
  outline: 2px solid $color-secondary-sea-foam-100

</style>
