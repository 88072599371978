<!--

Implements the ARIA listbox pattern.
https://www.w3.org/WAI/ARIA/apg/patterns/listbox/

In order to style the option-items set custom classnames to 'optionClassName' and 'optionActiveClassName'
and add the stylesheet in the parent component with "&:deep(.your-class)"

Example:
<aria-list-box
  class="listbox"
  option-class-name="listbox__option"
  option-active-class-name="listbox__option--active"
  ...
>
  <template #item="templateProps">
    {{ templateProps.option.your-property }}
  </template>
</aria-list-box>

.listbox
  ... your styling here

  &:deep(.listbox__option)
    ... your styling here

  &:focus-visible:deep(.listbox__option--active)
    outline: 2px solid blue

-->

<template>
  <div
    ref="listBoxEl"
    role="listbox"
    tabindex="0"
    aria-multiselectable="true"
    :aria-label="ariaLabel"
    :aria-activedescendant="optionElIdPrefix + activeDescendantId"
    :aria-orientation="orientation"
    @keydown.up.prevent.stop="onKeyArrowUp"
    @keydown.down.prevent.stop="onKeyArrowDown"
    @keydown.left.prevent.stop="onKeyArrowLeft"
    @keydown.right.prevent.stop="onKeyArrowRight"
    @keyup.space.prevent.stop="onSelectOptionByKeyboard"
    @keyup.home.prevent.stop="onKeyHome"
    @keyup.end.prevent.stop="onKeyEnd"
    @focus="onFocus"
    @blur="onBlur"
  >
    <div
      v-for="(option, index) in options"
      :id="optionElIdPrefix + option.id"
      :key="option.id"
      :class="(cssClass + ' ' + ((focused && index === currentIndex) ? cssClassActive : '')).trim()"
      role="option"
      type="button"
      :aria-selected="selection.has(option.id)"
      @click="onSelectOption(option)"
    >
      <slot name="item" :option="option"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref} from "vue";

const props = defineProps<{
  options: any[];
  selection: Map<string, any>;
  ariaLabel: string;
  optionClassName?: string;
  optionActiveClassName?: string;
  orientation?: "horizontal" | "vertical";
}>();

const emit = defineEmits(["update:selection", "addedToSelection", "removedFromSelection"]);

const currentIndex = ref<number>(0);
const focused = ref<boolean>(false);

const componentId = `listbox-${Math.floor(Math.random() * Date.now()).toString(36)}`; // eslint-disable-line
const optionElIdPrefix = `${componentId}-option-`;

const activeDescendantId = computed((): string | undefined => {
  return props.options[currentIndex.value]?.id as string ?? undefined; // eslint-disable-line
});

const orientation = computed((): "horizontal" | "vertical" => {
  return props.orientation ?? "vertical";
});

const cssClass = computed(() => {
  return (props.optionClassName ?? "listbox-option");
});

const cssClassActive = computed(() => {
  return (props.optionActiveClassName ?? "listbox-option--active");
});

const onSelectOption = (selectedOption: any): void => { // eslint-disable-line
  if (props.selection.has(selectedOption.id)) {  // eslint-disable-line
    props.selection.delete(selectedOption.id);  // eslint-disable-line
    emit("removedFromSelection", selectedOption);
  } else {
    props.selection.set(selectedOption.id, selectedOption);  // eslint-disable-line
    emit("addedToSelection", selectedOption);
  }

  emit("update:selection", props.selection);
};

const onSelectOptionByKeyboard = (): void => {
  onSelectOption(props.options[currentIndex.value]);
};

const onKeyArrowUp = (): void => {
  if (orientation.value === "vertical") {
    focusButton(currentIndex.value - 1);
  }
};

const onKeyArrowDown = (): void => {
  if (orientation.value === "vertical") {
    focusButton(currentIndex.value + 1);
  }
};

const onKeyArrowLeft = (): void => {
  if (orientation.value === "horizontal") {
    focusButton(currentIndex.value - 1);
  }
};

const onKeyArrowRight = (): void => {
  if (orientation.value === "horizontal") {
    focusButton(currentIndex.value + 1);
  }
};

const onKeyHome = (): void => {
  focusButton(0);
};

const onKeyEnd = (): void => {
  focusButton(props.options.length - 1);
};

const onFocus = (): void => {
  let newIndex = props.options.findIndex((option: any) => props.selection.has(option.id)); // eslint-disable-line
  newIndex = newIndex < 0 ? 0 : newIndex;
  focused.value = true;
  focusButton(newIndex);
};

const onBlur = (): void => {
  focused.value = false;
};

const focusButton = (requestedIndex: number): void => {
  if (requestedIndex >= 0 && requestedIndex < props.options.length) {
    currentIndex.value = requestedIndex;
  }
};

</script>

<style lang="sass" scoped>

.listbox-option
  &--active
    outline: 2px solid $color-secondary-sea-foam-100

</style>
