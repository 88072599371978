<template>
  <div v-if="friends.length">
    <p class="description" @click="isOpen = true">
      Colleagues who already finished this activity
    </p>
    <div v-for="friend in friends" :key="friend.id" class="friends-list">
      <img :src="friend.avatarPath" alt="friend-picture"/>
    </div>
    <friends-finished-activity-modal v-model="isOpen" :friends="friends">
      <slot/>
    </friends-finished-activity-modal>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
} from "vue-property-decorator";
import type {FinishedFriend} from "@/ts/types/dto/pathway.dto";
import FriendsFinishedActivityModal from "@/vue/molecules/pathway/friends-finished-activity-modal.vue";
import {mixins} from "vue-class-component";
import {LoadingMixin} from "@/ts/mixins/loading-mixin";

@Component({
  name: "FriendsFinishedActivity",
  components: {
    FriendsFinishedActivityModal,
  },
})
export default class FriendsFinishedActivity extends mixins(LoadingMixin) {
  @Prop()
  friends!: FinishedFriend[];

  isOpen: boolean = false;
}
</script>

<style lang="sass" scoped>
.friends-list
  display: flex
  flex-direction: row
  align-items: center

.description
  display: flex
  justify-content: center
  align-self: center
  color: $color-primary-hc-navy-100
  @include Asap700
  cursor: pointer

</style>
