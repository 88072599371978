<template>
  <div class="circles">
    <svg
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      :data-value="score"
    >
      <circle r="45" cx="50" cy="50"/>
      <path
        :class="`progress ${riskColor}`"
        d="M5,50a45,45 0 1,0 90,0a45,45 0 1,0 -90,0"
        stroke-linecap="square"
        stroke-linejoin="round"
        stroke-dashoffset="282.78302001953125"
        stroke-dasharray="282.78302001953125"
      />
    </svg>
    <div class="round">
      <div class="score">
        <div :class="['value-score', {'active': score}]">
          {{ score || "--" }}
        </div>
        <p :class="['text', {'active': score}]">
          Out of {{ maxScore || "--" }}
        </p>
        <slot/>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {onMounted, watch} from "vue";
import type {RiskColor} from "@/ts/types/dto/biometric-screening.dto";

const props = defineProps<{
  score?: number;
  maxScore?: number;
  riskColor?: RiskColor;
}>();

onMounted(() => {
 drawProgress();
});

watch(() => props.score, () => {
 drawProgress();
});

const drawProgress = (): void => {
  // Inspired by https://codepen.io/davatron5000/pen/jzMmME
  const progress = document.querySelectorAll("svg[data-value] .progress") as NodeListOf<SVGElement>;
  progress.forEach((path: SVGElement) => {
    const length = (path as SVGGeometryElement).getTotalLength();

    if (props.score && props.maxScore && props.maxScore > 0) {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      const percentage = (props.score / props.maxScore) * 100;
      const to = length * ((100 - percentage) / 100);
      path.getBoundingClientRect();
      path.style.strokeDashoffset = `${Math.max(0, to)}`;
    }
  });
};
</script>

<style lang="sass" scoped>
.round
  width: 155px
  height: 155px
  background-color: $color-neutral-platinum-40
  border-radius: 50%
  display: flex
  justify-content: center
  z-index: 1
  position: absolute


.score
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  align-content: center
  flex-wrap: wrap

.text
  @include Roboto400
  font-size: 14px
  line-height: 22px
  color: $color-primary-hc-navy-50
  margin: 0 0 8px

  &.active
    color: $color-secondary-sea-foam-100

.value-score
  @include Roboto600
  font-size: 50px
  color: $color-primary-hc-navy-50

  &.active
    color: $color-primary-hc-navy-100

.circles
  display: flex
  justify-content: center
  align-items: center

svg
  display: inline-flex
  vertical-align: bottom
  width: 230px
  height: 230px
  position: relative


circle
  stroke: $color-neutral-platinum-40
  stroke-width: 8px
  stroke-dasharray: 0
  fill: none

.progress
  stroke-width: 8px
  stroke: $color-secondary-sea-foam-100
  fill: none
  transition: stroke-dashoffset 1s cubic-bezier(0.43, 0.41, 0.22, 0.91)
  transform-origin: center center
  transform: rotate(-90deg) scaleX(-1)

  &.unknown
    stroke: $color-risk-unknown

  &.green
    stroke: $color-risk-green

  &.light_green
    stroke: $color-risk-light-green

  &.yellow
    stroke: $color-risk-yellow

  &.orange
    stroke: $color-risk-orange

  &.red
    stroke: $color-risk-red

  &.blue
    stroke: $color-secondary-sea-foam-100
</style>
