import {pagesPath} from "@/ts/router/pages-path";
import type {RouteRecordRaw} from "vue-router";
import NicotineParentPage from "@/vue/pages/nicotine-attestation/nicotine-attestation-parent.page.vue";
import NicotineAttestationStart from "@/vue/pages/nicotine-attestation/nicotine-attestation-start.page.vue";

export const nicotineAttestationPages: RouteRecordRaw = {
    path: pagesPath.nicotineAttestation.parent,
    component: NicotineParentPage,
    children: [
        {
            path: pagesPath.nicotineAttestation.start,
            component: NicotineAttestationStart,
        },
    ],
};
