<template>
  <div :class="className">
    <span v-if="icon" class="icon-container">
      <svg
        v-if="icon === 'plus'"
        class="icon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <!-- eslint-disable-next-line max-len -->
        <path d="M19 11H13V5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071C4.48043 12.8946 4.73478 13 5 13H11V19C11 19.2652 11.1054 19.5196 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20C12.2652 20 12.5196 19.8946 12.7071 19.7071C12.8946 19.5196 13 19.2652 13 19V13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z" fill="#1b2d3b"/>
      </svg>

      <svg
        v-if="icon === 'check'"
        class="icon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <!-- eslint-disable-next-line max-len -->
        <path d="M18.7319 7.2958C18.639 7.20207 18.5284 7.12768 18.4065 7.07691C18.2846 7.02614 18.1539 7 18.0219 7C17.8899 7 17.7592 7.02614 17.6373 7.07691C17.5155 7.12768 17.4049 7.20207 17.3119 7.2958L9.86192 14.7558L6.73192 11.6158C6.6354 11.5226 6.52146 11.4492 6.3966 11.4C6.27175 11.3508 6.13843 11.3267 6.00424 11.329C5.87006 11.3314 5.73765 11.3601 5.61458 11.4136C5.4915 11.4671 5.38016 11.5443 5.28692 11.6408C5.19368 11.7373 5.12037 11.8513 5.07117 11.9761C5.02196 12.101 4.99783 12.2343 5.00015 12.3685C5.00247 12.5027 5.0312 12.6351 5.0847 12.7581C5.13819 12.8812 5.2154 12.9926 5.31192 13.0858L9.15192 16.9258C9.24489 17.0195 9.35549 17.0939 9.47735 17.1447C9.59921 17.1955 9.72991 17.2216 9.86192 17.2216C9.99393 17.2216 10.1246 17.1955 10.2465 17.1447C10.3684 17.0939 10.479 17.0195 10.5719 16.9258L18.7319 8.7658C18.8334 8.67216 18.9144 8.5585 18.9698 8.432C19.0252 8.30551 19.0539 8.1689 19.0539 8.0308C19.0539 7.8927 19.0252 7.75609 18.9698 7.62959C18.9144 7.50309 18.8334 7.38944 18.7319 7.2958Z" fill="white"/>
      </svg>
    </span>

    <span v-if="selected" class="container">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <!-- eslint-disable-next-line max-len -->
        <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="white"/>
        <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#EAEAEA"/>
        <path d="M12.4852 12.1214L10.3638 10.0001L12.4852 7.8788C12.5789 7.78503 12.6316 7.65785 12.6316 7.52524C12.6316 7.39263 12.5789 7.26546 12.4852 7.17169C12.3914 7.07792 12.2642 7.02524 12.1316 7.02524C11.999 7.02524 11.8718 7.07792 11.7781 7.17169L9.65674 9.29301L7.53542 7.17169C7.44165 7.07792 7.31447 7.02524 7.18186 7.02524C7.04926 7.02524 6.92208 7.07792 6.82831 7.17169C6.73454 7.26546 6.68186 7.39263 6.68186 7.52524C6.68186 7.65785 6.73454 7.78503 6.82831 7.8788L8.94963 10.0001L6.82831 12.1214C6.73454 12.2152 6.68186 12.3424 6.68186 12.475C6.68186 12.6076 6.73454 12.7348 6.82831 12.8285C6.92208 12.9223 7.04926 12.975 7.18186 12.975C7.31447 12.975 7.44165 12.9223 7.53542 12.8285L9.65674 10.7072L11.7781 12.8285C11.8718 12.9223 11.999 12.975 12.1316 12.975C12.2642 12.975 12.3914 12.9223 12.4852 12.8285C12.5789 12.7348 12.6316 12.6076 12.6316 12.475C12.6316 12.3424 12.5789 12.2152 12.4852 12.1214Z" fill="#1B2D3B"/>
      </svg> 
    </span>


    <span class="value">{{ value }}</span>
  </div>
</template>
<script lang="ts" setup>
import {computed} from "vue";

const props = defineProps<{
  value: string;
  theme?: "dark" | "grey" | "light" | "white" | "navy";
  size?: "large-thin" | "large" | "small";
  icon?: "check" | "plus";
  selected?: boolean;
}>();

const theme = computed((): string => {
  return props.theme ?? "light";
});

const size = computed((): string => {
  return props.size ?? "large";
});

const className = computed((): string => {
  return `round-badge ${props.icon ? "round-badge--icon" : ""} ${theme.value} ${size.value}`;
});

</script>

<style lang="sass" scoped>
.round-badge
  border-radius: 16px
  padding: 4px 12px
  width: fit-content
  position: relative
  display: inline-block

  &--icon
    padding-left: 30px

.value
  margin: 0
  text-align: center
  letter-spacing: 0.2px

.container
  position: absolute
  top: -6px
  right: -6px
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer

.icon-container
  position: absolute
  top: 0
  left: 5px
  bottom: 0
  display: flex
  justify-content: center
  align-items: center

.large .value
  @include Roboto700
  font-size: 14px
  line-height: 22px

.large-thin .value
  @include Roboto400
  font-size: 14px
  line-height: 22px

.small .value
  @include Roboto600
  font-size: 12px
  line-height: 16px

.light
  background-color: $color-neutral-platinum-100

  .value
    color: $color-secondary-sea-foam-100

  .icon path
    fill: $color-secondary-sea-foam-100

.dark
  background-color: $color-secondary-sea-foam-100

  .value
    color: $color-neutral-platinum-40

  .icon path
    fill: $color-neutral-platinum-40

.white
  background-color: $color-white

  .value
    color: $color-secondary-sea-foam-100

  .icon path
    fill: $color-secondary-sea-foam-100

.grey
  background-color: $color-neutral-platinum-100

  .value
    color: $color-primary-hc-navy-100

  .icon path
    fill: $color-primary-hc-navy-100

.navy
  background-color: $color-primary-hc-navy-100 

  .value
    color: $color-white

</style>
