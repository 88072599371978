/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable max-lines */
import type {Profile} from "@/ts/types/store/default-store-types";
import type {PutProfileRequestDTO} from "@/ts/types/dto/profile.dto";
import type {Reward} from "@/ts/types/dto/reward.dto";
import {RewardType} from "@/ts/types/dto/reward.dto";
import type {BiometricScreeningLocation} from "@/ts/types/dto/biometric-screening.dto";
import {
  addToDate,
  convertDateToServerFormat,
  formatDateForCalendar,
  formatDurationNiceReadable,
  isDayAfterReferenceDay,
  isDayBeforeReferenceDay,
  today,
} from "@/ts/utils/date-pure-functions";
import type {TaskType} from "@/ts/types/dto/activity.dto";
import {TaskTargetValueStorageUnit} from "@/ts/types/dto/activity.dto";
import {TaskTypeUnitCategory} from "@/ts/types/component/tracking-list";
import {ChallengeStatus} from "@/ts/types/component/challenges";
import type {ChallengeDTO} from "@/ts/types/dto/challenges.dto";
import type {LeaderboardChallengeDTO} from "@/ts/types/dto/leaderboard-challenge.dto";

export function formatPhoneNumberForAPI(phoneNumber: string): string {
  const digitsOnly = phoneNumber.replace(/\D/g, "");
  return `+1${digitsOnly}`;
}

export function formatAddress(location: BiometricScreeningLocation): string {
  const result = [];
  if (location.name) {
    result.push(location.name);
  }
  if (location.room) {
    result.push(location.room);
  }
  if (location.address1) {
    result.push(location.address1);
  }
  if (location.address2) {
    result.push(location.address2);
  }
  if (location.city) {
    result.push(location.city);
  }
  if (location.stateName) {
    result.push(location.stateName);
  }
  if (location.stateCode) {
    result.push(location.stateCode);
  }
  return result.join(", ");
}

export function uniqueOnly<T>(array: T[]): T[] {
  return [...new Set(array)];
}

export function uniqueOnlyById<T>(array: T[], getter: (value: T) => number | string): T[] {
  return array.filter((item, index) => array.findIndex((inner) => getter(inner) === getter(item)) === index);
}

export function getAddToGoogleCalLink(text: string, date: Date, details: string, location: string): string {
  const start = formatDateForCalendar(date);
  const end = formatDateForCalendar(addToDate(date, {minutes: 15}));

  const queryParams = new URLSearchParams({
    action: "TEMPLATE",
    text,
    dates: `${start}/${end}`,
    details,
    location,
  }).toString();
  return `http://www.google.com/calendar/event?${queryParams}`;
}

export async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms)); // eslint-disable-line no-promise-executor-return
}

export function formatCash(cash: number): string {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(cash ?? 0);
}

export function getRewardAmountFormatted(reward?: Reward | null): string {
  if (reward?.type === RewardType.cash) {
    return formatCash(reward.amount ?? 0);
  } else if (reward?.type === RewardType.point) {
    return `${reward.amount ?? 0} PTS`;
  }
  return reward?.amount?.toString() ?? "";
}

export function groupBy<T>(xs: T[], keyExtractor: (item: T) => string): Record<string, T[]> {
  return xs.reduce<Record<string, T[]>>((resVal, curVal) => {
    const key = keyExtractor(curVal);
    if (!resVal[key]) {
      resVal[key] = [];
    }
    resVal[key].push(curVal);
    return resVal;
  }, {});
}

export function profileToPutProfileRequest(
  profile: Profile,
): PutProfileRequestDTO {
  return {
    firstName: profile.firstName,
    lastName: profile.lastName,
    city: profile.city,
    motivationQuote: profile.motivationQuote,
    address1: profile.address1,
    address2: profile.address2,
    dateOfBirth: convertDateToServerFormat(profile.dateOfBirth),
    email: profile.email,
    gender: profile.gender,
    postcode: profile.postcode,
    state: profile.state,
    height: profile.height,
    phoneNumber: profile.phoneNumber,
    uniqueId: profile.uniqueId,
    interests: profile.interests,
  };
}

export function calculateCmToMiles(cm: number): number {
  return cm / 160934.4;
}

export function calculateMilesToCm(miles: number): number {
  return Math.round(miles * 160934.4);
}

export function calculateGramToPound(grams: number): number {
  return grams / 453.592;
}

export function calculatePoundToGram(lbs: number): number {
  return Math.round(lbs * 453.592);
}

export function calculateFlOzToMilliliter(floz: number): number {
  return Math.ceil(floz * 29.574);
}

export function calculateMilliliterToFlOz(milliliter: number): number {
  return Math.round(milliliter / 29.574);
}

export function getTaskTypeCategoryByTaskType(taskType: TaskType): TaskTypeUnitCategory {
  let unitCategory;
  switch (true) {
    case taskType.toString().includes("_distance"):
      unitCategory = TaskTypeUnitCategory.distance;
      break;
    case taskType.toString().includes("_time"):
      unitCategory = TaskTypeUnitCategory.duration;
      break;
    case taskType.toString().includes("seconds_activity"):
      unitCategory = TaskTypeUnitCategory.duration;
      break;
    case taskType.toString().includes("_volume"):
      unitCategory = TaskTypeUnitCategory.volume;
      break;
    case taskType.toString().includes("weight"):
      unitCategory = TaskTypeUnitCategory.weight;
      break;
    case taskType.toString().includes("steps"):
      unitCategory = TaskTypeUnitCategory.steps;
      break;
    default:
      unitCategory = TaskTypeUnitCategory.none;
  }
  return unitCategory;
}

export function formatTaskAmountByTypeUnit(taskAmount: number, unit: TaskTargetValueStorageUnit): string {
  if (unit === TaskTargetValueStorageUnit.Centimeter) {
    const miles = calculateCmToMiles(taskAmount);
    const roundedMiles = Math.round(miles * 100) / 100;
    const formattedMiles = new Intl.NumberFormat("en-US", {}).format(roundedMiles);
    return `${formattedMiles} mi.`;
  }

  if (unit === TaskTargetValueStorageUnit.Seconds) {
    return formatDurationNiceReadable(taskAmount);
  }

  if (unit === TaskTargetValueStorageUnit.Steps) {
    const formattedSteps = new Intl.NumberFormat("en-US", {}).format(taskAmount);
    return `${formattedSteps} steps`;
  }

  if (unit === TaskTargetValueStorageUnit.Milliliters) {
    return `${calculateMilliliterToFlOz(taskAmount)} fl oz`;
  }

  if (unit === TaskTargetValueStorageUnit.Grams) {
    const lbs = calculateGramToPound(taskAmount);
    const roundedLbs = Math.round(lbs * 10) / 10;
    const formattedLbs = new Intl.NumberFormat("en-US", {}).format(roundedLbs);
    return `${formattedLbs} lbs.`;
  }

  return new Intl.NumberFormat("en-US", {}).format(taskAmount);
}

export function formatTaskAmountByType(taskAmount: number|undefined, taskType: TaskType): string {
  if(taskAmount == undefined){
    taskAmount = 0 
  }
  const kindOfAction = getTaskTypeCategoryByTaskType(taskType);
  if (kindOfAction === TaskTypeUnitCategory.distance) {
    return formatTaskAmountByTypeUnit(taskAmount, TaskTargetValueStorageUnit.Centimeter);
  }

  if (kindOfAction === TaskTypeUnitCategory.duration) {
    return formatTaskAmountByTypeUnit(taskAmount, TaskTargetValueStorageUnit.Seconds);
  }

  if (kindOfAction === TaskTypeUnitCategory.steps) {
    return formatTaskAmountByTypeUnit(taskAmount, TaskTargetValueStorageUnit.Steps);
  }

  if (kindOfAction === TaskTypeUnitCategory.volume) {
    return formatTaskAmountByTypeUnit(taskAmount, TaskTargetValueStorageUnit.Milliliters);
  }

  if (kindOfAction === TaskTypeUnitCategory.weight) {
    return formatTaskAmountByTypeUnit(taskAmount, TaskTargetValueStorageUnit.Grams);
  }

  return new Intl.NumberFormat("en-US", {}).format(taskAmount);
}

export function determineChallengeStatus(challenge: ChallengeDTO | LeaderboardChallengeDTO): ChallengeStatus {
  let status = ChallengeStatus.Available;

  const isStartInFuture = isDayBeforeReferenceDay(today(), challenge.startDate);
  const isEndInPast = isDayAfterReferenceDay(today(), challenge.endDate);

  if (challenge.challengeStatus == ChallengeStatus.Joined) {
    status = ChallengeStatus.Joined;
  } else if (challenge.challengeStatus == ChallengeStatus.Active) {
    status = ChallengeStatus.Active;
  } else if (challenge.challengeStatus == ChallengeStatus.GracePeriod) {
    status = ChallengeStatus.GracePeriod;
  } else if (challenge.challengeStatus == ChallengeStatus.Completed) {
    status = ChallengeStatus.Completed;
  }

  return status;
}
