<template>
    <navbar-page
      :title="leaderboardChallenge.name"
      class="body"
      :display-right-nav="false"
      :back-link="prevPage"
      subtitle="leaderboard challenge"
      theme="orange"
    >
  <loading-suspense :error="error" :loading="loading">
      <leaderboard-challenge-available
        v-if="status === ChallengeStatus.Available"
        :participants="participants"
        @join-challenge="handleJoinChallenge" :displayType="DisplayType"
      />
      <leaderboard-challenge-active
        v-if="status === ChallengeStatus.Active || status === ChallengeStatus.Joined"
        :ranking="ranking" :displayType="DisplayType"
      />
      <leaderboard-challenge-completed
        v-if="status === ChallengeStatus.Completed || status === ChallengeStatus.GracePeriod"
        :ranking="ranking" :displayType="DisplayType"
      />
  </loading-suspense>
    </navbar-page>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import {DefaultGrowlError, LoadingMixin} from "@/ts/mixins/loading-mixin";
import {mixins} from "vue-class-component";
import type {
  ChallengeParticipant,
  LeaderboardChallengeDTO,
  RankingPosition,
} from "@/ts/types/dto/leaderboard-challenge.dto";
import LeaderboardChallengeAvailable from "@/vue/organisms/leaderboard-challenge/leaderboard-challenge-available.vue";
import LeaderboardChallengeActive from "@/vue/organisms/leaderboard-challenge/leaderboard-challenge-active.vue";
import LeaderboardChallengeCompleted from "@/vue/organisms/leaderboard-challenge/leaderboard-challenge-completed.vue";
import {DefaultState, DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
import {GrowlsStoreMixin} from "@/ts/store/growl/growl-store-instance";
import {ChallengeStatus} from "@/ts/types/component/challenges";
import {determineChallengeStatus} from "@/ts/utils/pure-functions";
import { ChallengeDisplayType } from "@/ts/types/dto/challenges.dto";
import {RouteLocationNormalized} from "vue-router";

@Component({
  components: {
    NavbarPage,
    LeaderboardChallengeAvailable,
    LeaderboardChallengeActive,
    LoadingSuspense,
    LeaderboardChallengeCompleted,
  },
})
export default class LeaderboardChallenge extends mixins(
  LoadingMixin,
  DefaultStoreMixin,
  GrowlsStoreMixin,
) {
  participants!: ChallengeParticipant[];

  ranking!: RankingPosition[];

  ChallengeStatus = ChallengeStatus;

  DisplayType = ChallengeDisplayType.total_contributions;
  @Prop() id!: string;

  @DefaultState
  leaderboardChallenge!: LeaderboardChallengeDTO;

  get status(): ChallengeStatus {
    return this.leaderboardChallenge.challengeStatus;
  }

  @DefaultGrowlError
  async handleJoinChallenge(): Promise<void> {
    const joinedChallenge = await this.$api.postLeaderboardChallenge(this.id);
    this.defaultStore.setLeaderboardChallenge(joinedChallenge);
    // After user joins get participants list updated
    this.participants = (
      await this.$api.getLeaderboardChallengeParticipants(this.id)
    ).items;

    this.ranking = (
      await this.$api.getLeaderboardChallengeRanking(this.id)
    ).items;
  }

  @DefaultGrowlError
  async created(): Promise<void> {
    const challenge = await this.$api.getLeaderboardChallenge(this.id);
    this.defaultStore.setLeaderboardChallenge(challenge);

    if (this.status === ChallengeStatus.Available) {
      this.participants = (
        await this.$api.getLeaderboardChallengeParticipants(this.id)
      ).items;
    }

    if (this.status !== ChallengeStatus.Available) {
      const {items} = await this.$api.getLeaderboardChallengeRanking(this.id);
      this.ranking = items;
    }
  }

  prevPage: string = "";

  mounted() {
    const storedPreviousPage = sessionStorage.getItem("challengePreviousPage");
    if (storedPreviousPage) {
      this.prevPage = storedPreviousPage;
    } else {
      this.prevPage = this.$pagesPath.program.programChallenges;
    }
  }

  @Watch("$route", { immediate: true, deep: true })
  onRouteChange(to: RouteLocationNormalized, from: RouteLocationNormalized) {
    if (!from?.fullPath) {
      return;
    }

    if (to.fullPath === from.fullPath) {
      return;
    }

    if (to.fullPath !== sessionStorage.getItem("challengePreviousPage")) {
      sessionStorage.removeItem("challengePreviousPage");
    }
  }
}
</script>

<style lang="sass" scoped>
.body
  padding-left: 100px
</style>
