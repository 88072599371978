<template>
  <span>
    <span class="bold">{{ number }}</span>
    <span class="normal">/{{ total }}</span>
  </span>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";

@Component({name: "SectionNumber"})
export default class SectionNumber extends Vue {
  @Prop() number!: number;

  @Prop() total!: number;
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.bold
  @include Asap700
  font-size: 20px
  color: $color-primary-hc-navy-100

.normal
  @include Asap400
  font-size: 20px
  color: $color-primary-hc-navy-50
</style>
