<template>
  <physician-form-page step="1" title="Download Physician’s form">
    <section class="download">
      <div class="card-box">
        <div class="text">
          <h3>Download form</h3>
          <p>Ask your physician to fill it out and sign it. Once you have it proceed to the next step.</p>
        </div>
        <button-primary :loading="isDownloading" @click.prevent="onClickDownload">
          <img src="@/assets/img/icons/download_file.svg" alt="download form"/> Download
        </button-primary>
      </div>
      <div class="button-wrapper">
        <button-link :to="$pagesPath.physicianForm.uploadForm">
          Next
        </button-link>
      </div>
    </section>
  </physician-form-page>
</template>

<script lang="ts" setup>
import PhysicianFormPage from "@/vue/templates/physician-form-page.vue";
import type {Ref} from "vue";
import {inject, ref} from "vue";
import type {Api} from "@/ts/classes/api";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import ButtonLink from "@/vue/atoms/button-link.vue";
import {getModule} from "vuex-module-decorators";
import {vueStore} from "@/ts/store/vue-store";
import {BiometricPhysicianFormStore} from "@/ts/store/biometric-physician-form/biometric-physician-form-store";

const $api = inject("$api")! as Api;

const isDownloading: Ref<boolean> = ref(false);

const physicianFormStore: BiometricPhysicianFormStore = getModule(BiometricPhysicianFormStore, vueStore);

const onClickDownload = async(): Promise<void> => {
  isDownloading.value = true;
  // Fetch PDF blob
  const blob = await $api.downloadPhysicianForm();
  // Create a temporary URL for the PDF blob
  const url = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");
  link.href = url;
  link.download = `physician-form-${physicianFormStore.physicianForm?.id}.pdf`;
  // Programmatically click the link to trigger the download
  link.click();
  // Clean up the temporary URL
  URL.revokeObjectURL(url);

  isDownloading.value = false;
};
</script>

<style lang="sass" scoped>
.card-box
  padding: 24px
  width: 681px
  background-color: $color-neutral-platinum-40
  border-radius: 16px
  margin: 1rem 0
  display: flex
  flex-direction: column
  gap: 40px

  @include max-device(md)
    width: min-content

.text
  h3
    @include Asap700
    font-size: 20px
    line-height: 28px
    color: $color-primary-hc-navy-100
    margin: 0

  p
    @include Roboto400
    font-size: 16px
    line-height: 24px
    color: $color-primary-hc-navy-100
    margin: 8px 0

.button-wrapper
  display: flex
  justify-content: center
  margin-top: 100px
</style>
