<template>
  <scheduling-page :step="3" title="Select a Timeslot">
    <div>
      <div class="fields-holder">
        <datepicker-calendar v-model="selectedDay" :available-dates="dates"/>
        <loading-suspense :loading="loading" :error="error">
          <time-selector
            v-if="availableSlots.length"
            v-model="selectedTime"
            :values="availableSlots"
            :selected-day="selectedDay"
          />
          <div v-else class="text">
            No available slots on this day
          </div>
        </loading-suspense>
      </div>
      <div class="buttons">
        <button-link :to="$pagesPath.biometricScheduling.location" theme="white">
          <img src="@/assets/img/left-arrow-cal.svg"/>Back
        </button-link>
        <button-primary :disabled="!selectedTime" @click="schedule">
          Schedule
          <img class="next-img" src="@/assets/img/right-arrow-white.svg"/>
        </button-primary>
      </div>
      <schedule-appointment-modal
        v-if="showPopup"
        v-model="showPopup"
        :selected-time="selectedTime"
      />
    </div>
  </scheduling-page>
</template>

<script lang="ts">
import {Component, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {DefaultGrowlError, LoadingMixin} from "@/ts/mixins/loading-mixin";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import SchedulingPage from "@/vue/templates/scheduling-page.vue";
import ButtonLink from "@/vue/atoms/button-link.vue";
import DatepickerCalendar from "@/vue/atoms/datepicker-calendar.vue";
import {
  SchedulingBiometricState,
  SchedulingBiometricStoreMixin,
} from "@/ts/store/scheduling-biometric/scheduling-biometric-store-instance";
import {convertDateToServerFormat, parseDate, today} from "@/ts/utils/date-pure-functions";
import TimeSelector from "@/vue/molecules/time-selector.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import type {BiometricScreeningLocation} from "@/ts/types/dto/biometric-screening.dto";
import ScheduleAppointmentModal from "@/vue/organisms/schedule-appointment-modal.vue";

@Component({
  name: "SchedulingSelectTimePage",
  components: {
    ScheduleAppointmentModal,
    LoadingSuspense,
    TimeSelector,
    ButtonPrimary,
    ButtonLink,
    SchedulingPage,
    DatepickerCalendar,
  },
})
export default class SchedulingSelectTimePage extends mixins(
  LoadingMixin,
  SchedulingBiometricStoreMixin,
) {
  @SchedulingBiometricState
  location!: BiometricScreeningLocation | null;

  dates: string[] = [];

  selectedDay: Date | null = today();

  selectedTime: Date | null = null;

  availableSlots: Date[] = [];

  showPopup: boolean = false;

  @DefaultGrowlError
  async created(): Promise<void> {
    if (!this.location) {
      void this.$router.push(this.$pagesPath.biometricScheduling.location);
      return;
    }
    this.dates = await this.$api.getBiometricScreeningAvailableLocationDates({
      locationId: String(this.location!.id),
    });
    await this.handleAvailableSlots();
  }

  @Watch("selectedDay")
  @DefaultGrowlError
  async onSelectedDayChange(): Promise<void> {
    await this.handleAvailableSlots();
  }

  async handleAvailableSlots(): Promise<void> {
    this.selectedTime = null;
    const availableSlots =
      await this.$api.getBiometricScreeningAvailableLocationDateSlots({
        date: convertDateToServerFormat(this.selectedDay!),
        locationId: String(this.location!.id),
      });
    this.availableSlots = availableSlots.map((as) => parseDate(as));
  }

  schedule(): void {
    if (this.selectedTime) {
      this.showPopup = true;
    }
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>

.text
  @include Asap700
  color: $color-primary-hc-navy-100
  font-size: 16px
  line-height: 22px
  letter-spacing: 0.15px

.fields-holder
  display: flex
  flex-direction: row
  gap: 80px

  > *
    width: 100%

.next-img
  margin-left: 8px

.buttons
  display: flex
  width: 100%
  margin-top: 56px
  justify-content: space-between

  :deep(button)
    width: auto
    padding-left: 29px
    padding-right: 29px

.text
  color: $color-primary-hc-navy-100
  @include Roboto400
  font-size: 16px
  line-height: 22px

</style>
