<template>
  <card-row v-if="healthContent?.length > 0" :title="title" :amount="healthContent.length">
    <template #extraButton>
      <button class="icon" @click="togglePanel">
        <icon-chameleon
            color="primary100"
            hover-color="primary100"
            :icon="(showPanel ? DownChevron : RightChevron)"
        />
      </button>
    </template>
    <expansion-panel :expanded="showPanel">
      <template #body>
        <div class="health-content-list">
          <health-card v-for="item in healthContent" :key="item.id" :item="item"/>
        </div>
      </template>
    </expansion-panel>
  </card-row>
</template>

<script lang="ts" setup>
import {ref, computed, inject} from "vue";
import CardRow from "@/vue/molecules/card-row.vue";
import CardItem from "@/vue/atoms/card-item.vue";
import ExpansionPanel from "@/vue/atoms/expansion-panel.vue";
import CompletedIcon from "@/vue/atoms/completed-icon.vue";
import GatekeeperIcon from "@/vue/atoms/gatekeeper-icon.vue";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";
import RightChevron from "@/assets/img/right-chevron.svg";
import DownChevron from "@/assets/img/down-chevron.svg";
import { formatDateShortMonthName } from "@/ts/utils/date-pure-functions";
import type { GatekeeperItem } from "@/ts/types/dto/program.dto";
import HealthCard from "@/vue/molecules/health-card.vue";
import {ContentReferenceType} from "@/ts/types/dto/content-reference.dto";
import type {Router} from "vue-router";
import type {PagesPath} from "@/ts/router/pages-path";

const props = defineProps<{ title: string; healthContent: GatekeeperItem[] }>();
const showPanel = ref<boolean>(true);



const togglePanel = () => {
  showPanel.value = !showPanel.value;
};

const getEndDate = (endDate: string | undefined) => (endDate ? `End date: ${formatDateShortMonthName(endDate)}` : "");
const getCompletedDate = (completedDate: string | undefined) => (completedDate ? `Completed on: ${formatDateShortMonthName(completedDate)}` : "");


</script>

<style lang="sass" scoped>
.icon
  margin-bottom: 4px
  cursor: pointer
  border: none
  background: none

.card-item
  cursor: pointer
.content
  display: flex
  flex-direction: column
  position: relative

  h1
    font-size: 1rem
    max-width: 300px
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    margin-top: 0
    margin-bottom: 5px

.points
  background: #e3f2fd
  border-radius: 16px
  font-size: 12px
  color: #1e88e5
  display: flex
  padding: 4px 8px
  align-items: center
  width: fit-content
  position: absolute
  bottom: 16px
  left: 16px

.gatekeeper-label
  font-size: 11px
  text-transform: uppercase
  position: absolute
  margin-top: -14px

.health-content-list
  display: flex
  flex-wrap: wrap
  gap: .3rem
</style>
