<template>
  <hc-logo/>
  <div>
    <br/><br/><br/><br/>
      <h1 class="h1" v-if="!errorMessage">
        Processing authentication callback...
      </h1>
      <loading-suspense :loading="!errorMessage"/>
      <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}

    </div>
    <div class="go-home" v-if="errorMessage">      
      <button-link 
          :to="$pagesPath.main.dashboard"
          :flexible-width="true"
          theme="dark"
          :loading="loading"
          class="confirm-btn"
        >
         Start Over
      </button-link>
    </div>
  </div>
  
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {inject} from "vue";
import ButtonLink from "@/vue/atoms/button-link.vue";

import {LoadingMixin} from "@/ts/mixins/loading-mixin";
import {DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
import {OnboardingStoreMixin} from "@/ts/store/onboarding/onboarding-store-instance";

import type {PagesPath} from "@/ts/router/pages-path";
import type {CachedApi} from "@/ts/classes/cached-api";
import HcLogo from "@/vue/molecules/hc-logo.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";

  const $cachedApi = inject<CachedApi>("$cachedApi")!;
@Component({
  name: "Callback",
  components:{
    HcLogo,
    LoadingSuspense,
    ButtonLink
  }

})

export default class Callback extends mixins(LoadingMixin, DefaultStoreMixin, OnboardingStoreMixin) {
  errorMessage: string | null = null;

  $pagesPath = inject<PagesPath>("$pagesPath")!;

  async mounted() {
    try {
      const url = new URL(window.location.href);
      const code = url.searchParams.get("code");
      const state = url.searchParams.get("state");

      if (!code || code.trim() === "") {
        this.errorMessage = "Error: Missing or invalid 'code' parameter.";
        return;
      }

      if (!state || state.trim() === "") {
        this.errorMessage = "Error: Missing or invalid 'state' parameter.";
        return;
      }

      // Check if the state ends with "-mobile" and deep link to the mobile app if so
      if (state.endsWith("-mobile")) {
        const cleanedState = state.slice(0, -"-mobile".length); // Remove the "-mobile" part from the state
        const deepLinkUrl = `mhc360plusdev://sso-flow?code=${code}&state=${cleanedState}`;
        window.location.href = deepLinkUrl;
        return; // Ensure the rest of the code is not executed
      }

      const authLoginResponseDTO = await this.$api.handleOIDCCallback(code, state);

      if (authLoginResponseDTO.registerUrl) {
        if (authLoginResponseDTO.censusToken) {
          localStorage.setItem("censusToken", authLoginResponseDTO.censusToken);
          this.onboardingStore.setCheckToken(authLoginResponseDTO.censusToken);
        } else {
          window.location.href = authLoginResponseDTO.registerUrl;
          return;
        }
        if (authLoginResponseDTO.censusData) {
          this.onboardingStore.setUserCensusData(authLoginResponseDTO.censusData);
        }
        this.$router.push(this.$pagesPath.auth.todoSso);
        return;
      }
      this.defaultStore.setAuthTokens(authLoginResponseDTO);
      void this.$router.push("/todo");
    } catch (error) {
      this.errorMessage = "SSO callback failed, please confirm you are entering correct identifiers";
    }
  }
}
</script>

<style lang="sass" scoped>
.error-message
  color: red
  font-weight: bold
  text-align: center

.go-home
  text-align: center
  padding-top: 2%

.h1
  font-size: 55px
  font-style: normal
  text-align: center
  flex-wrap: wrap
  color: $color-primary-hc-navy-100
  @include Asap700

</style>
