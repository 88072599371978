<template>
  <alert-icon-card
    theme="error"
    title="Physician Form Submission Denied"
  >
    <p class="text">
      Your Physician Form was denied on <span class="bold">{{ deniedDate }}</span> and must be corrected by <span class="bold">{{ cutOffDate }}</span>. Please resubmit your Physician Form with the changes stated below.
    </p>
    <p v-if="physicianForm?.denialDetails?.deniedReason" class="text">
      <span class="bold">Denial Reason:</span>
      {{ physicianForm?.denialDetails?.deniedReason }}
    </p>
    <p class="text">
      If you need to submit a new form, click the button below and resubmit the form.
    </p>
  </alert-icon-card>
</template>

<script lang="ts" setup>
import AlertIconCard from "@/vue/atoms/alert-icon-card.vue";
import {formatDateShortMonthName} from "@/ts/utils/date-pure-functions";
import type {GetPhysicianFormBiometricResponse} from "@/ts/types/dto/physician-form.dto";
import {computed} from "vue";

const props = defineProps<{
  physicianForm: GetPhysicianFormBiometricResponse | null;
}>();

const deniedDate = computed(() => {
  if (props.physicianForm?.denialDetails?.deniedDate) {
    return formatDateShortMonthName(props.physicianForm.denialDetails.deniedDate);
  }
  return null;
});

const cutOffDate = computed(() => {
  if (props.physicianForm?.denialDetails?.cutoffDate) {
    return formatDateShortMonthName(props.physicianForm.denialDetails.cutoffDate);
  }
  return null;
});
</script>

<style lang="sass" scoped>
.bold
  @include Roboto700

.text
  @include Roboto400
  color: $color-primary-hc-navy-100
  font-size: 14px
  letter-spacing: 0.2px
  line-height: 22px
  margin: 0 0 4px
</style>
