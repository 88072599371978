<template>
  <div v-if="participantsList?.length" class="wrap">
    <p class="title" @click="isOpen = true">
      {{ participantsList?.length }} friends already joined this challenge
    </p>
    <div class="friends-list" @click="isOpen = true">
      <user-avatar
        v-for="friend in participantsList"
        :key="friend.id"
        :avatar="friend.avatarPath"
        :is-me="friend.id === profile.id"
        :firstname="friend.firstName"
        :lastname="friend.lastName"
        size="mid"
        :user-status="friend.status"
      />
      <div v-if="friends.length > listLength">
        <avatar-frame
          image=""
          :letters="`+${participants.length - listLength}`"
          letters-theme="orange"
          :size="48"
        />
      </div>
    </div>
    <challenge-participants-modal
      v-model="isOpen"
      :friends="friends"
      :participants="participants"
    />
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import type {FinishedFriend} from "@/ts/types/dto/pathway.dto";
import ChallengeParticipantsModal from "@/vue/molecules/challenge/challenge-participants-modal.vue";
import {mixins} from "vue-class-component";
import {LoadingMixin} from "@/ts/mixins/loading-mixin";
import UserAvatar from "@/vue/atoms/user-avatar.vue";
import type {Profile} from "@/ts/types/store/default-store-types";
import {DefaultState} from "@/ts/store/default/default-store-instance";
import type {ChallengeParticipant} from "@/ts/types/dto/leaderboard-challenge.dto";
import AvatarFrame from "@/vue/atoms/avatar-frame.vue";

@Component({
  name: "ChallengeFriendsJoined",
  components: {
    ChallengeParticipantsModal,
    UserAvatar,
    AvatarFrame,
  },
})
export default class ChallengeFriendsJoined extends mixins(LoadingMixin) {
  @Prop()
  friends!: FinishedFriend[];

  @Prop()
  participants!: ChallengeParticipant[];

  @DefaultState
  public profile!: Profile;

  isOpen: boolean = false;

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  listLength = 10;

  // TODO: deal with byyou- if user has joined display in first place the user avatar
  get participantsList(): FinishedFriend[] {
    const matches = this.friends.filter((value) => this.participants.some((value2) => value.id === value2.id));

    return matches.length > this.listLength
      ? matches?.slice(0, this.listLength)
      : matches || [];
  }
}
</script>

<style lang="sass" scoped>
.wrap
  max-width: 686px
  margin: 0 auto 2rem
  padding: 1rem


.friends-list
  cursor: pointer
  display: flex
  align-items: center
  gap: 10px

.title
  color: $color-primary-hc-navy-100
  @include Asap700

</style>
