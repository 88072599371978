<template>
  <card-item class="card-item" @click="isOpen = true">
    <div>
      <completed-icon v-if="activity.status === 'completed'" big/>
      <gatekeeper-icon v-else-if="activity.isGateKeeper || activity.isRecommended" big/>
      <protected-svg
        v-else-if="activity.categoryIcon && activity.categoryIcon.path"
        :src="activity.categoryIcon.path" 
        size="large"
        :ariaHidden="false"
        alt="Activity Icon"
      />
      <activity-icon v-else :type="iconType" big/>
    </div>
    <div class="content">
      <h1>{{ activity.name }}</h1>
      <span v-if="activity.isGateKeeper" class="gatekeeper-label">Required Activity</span>
      <span v-if="activity.isRecommended" class="gatekeeper-label">Recommended Activity</span>
    </div>
    <content-badges :content="activity" />
  </card-item>
  <div v-if="status === 'available' || status === 'active'">
    <activity-detail-modal
      v-model="isOpen"
      :activity="activity"
      :activity-icon="iconType"
      :frequency="durationType"
      :is-from-to-do-page="false"
      @change-item="changeItem"
      @proof-sent="proofSent"
    />
  </div>
  <div v-else-if="status === 'completed'">
    <activity-modal-completed
      v-model="isOpen"
      :activity="activity"
      :activity-icon="iconType"
      :frequency="durationType"
    />
  </div>
</template>

<script lang="ts" setup>
import CardItem from "@/vue/atoms/card-item.vue";
import ActivityDetailModal from "@/vue/organisms/activity-modals/activity-detail-modal.vue";
import ActivityModalCompleted from "@/vue/organisms/activity-modals/activity-modal-completed.vue";
import ProtectedSvg from "@/vue/atoms/protected-svg.vue";
import ActivityIcon from "@/vue/atoms/activity-icon.vue";
import CompletedIcon from "@/vue/atoms/completed-icon.vue";
import GatekeeperIcon from "@/vue/atoms/gatekeeper-icon.vue";
import { SubmissionFrequency } from "@/ts/types/dto/activity.dto";
import { getRewardAmountFormatted } from "@/ts/utils/pure-functions";
import type { ActivityCardModel, ActivityIconType } from "@/ts/types/component/activity-card";
import { KindOfActivity } from "@/ts/types/component/activity-card";
import { computed, ref, watch } from "vue";
import ContentBadges from "@/vue/atoms/content-badges.vue";

const props = defineProps<{
  activity: ActivityCardModel;
  setOpen: boolean;
}>();

const emit = defineEmits(["changeItem", "proofSent"]);

const isOpen = ref(props.setOpen ?? false);

const reward = computed((): string | null => {
  return props.activity.reward ? getRewardAmountFormatted(props.activity.reward) : null;
});

const iconType = computed((): ActivityIconType => {
  return props.activity.kindOf === KindOfActivity.platform ? "platform" : props.activity.task?.type ?? "generic";
});

const status = computed((): string => props.activity.status);

const durationType = computed((): string => {
  if (props.activity.submissionFrequency === SubmissionFrequency.once) return "One time";
  if (props.activity.submissionFrequency === SubmissionFrequency.week_7_times) return "Daily";
  if (props.activity.submissionFrequency === SubmissionFrequency.week_3_times) return "3x per week";
  if (props.activity.submissionFrequency === SubmissionFrequency.week_2_times) return "2x per week";
  if (props.activity.submissionFrequency === SubmissionFrequency.unlimited) return "Unlimited";
  return "";
});

watch(() => props.activity.status, (newStatus, oldStatus) => {
  if (newStatus !== oldStatus) {
    changeItem(props.activity);
    console.log("hit the watch");
  }
});

const changeItem = (item: ActivityCardModel): void => {
  emit("changeItem", item);
};

const proofSent = (id: string): void => {
  emit("proofSent", id);
};
</script>

<style lang="sass" scoped>
.card-item
  cursor: pointer

.content
  display: flex
  flex-direction: column
  margin-top: 5px
  position: relative
  text-align: center

  h1
    @include Asap700
    color: $color-primary-hc-navy-100
    font-size: 1rem
    max-width: 300px
    /* stylelint-disable */
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    margin-top: 0
    margin-bottom: 5px

.duration
  @include Roboto400
  font-size: 14px
  color: $color-primary-hc-navy-50

.gatekeeper-label
  @include Roboto400
  font-size: 11px
  color: $color-primary-hc-navy-100
  text-transform: uppercase
  align-self: center
</style>