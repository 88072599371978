<template>
  <div class="program-container">
    <div class="program-content-container">
      <div class="program-name">
        <markdown-it :text="program.name"/>
      </div>
      <div class="program-description">
        <expandable-markdown-it 
          v-if="program.description.length > 250" 
          :text="program.description" 
          :truncation-length="250" 
        />
        <markdown-it v-else :text="program.description"/>
      </div>
      <div class="rewards-container">
        <dashboard-rewards v-if="rewardResponse" :reward-response="rewardResponse"/>
        <div v-if="requiredActivitiesNotCompletedCount > 0" class="rewards-warning">
          <tooltip-wrapper 
            :tooltip-text="`Complete all required activities to receive your incentive.
            You have ${requiredActivitiesNotCompletedCount} activities remaining.`"
          >
            <div class="rewards-warning-content">
              <img src="@/assets/img/exclamation-mark.svg" alt="tooltip" class="tooltip-icon"/>
              You have not completed all of your Required Activities to receive your incentive
            </div>
          </tooltip-wrapper>
        </div>
      </div>
    </div>
    <div class="company-logo-container">
      <div v-if="!companyLogo" class="no-logo">
        <img src="@/assets/img/illustrations/connected-devices.svg" alt="Company Logo"/>
      </div>
      <protected-image-rounded-rectangle v-else :src="companyLogo" alt="Company Logo"/>
      <hc-button 
        class="program-button"
        label="Discover My Program"
        :to="to"
        :icon="rightArrowIcon"
        iconPosition="right"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import DashboardRewards from "@/vue/molecules/dashboard-rewards.vue";
import ProtectedImageRoundedRectangle from "@/vue/atoms/protected-image-rounded-rectangle.vue";
import TooltipWrapper from "@/vue/atoms/tooltip-wrapper.vue";
import HcButton from "@/vue/atoms/hc-button.vue";
import rightArrowIcon from "@/assets/img/right-arrow-white.svg";
import type { Program } from "@/ts/types/store/default-store-types";
import type { RewardTrackerResponse } from "@/ts/types/dto/reward-tracker";
import MarkdownIt from "@/vue/atoms/markdown-it.vue";
import ExpandableMarkdownIt from "@/vue/atoms/expandable-markdown-it.vue";

const props = defineProps<{
  program: Program;
  rewardResponse?: RewardTrackerResponse | null;
  requiredActivitiesNotCompletedCount: number;
  to: string;
  companyLogo?: string;
}>();

const showPanel = ref(false);
</script>

<style lang="sass" scoped>
.program-container
  max-width: 1258px
  border-radius: 16px
  background: rgba(234, 234, 234, 0.93)
  box-shadow: $content-shadow
  padding-bottom: 2rem
  display: flex
  flex-direction: row
  justify-content: space-between
  margin-top: 2rem

.program-content-container
  display: flex
  padding-left: 2rem
  flex-direction: column
  align-items: flex-start

.program-name
  margin-bottom: -2rem
  font-size: 32px

.program-description
  font-size: 16px
  line-height: 24px
  color: $color-primary-hc-navy-100
  @include Roboto400

.company-logo-container
  display: flex
  flex-direction: column
  align-items: center
  margin-left: 5rem
  margin-right: 2rem
  margin-top: 2rem

.no-logo
  height: 250px
  width: 250px

.program-button
  margin-top: 40px
  margin-bottom: 20px

.rewards-container
  display: flex
  max-width: 580px
  min-width: 300px
  padding-top: 20px
  flex-direction: column
  align-items: flex-start

.rewards-warning
  display: flex
  margin-top: 20px
  flex-direction: column
  color: $color-status-error-red

.rewards-warning-content
  display: flex
  align-items: center
  gap: 6px
  font-size: 14px
  line-height: 22px
  letter-spacing: 0.2px
  @include Roboto400

.tooltip-icon
  width: 27px
  height: 27px
  cursor: pointer

@media (max-width: 1500px)
  .program-container
    margin-left: 5rem
    margin-right: 1rem
    padding: 1rem
    justify-self: center

  .program-content-container
    padding-left: 1rem
    padding-right: 1rem

@media (max-width: 1200px)
  .program-container
    flex-direction: column
    margin-left: 0.5rem
    margin-right: 0.5rem
    padding: 0.5rem

  .program-content-container
    padding-left: 0.5rem
    padding-right: 0.5rem

@media (max-width: 900px)
  .program-container
    margin-left: 0.25rem
    margin-right: 0.25rem
    padding: 0.25rem

  .program-content-container
    padding-left: 0.25rem
    padding-right: 0.25rem

@media (max-width: 600px)
  .program-container
    margin-left: 0
    margin-right: 0
    padding: 0

  .program-content-container
    padding-left: 0
    padding-right: 0
</style>