<template>
  <loading-suspense :error="error" :loading="loading">
    <main v-if="dataFound">
      <div class="tracker-container">
        <div class="point-cap-tracker">
          <point-cap-tracker
            v-if="activityCapRule !== null && showCapTracker"
            :rule="activityCapRule"
          />
        </div>
        <div class="point-cap-tracker">
          <point-cap-tracker
            v-if="platformActivityCapRule !== null && showCapTracker"
            :rule="platformActivityCapRule"
          />
        </div>
      </div>
      <activity-card-row
        title="Available"
        :activities="combinedAvailable"
        :categories="availableCategories"
        :filter-key="'availableCombined'"
        @change-item="changeCombinedItem"
        @proof-sent="combinedProofSent"
        @apply-filter="applyCombinedAvailableFilter"
      />
      <activity-card-row
        title="Completed"
        :activities="combinedCompleted"
        :categories="completedCategories"
        :filter-key="'completedActivities'"
        @apply-filter="applyCompletedFilter"
      />
    </main>
    <empty-state
      v-else
      :image="noActivityImage"
      title="No Activities Available"
      description="Please contact your admin."
    />
  </loading-suspense>
  <add-tracking-button />
</template>

<script lang="ts" setup>
import AddTrackingButton from "@/vue/atoms/add-tracking-button.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import ActivityCardRow from "@/vue/molecules/activity-card-row.vue";
import EmptyState from "@/vue/atoms/empty-state.vue";
import noActivityImageSvg from "@/assets/img/illustrations/no-activities-available-1.svg";
import { computed, inject, onMounted, ref, watch } from "vue";
import type { ActivityDTO } from "@/ts/types/dto/activity.dto";
import type { Api } from "@/ts/classes/api";
import { ActivityCardModel } from "@/ts/types/component/activity-card";
import type {ContentCategory, GetProgramResponseDTO} from "@/ts/types/dto/program.dto";
import PointCapTracker from "@/vue/molecules/point-cap-tracker.vue";
import {PointCapUISelect, UserPointCapTotalsDTO} from "@/ts/types/dto/reward.dto";

const $api: Api = inject("$api")!;

const availableCategories = ref<ContentCategory[]>([]);
const completedCategories = ref<ContentCategory[]>([]);

const error = ref("");
const loading = ref(true);
const dataFound = ref(true);

const activeActivities = ref<ActivityCardModel[]>([]);
const availableActivities = ref<ActivityCardModel[]>([]);
const availablePlatformActivities = ref<ActivityCardModel[]>([]);
const completedActivities = ref<ActivityCardModel[]>([]);
const completedPlatformActivities = ref<ActivityCardModel[]>([]);
const userPointTotals = ref<UserPointCapTotalsDTO[]>([]);
const program = ref<GetProgramResponseDTO>();
const activityCapRule = ref<UserPointCapTotalsDTO | null>(null);
const platformActivityCapRule = ref<UserPointCapTotalsDTO | null>(null);
const showCapTracker = ref(false);

const noActivityImage = computed((): string => noActivityImageSvg);

const combinedAvailable = computed((): ActivityCardModel[] => {
  return ([
    ...(availableActivities.value ?? []),
    ...(activeActivities.value ?? []),
    ...(availablePlatformActivities.value ?? []),
  ] as unknown) as ActivityCardModel[];
});

const combinedCompleted = computed((): ActivityCardModel[] => {
  return ([
    ...(completedActivities.value ?? []),
    ...(completedPlatformActivities.value ?? []),
  ] as unknown) as ActivityCardModel[];
});

const changeCombinedItem = (item: ActivityCardModel): void => {
  refreshActivities()
};

const activeProofSent = async (id: string): Promise<void> => {
  if (!activeActivities.value) return;
  const activityByIdResponse: ActivityDTO = await $api.getActivityById(id);
  activeActivities.value = activeActivities.value.map(item =>
    item.id === id ? ActivityCardModel.createActivity(activityByIdResponse) : item
  );
};

const availableProofSent = async (id: string): Promise<void> => {
  if (!availableActivities.value) return;
  const activityByIdResponse: ActivityDTO = await $api.getActivityById(id);
  availableActivities.value = availableActivities.value.map(item =>
    item.id === id ? ActivityCardModel.createActivity(activityByIdResponse) : item
  );
};

const combinedProofSent = async (id: string): Promise<void> => {
  if (activeActivities.value?.find(a => a.id === id)) {
    await activeProofSent(id);
  } else if (availableActivities.value?.find(a => a.id === id)) {
    await availableProofSent(id);
  }
  // Platform activities do not support proof events
};

const applyCombinedAvailableFilter = async (categoryIds: string[]): Promise<void> => {
  try {
    const unifiedRes = await $api.getActivitiesUnified({
      onlyCompleted: false,
      categoryIds: categoryIds.join(","),
      limit: 100,
    });
    activeActivities.value = [];
    availableActivities.value = [];
    availablePlatformActivities.value = [];
    unifiedRes.items.forEach((item: any) => {
      if (!item.IsActivity) {
        availablePlatformActivities.value.push(ActivityCardModel.createPlatformActivity(item));
      } else if (item.status === "active") {
        activeActivities.value.push(ActivityCardModel.createActivity(item));
      } else if (item.status === "available") {
        availableActivities.value.push(ActivityCardModel.createActivity(item));
      }
    });
  } catch (err) {
    error.value = err as string;
  }
};

const applyCompletedFilter = async (categoryIds: string[]): Promise<void> => {
  try {
    const unifiedRes = await $api.getActivitiesUnified({
      onlyCompleted: true, 
      categoryIds: categoryIds.join(","),
      limit: 100,
    });
    completedActivities.value = [];
    completedPlatformActivities.value = [];
    unifiedRes.items.forEach((item: any) => {
      if (!item.IsActivity) {
        completedPlatformActivities.value.push(ActivityCardModel.createPlatformActivity(item));
      } else {
        completedActivities.value.push(ActivityCardModel.createActivity(item));
      }
    });
  } catch (err) {
    error.value = err as string;
  }
};

const loadUserPointCaps = async (): Promise<void> => {
  try {
    program.value = await $api.getProgram(true);
    const response = await $api.getUserPointCapTotals(program.value.id);
    userPointTotals.value = response?.userPointCapTotal ?? [];
    showCapTracker.value = response?.uiSelection === PointCapUISelect.content_page;
  } catch (err) {
    console.error(err);
  }
};

const loadCategories = async (): Promise<void> => {
  const [availableCatRes, completedCatRes] = await Promise.allSettled([
    $api.getActivityCategories({ onlyActive: true, onlyAvailable: true }),
    $api.getActivityCategories({ onlyCompleted: true }),
  ]);

  if (availableCatRes.status === "fulfilled") {
    availableCategories.value = availableCatRes.value
      .sort((a, b) => b.count - a.count)
      .map(category => ({
        id: category.id,
        name: `${category.name} (${category.count})`,
        color: category.color
      }));
  }
  if (completedCatRes.status === "fulfilled") {
    completedCategories.value = completedCatRes.value
      .sort((a, b) => b.count - a.count)
      .map(category => ({
        id: category.id,
        name: `${category.name} (${category.count})`,
        color: category.color
      }));
  }
};

const refreshActivities = async (): Promise<void> => {
  try {
    loading.value = true;
    const [unifiedAvailableRes, unifiedCompletedRes] = await Promise.allSettled([
      $api.getActivitiesUnified({ onlyCompleted: false, limit: 100 }),
      $api.getActivitiesUnified({ onlyCompleted: true, limit: 100 }),
    ]);

    activeActivities.value = [];
    availableActivities.value = [];
    availablePlatformActivities.value = [];
    completedActivities.value = [];
    completedPlatformActivities.value = [];

    if (unifiedAvailableRes.status === "fulfilled") {
      unifiedAvailableRes.value.items.forEach((item: any) => {
        if (!item.IsActivity) {
          availablePlatformActivities.value.push(ActivityCardModel.createPlatformActivity(item));
        } else if (item.status === "active") {
          activeActivities.value.push(ActivityCardModel.createActivity(item));
        } else if (item.status === "available") {
          availableActivities.value.push(ActivityCardModel.createActivity(item));
        }
      });
    }

    if (unifiedCompletedRes.status === "fulfilled") {
      unifiedCompletedRes.value.items.forEach((item: any) => {
        if (!item.IsActivity) {
          completedPlatformActivities.value.push(ActivityCardModel.createPlatformActivity(item));
        } else {
          completedActivities.value.push(ActivityCardModel.createActivity(item));
        }
      });
    }

    dataFound.value = Boolean(
      activeActivities.value.length ||
      availableActivities.value.length ||
      completedActivities.value.length ||
      availablePlatformActivities.value.length
    );
  } catch (err) {
    error.value = err as string;
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  try {
    await loadUserPointCaps();
    await loadCategories();
    const [unifiedAvailableRes, unifiedCompletedRes] = await Promise.allSettled([
      $api.getActivitiesUnified({ onlyCompleted: false, limit: 100 }),
      $api.getActivitiesUnified({ onlyCompleted: true, limit: 100 }),
    ]);

    if (unifiedAvailableRes.status === "fulfilled") {
      unifiedAvailableRes.value.items.forEach((item: any) => {
        if (!item.IsActivity) {
          availablePlatformActivities.value.push(ActivityCardModel.createPlatformActivity(item));
        } else if (item.status === "active") {
          activeActivities.value.push(ActivityCardModel.createActivity(item));
        } else if (item.status === "available") {
          availableActivities.value.push(ActivityCardModel.createActivity(item));
        }
      });
    }
    if (unifiedCompletedRes.status === "fulfilled") {
      unifiedCompletedRes.value.items.forEach((item: any) => {
        if (!item.IsActivity) {
          completedPlatformActivities.value.push(ActivityCardModel.createPlatformActivity(item));
        } else {
          completedActivities.value.push(ActivityCardModel.createActivity(item));
        }
      });
    }
  } catch (err) {
    error.value = err as string;
  } finally {
    loading.value = false;
    dataFound.value = Boolean(
      activeActivities.value.length ||
      availableActivities.value.length ||
      completedActivities.value.length ||
      availablePlatformActivities.value.length
    );
  }
});

watch(userPointTotals, (newVal) => {
  activityCapRule.value = newVal.find((rule) =>
    rule.pointCapRule.ruleType === "content" &&
    rule.pointCapRule.contentType === "activity"
  ) || null;

  platformActivityCapRule.value = newVal.find((rule) =>
    rule.pointCapRule.ruleType === "content" &&
    rule.pointCapRule.contentType === "platform_activity"
  ) || null;
});
</script>

<style lang="sass" scoped>
main
  margin-top: 4rem
  padding-bottom: 4rem


.tracker-container
  display: flex
  justify-content: flex-start
  gap: 60px
  max-width: 800px
  margin-left: 8rem
  margin-bottom: 2rem

.point-cap-tracker
  display: flex
  flex-direction: column
  align-items: stretch
  max-width: 400px
</style>
