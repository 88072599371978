<template>
  <div>
    <navbar-page class="body" title="My Coaching Health" :back-link="$pagesPath.program.programOverview">
      <div class="description">
        <img src="@/assets/img/illustrations/receive-support.svg"/>
        <h1>You don't have a coaching call scheduled yet.</h1>
      </div>
      <coaching-appointment/>
      <div class="coaching-info">
        <p>HealthCheck360 Health Coaching motivates and supports you in making long-term lifestyle changes that will help you achieve your personal wellness goals. This is accomplished by providing you with a coaching experience that is unique to you and tailored to fit your lifestyle.</p>
        <p>Directly contact one of our coaches today at:</p>
        <div class="coaching-contact">
          <p>Phone:</p>
          <a href="tel:1-866-511-0360">1-866-511-0360</a>
          <b>ext 5099</b>
        </div>
        <div class="coaching-contact">
          <p>Email:</p>
          <a href="mailto:healthcoach@healthcheck360.com">healthcoach@healthcheck360.com</a>
        </div>
        <br/>
      </div>
      <button-link
        class="schedule-button"
        :to="$pagesPath.coaching.coachingSchedule"
        theme="dark"
      >
        Schedule
      </button-link>
    </navbar-page>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import ButtonLink from "@/vue/atoms/button-link.vue";
import CoachingAppointment from "@/vue/pages/coaching/coaching-appointment-confirmed.page.vue";

@Component({
  name: "CoachingPage",
  components: {
    ButtonPrimary,
    NavbarPage,
    ButtonLink,
    CoachingAppointment,
  },
})
export default class CoachingPage extends Vue {
}
</script>

<style lang="sass" scoped>
.body
  padding-left: 100px

.description
  display: flex
  flex-direction: column
  align-items: center

  img
    width: 250px
    height: 250px

  > h1
    @include Asap700
    font-size: 24px
    color: $color-primary-hc-navy-100
    margin-top: 1rem

.coaching-info
  display: flex
  flex-direction: column
  align-items: center
  margin: auto
  max-width: 600px

  > p
    @include Asap400
    font-size: 16px
    line-height: 24px
    color: $color-primary-hc-navy-100
    text-align: center

.coaching-contact
  display: flex
  flex-direction: row
  align-items: center

  > p
    font-weight: bold
    margin-right: 0.5rem

  > b
    font-weight: normal
    margin-left: 0.5rem

.schedule-button
  display: flex
  justify-content: center

</style>
