<template>
  <div class="container">
    <img src="@/assets/bg_my_care_360.png" alt="Header Image" class="header-image"/>
    <h1>myCare360</h1>
    <p class="my-care-text">
      The myCare360 Condition Management Program provides education and support for members who are currently managing a chronic health condition. If the program is available to you, you will receive additional information from the myCare360 team.
    </p>
    <div class="external-btns">
      <button-link :to="appStoreLink" theme="dark">
        Get it on the App Store
        <img
          class="external-link"
          src="@/assets/img/icons/external-link.svg"
        />
      </button-link>
      <button-link :to="playStoreLink" theme="dark">
        Get it on the Play Store
        <img
          class="external-link"
          src="@/assets/img/icons/external-link.svg"
        />
      </button-link>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import ButtonLink from "@/vue/atoms/button-link.vue";

export default defineComponent({
  components: {
    ButtonLink,
  },
  setup() {
    const appStoreLink = ref("https://apps.apple.com/us/app/mycare360/id1488795316");
    const playStoreLink = ref("https://play.google.com/store/apps/details?id=com.hc360.mycare360");

    return {appStoreLink,
playStoreLink};
  },
});
</script>

<style lang="sass" scoped>

.container
  display: flex
  flex-direction: column
  align-items: center
  text-align: center

.header-image
  width: 100%
  max-height: 300px
  object-fit: cover
  margin-bottom: 20px
  margin-top: -6rem

.my-care-text
  max-width: 600px
  margin-bottom: 40px

.external-btns
  display: flex
  justify-content: center
  gap: 20px
  margin-bottom: 20px

.external-link
  filter: brightness(0) invert(1)


</style>
