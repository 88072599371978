<template>
  <navbar-page :profile-section="true" class="body">
    <loading-suspense :loading="loading" :error="error" class="loading">
      <template v-if="dataLoaded">
        <greeting-section/>
        <program-section
          v-if="program"
          :program="program"
          :reward-response="rewardResponse"
          :required-activities-not-completed-count="requiredActivitiesNotCompletedCount"
          :to="to"
          :company-logo="company?.logo"
        />
        <div class="todo-progress-container">
          <todo-section :current-day="currentDay" @todos-updated="refreshDashboard"/>
          <progress-section
            :steps-value="stepsValue"
            :activity-value="activityValue"
            :sleep-value="sleepValue"
          />
        </div>
      </template>
    </loading-suspense>
    <add-tracking-button @tracking-added="refreshDashboard"/>
  </navbar-page>
</template>

<script lang="ts" setup>
import { ref, computed, inject, onMounted } from "vue";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import AddTrackingButton from "@/vue/atoms/add-tracking-button.vue";
import GreetingSection from "@/vue/organisms/dashboard/greeting-section.vue";
import ProgramSection from "@/vue/organisms/dashboard/program-section.vue";
import TodoSection from "@/vue/organisms/dashboard/todo-section.vue";
import ProgressSection from "@/vue/organisms/dashboard/progress-section.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import { today, convertDateToServerFormat, getMondayOfTheWeek, addToDate, formatDate } from "@/ts/utils/date-pure-functions";
import type { Program } from "@/ts/types/store/default-store-types";
import type { RewardTrackerResponse } from "@/ts/types/dto/reward-tracker";
import type { Api } from "@/ts/classes/api";
import type { GatekeeperItem } from "@/ts/types/dto/program.dto";
import { GatekeeperStatus } from "@/ts/types/dto/program.dto";
import { TaskType } from "@/ts/types/dto/activity.dto";
import type { CompanyDTO } from "@/ts/types/dto/company.dto";
import { HealthContentActivityResponseType } from "@/ts/types/dto/health.dto";

const api = inject("$api")! as Api;
const cachedApi = inject("$cachedApi")! as Api;
const currentDay = ref(today());
const requiredActivitiesNotCompletedCount = ref(0);
const program = ref<Program | null>(null);
const rewardResponse = ref<RewardTrackerResponse | null>(null);
const stepsValue = ref(0);
const activityValue = ref(0);
const sleepValue = ref(0);
const company = ref<CompanyDTO | null>(null);
const loading = ref(false);
const error = ref<string | null>(null);
const dataLoaded = ref(false);

const to = computed(() => "/program/overview");

onMounted(async () => {
  await fetchDashboardData();
});

async function fetchDashboardData() {
  loading.value = true;
  error.value = null;
  try {
    await fetchProgram();
    await fetchRewardTracker();
    await fetchGatekeeperItems();
    await fetchTrackingData();
    await fetchCompany();
    dataLoaded.value = true;
  } catch (err) {
    error.value = "Failed to load data";
    console.log("Failed to load data: ", err)
  } finally {
    loading.value = false;
  }
}

async function refreshDashboard() {
  error.value = null;
  try {
    await fetchProgram();
    await fetchRewardTracker();
    await fetchGatekeeperItems();
    await fetchTrackingData();
    await fetchCompany();
  } catch (err) {
    error.value = "Failed to load data";
  }
}

async function fetchProgram() {
  program.value = await cachedApi.getProgram();
}

async function fetchRewardTracker() {
  rewardResponse.value = await cachedApi.getRewardTracker();
}

async function fetchGatekeeperItems() {
  const gatekeeperItems: GatekeeperItem[] = await api.getGatekeeperItems(
    program.value!.id,
    HealthContentActivityResponseType.HealthActivityProgramOverview
  );
  requiredActivitiesNotCompletedCount.value = gatekeeperItems.filter(
    (item) => item.isRequired && item.status !== GatekeeperStatus.completed
  ).length;
}

async function fetchTrackingData() {
  const days = Array.from({ length: 7 }, (_, index) => addToDate(getMondayOfTheWeek(new Date()), { days: index }));
  await fetchStepsTrackingItems(days);
  await fetchActivityTrackingItems(days);
  await fetchSleepTrackingItems(days);
}

async function fetchStepsTrackingItems(days: Date[]) {
  const stepsTrackingItems = await api.getTrackingList({
    startDate: convertDateToServerFormat(days[0]),
    endDate: convertDateToServerFormat(days[days.length - 1]),
    taskType: TaskType.steps,
  });
  stepsValue.value = stepsTrackingItems.reduce(
    (acc, item) => (formatDate(item.date) === formatDate(currentDay.value) ? acc + item.trackedAmount : acc),
    0
  );
}

async function fetchActivityTrackingItems(days: Date[]) {
  const activityTrackingItems = await api.getTrackingList({
    startDate: convertDateToServerFormat(days[0]),
    endDate: convertDateToServerFormat(days[days.length - 1]),
    taskType: Object.values(TaskType)
      .filter((taskType) => taskType.includes("time") || taskType === TaskType.general_physical_activity_time)
      .filter((taskType) => taskType !== TaskType.sleep_time)
      .join(","),
  });
  activityValue.value = activityTrackingItems.reduce(
    (acc, item) => (formatDate(item.date) === formatDate(currentDay.value) ? acc + Math.floor(item.trackedAmount / 60) : acc),
    0
  );
}

async function fetchSleepTrackingItems(days: Date[]) {
  const sleepTrackingItems = await api.getTrackingList({
    startDate: convertDateToServerFormat(days[0]),
    endDate: convertDateToServerFormat(days[days.length - 1]),
    taskType: TaskType.sleep_time,
  });
  sleepValue.value = parseFloat(
    sleepTrackingItems.reduce(
      (acc, item) => (formatDate(item.date) === formatDate(currentDay.value) ? acc + item.trackedAmount / 3600 : acc),
      0
    ).toFixed(1)
  );
}

async function fetchCompany() {
  company.value = await api.getCompany();
}
</script>

<style lang="sass" scoped>
.body
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  background-image: url("@/assets/img/background-wave.svg")
  background-position-y: -1rem
  background-repeat: no-repeat
  background-size: contain
  color: $color-primary-hc-navy-100
  @include Asap700
  position: absolute
  min-height: 100vh
  width: 100%
  padding-left: 200px

.todo-progress-container
  display: flex
  max-width: 1258px
  padding-bottom: 2rem
  flex-direction: row
  justify-content: space-between
  align-items: flex-start
  margin-top: 3rem
  width: 100%

.loading
  align-content: stretch
  display: flex
  flex-direction: column
  flex-wrap: nowrap
  justify-content: center
  align-items: center
  width: 100%
  margin: 6rem 0

@media (min-width: 1800px)
  .body
    padding-left: 380px

@media (max-width: 1500px)
  .body
    padding-left: 100px
    padding-right: 50px

  .todo-progress-container
    margin: 3rem 4rem

  .loading
    margin: 3rem 0

@media (max-width: 1200px)
  .body
    padding-left: 150px
    padding-right: 50px

  .todo-progress-container
    flex-direction: column
    align-items: center
    margin: 2rem 0
    padding: 0.5rem

  .loading
    margin: 2rem 0

@media (max-width: 900px)
  .body
    padding-left: 50px
    padding-right: 50px

  .todo-progress-container
    padding: 0

  .loading
    margin: 1rem 0

@media (max-width: 600px)
  .body
    padding: 0

  .todo-progress-container
    padding: 0

  .loading
    margin: 0.5rem 0
</style>
