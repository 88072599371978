<template>
  <section class="rewards-progress">
    <div class="image-container">
      <protected-image 
            :src="noCapsPlaceholderImage"
            :ariaHidden="false"
            alt="No Caps Placeholder Image"
            :load-raw-image="true"
        />
    </div>
    <div v-if="showActionsContainer" class="actions-container">
      <gatekeeper-items
        v-if="gatekeeperItems.length && showRequired"
        default-description="In order to earn the incentive tied to your wellness program, you must complete the required activities."
        :items="gatekeeperItems"
      />
      <primary-action-card :gatekeeper-items="gatekeeperItems"/>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { defineProps, computed } from "vue";
import GatekeeperItems from "@/vue/molecules/gatekeeper-items.vue";
import PrimaryActionCard from "@/vue/molecules/primary-action-card.vue";
import type { GatekeeperItem } from "@/ts/types/dto/program.dto";
import ProtectedImage from "@/vue/atoms/protected-image.vue";

const props = defineProps<{
  gatekeeperItems: GatekeeperItem[];
  showRequired: boolean;
  showActionsContainer: boolean;
}>();

// TODO add point caps in place of image after feature integrated. keep image as placeholder for when there are no point caps
const noCapsPlaceholderImage = "147f9b80-6eca-4c15-911c-3db9ab767505-static/photos/GettyImages-1502802152.jpg"

</script>

<style lang="sass" scoped>
.rewards-progress
  max-width: 1320px
  min-width: 300px
  margin: 0 auto
  align-items: flex-start
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 26px
  padding: 3rem 0

.image-container
  width: 550px
  height: 400px
  display: flex
  justify-content: center
  align-items: center
  background-color: $color-white
  border-radius: 16px
  box-shadow: $content-shadow
  overflow: hidden  

  img
    width: 90%
    height: 90%
    object-fit: cover
    border-radius: 16px

.actions-container
  background-color: $color-neutral-platinum-40
  display: flex
  gap: 26px
  flex-wrap: wrap
  padding: 24px
  border-radius: 16px
  box-shadow: $content-shadow

@media (max-width: 1500px)
  .rewards-progress
      margin: 58px

@media (max-width: 1200px)
  .rewards-progress
    grid-template-columns: 1fr
    padding: 1.5rem
</style>