<template>
  <div class="progress-container">
    <progress-item type="steps" :value="stepsValue" @click="navigateToPath('steps')" />
    <progress-item type="activity" :value="activityValue" @click="navigateToPath('activity')" />
    <progress-item type="sleep" :value="sleepValue" @click="navigateToPath('sleep')" />
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import ProgressItem from "@/vue/molecules/dashboard/progress-item.vue";
import { pagesPath } from "@/ts/router/pages-path";

defineProps<{
  stepsValue: number;
  activityValue: number;
  sleepValue: number;
}>();

const router = useRouter();

const navigateToPath = (type: string) => {
  switch (type) {
    case "steps":
      router.push(pagesPath.profile.myStepsProgress);
      break;
    case "activity":
      router.push(pagesPath.profile.myMinutesOfActivityProgress);
      break;
    case "sleep":
      router.push(pagesPath.profile.myProgress);
      break;
    default:
      router.push(pagesPath.profile.myPath);
  }
};
</script>

<style lang="sass" scoped>
.progress-container
  display: flex
  flex-direction: column
  gap: 12px
  flex-shrink: 0

@media (max-width: 1500px)
  .progress-container
    margin-left: 1rem
    margin-right: 1rem
    margin-bottom: 4rem

@media (max-width: 1200px)
  .progress-container
    margin-left: 0.5rem
    margin-right: 0.5rem
    gap: 0.5rem
    margin-bottom: 4rem
    margin-top: 3rem

@media (max-width: 900px)
  .progress-container
    margin-left: 0.25rem
    margin-right: 0.25rem
    margin-bottom: 4rem

@media (max-width: 600px)
  .progress-container
    margin-left: 0
    margin-right: 0
    margin-right: 10rem
</style>