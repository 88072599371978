<template>
  <bordered-box theme="white">
    <box-row>
      <template #left>
        <icon-title title="Completed on" icon="completed"/>
      </template>
      <template #right>
        <p class="date align-right">
          {{ formatDate }}
        </p>
      </template>
    </box-row>
  </bordered-box>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import BorderedBox from "@/vue/molecules/activities/bordered-box.vue";
import BoxRow from "@/vue/atoms/box-row.vue";
import IconTitle from "@/vue/molecules/activities/icon-title.vue";
import {formatDateShortMonthName, parseDate} from "@/ts/utils/date-pure-functions";

@Component({
  name: "ActivityCompletedBox",
  components: {BorderedBox, BoxRow, IconTitle},
})
export default class ActivityCompletedBox extends Vue {
  @Prop() date!: string;

  get formatDate(): string {
    const date = parseDate(this.date);
    return formatDateShortMonthName(date);
  }
}
</script>

<style lang="sass" scoped>

.date
  @include Roboto700
  font-size: 14px
  line-height: 22px
  color: $color-primary-hc-navy-100
  margin: 0

.text
  @include Roboto400
  font-size: 14px
  line-height: 22px
  color: $color-primary-hc-navy-100
  margin: 0
  text-align: right
</style>
