<template>
  <form @submit.prevent="submitform">
    <physician-form-card-button type="upload" :disabled="!canEdit" @file-uploaded="uploadForm"/>
    <div class="information">
      <h2>Physician Information</h2>
      <div class="row">
        <field-set id="clinic" label="Physician’s Clinic" class="field">
          <input-text
            id="clinic"
            :model-value="clinic"
            required
            autocomplete="clinic"
            placeholder="Insert the clinic’s name"
            :disabled="!canEdit"
            @update:model-value="updateClinic"
          />
        </field-set>
        <field-set id="phone" label="Phone Number" class="field">
          <input-phone
            id="phone"
            v-model="phone"
            required
            autocomplete="phone"
            placeholder="Insert the clinic’s phone"
            :disabled="!canEdit"
            @update:model-value="updatePhone"
          />
        </field-set>
      </div>
      <field-set id="name" label="Physician’s Name" class="field">
        <input-text
          id="name"
          :model-value="name"
          required
          autocomplete="name"
          placeholder="Insert the physician’s name"
          :disabled="!canEdit"
          @update:model-value="updateName"
        />
      </field-set>
      <input-check
        id="signed"
        class="checkbox"
        required
        :model-value="signed"
        theme="purple"
        :disabled="!canEdit"
        @update:model-value="updateSigned"
      >
        My physician has signed my Wellness Screening Results Form.
      </input-check>
      <div>
        <p class="release">
          Release of Health Information
        </p>
        <input-check
          id="release"
          class="checkbox"
          required
          :model-value="release"
          theme="purple"
          :disabled="!canEdit"
          @update:model-value="updateRelease"
        >
          I authorize the release of my personal health information and
          preventine health screening results listed on this form by my health
          care provider. This authorization shall remain in force for 12 months
          following the date of my signature below and a copy of this
          authorization is as valid as the original. I understand that I have
          the right to revoke this authorization in writing, at any time, by
          providing written notification. I acknowledge that all fields have
          been accurately completed in order for my form to be accepted.
        </input-check>
      </div>
      <div class="buttons">
        <button-link theme="white" :to="$pagesPath.physicianForm.downloadForm">
          <img src="@/assets/img/left-arrow-cal.svg" alt="arrow left" class="left-arrow"/> Back
        </button-link>
        <button-primary v-if="canEdit" type="submit">
          Next <img src="@/assets/img/right-arrow-white.svg" alt="arrow right"/>
        </button-primary>
        <button-link v-else :to="$pagesPath.physicianForm.examinationInformation">
          Next <img src="@/assets/img/right-arrow-white.svg" alt="arrow right"/>
        </button-link>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import {Component, Emit} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {
  BiometricPhysicianFormState,
  BiometricPhysicianFormStoreMixin,
} from "@/ts/store/biometric-physician-form/biometric-physician-form-store-instance";
import ButtonLink from "@/vue/atoms/button-link.vue";
import FieldSet from "@/vue/molecules/field-set.vue";
import InputText from "@/vue/atoms/input-text.vue";
import InputPhone from "@/vue/atoms/input-phone.vue";
import InputCheck from "@/vue/atoms/input-check.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import type {PhysicianFormBiometric} from "@/ts/types/store/biometric-physician-form-store-types";
import PhysicianFormCardButton from "@/vue/molecules/physician-form-card-button.vue";
import {PhysicianFormStatus} from "@/ts/types/dto/physician-form.dto";
import {formatPhoneNumberForAPI} from "@/ts/utils/pure-functions";

@Component({
  components: {
    ButtonLink,
    FieldSet,
    InputText,
    InputPhone,
    InputCheck,
    ButtonPrimary,
    PhysicianFormCardButton,
  },
})
export default class PhysicianFormInformation extends mixins(
  BiometricPhysicianFormStoreMixin,
) {
  @BiometricPhysicianFormState
  physicianForm!: PhysicianFormBiometric;

  clinic: string | null = "";

  name: string | null = "";

  phone: string | null = "";

  signed!: boolean | null;

  release!: boolean | null;

  fileUploaded!: File | null;

  get canEdit(): boolean {
    return this.physicianForm.status != PhysicianFormStatus.approved && this.physicianForm.status != PhysicianFormStatus.overdue;
  }

  uploadForm(value: File | null): void {
    this.fileUploaded = value;

    if (value) {
      const fileNameLength = value.name.length;
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      const fileName = fileNameLength > 255 ? "physician-form.pdf" : value.name;

      this.biometricPhysicianFormStore.setPhysicianForm({
        ...this.physicianForm,
        uploadFormFileName: fileName,
      });
      this.biometricPhysicianFormStore.setPhysicianFormFile(value);
    }
  }

  @Emit("update:updateClinic")
  updateClinic(value: string): string {
    this.clinic = value;
    return value;
  }

  @Emit("update:updateName")
  updateName(value: string): string {
    this.name = value;
    return value;
  }

  @Emit("update:updatePhone")
  updatePhone(value: string): string {
    this.phone = value;
    return value;
  }

  @Emit("update:updateSigned")
  updateSigned(value: boolean): boolean {
    this.signed = value;
    return value;
  }

  @Emit("update:updateRelease")
  updateRelease(value: boolean): boolean {
    this.release = value;
    return value;
  }

  submitform(): void {
    const formattedPhone = formatPhoneNumberForAPI(this.phone!);

    this.biometricPhysicianFormStore.setPhysicianForm({
      ...this.physicianForm,
      physicianClinic: this.clinic!,
      physicianName: this.name!,
      physicianPhone: formattedPhone,
      physicianSigned: this.signed!,
      healthInfoRelease: this.release!,
    });

    void this.$router.push(
      this.$pagesPath.physicianForm.examinationInformation,
    );
  }

  created(): void {
    if (this.physicianForm.physicianPhone && this.physicianForm.physicianPhone.startsWith("+1")) {
      this.phone = this.physicianForm.physicianPhone.substring(2);
    } else {
      this.phone = this.physicianForm.physicianPhone;
    }
    this.signed = this.physicianForm.physicianSigned;
    this.name = this.physicianForm.physicianName;
    this.clinic = this.physicianForm.physicianClinic;
    this.release = this.physicianForm.healthInfoRelease;
  }
}
</script>

<style lang="sass" scoped>

.information
  width: 681px
  display: flex
  flex-direction: column
  gap: 24px

  @include max-device(md)
    flex-direction: row

  h2
    @include Asap700
    font-size: 20px
    line-height: 28px
    color: $color-primary-hc-navy-100

.row
  display: flex
  justify-content: space-between

.field
  width: 300px

.checkbox
  @include Roboto400
  font-size: 14px
  line-height: 22px
  color: $color-primary-hc-navy-100

.release
  @include Asap700
  font-size: 16px
  line-height: 22px
  color: $color-primary-hc-navy-100

.buttons
  width: 681px
  display: flex
  justify-content: space-between
  margin: 48px auto

  button,
  a
    width: 130px

.left-arrow
  width: 7px
</style>
