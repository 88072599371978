<template>
  <div :class="{['big-icon']: big}">
    <squared-icon icon-type="bio"/>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import SquaredIcon from "@/vue/atoms/squared-icon.vue";

@Component({
  name: "BioScreeningIcon",
  components: {
    SquaredIcon,
  },
})

export default class GatekeeperIcon extends Vue {
  @Prop({default: false}) big?: boolean;
}
</script>

<style lang="sass" scoped>
div
  color: $color-primary-hc-navy-100
  width: 48px
  height: 48px

  .big-icon
    width: 60px
    height: 60px
</style>
