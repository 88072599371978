<template>
  <loading-suspense :error="error" :loading="loading">
    <p>Activate these related activities to earn points</p>
    <div>
      <related-activity
        v-for="related in relateds"
        :key="related.id"
        :related="related"
      />
    </div>
  </loading-suspense>
</template>

<script lang="ts">
import {Component, Prop} from "vue-property-decorator";
import type {ActivityDTO} from "@/ts/types/dto/activity.dto";
import RelatedActivity from "@/vue/molecules/tracking/related-activity.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import {mixins} from "vue-class-component";
import {
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";


@Component({
  name: "RelatedActivityList",
  components: {
    RelatedActivity,
    LoadingSuspense
  },
})

export default class RelatedActivityList extends mixins(LoadingMixin) {
  @Prop() relateds!: ActivityDTO[];
}
</script>

<style lang="sass" scoped>

p
  @include Asap700
  font-size: 20px
  color: $color-primary-hc-navy-100
  border-bottom: 1px solid $color-neutral-platinum-40
  padding-left: 10px
  margin: 0

</style>
