<template>
  <program-without-score-card :program="program"/>

  <div class="description">
    <p v-if="showBioscreeningText">
      Complete <b>Biometric Screening</b> to view your score.
    </p>
    <p v-if="showHraText">
      Complete <b>Health Risk Assessment</b>.
    </p>
    <p v-if="showScreeningAndHraText">
      Complete <b>Health Risk Assessment</b> and <b>Biometric Screening</b> to view your score.
    </p>
  </div>

  <alert-icon-card
    v-if="showSuccessText"
    theme="info"
  >
    <div class="alert-text">
      You’ll be notified when you get your score.
    </div>
  </alert-icon-card>
</template>

<script lang="ts">
import {Component, Vue, Prop} from "vue-property-decorator";
import AlertIconCard from "@/vue/atoms/alert-icon-card.vue";
import type {
  BiometricScreeningAvailable,
  BiometricScreeningStatus,
  BiometricScreeningWrapperResult,
  GetBiometricScreeningScheduleAppointmentDTO,
  PhysicianFormAvailable,
} from "@/ts/types/dto/biometric-screening.dto";
import type {HRASummary} from "@/ts/types/dto/health-risk-assessment.dto";
import type {MyProgramResponse} from "@/ts/types/dto/program.dto";
import type {GetPhysicianFormBiometricResponse} from "@/ts/types/dto/physician-form.dto";
import {isHraUnavailableOrNotAvailableInProgram} from "@/ts/utils/my-health/hra";
import {isBioscreeningUnavailableOrNotAvailableInProgram} from "@/ts/utils/my-health/bioscreening";
import ProgramWithoutScoreCard from "@/vue/molecules/my-health/my-score/program-without-score-card.vue";
import {isBioscreeningRequired, isHraRequired} from "@/ts/utils/my-health/my-score";

@Component({
  name: "MyScoreMessage",
  components: {
    AlertIconCard,
    ProgramWithoutScoreCard,
  }
})
export default class MyScoreMessage extends Vue {
  @Prop({default: null})
  hraSummary!: HRASummary | null;

  @Prop({default: null})
  biometricResult!: BiometricScreeningWrapperResult | null;

  @Prop({default: null})
  biometricStatus!: BiometricScreeningStatus | null;

  @Prop({default: null})
  program!: MyProgramResponse | null;

  @Prop({default: null})
  biometricAvailable!: BiometricScreeningAvailable | null;

  @Prop({default: null})
  physicianFormAvailable!: PhysicianFormAvailable | null;

  @Prop({default: null})
  appointment!: GetBiometricScreeningScheduleAppointmentDTO | null;

  @Prop({default: null})
  physicianForm!: GetPhysicianFormBiometricResponse | null;

  get isHraUnavailableOrNotAvailableInProgram(): boolean {
    return isHraUnavailableOrNotAvailableInProgram({
      program: this.program,
      hraSummary: this.hraSummary,
    });
  }

  get isBioscreeningUnavailableOrNotAvailableInProgram(): boolean {
    return isBioscreeningUnavailableOrNotAvailableInProgram({
      program: this.program,
      biometricAvailable: this.biometricAvailable,
      appointment: this.appointment,
      biometricStatus: this.biometricStatus,
      physicianFormAvailable: this.physicianFormAvailable,
      physicianForm: this.physicianForm,
    });
  }

  get isBioscreeningRequired(): boolean {
    return isBioscreeningRequired({
      program: this.program,
      physicianFormAvailable: this.physicianFormAvailable,
      biometricAvailable: this.biometricAvailable,
      appointment: this.appointment,
      physicianForm: this.physicianForm,
      biometricStatus: this.biometricStatus,
    });
  }

  get isHraRequired(): boolean {
    return isHraRequired({
      program: this.program,
      hraSummary: this.hraSummary,
    });
  }

  get showScreeningAndHraText(): boolean {
    return this.isHraRequired && this.isBioscreeningRequired;
  }

  get showHraText(): boolean {
    return this.isHraRequired && !this.isBioscreeningRequired;
  }

  get showBioscreeningText(): boolean {
    return !this.isHraRequired && this.isBioscreeningRequired;
  }

  get showNothing(): boolean {
    return this.isHraUnavailableOrNotAvailableInProgram && this.isBioscreeningUnavailableOrNotAvailableInProgram;
  }

  get showSuccessText(): boolean {
    return !this.biometricResult && !this.showNothing && !this.isBioscreeningRequired && !this.isHraRequired;
  }
}
</script>

<style lang="sass" scoped>
.description
  @include Roboto400
  font-size: 16px
  line-height: 24px
  color: $color-primary-hc-navy-100
  margin: 0

.alert-text
  @include Roboto400
  color: $color-primary-hc-navy-100
  font-size: 14px
  line-height: 22px
  margin: 0 0 4px
</style>
