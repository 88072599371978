<template>
  <alert-icon-card
    v-if="showBioscreeningAvailableInFuture"
    theme="info"
  >
    <div class="text">
      The biometric screening scheduler will be available <span class="bold"> {{ bioscreeningStartDate }} </span>
    </div>
  </alert-icon-card>
</template>

<script lang="ts">
import {Component, Vue, Prop} from "vue-property-decorator";
import AlertIconCard from "@/vue/atoms/alert-icon-card.vue";
import {formatDateShortMonthName} from "@/ts/utils/date-pure-functions";
import type {MyProgramResponse} from "@/ts/types/dto/program.dto";
import {hasProgramBioscreeningStartDateInFuture} from "@/ts/utils/my-health/program-bioscreening";

@Component({
  name: "BioscreeningAvailableFutureCard",
  components: {AlertIconCard}
})
export default class BioscreeningAvailableFutureCard extends Vue {
  @Prop()
  program!: MyProgramResponse;

  get hasProgramBioscreeningStartDateInFuture(): boolean {
    return hasProgramBioscreeningStartDateInFuture(this.program);
  }

  get showBioscreeningAvailableInFuture(): boolean {
    return this.hasProgramBioscreeningStartDateInFuture;
  }

  get bioscreeningStartDate(): string {
    return formatDateShortMonthName(this.program.biometricScreeningStartDate!);
  }
}
</script>

<style lang="sass" scoped>
.bold
  @include Roboto700

.text
  @include Roboto400
  color: $color-primary-hc-navy-100
  font-size: 14px
  letter-spacing: 0.2px
  line-height: 22px
  margin: 0 0 4px
</style>
