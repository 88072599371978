<template>
  <card-row>
    <template #header>
      <headline-with-counter :title="title" :amount="activities.length"/>
    </template>

    <template #filter>
      <div v-if="categories && categories.length > 0 && filterKey">
        <program-filter :categories="categories" @applyFilter="applyFilter" :filterKey="filterKey">
          <template #trigger>
            <button class="icon">
              <icon-chameleon color="primary100" hover-color="primary100" :icon="SvgFilter" />
            </button>
          </template>
        </program-filter>
      </div>
    </template>

    <template #extraButton>
      <button class="icon" @click="togglePanel">
        <icon-chameleon
          color="primary100"
          hover-color="primary100"
          :icon="(showPanel ? DownChevron : RightChevron)"
        />
      </button>
    </template>

    <template #selectedFilters>
      <div v-for="filter in selectedFilters" :key="filter.id" @click="removeFilter(filter)">
        <category-badge
          :value="filter.name"
          :color="filter.color"
          :selected="true"
        />
      </div>
    </template>

    <expansion-panel :expanded="showPanel">
      <template #body>
        <activity-card
          v-for="activity in activities"
          :key="activity.id"
          :activity="activity"
          :set-open="activity.id === queryActivityId"
          @change-item="changeItem"
          @proof-sent="proofSent"
        />
      </template>
    </expansion-panel>
  </card-row>
</template>

<script lang="ts" setup>
import ActivityCard from "@/vue/atoms/activity-card.vue";
import CardRow from "@/vue/molecules/card-row.vue";
import HeadlineWithCounter from "@/vue/atoms/headline-with-counter.vue";
import ProgramFilter from "@/vue/molecules/program-filter.vue";
import CategoryBadge from "@/vue/atoms/category-badge.vue";
import ExpansionPanel from "@/vue/atoms/expansion-panel.vue";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";
import SvgFilter from "@/assets/img/filter.svg";
import RightChevron from "@/assets/img/right-chevron.svg";
import DownChevron from "@/assets/img/down-chevron.svg";
import type { LocationQueryValue } from "vue-router";
import type { ActivityCardModel } from "@/ts/types/component/activity-card";
import type { ContentCategory } from "@/ts/types/dto/program.dto";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";

const props = defineProps<{
  title: string;
  activities: ActivityCardModel[];
  categories?: ContentCategory[];
  filterKey?: string;
}>();

const selectedFilters = ref<ContentCategory[]>([]);
const showPanel = ref<boolean>(true);
const route = useRoute();

const queryActivityId = computed((): string | null => {
  return route.query.id as LocationQueryValue;
});

const togglePanel = () => {
  showPanel.value = !showPanel.value;
};

const changeItem = (item: ActivityCardModel): void => {
  emit("changeItem", item);
};

const proofSent = (id: string): void => {
  emit("proofSent", id);
};

const applyFilter = (selectedCategories: ContentCategory[]): void => {
  emit("applyFilter", selectedCategories.map(item => item.id));
  selectedFilters.value = selectedCategories;
};

const removeFilter = (filter: ContentCategory): void => {
  selectedFilters.value = selectedFilters.value.filter(item => item.id !== filter.id);
  if (props.filterKey) {
    localStorage.setItem(`selectedCategories_${props.filterKey}`, JSON.stringify(selectedFilters.value));
  }
  applyFilter(selectedFilters.value);
};

const emit = defineEmits(["changeItem", "proofSent", "applyFilter"]);
</script>

<style lang="sass" scoped>
.card-row
  width: calc(33.33% - 16px)

.icon
  margin-bottom: 4px
  cursor: pointer
  border: none
  background: none
</style>
