import {GetActivitiesResponseDTO, GetPlatformActivitiesResponseDTO, TaskType} from "@/ts/types/dto/activity.dto";
import type {Pagination} from "@/ts/types/dto/pagination.dto";
import {GetPathwaysResponseDTO} from "@/ts/types/dto/pathway.dto";
import {PaginatedChallengeDTO} from "@/ts/types/dto/challenges.dto";
import {ContentCategoryIcon, GatekeeperItem} from "@/ts/types/dto/program.dto";

export interface CompletionValidation {
  type: RewardValidationType;
  status: RewardCompletionStatus;
}

export enum RewardValidationType {
  Default = "default",
  AdminApproval = "admin_approval",
  AutoApproval = "auto_approval",
  AdminUpload = "admin_upload",
}

export enum RewardCompletionStatus {
  not_submitted = "not_submitted",
  submitted = "submitted",
  accepted = "accepted",
  declined = "declined",
}

export enum RewardType {
  cash = "cash",
  point = "point",
}

export interface Reward {
  type: RewardType;
  amount: number;
}

export interface RewardValidation {
  type: RewardType;
  amount: number;
  completionValidation: CompletionValidation;
}

export enum RewardHistoryContentType {
  reward_history = "reward_history",
  activity = "activity",
  platform_activity = "platform_activity",
  pathway = "pathway",
  challenge_leaderboard = "challenge_leaderboard",
  challenge_target = "challenge_target",
  challenge_map_race = "challenge_map_race",
  challenge_relay_race = "challenge_relay_race",
  bioscreening = "bioscreening",
  hra = "hra",
  coaching = "coaching",
  redeem_reward = "redeem_reward",
}

export interface RewardHistoryItem {
  id: string;
  amount: number;
  contentType: RewardHistoryContentType;
  date: string;
  displayText: string;
  isGatekeeper: boolean;
  isRecommended: boolean;
  rewardType: RewardType;
  taskType: TaskType;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  path?: string;
}

export interface GetRewardHistoryRequestDTO {
  limit?: number;
  offset?: number;
}

export interface GetRewardHistoryResponseDTO {
  pagination: Pagination;
  items: RewardHistoryItem[];
}

export interface PointCapRule {
  id: string;
  ruleType: string;
  contentType: string;
  name: string;
  amount: number;
  rewardType: RewardType;
  programId: string;
}

export interface UserPointCapTotalResponseDTO{
  uiSelection: PointCapUISelect;
  userPointCapTotal: UserPointCapTotalsDTO[];
}

export enum PointCapUISelect {
  rewards_page = "rewards_page",
  content_page = "content_page",
}


export interface UserPointCapTotalsDTO {
  pointCapRuleId: string;
  pointCapRule: PointCapRule;
  total: number;
}



export interface PointCapItemResponseDTO {
  pointCapRuleId: string;
  pointCapRule: PointCapRule;
  activities: GetActivitiesResponseDTO;
  platformActivities: GetPlatformActivitiesResponseDTO;
  pathways: GetPathwaysResponseDTO;
  challenges: PaginatedChallengeDTO;
  healthContent: GatekeeperItem[];
}
