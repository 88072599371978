<template>
  <router-link :to="$pagesPath.main.rewards" class="reward-img">
    <div v-if="rewardTracker" class="wrapper">
      <div class="icon-container">
        <img v-if="showPoints" src="@/assets/img/trophy-icon-white.svg" class="icon-img"/>
        <img v-else src="@/assets/img/trophy-icon-white.svg" class="icon-img"/>
      </div>
      <div class="progress">
        <div v-if="showPoints" class="points">
          {{ rewardTracker.currentProgramPointsInfo.earnedPoints }} PTS
        </div>
        <div v-else class="points">
          {{ earnedCashFormatted }}
        </div>
        <div v-if="showPoints">
          <loading-bar
            v-if="showPoints"
            :steps="rewardTracker.currentProgramPointsInfo.maxPoints"
            :current="rewardTracker.currentProgramPointsInfo.earnedPoints"
            theme="blue"
          />
        </div>
        <div v-else>
          <loading-bar
            :steps="rewardTracker.currentProgramPointsInfo.maxCash"
            :current="rewardTracker.earnedCash"
            theme="blue"
          />
        </div>
      </div>
    </div>
  </router-link>
</template>

<script lang="ts" setup>
import { ref, computed, inject, onMounted } from "vue";
import type { RewardsTracker } from "@/ts/types/store/default-store-types";
import { formatCash } from "@/ts/utils/pure-functions";
import LoadingBar from "@/vue/atoms/loading-bar.vue";
import type { CachedApi } from "@/ts/classes/cached-api";

const $cachedApi = inject("$cachedApi")! as CachedApi;

const rewardTracker = ref<RewardsTracker | null>(null);
const isLoading = ref<boolean>(true);
const error = ref<string>("");

const showPoints = computed(() => {
  const programInfo = rewardTracker.value?.currentProgramPointsInfo;
  return !(programInfo?.isCashRewardsAvailable && !programInfo?.isPointRewardsAvailable);
});

const earnedCashFormatted = computed(() => {
  return formatCash(rewardTracker.value?.earnedCash ?? 0);
});

onMounted(async () => {
  try {
    rewardTracker.value = await $cachedApi.getRewardTracker();
  } catch (err) {
    error.value = err as string;
  } finally {
    isLoading.value = false;
  }
});
</script>

<style lang="sass" scoped>
.points
  color: $color-secondary-sea-foam-100
  @include Roboto700
  font-size: 14px
  letter-spacing: 0.2px

.wrapper
  display: flex
  gap: 4px
  cursor: pointer
  align-items: center

.reward-img
  border-radius: 1.5rem
  padding: 10px

.progress
  display: flex
  flex-direction: column

.icon-container
  padding: 6px
  height: 40px
  width: 40px

.icon-img
  height: 42px
  width: 42px 

</style>