<template>
  <button :type="type" :disabled="loading || disabled" :class="className">
    <div v-if="loading" class="spinner"/>
    <slot v-else>
      {{ text }}
    </slot>
  </button>
</template>

<script lang="ts" setup>
import {computed} from "vue";

/**
 * If you change of any these properties, the properties of <button-link> should change as well
 */

const props = defineProps<{
  text?: string;
  type?: "button" | "reset" | "submit";
  theme?: "dark" | "white-outlined" | "white-with-secondary-blue" | "white";
  disabled?: boolean;
  loading?: boolean;
  flexibleWidth?: boolean;
}>();

const type = computed((): "button" | "reset" | "submit" => {
  return props.type ?? "button";
});

const theme = computed((): "dark" | "white-outlined" | "white-with-secondary-blue" | "white" => {
  return props.theme ?? "dark";
});

const className = computed((): string => {
  return `
    theme-${theme.value}
    ${props.disabled ? "disabled" : ""}
    ${props.flexibleWidth ? "flexible-width" : ""}
    keyboard-focus-border
  `;
});

</script>

<style lang="sass" scoped>
button
  border-radius: 8px
  border: none
  cursor: pointer
  display: flex
  justify-content: center
  align-items: center
  @include Asap600
  font-size: 17px
  font-style: normal
  font-weight: 600
  gap: 8px
  width: 260px
  height: 45px
  line-height: 3px
  padding: 12px 0
  max-width: 100%

.flexible-width
  width: auto
  padding-left: 29px
  padding-right: 29px

.theme-dark
  background-color: $color-primary-hc-navy-100
  color: $color-white

  &.disabled
    opacity: 0.1
    border: 1px solid $color-primary-hc-navy-100
    cursor: not-allowed

.theme-white
  background-color: $color-white
  color: $color-primary-hc-navy-100
  border: 2px solid $color-primary-hc-navy-50

  &.disabled
    color: $color-primary-hc-navy-50
    background-color: transparent
    border: 1px solid $color-primary-hc-navy-50
    opacity: 0.2
    cursor: not-allowed

.theme-white-outlined
  background-color: $color-neutral-platinum-100
  color: $color-primary-hc-navy-100
  border: 2px solid $color-primary-hc-navy-50

  &.disabled
    background-color: $color-neutral-platinum-100
    color: $color-primary-hc-navy-50
    border: 2px solid $color-primary-hc-navy-50
    opacity: 0.2
    cursor: not-allowed

.theme-white-with-secondary-blue
  background-color: $color-white
  color: $color-secondary-sea-foam-100
  border: 2px solid $color-white

  &.disabled
    background-color: $color-white
    color: $color-secondary-sea-foam-100
    border: 2px solid $color-white
    opacity: 0.2
    cursor: not-allowed

  .spinner
    @include lds-spinner(10px, "Loading", true, $color-secondary-sea-foam-100)

.spinner
  @include lds-spinner(10px, "Loading", true)
</style>
