<template>
  <div class="page-register">
    <div class="registration-header">
      <router-link v-if="prevPage" :to="prevPage" class="button-go-back">
        <img src="@/assets/img/left.svg"/>
      </router-link>
      <h3 class="page-title">
        User Registration
      </h3>
    </div>

    <loading-bar :steps="6" :current="currentStep"/>
    <form @submit.prevent="next">
      <div>
        <div class="title-wrapper">
          <h1 class="title">
            {{ title }}
          </h1>
          <div v-if="subtitle" class="subtitle">
            {{ subtitle }}
          </div>
        </div>
        <div class="container">
          <slot/>
        </div>
      </div>
      <button-primary type="submit" class="button-next" :loading="loading">
        {{ buttonText }}
        <bigger-sign/>
      </button-primary>
    </form>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";
import LoadingBar from "@/vue/atoms/loading-bar.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import BiggerSign from "@/vue/atoms/bigger-sign.vue";

@Component({
  name: "RegisterPage",
  components: {
    BiggerSign,
    ButtonPrimary,
    LoadingBar,
  },
})
export default class RegisterPage extends Vue {
  @Prop()
  readonly prevPage!: string;

  @Prop()
  readonly currentStep!: 1 | 2 | 3 | 4 | 5 | 6; // eslint-disable-line @typescript-eslint/no-magic-numbers

  @Prop()
  readonly title!: string;

  @Prop()
  readonly subtitle!: string | null;

  @Prop({default: false})
  readonly loading!: boolean;

  @Prop({default: "Next"})
  readonly buttonText!: string;

  @Emit('next')
  next(): void {
  }
}

</script>

<style lang="sass" scoped>
.container
  max-width: max-container-width(24px, $container-width, 2)
  @include flex-container(24px, $container-width, 2)
  align-items: unset
  margin: auto

.page-title
  color: $color-primary-hc-navy-100
  font-size: 1.5rem
  margin-bottom: 1rem
  text-align: center
  @include Asap700

.registration-header
  margin-top: 4rem
  margin-bottom: 2rem

.button-go-back
  cursor: pointer
  top: 56px
  position: absolute
  left: 56px

.title-wrapper
  margin-bottom: 5rem
  text-align: center
  color: $color-primary-hc-navy-100

.title
  @include Asap700
  font-size: 28px
  margin: 0 0 8px

.subtitle
  @include Roboto400
  font-size: 16px
  letter-spacing: 0.1px

.button-next
  display: block
  margin: auto
  width: 260px
  margin-top: 64px

form
  padding: 20px
  padding-top: 40px
</style>
