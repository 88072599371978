<template>
  <div class="wrapper">
    <div class="header">
      <div>{{ nicotineAttestationResponse.description }}</div>
      <router-link :to="$pagesPath.myHealthPath.myhealth" class="button-back">
        <img src="@/assets/img/x-mark.svg" alt="close"/>
      </router-link>
    </div>

    <loading-bar :steps="1" :current="0"/>

    <div class="title-wrapper">
      <h1 class="title">
        {{ nicotineAttestationResponse.confirmationQuestion.question }}
      </h1>
    </div>

    <div class="option">
      <multiple-checkbox
        v-model="selected"
        :allow-multiple="false"
        :values="options"
        theme="purple"
        class="multiple-checkbox"
      />
    </div>

    <div v-if="requiresContactInfo" class="extra-content">
      <h3 class="contact-heading">
        Please verify your contact information
      </h3>
      <div class="contact-fields">
        <div class="field">
          <label for="email">Email Address:</label>
          <input
            id="email"
            v-model="email"
            type="email"
            placeholder="Enter your email address"
          />
          <div v-if="emailError" class="field-error">
            {{ emailError }}
          </div>
        </div>
        <div class="field">
          <label for="phone">Primary Phone Number:</label>
          <input
            id="phone"
            v-model="phone"
            type="tel"
            placeholder="Enter your phone number"
          />
          <div v-if="phoneError" class="field-error">
            {{ phoneError }}
          </div>
        </div>
        <div class="field">
          <label for="extension">Extension:</label>
          <input
            id="extension"
            v-model="extension"
            type="text"
            placeholder="Enter extension (if applicable)"
          />
        </div>
      </div>
      <!-- Consent text is rendered as sanitized rich text -->
      <p class="consent-text" v-html="sanitizedConsentText"/>
      <div class="consent">
        <input id="consentCheckbox" v-model="consentGiven" type="checkbox"/>
        <label for="consentCheckbox">
          {{ nicotineAttestationResponse.consentGivenText }}
        </label>
      </div>
    </div>

    <div v-if="error" class="error-message">
      {{ error }}
    </div>

    <div class="buttons">
      <button-primary
        :disabled="isSubmitDisabled"
        class="button-next"
        @click="submit"
      >
        Submit
      </button-primary>
    </div>

    <!-- Modal: only shown if modalMessage is not null -->
    <div v-if="showModal" class="modal-overlay">
      <div class="modal">
        <div class="modal-content">
          <p>{{ modalMessage }}</p>
          <button-primary @click="continueModal">
            Continue
          </button-primary>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import type {
  NicotineAttestationResponse,
  SendNicotineRequest,
  PhoneNumber,
  SendNicotineResponse,
} from "@/ts/types/dto/nicotine-attestation.dto";
import MultipleCheckbox from "@/vue/molecules/multiple-checkbox.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import LoadingBar from "@/vue/atoms/loading-bar.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import DOMPurify from "dompurify";
import MarkdownIt from "markdown-it";

// Define an interface for the option object.
interface Option {
  id: number;
  text: string;
  isYesResponse: boolean;
}

@Component({
  name: "NicotineAttestationPage",
  components: {
    MultipleCheckbox,
    ErrorText,
    LoadingBar,
    ButtonPrimary,
  },
})
export default class NicotineAttestationPage extends Vue {
  @Prop({ required: true })
  nicotineAttestationResponse!: NicotineAttestationResponse;

  // Local state
  options: Option[] = [];

  selected: Option[] = [];

  error: string = "";

  email: string = "";

  phone: string = "";

  extension: string = "";

  consentGiven: boolean = false;

  emailError: string = "";

  phoneError: string = "";

  // New state for the modal
  showModal: boolean = false;

  modalMessage: string = "";

  // Computed property: require contact info if selection is made and it's a yes response
  get requiresContactInfo(): boolean {
    return this.selected.length > 0 && this.selected[0].isYesResponse;
  }

  // Computed property: disable submit if required selections or validations are not met
  get isSubmitDisabled(): boolean {
    if (!this.selected.length) {
      return true;
    }
    if (this.requiresContactInfo) {
      return (
          !this.consentGiven ||
          !this.validateEmail(this.email) ||
          !this.validatePhone(this.phone)
      );
    }
    return false;
  }

  // Computed property: sanitize consent text
  get sanitizedConsentText(): string {
    const md = new MarkdownIt();
    const html = md.render(this.nicotineAttestationResponse.consentText || "");
    return DOMPurify.sanitize(html);
  }

  // Lifecycle hook: initialize options and contact info
  mounted(): void {
    this.options = this.nicotineAttestationResponse.confirmationQuestion.answerChoices.map(
        (choice) => ({
          id: choice.id,
          text: choice.choiceText,
          isYesResponse: choice.isYesResponse,
        }),
    );
    this.email = this.nicotineAttestationResponse.contactInfo?.email || "";
    this.phone =
        this.nicotineAttestationResponse.contactInfo?.phone?.number || "";
    this.extension =
        this.nicotineAttestationResponse.contactInfo?.phone?.ext || "";
  }

  // Validation method for email using a standard regex pattern
  validateEmail(email: string): boolean {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  // Validation method for phone: checks if it contains 7-15 digits after stripping non-digits
  validatePhone(phone: string): boolean {
    const digits = phone.replace(/\D/g, "");
    return digits.length >= 7 && digits.length <= 15;
  }

  // Watch for changes to email and update error accordingly
  @Watch("email")
  onEmailChanged(newVal: string): void {
    if (newVal && !this.validateEmail(newVal)) {
      this.emailError = "Please enter a valid email address.";
    } else {
      this.emailError = "";
    }
  }

  // Watch for changes to phone and update error accordingly
  @Watch("phone")
  onPhoneChanged(newVal: string): void {
    if (newVal && !this.validatePhone(newVal)) {
      this.phoneError = "Please enter a valid phone number.";
    } else {
      this.phoneError = "";
    }
  }

  async submit(): Promise<void> {
    if (this.isSubmitDisabled) {
      this.error = "Please ensure all required fields are completed correctly.";
      return;
    }

    this.error = "";
    const payload: SendNicotineRequest = {
      scheduleType: 8, // TypeNicotineAttestation
      confirmationResponseId: this.selected[0].id,
      email: this.email,
      phone: {
        number: this.phone,
        ext: this.extension === "" ? null : this.extension,
        type: this.nicotineAttestationResponse.contactInfo?.phone?.type || "",
      } as PhoneNumber,
    };

    try {
      const response: SendNicotineResponse = await this.$api.sendNicotineAttestation(payload);
      // If a message is returned, show the modal; otherwise, route directly
      if (response.message) {
        this.modalMessage = response.message;
        this.showModal = true;
      } else {
        await this.$router.push(this.$pagesPath.main.dashboard);
      }
    } catch (err) {
      this.error = "Submission failed, please try again.";
      throw err;
    }
  }

  continueModal(): void {
    this.showModal = false;
    this.$router.push(this.$pagesPath.main.dashboard);
  }
}
</script>

<style lang="sass" scoped>
.wrapper
  width: 100%

.header
  @include Asap700
  font-size: 20px
  line-height: 28px
  text-align: center
  color: $color-primary-hc-navy-100
  display: grid
  grid-template-columns: 1fr auto
  justify-items: center
  align-items: center
  padding: 60px 70px 27px

  > div
    grid-column: 1

.button-back
  margin-left: auto
  cursor: pointer

.title-wrapper
  @include Asap700
  color: $color-primary-hc-navy-100
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  text-align: center

.option
  display: grid
  justify-content: center
  margin-top: 3rem

.extra-content
  max-width: 950px
  margin: 2rem auto 0 auto
  padding: 20px
  background-color: #f9f9f9
  border: 1px solid #e0e0e0
  border-radius: 8px
  text-align: center
  color: $color-primary-hc-navy-100

.contact-fields
  display: flex
  flex-wrap: wrap
  justify-content: center
  gap: 1rem
  margin: 1rem 0

.field
  flex: 1 1 200px
  max-width: 200px

.field label
  display: block
  margin-bottom: 0.5rem
  font-weight: bold

.field input
  width: 100%
  padding: 0.5rem
  border: 1px solid #ccc
  border-radius: 4px

.field-error
  color: red
  font-size: 0.9rem
  margin-top: 0.25rem

.consent
  display: flex
  align-items: center
  justify-content: center
  margin-top: 1rem

.consent input[type="checkbox"]
  margin-right: 0.5rem

.buttons
  display: flex
  justify-content: center
  margin-top: 2rem
  margin-bottom: 20px

.contact-heading
  font-size: 18px
  font-weight: bold
  margin-bottom: 2rem
  color: $color-primary-hc-navy-100

.error-message
  color: red
  font-weight: bold
  text-align: center

// Modal styling
.modal-overlay
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, 0.5)
  display: flex
  align-items: center
  justify-content: center

.modal
  background: #fff
  padding: 20px
  border-radius: 8px
  width: 300px  // smaller modal width
  text-align: center

.modal-content
  p
    word-wrap: break-word
    white-space: pre-wrap
    margin-bottom: 1rem
  button-primary
    display: block
    margin: 0 auto 0  // centers the button horizontally

</style>
