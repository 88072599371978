<template>
  <router-link :to="to">
    <card-wrapper size="large">
      <div type="button">
        <completed-icon v-if="item.status === 'completed'"/>
        <gatekeeper-icon v-else/>
      </div>
      <div class="content">
        <span v-if="item.isRequired" class="gatekeeper-label">Required Activity</span>
        <span v-else class="gatekeeper-label">Recommended Activity</span>
        <h1>{{ item.name }}</h1>
        <div class="duration">
          <span>{{ formattedDate }}</span>
        </div>
      </div>
      <div class="footer">
        <badge-rounded v-if="item.rewardAmount" theme="light" size="small" :value="rewardText"/>
      </div>
    </card-wrapper>
  </router-link>
</template>

<script lang="ts" setup>
import {computed, inject} from "vue";
import CardWrapper from "@/vue/atoms/card-wrapper.vue";
import AvatarFrame from "@/vue/atoms/avatar-frame.vue";
import BadgeRounded from "@/vue/atoms/badge-rounded.vue";
import clockIcon from "@/assets/img/challenge-card-icons/clock-icon.svg";
import calendarIcon from "@/assets/img/challenge-card-icons/calendar-clean-icon.svg";
import type {GatekeeperItem} from "@/ts/types/dto/program.dto";
import {formatDateShortMonthName} from "@/ts/utils/date-pure-functions";
import {getRewardAmountFormatted} from "@/ts/utils/pure-functions";
import {ContentReferenceType} from "@/ts/types/dto/content-reference.dto";
import type {RouteLocationRaw, Router} from "vue-router";
import type {PagesPath} from "@/ts/router/pages-path";
import GatekeeperIcon from "@/vue/atoms/gatekeeper-icon.vue";
import CompletedIcon from "@/vue/atoms/completed-icon.vue";
import ActivityIcon from "@/vue/atoms/activity-icon.vue";

// Props
const props = defineProps<{ item: GatekeeperItem }>();

const $pagesPath = inject<PagesPath>("$pagesPath")!;

// Determine date display
const formattedDate = computed(() => {
  if (props.item.completedDate) {
    return `Completed on: ${formatDateShortMonthName(props.item.completedDate)}`;
  }
  if (props.item.endDate) {
    return `End date: ${formatDateShortMonthName(props.item.endDate)}`;
  }
  return "";
});

const reward = computed(() => {
  return {
    amount: props.item.rewardAmount,
    type: props.item.rewardType,
  };
});

// Determine reward display
const rewardText = computed(() => {
  return getRewardAmountFormatted(reward.value);
});

const iconComponent = computed(() => {
  return props.item.completedDate ? CompletedIcon : GatekeeperIcon;
});

const to = computed<RouteLocationRaw>(() => {
  switch(props.item.contentReference.type) {
    case ContentReferenceType.BiometricScreening:
    case ContentReferenceType.HealthRiskAssessment:
      return { path: $pagesPath.myHealthPath.myhealth };
    case ContentReferenceType.Coaching:
      return { path: $pagesPath.coaching.info };
    default:
      return { path: "/"};
}
});
</script>

<style lang="sass" scoped>
h1
  font-size: 1rem
  margin: 0
  line-height: 22px
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  overflow: hidden

.gatekeeper-label
  @include Roboto400
  font-size: 11px
  color: $color-primary-hc-blue-100
  text-transform: uppercase
  position: absolute
  margin-top: -14px

.duration
  @include Roboto400
  font-size: 14px
  color: $color-primary-hc-blue-50

</style>
