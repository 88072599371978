<template>
  <label
    :for="id"
    :class="className"
  >
    <input
      :id="id"
      type="checkbox"
      :required="required"
      :checked="modelValue"
      :disabled="disabled"
      @input="input"
    />
    <div class="span">
      <slot/>
    </div>
  </label>
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";

@Component({
  name: "InputCheck",
})
export default class InputCheck extends Vue {
  @Prop() modelValue!: boolean;

  @Prop({required: true}) id!: string;

  @Prop({default: false}) required!: boolean;

  @Prop({default: 'purple'}) theme!: "pink" | "purple";

  @Prop({default: false}) disabled!: boolean;

  get className(): string {
    return `check ${this.theme}${this.modelValue ? " is-checked" : ""}`;
  }

  @Emit('update:modelValue')
  input(event: any): boolean {
    return event.target.checked as boolean; // eslint-disable-line @typescript-eslint/no-unsafe-member-access
  }
}
</script>

<style lang="sass" scoped>
.check
  display: flex
  cursor: pointer

input
  border: 0
  clip: rect(0 0 0 0)
  height: 1px
  margin: -1px
  overflow: hidden
  padding: 0
  position: absolute
  width: 1px

  + .span
    cursor: pointer
    display: block
    position: relative

    &::before
      content: ""
      display: inline-block
      width: 20px
      height: 20px
      vertical-align: -0.25em
      border-radius: 8px
      margin-right: 0.75em
      background: $color-neutral-platinum-40
      position: relative
      top: 3px
      transition: 0.5s ease all

  &:checked + .span
    &::after
      content: "\2713"
      position: absolute
      left: 6px
      color: $color-white
      top: 4px
      font-size: 18px

  &:focus + .span::before
    content: ""

@mixin check-colorize($color)
  input
    + .span

      &::before
        border: 2px solid lighten($color, 10%)

    &:checked + .span
      &::before
        background: $color
        border: 2px solid $color

    &:focus + .span::before
      border: 2px solid $color
      outline: 2px solid $color


    &:checked:focus + .span::before
      border-color: lighten($color, 10%)
      background: lighten($color, 10%)

.purple
  + .span
    @include Roboto400
    font-size: 14px
    color: $color-primary-hc-navy-100
    letter-spacing: 0.2px
  @include check-colorize($color-secondary-sea-foam-100)

.pink
  width: 100%
  padding: 12px 12px 16px 16px
  @include Roboto700
  font-size: 16px
  background-color: $color-white
  border-radius: 8px
  @include check-colorize($color-secondary-sea-foam-100)

  &.is-checked
    border: 1px solid $color-secondary-sea-foam-100

  &:not(.is-checked)
    border: 1px solid transparent

  input + .span::before
    background: $color-neutral-platinum-40
</style>
