<template>
  <loading-suspense :error="error" :loading="loading">
    <router-view :nicotine-attestation-response="nicotineAttestationResponse"/>
  </loading-suspense>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import {LoadingMixin} from "@/ts/mixins/loading-mixin";
import {ApplyGrowlErr} from "@/ts/utils/decorators";
import type {NicotineAttestationResponse} from "@/ts/types/dto/nicotine-attestation.dto";

@Component({
  name: 'NicotineAttestationParentPage',
  components: { LoadingSuspense, ErrorText },
})
export default class NicotineAttestationParentPage extends mixins(LoadingMixin) {
  nicotineAttestationResponse!: NicotineAttestationResponse;

  @ApplyGrowlErr({
    loadingProperty: 'loading',
    errorProperty: 'error',
    allowSpam: true,
  })
  async created(): Promise<void> {
    this.nicotineAttestationResponse = await this.$api.getNicotineAttestationStart();
  }
}
</script>

<style lang="sass" scoped>
/* Your styles here */
</style>
