<template>
  <section v-if="levels.length" class="level-section">
    <div class="heading">
      <img src="@/assets/img/levels-icon.svg" alt="levels"/>
      <h3>Levels</h3>
    </div>
    <div class="levels">
      <level-card
        v-for="level in levels"
        :key="level.id"
        :level="level"
      />
    </div>
    <div class="row">
      <img alt="trophy" src="@/assets/img/trophy-icon.svg"/>
      <span>Total rewards required in order to level up</span>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import LevelCard from "@/vue/molecules/level-card.vue";
import type {ProgramLevelInfo} from "@/ts/types/component/levels";
import type {MyProgramResponse} from "@/ts/types/dto/program.dto";

const props = defineProps<{
  program: MyProgramResponse | null;
}>();

const levels = computed<ProgramLevelInfo[]>(() => {
  return props.program?.levels
    ? props.program.levels
        .map((level) => ({
          ...level,
          isActive: level.id === props.program?.currentLevel?.id,
        }))
        .sort((l1, l2) => l1.rewardAmountThreshold - l2.rewardAmountThreshold)
    : [];
});
</script>

<style lang="sass" scoped>
.level-section
  background-color: $color-neutral-platinum-100
  padding: 2rem
  display: flex
  flex-direction: column
  margin-top: 16px
  margin-bottom: 24px

.levels
  margin-left: 5rem
  margin-right: 5rem
  gap: 16px
  overflow: auto
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: center

.heading
  font-size: 20px
  @include Asap700
  color: $color-primary-hc-navy-100
  margin: 1rem auto
  padding: 0 16px
  display: flex
  align-items: center
  gap: 8px
  max-width: 920px

  h3
    margin: 0

.row
  @include Roboto400
  align-items: center
  border-top: 1px solid $color-white
  color: $color-primary-hc-navy-100
  display: flex
  font-size: 14px
  line-height: 22px
  letter-spacing: 0.2px
  justify-content: center
  padding-top: 16px
  max-width: 920px
  margin: 0 auto
  margin-top: 40px
</style>
