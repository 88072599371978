<template>
  <div class="hc-logo">
    <div>
      <img src="@/assets/img/hc360-logo.svg" alt="MyHealthCheck360 Logo"/>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Vue,
} from "vue-property-decorator";

@Component({
  name: "HcLogo",
})
export default class HcLogo extends Vue {
}
</script>

<style lang="sass" scoped>
.hc-logo
  flex-direction: column
  display: flex
  align-items: center
  color: $color-primary-hc-navy-100

</style>
