<template>
  <div :class="profileButtonClasses">
    <div>
      <div v-if="warning && useAlertIcon == false" class="warn-placeholder">
        <img
          src="@/assets/img/warning-yellow.svg"
          class="warn-icon"
        />
      </div>
      <span class="profile-name">{{ name }}</span>

      <button v-if="isEnabled" class="profile-next" @click="isOpen = true">
        <img src="@/assets/img/profile-icons/edit-profile.svg" alt="edit" />
      </button>
    </div>
    <div v-if="content || warning" class="profile-info">
      <div v-if="useAlertIcon === false">
        <span v-if="warning" class="warn-txt">{{ warning }}</span>
        {{ content == 'non_binary' ? 'non binary' : content }}
      </div>

      <div v-if="useAlertIcon === true">
        <alert-icon-card v-if="alertIconStatus" :theme="alertIconStatus == 'pending' ? 'info' : 'warning'">
          <div class="text">
            <span style="font-weight: bold">{{ warning }}</span>
            <br>
            {{ content == 'non_binary' ? 'non binary' : content }}
          </div>
        </alert-icon-card>
      </div>
    </div>
    <edit-profile-modal v-if="isEnabled" v-model="isOpen" :error="error ?? ''" :name="name" :description="description"
      :loading="Boolean(loading)" :show-success="showSuccess" :save-verbiage="saveVerbiage" @save="save">
      <slot />
    </edit-profile-modal>
  </div>
</template>

<script lang="ts" setup>

import EditProfileModal from "@/vue/molecules/edit-profile-modal.vue";
import {computed, ref} from "vue";
import AlertIconCard from "@/vue/atoms/alert-icon-card.vue";

const props = defineProps<{
  name: string;
  warning?: string;
  description?: string;
  content?: string;
  loading?: boolean;
  showSuccess?: boolean;
  error?: string | null;
  isEnabled?: boolean;
  saveVerbiage?: string;
  useAlertIcon?: boolean;
  alertIconStatus?: string;
}>();

const emit = defineEmits(["save"]);

const isOpen = ref<boolean>(false);

const profileButtonClasses = computed((): string[] => {
  return ["profile-button", props.isEnabled ? "" : "greyed"];
});
const saveVerbiage = computed((): string => {
  return props.saveVerbiage ? props.saveVerbiage : "Save Changes"
});

const save = (): void => {
  isOpen.value = false;
  emit("save");
};

</script>

<style lang="sass" scoped>

.profile-name
  @include Roboto700
  color: $color-primary-hc-navy-100
  font-size: 16px
  line-height: 24px
  letter-spacing: 0.1px

.profile-button
  display: flex
  flex-direction: column
  padding: 16px
  height: auto
  background: $color-white
  border-radius: 12px
  position: relative
  gap: 10px
  min-width: 218px
  width: auto

.profile-next
  cursor: pointer
  background: none
  border: none
  position: absolute
  right: 0

.profile-info
  @include Roboto400
  color: $color-secondary-sea-foam-100
  font-size: 14px
  line-height: 22px
  letter-spacing: 0.2px

.warn-txt
  display: block
  color: $color-primary-hc-navy-100

.warn-placeholder
  width: 28px
  display: inline-block

.warn-icon
  width: 24px
  position: absolute
  top: 15px
  left: 15px

</style>
