<template>
  <alert-icon-card title="Physician Form Submission Pending" theme="warning">
    <div class="text">
      <br/>Your Physician Form was submitted on <span class="bold"> {{ submittedOn }} </span>. It will be reviewed within 10 business days.
      You will receive an email when the status of your submission changes.
      <br/><br/>If you need to submit a new form, click the button below.
    </div>
  </alert-icon-card>
</template>

<script lang="ts" setup>
import AlertIconCard from "@/vue/atoms/alert-icon-card.vue";
import {formatDateShortMonthName} from "@/ts/utils/date-pure-functions";
import type {GetPhysicianFormBiometricResponse} from "@/ts/types/dto/physician-form.dto";
import {computed} from "vue";

const props = defineProps<{
  physicianForm: GetPhysicianFormBiometricResponse | null;
}>();

const submittedOn = computed(() => {
  if (props.physicianForm?.submittedOn) {
    return formatDateShortMonthName(props.physicianForm.submittedOn);
  }
  return null;
});
</script>

<style lang="sass" scoped>
.bold
  @include Roboto700

.text
  @include Roboto400
  color: $color-primary-hc-navy-100
  font-size: 14px
  letter-spacing: 0.2px
  line-height: 22px
  margin: 0 0 4px
</style>
