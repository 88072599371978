export interface ContentReference {
  type: ContentReferenceType;
  id: string;
}

export enum ContentReferenceType {
  Activity = "activity",
  Pathway = "pathway",
  RewardHistory = "reward_history",
  ChallengeLeaderboard = "challenge_leaderboard",
  ChallengeMapRace = "challenge_map_race",
  ChallengeRelayRace = "challenge_relay_race",
  BiometricScreening = "bioscreening",
  HealthRiskAssessment = "hra",
  Coaching = "coaching",
  NicotineAttestation = "nicotineAttestation",
  PlatformActivity = "platform_activity",
}
