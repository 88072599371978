<template>
  <loading-suspense :error="error" :loading="loading">
    <navbar-main
      :title="(capRule?.pointCapRule?.name) + ' Progress'"
      class="body"
      :back-link="$pagesPath.main.rewards"
      :profile-section="true"
      :has-tabs="true"
      :hide-left-section="true"
      :hide-right-on-scroll="true"
    >
      <template #tabs>
        <point-cap-page-select :ids="capRules" />
      </template>
      <main v-if="dataFound" class="content">
        <div class="point-cap-tracker">
          <point-cap-tracker
            v-if="capRule !== null"
            :rule="userCapRule"
          />
        </div>
        <div v-if="capRule?.pathways?.items?.length">
          <pathway-card-row
            title="Pathways"
            :pathways="capRule?.pathways?.items.filter((item: PathwayFullDto) => item.status !== TodoStatus.completed)"
          />
          <pathway-card-row
            title="Completed Pathways"
            :pathways="capRule?.pathways?.items.filter((item: PathwayFullDto) => item.status === TodoStatus.completed)"
          />
        </div>
        <div v-if="capRule?.activities?.items?.length">
          <activity-card-row
            title="Activities"
            :activities="activitiesMapped ?? []"
            @change-item="changeActiveItem"
          />
          <activity-card-row
            title="Completed Activities"
            :activities="completedActivitiesMapped ?? []"
          />
        </div>
        <div v-if="capRule?.platformActivities?.items?.length">
          <activity-card-row
            title="Platform Activities"
            :activities="platActivitiesMapped ?? []"
            @change-item="changePlatformItem"
          />
          <activity-card-row
            title="Completed Platform Activities"
            :activities="completedPlatActivitiesMapped ?? []"
          />
        </div>
        <div v-if="capRule?.challenges?.items?.length && challenges && completedchallenges">
          <challenge-card-row
            title="Challenges"
            :challenges="challenges"
          />
          <challenge-card-row
            title="Completed Challenges"
            :challenges="completedchallenges"
          />
        </div>
        <div v-if="capRule?.healthContent?.length">
          <health-card-row
            v-if="healthContent?.length"
            title="Health Content"
            :health-content="healthContent"
          />
          <health-card-row
            v-if="completedHealthContent?.length"
            title="Completed Health Content"
            :health-content="completedHealthContent"
          />
        </div>
      </main>

      <empty-state
        v-else
        :image="noPathwaysImage"
        title="No Content Available"
        description="Please contact your admin."
      />
    </navbar-main>
  </loading-suspense>
  <add-tracking-button/>
</template>

<script lang="ts" setup>
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import PathwayCardRow from "@/vue/molecules/pathway-card-row.vue";
import ActivityCardRow from "@/vue/molecules/activity-card-row.vue";
import ChallengeCardRow from "@/vue/molecules/challenge-card-row.vue";
import EmptyState from "@/vue/atoms/empty-state.vue";
import AddTrackingButton from "@/vue/atoms/add-tracking-button.vue";
import noPathwayImage from "@/assets/img/challenge-card-icons/person-practicing-yoga.svg";
import {computed, inject, onMounted, ref, watch} from "vue";
import {type Router, useRoute} from "vue-router";
import type {Api} from "@/ts/classes/api";
import PointCapTracker from "@/vue/molecules/point-cap-tracker.vue";
import {PointCapItemResponseDTO, PointCapUISelect, RewardType, UserPointCapTotalsDTO} from "@/ts/types/dto/reward.dto";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import {ActivityCardModel} from "@/ts/types/component/activity-card";
import {ActivityDTO, ActivityStatus} from "@/ts/types/dto/activity.dto";
import {PathwayFullDto, TodoStatus} from "@/ts/types/dto/pathway.dto";
import {ChallengeDTO, PaginatedChallengeDTO} from "@/ts/types/dto/challenges.dto";
import {ChallengeStatus} from "@/ts/types/component/challenges";
import ProgramPageSelect from "@/vue/molecules/program-page-select.vue";
import PointCapPageSelect from "@/vue/molecules/point-cap-page-select.vue";
import SquaredIcon from "@/vue/atoms/squared-icon.vue";
import {GatekeeperItem, GatekeeperStatus} from "@/ts/types/dto/program.dto";
import {ContentReferenceType} from "@/ts/types/dto/content-reference.dto";
import {formatDateShortMonthName} from "@/ts/utils/date-pure-functions";
import type {PagesPath} from "@/ts/router/pages-path";
import CompletedIcon from "@/vue/atoms/completed-icon.vue";
import ActivityModalCompleted from "@/vue/organisms/activity-modals/activity-modal-completed.vue";
import ActivityIcon from "@/vue/atoms/activity-icon.vue";
import ActivityModalAvailable from "@/vue/organisms/activity-modals/activity-modal-available.vue";
import GatekeeperIcon from "@/vue/atoms/gatekeeper-icon.vue";
import ActivityModalActive from "@/vue/organisms/activity-modals/activity-modal-active.vue";
import CardItem from "@/vue/atoms/card-item.vue";
import ExpansionPanel from "@/vue/atoms/expansion-panel.vue";
import NavbarMain from "@/vue/templates/navbar-main.vue";
import HealthCardRow from "@/vue/molecules/health-card-row.vue";
import GatekeeperItems from "@/vue/molecules/gatekeeper-items.vue";
const activitiesMapped = ref<ActivityCardModel[]>();
const completedActivitiesMapped = ref<ActivityCardModel[]>();
const platActivitiesMapped = ref<ActivityCardModel[]>();
const completedPlatActivitiesMapped = ref<ActivityCardModel[]>();

const challenges = ref<PaginatedChallengeDTO>();
const completedchallenges = ref<PaginatedChallengeDTO>();

const healthContent = ref<GatekeeperItem[]>();
const completedHealthContent = ref<GatekeeperItem[]>();

const $api: Api = inject("$api")! as Api;
const route = useRoute();

const error = ref("");
const loading = ref(true);
const dataFound = ref(true);

const userCapRule = ref<UserPointCapTotalsDTO | null>(null);
const capRule = ref<PointCapItemResponseDTO | null>(null);
const capRules = ref<UserPointCapTotalsDTO[]>();

const noPathwaysImage = computed((): string => {
  return noPathwayImage;
});

const $pagesPath = inject<PagesPath>("$pagesPath")!;

onMounted(async () => {
  await fetchData();
});

watch(() => route.params.id, async () => {
  await fetchData();
});

const fetchData = async () => {
  try {
    var program = await $api.getProgram(true);
    const response = await $api.getUserPointCapTotals(program.id);
    capRules.value = response?.userPointCapTotal ?? [];

    userCapRule.value = capRules.value.find((rule) =>
        rule.pointCapRuleId == route.params.id.toString()
    ) || null;

    capRule.value  = await $api.getPointCapItemsByRuleId(route.params.id.toString());

    if(capRule.value?.activities?.items?.length) {
      const data = capRule.value?.activities?.items
          .filter((item) => item.status !== ActivityStatus.completed)
          .map((item) => {
          return ActivityCardModel.createActivity(item);
      });
      activitiesMapped.value = data;

      const complete = capRule.value?.activities?.items
          .filter((item) => item.status === ActivityStatus.completed)
          .map((item) => ActivityCardModel.createActivity(item));
      completedActivitiesMapped.value = complete;
    }

    if(capRule.value?.platformActivities?.items?.length) {
      const data = capRule.value?.platformActivities?.items
          .filter((item) => item.status !== ActivityStatus.completed)
          .map((item) => {
        return ActivityCardModel.createPlatformActivity(item);
      });
      platActivitiesMapped.value = data;

      const complete = capRule.value?.platformActivities?.items
          .filter((item) => item.status === ActivityStatus.completed)
          .map((item) => ActivityCardModel.createPlatformActivity(item));
      completedPlatActivitiesMapped.value = complete;
    }

    if(capRule.value?.challenges?.items?.length) {
      challenges.value = {
        items: capRule.value?.challenges?.items.filter((item) => item.challengeStatus !== ChallengeStatus.Completed),
        pagination: capRule.value?.challenges?.pagination
      };
      completedchallenges.value = {
        items: capRule.value?.challenges?.items.filter((item) => item.challengeStatus === ChallengeStatus.Completed),
        pagination: capRule.value?.challenges?.pagination
      };

      if (challenges.value?.pagination) {
        challenges.value.pagination.total = challenges.value.items.length;
      }
      if (completedchallenges.value?.pagination) {
        completedchallenges.value.pagination.total = completedchallenges.value.items.length;
      }
    }

    if(capRule.value.healthContent.length > 0) {
      healthContent.value = capRule.value.healthContent.filter((item) => item.status === GatekeeperStatus.pending);
      completedHealthContent.value = capRule.value.healthContent.filter((item) => item.status === GatekeeperStatus.completed);
    }

  } catch (err) {
    error.value = err as string;
  } finally {
    loading.value = false;
    dataFound.value = capRule.value !== null;
  }
}

const changeActiveItem = (item: ActivityCardModel): void => {
  if (!activitiesMapped.value) {
    return;
  }
  const index = activitiesMapped.value.findIndex(
      (activity) => activity.id === item.id,
  );
  activitiesMapped.value.splice(index, 1, item);
};


const changePlatformItem = (item: ActivityCardModel): void => {
  if (!platActivitiesMapped.value) {
    return;
  }
  const index = platActivitiesMapped.value.findIndex(
      (activity) => activity.id === item.id,
  );
  platActivitiesMapped.value.splice(index, 1, item);
};

</script>

<style lang="sass" scoped>
.body
  background-image: url("@/assets/img/header-wave-background-2.svg")
  background-position-y: -3rem
  background-repeat: no-repeat
  background-size: contain
  color: $color-primary-hc-navy-100
  @include Asap700
  position: absolute
  height: 100%
  width: 100%
  margin-left: 100px

  @media (max-width: 1500px)
    background-position-y: 1rem

main
  margin-top: 3rem

  @media (min-width: 1800px)
    margin-left: 12rem

.point-cap-tracker
  display: flex
  justify-content: flex-start
  margin-left: 4rem
  margin-bottom: 1rem

  @media (min-width: 1800px)
    margin-right: 2rem

.items
  display: flex
  flex-direction: column
  margin-left: 10rem
  margin-top: 16px
  margin-bottom: 24px


.item
  background-color: $color-white
  border-radius: 16px
  display: flex
  padding: 12px
  width: 100%
  border: none
  color: inherit
  cursor: pointer
  &.success
    background-color: $color-status-success-green-10

  .icon
    width: 48px
    height: 48px

    &.activity
      color: $color-secondary-sea-foam-100

  .label
    display: flex
    flex-direction: column
    justify-content: space-between
    margin-left: 10px
    align-items: baseline
    height: 42px
    cursor: inherit

  .name
    grid-row: 1
    grid-column: 2
    @include Asap700
    color: $color-primary-hc-navy-100
    font-size: 16px
    line-height: 22px
    margin: 0

.end-date
  @include Roboto400
  font-size: 14px
  color: $color-primary-hc-navy-50

.card-item
  cursor: pointer
.content
  display: flex
  flex-direction: column
  margin-top: 35px
  margin-right: 5rem
  position: relative

  h1
    @include Asap700
    color: $color-primary-hc-navy-100
    font-size: 1rem
    max-width: 300px
    /* stylelint-disable */
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    margin-top: 0
    margin-bottom: 5px

.points
  background: $color-secondary-sea-foam-10
  border-radius: 16px
  @include Roboto600
  font-size: 12px
  color: $color-secondary-sea-foam-100
  display: flex
  padding: 4px 8px
  align-items: center
  width: fit-content
  position: absolute
  bottom: 16px
  left: 16px

.gatekeeper-label
  @include Roboto400
  font-size: 11px
  color: $color-primary-hc-blue-100
  text-transform: uppercase
  position: absolute
  margin-top: -14px

</style>
