<template>
  <div class="container">
    <input-check
      v-for="value in values"
      :id="value.id"
      :key="value.id"
      class="multiple-checkbox"
      :model-value="isChecked(value)"
      :theme="theme"
      @update:model-value="updateModel(value)"
    >
      {{ value.text }}
    </input-check>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";
import InputCheck from "@/vue/atoms/input-check.vue";
import type {MultipleCheckboxItem} from "@/ts/types/component/input.select";

@Component({
  name: "MultipleCheckbox",
  components: {InputCheck},
})
export default class MultipleCheckbox<T> extends Vue {
  @Prop() readonly modelValue!: MultipleCheckboxItem<T>[];

  @Prop() values!: MultipleCheckboxItem<T>[];

  @Prop({default: false}) allowMultiple!: boolean;

  @Prop({default: "pink"}) theme!: string;

  isChecked(item: MultipleCheckboxItem<T>): boolean {
    return Boolean(this.modelValue.find((el) => el.id === item.id));
  }

  @Emit('update:modelValue')
  updateModel(check: MultipleCheckboxItem<T>): MultipleCheckboxItem<T>[] {
    if (this.allowMultiple) {
      if (this.isChecked(check)) {
        return this.modelValue.filter((item) => item.id !== check.id);
      }
      return [...this.modelValue, check];
    }
    return this.isChecked(check) ? [] : [check];
  }
}
</script>

<style lang="sass" scoped>
.multiple-checkbox
  display: flex
  justify-content: flex-start
  border-radius: 8px
  @include Roboto700
  color: $color-primary-hc-navy-100
  font-size: 16px
  gap: 12px
  margin: auto
  width: 100%

.container
  display: flex
  flex-direction: column
</style>
