<template>
  <div>
    <div class="wrapper">
      <user-avatar
        :avatar="defaultStore?.profile?.avatarPath ?? ''"
        :firstname="defaultStore?.profile?.firstName ?? ''"
        :lastname="defaultStore?.profile?.lastName ?? ''"
        size="xlarge"
      />
      <button class="upload-btn" @click="isUploadDialogOpened = true">
        <img src="@/assets/img/profile-icons/edit-profile-white.svg" alt="edit"/>
      </button>
    </div>

    <popup-modal :slot-class="fileSrc ? 'bg-blue' : ''" :model-value="isUploadDialogOpened" @update:model-value="onCloseUploadDialog">
      <div v-if="!isStep2" class="content-container">
        <h1 class="headline">
          Upload Your Picture
        </h1>
        <div class="placeholder-img">
          <img src="@/assets/img/profile.svg" aria-hidden="true"/>
        </div>

        <input
          id="upload-file"
          type="file"
          name="upload-file"
          accept="image/*"
          hidden
          :disabled="Boolean(file)"
          @change="onFileChanged"
        />
        <label for="upload-file" refs="upload-file" class="file-picker-btn">
          <img src="@/assets/img/upload-icon.svg" aria-hidden="true"/> Choose a Photo
        </label>
      </div>

      <div v-if="isStep2" class="content-container">
        <h1 class="headline headline--step2">
          Confirm Upload
        </h1>
        <avatar-frame
          :fallback-image="fileSrc"
          :size="110"
        />
        <div class="button-row">
          <button-primary
            theme="white-outlined"
            :flexible-width="true"
            :disabled="uploadInProgress"
            @click="onCloseUploadDialog"
          >
            Cancel
          </button-primary>

          <button-primary
            :flexible-width="true"
            :loading="uploadInProgress"
            @click="onSave"
          >
            Save
          </button-primary>
        </div>
      </div>
    </popup-modal>
  </div>
</template>

<script lang="ts" setup>
import UserAvatar from "@/vue/atoms/user-avatar.vue";
import {DefaultStore} from "@/ts/store/default/default-store";
import {getModule} from "vuex-module-decorators";
import {vueStore} from "@/ts/store/vue-store";
import {computed, inject, ref} from "vue";
import type {Api} from "@/ts/classes/api";
import AvatarFrame from "@/vue/atoms/avatar-frame.vue";
import PopupModal from "@/vue/atoms/popup-modal.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";

const $api = inject("$api")! as Api;

const defaultStore = getModule(DefaultStore, vueStore);
const isUploadDialogOpened = ref(false);

const file = ref<File>();
const fileSrc = ref<string>();

const uploadInProgress = ref<boolean>(false);

const onSave = async(): Promise<void> => {
  uploadInProgress.value = true;
  try {
    const {path} = await $api.uploadProfileImage(file.value!);
    if (defaultStore.profile) {
      defaultStore.profile.avatarPath = path;
    }
    onCloseUploadDialog();
  } finally {
    uploadInProgress.value = false;
  }
};

// eslint-disable-next-line id-length, @typescript-eslint/no-unsafe-member-access
const onFileChanged = (e: any): void => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const {files} = e.target as HTMLInputElement;

  // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
  file.value = files?.[0] as File;
  const fr = new FileReader();
  fr.onload = () => {
    fileSrc.value = fr.result as string;
  };
  fr.readAsDataURL(file.value);
};

const onCloseUploadDialog = (): void => {
  isUploadDialogOpened.value = false;
  // eslint-disable-next-line no-undefined
  file.value = undefined;
  // eslint-disable-next-line no-undefined
  fileSrc.value = undefined;
};

const isStep2 = computed(() => {
  return Boolean(fileSrc.value);
});

</script>

<style lang="sass" scoped>

.wrapper
  position: relative
  display: inline-block

.upload-btn
  position: absolute
  bottom: -10px
  right: -10px
  background-color: $color-primary-hc-navy-100
  border-radius: 50%
  height: 44px
  width: 44px
  padding-left: 8px
  padding-top: 2px
  border: none
  cursor: pointer

.file-picker-btn
  border-radius: 8px
  cursor: pointer
  display: flex
  justify-content: center
  align-items: center
  @include Asap600
  font-size: 17px
  font-style: normal
  font-weight: 600
  gap: 8px
  width: 260px
  height: 45px
  line-height: 3px
  padding: 12px 0
  border: none
  background-color: $color-primary-hc-navy-100
  color: $color-white

.content-container
  width: 320px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

.headline
  margin: 0 0 20px 0

  &--step2
    color: $color-white

.button-row
  display: flex
  gap: 10px
  align-items: center
  justify-content: center
  margin-top: 30px

.placeholder-img
  max-height: 120px
  margin-bottom: 30px

</style>
