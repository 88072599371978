<template>
  <div v-if="error" class="header">
    <div>Physician form</div>
    <router-link :to="$pagesPath.myHealthPath.myhealthCheckScore" class="close">
      <img src="@/assets/img/x-mark.svg" alt="close"/>
    </router-link>
  </div>
  <loading-suspense :error="error" :loading="loading">
    <router-view/>
  </loading-suspense>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import {mixins} from "vue-class-component";
import {DefaultGrowlError, LoadingMixin} from "@/ts/mixins/loading-mixin";
import LoadingBar from "@/vue/atoms/loading-bar.vue";
import {
  BiometricPhysicianFormState,
  BiometricPhysicianFormStoreMixin,
} from "@/ts/store/biometric-physician-form/biometric-physician-form-store-instance";
import type {PhysicianFormBiometric} from "@/ts/types/store/biometric-physician-form-store-types";
import {GrowlsStoreMixin} from "@/ts/store/growl/growl-store-instance";
import {PhysicianFormStatus} from "@/ts/types/dto/physician-form.dto";

@Component({
  name: "PhysicianFormPage",
  components: {LoadingBar, LoadingSuspense},
})
export default class PhysicianFormPage extends mixins(
  LoadingMixin,
  BiometricPhysicianFormStoreMixin,
  GrowlsStoreMixin,
) {
  @BiometricPhysicianFormState
  physicianForm!: PhysicianFormBiometric;

  @DefaultGrowlError
  async created(): Promise<void> {
    const form = await this.$api.getPhysicianBiometricForm();

    this.biometricPhysicianFormStore.setPhysicianForm(form);

    /*
     * Legacy - we dont know what was the context of this
     * if (form && form.status !== PhysicianFormStatus.pending) {
     *   void this.$router.push(this.$pagesPath.physicianForm.downloadForm);
     * } else {
     *   void this.growlsStore.growlInfo("Physician form not available");
     *
     *   void this.$router.push(this.$pagesPath.myHealthPath.myhealthCheckScore);
     * }
     */
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.header
  @include Asap700
  font-size: 20px
  line-height: 28px
  text-align: center
  color: $color-primary-hc-navy-100
  display: grid
  grid-template-columns: 1fr repeat(1, auto) 1fr
  grid-column-gap: 5px
  justify-items: center
  align-items: center
  padding: 60px 70px 27px

  div
    grid-column-start: 2

</style>
