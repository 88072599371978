<template>
  <textarea
    :name="name"
    :value="modelValue"
    :required="required"
    :placeholder="placeholder || 'Write your text here'"
    :style="{'height': `${height}px`}"
    @input="updateValue"
  />
</template>

<script lang="ts">
import {
  Component,
  Emit,
  Prop,
  Vue,
} from "vue-property-decorator";

@Component({
  name: "InputTextarea",
})
export default class InputTextarea extends Vue {
  @Prop() name!: string;

  @Prop() readonly modelValue!: string;

  @Prop() placeholder!: string;

  @Prop({default: false}) required!: boolean;

  @Prop({default: 184}) height!: number;

  @Emit('update:modelValue')
  updateValue(event: any): string {
    return event.target.value as string; // eslint-disable-line @typescript-eslint/no-unsafe-member-access
  }
}
</script>

<style lang="sass" scoped>
textarea
  width: 100%
  resize: none
  background-color: $color-white
  padding: 12px
  border-radius: 8px
  border-color: $color-primary-hc-navy-50
  outline: none
  @include Roboto400
  font-size: 16px
  color: $color-primary-hc-navy-100
</style>
