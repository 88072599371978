<template>
  <div class="wrapper">
    <form @submit.prevent="postTrackingData">
      <div class="tracking">
        <tracking-icon :type="type" :big="false"/>
        <div class="tracking-title">
          {{ trackActivity }}
        </div>

        <div v-if="type === 'mood'" class="mood-selection">
          <div
            v-for="mood in moods"
            :key="mood.value"
            class="mood-row"
            :class="{'selected': mood.value === selectedMood}"
            @click="selectMood(mood.value)"
          >
            <span class="mood-icon">{{ mood.icon }}</span>
            <span class="mood-title">{{ mood.title }}</span>
          </div>
        </div>


        <fieldset v-else class="input-row">
          <label class="input-box input-box--minutes">
            <span class="input-box__label">{{ unit }}</span>
            <input-text
              v-model="amount"
              class="input-number"
              type="number"
              :min="minAmount"
              :step="step"
              required
            />
          </label>

          <label class="input-box">
            <span class="input-box__label">Select date</span>
            <input-date
              v-model="day"
              name="day"
              :min="dayMin"
              :max="dayMax"
              :required="true"
            />
          </label>
        </fieldset>
      </div>
      <!-- TODO: Removed the swipe feature due to incorrect implementation. Will revise and fix later. - Skye https://software.cottinghambutler.com/healthcheck360/myhc360/-/issues/508
      <div>
        <activity-relation-log
          :type="type"
        />
      </div> -->
      <div class="tracking-log">
        <button-primary class="tracking-log" :loading="loading" type="submit">
          <icon-chameleon
            :icon="plusIcon"
            color="white"
            alt="plus icon"
            aria-hidden="true"
          /> Log It
        </button-primary>
        <error-text :error="error"/>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import {Component, Emit, Prop} from "vue-property-decorator";
import TrackingIcon from "@/vue/atoms/tracking-icon.vue";
import InputText from "@/vue/atoms/input-text.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";
import ActivityRelationLog from "@/vue/molecules/tracking/activity-relation-log.vue";
import type {ActivityDTO} from "@/ts/types/dto/activity.dto";
import {TaskType} from "@/ts/types/dto/activity.dto";
import {DefaultGrowlError, LoadingMixin} from "@/ts/mixins/loading-mixin";
import type {PutTrackingRequestDTO} from "@/ts/types/dto/tracking.dto";
import {TrackingProvider} from "@/ts/types/dto/tracking.dto";
import {mixins} from "vue-class-component";
import ErrorText from "@/vue/atoms/error-text.vue";
import {GrowlsStoreMixin} from "@/ts/store/growl/growl-store-instance";
import {
  convertDateTimeToServerFormat,
  convertDateToServerFormat,
  convertHoursToSec,
  convertMinToSec, getStartOfTheDay,
  today,
} from "@/ts/utils/date-pure-functions";
import {calculateFlOzToMilliliter, calculateMilesToCm, calculatePoundToGram} from "@/ts/utils/pure-functions";
import InputDate from "@/vue/atoms/input-date.vue";
import {CachedApiMixin} from "@/ts/mixins/cached-api-mixin";
import SvgPlusIcon from "@/assets/img/plus-icon.svg";


@Component({
  name: "TaskCard",
  components: {
    InputDate,
    ErrorText,
    TrackingIcon,
    InputText,
    ButtonPrimary,
    ActivityRelationLog,
    IconChameleon,
  },
})

export default class ActivityLogging extends mixins(LoadingMixin, GrowlsStoreMixin, CachedApiMixin) {
  @Prop() type!: TaskType;

  @Prop() trackActivity!: string;

  @Prop() relatedActivity!: boolean;

  amount: string = "0";

  day: Date = today();

  dayMin: string = "2023-01-01"; // default value, will be overridden by program start-day

  dayMax: string = convertDateToServerFormat(today());

  activities: ActivityDTO[] = [];

  get taskType(): TaskType {
    return this.type;
  }

  get unit(): "beats per minute" | "calories" | "fl.oz." | "hours" | "lbs." | "miles" | "minutes" | "mood" | "steps" | "units" {
    switch (this.type) {
      case TaskType.biking_distance:
      case TaskType.hiking_distance:
      case TaskType.jogging_distance:
        return "miles";
      case TaskType.steps:
        return "steps";
      case TaskType.sleep_time:
        return "hours";
      case TaskType.nicotine_use:
        return "units";
      case TaskType.heart_rate:
        return "beats per minute";
      case TaskType.calorie_intake:
        return "calories";
      case TaskType.weight:
        return "lbs.";
      case TaskType.water_drinking_volume:
        return "fl.oz.";
      case TaskType.mood:
        return "mood";
      default:
        return "minutes";
    }
  }

  get minAmount(): number {
    switch (this.unit) {
      case "beats per minute":
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 20;
      case "calories":
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 1;
      case "units":
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 0;
      case "hours":
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 0.1;
      case "lbs.":
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 1;
      case "miles":
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 0.1;
      case "minutes":
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 1;
      case "steps":
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 1;
      case "fl.oz.":
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 1;
      default:
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 1;
    }
  }

  get step(): number {
    switch (this.unit) {
      case "beats per minute":
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 1;
      case "calories":
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 1;
      case "units":
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 1;
      case "hours":
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 0.1;
      case "lbs.":
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 0.1;
      case "miles":
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 0.1;
      case "minutes":
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 1;
      case "steps":
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 1;
      case "fl.oz.":
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 1;
      default:
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return 1;
    }
  }

  get plusIcon(): string {
    return SvgPlusIcon;
  }

  @Emit('finish')
  onFinish(): void {
  }

  @Emit('tracking-updated')
  trackingUpdated(): void {}

  created(): void {
    void this.determineDayMin();
  }

  async determineDayMin(): Promise<void> {
    const data = await this.cachedApi.getProgram();
    this.dayMin = data.startDate;
  }

  @DefaultGrowlError
  async postTrackingData(): Promise<void> {
    let trackedAmount = 0;

    switch (this.unit) {
      case "minutes":
        trackedAmount = convertMinToSec(parseInt(this.amount, 10));
        break;
      case "hours":
        trackedAmount = convertHoursToSec(parseFloat(this.amount));
        break;
      case "miles":
        trackedAmount = calculateMilesToCm(parseFloat(this.amount));
        break;
      case "lbs.":
        trackedAmount = calculatePoundToGram(parseFloat(this.amount));
        break;
      case "fl.oz.":
        trackedAmount = calculateFlOzToMilliliter(parseFloat(this.amount));
        break;
      default: trackedAmount = parseInt(this.amount, 10);
    }

    const data: PutTrackingRequestDTO = [
      {
        trackedAmount,
        trackedAtUTC: convertDateTimeToServerFormat(getStartOfTheDay(this.day)), // learn more: HVV-2660
        date: convertDateToServerFormat(this.day),
        taskType: this.taskType,
        provider: TrackingProvider.manual,
      },
    ];
    await this.$api.postTrackingAmount(data);
    void this.growlsStore.growlInfo("Tracking has been added");
    this.onFinish();
    this.trackingUpdated();
    console.log("Event emitted from activity-logging: tracking-updated");
  }

  moods = [
    {value: 1,
icon: "😆",
title: "Very good"},
    {value: 2,
icon: "😀",
title: "Good"},
    {value: 3,
icon: "🙂",
title: "Normal"},
    {value: 4,
icon: "☹️",
title: "Bad"},
    {value: 5,
icon: "🤕",
title: "Very bad"},
  ];

  selectedMood: number | null = null;

  selectMood(value: number): void {
    this.selectedMood = value;
    this.amount = value.toString();
  }
}
</script>

<style lang="sass" scoped>
.input-number
  width: 120px

.tracking
  display: flex
  align-items: center
  @include Roboto700
  color: $color-primary-hc-navy-100
  flex-direction: column
  margin-top: 0.5rem
  margin-bottom: 1.5rem

.tracking-title
  margin: 1rem
  font-size: 32px

.tracking-log
  display: flex
  margin-top: 0.5rem
  align-items: center
  justify-content: center

.wrapper
  display: flex
  flex-direction: column

.input-row
  display: flex
  border: none
  gap: 20px

.input-box
  display: flex
  flex-direction: column-reverse

  &--minutes
    align-items: center

  &__label
    margin-top: 10px

.mood-selection
  display: flex
  flex-direction: column

.mood-row
  display: flex
  align-items: center
  padding: 8px 24px
  cursor: pointer

.mood-icon
  margin-right: 12px
  font-size: 24px

.mood-title
  font-size: 16px

.mood-row.selected
  background-color: #e0e0e0


</style>
