<template>
  <navbar-main
    title="My Community" 
    :profile-section="true" 
    :reward-points="true"
    :hasTabs="true"
    class="body"
    >
      <template #tabs>
        <tabs-navigation :items="communityTabs" />
      </template>
      <router-view />
  </navbar-main>
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import NavbarMain from "@/vue/templates/navbar-main.vue";
import TabsNavigation from "@/vue/atoms/tabs-navigation.vue";
import type { Tab } from "@/ts/types/component/tabs-navigation";

@Component({
  name: "MyCommunityPage",
  components: {
    NavbarMain,
    TabsNavigation,
  },
})
export default class MyCommunityPage extends Vue {
  get communityTabs(): Tab[] {
    return [
      {
        link: this.$pagesPath.community.communityFeed,
        title: "Feed",
      },
      {
        link: this.$pagesPath.community.communityFriends,
        title: "Friends",
      },
      {
        link: this.$pagesPath.community.communityGroups,
        title: "Groups",
      },
    ];
  }
}
</script>

<style lang="sass" scoped>
.body
  background-image: url("@/assets/img/header-wave-background-2.svg")
  background-position-y: -3rem
  background-repeat: no-repeat
  background-size: contain
  color: $color-primary-hc-navy-100
  @include Asap700
  position: absolute
  height: 100%
  width: 100%  
  padding-left: 100px

@media (min-width: 1800px)

  .body
    padding-left: 280px

@media (min-width: 1500px)

  .body
    background-position-y: -5rem

@media (max-width: 1300px)

  .body
    background-position-y: 0rem

@media (max-width: 1000px)

  .body
    background-position-y: 2rem

</style>
