<template>
  <div class="item">
    <challenge-icons :type="image"/>
    <div>
      <p class="title">
        {{ title }}
      </p>
      <p class="text">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import type {TaskType} from "@/ts/types/dto/activity.dto";
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import ChallengeIcons from "@/vue/molecules/challenge/challenge-icons.vue";

type IconType = TaskType | "duration";


@Component({
  components: {
    ChallengeIcons
  }
})
export default class ProgramInfo extends Vue {
  @Prop()
  title!: string;

  @Prop()
  image!: IconType;

  @Prop()
  text!: string;
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>
.item
  display: flex
  gap: 8px

.title
  @include Roboto400
  color: $color-primary-hc-navy-100
  margin: 0
  font-size: 14px
  line-height: 22px
  text-align: left

.text
  @include Roboto400
  color: $color-tertiary-orange-sunset-100
  margin: 0
  font-size: 14px
  line-height: 22px
  text-align: left
</style>
