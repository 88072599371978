<template>
  <div :class="cardClasses">
    <div class="header">
      <p>{{ level.name }}</p>
      <status-badge v-if="level.isActive" value="Active"/>
    </div>
    <div class="description">
      <p>
        {{ level.description }}
      </p>
    </div>
    <p
      v-if="level.isActive && level.levelInfo !== ''"
      class="level-info"
      :class="{capped: level.isCapped}"
    >
      🗝️ {{ level.levelInfo }}
    </p>
    <div class="divider"/>
    <div class="footer">
      <div class="row">
        <span class="icon"><img src="@/assets/img/trophy-icon.svg"/>
          <p>Total Rewards</p></span>
        <badge-rounded :value="rewardPoints"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import BadgeRounded from "@/vue/atoms/badge-rounded.vue";
import StatusBadge from "@/vue/atoms/status-badge.vue";
import type {ProgramLevelInfo} from "@/ts/types/component/levels";

@Component({
  name: "LevelCard",
  components: {BadgeRounded, StatusBadge},
})
export default class LevelCard extends Vue {
  @Prop()
  readonly level!: ProgramLevelInfo;

  get rewardPoints(): string {
    return `${this.level.rewardAmountThreshold} PTS`;
  }

  get cardClasses(): string[] {
    return ["card", this.level.isActive ? "active" : ""];
  }
}
</script>
<style lang="sass" scoped>
.card
  background-color: $color-white
  border-radius: 16px
  padding: 16px
  width: 296px
  height: fit-content

.active
  border: 1.5px solid $color-secondary-sea-foam-100

.divider
  border-top: 2px solid $color-neutral-platinum-40
  margin: 32px 0 18px

.header
  display: flex
  justify-content: space-between
  align-items: center
  color: $color-primary-hc-navy-100
  gap: 8px

  p
    font-size: 16px
    @include Asap700
    color: $color-primary-hc-navy-100
    max-width: 160px

.description
  color: $color-primary-hc-navy-100
  @include Roboto400
  font-size: 14px
  line-height: 22px

.footer
  display: flex
  flex-direction: column

.row
  display: flex
  align-items: center
  justify-content: space-between

  b
    font-size: 14px
    color: $color-primary-hc-navy-100
    @include Roboto700

  .icon
    display: flex
    gap: 8px

    p
      font-size: 14px
      @include Roboto400
      color: $color-primary-hc-navy-100

.level-info
  color: $color-secondary-sea-foam-100
  font-style: italic
  @include Roboto400
  font-size: 14px
  line-height: 18px
  margin-top: 1rem
  margin-bottom: -1rem

  &.capped
    color: $color-status-error-red
</style>
