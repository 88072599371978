<template>
  <div class="todo-container">
    <h1 class="todo-title">
      My To-Do's
      <tooltip-wrapper tooltip-text="The To Do list is a way for you to prioritize required or recommended activities outlined by your company and customize your goals by including activities that are important to you.">
        <img src="@/assets/img/tooltip-question.svg" alt="tooltip" class="tooltip-icon"/>
      </tooltip-wrapper>
    </h1>
    <calendar-list v-model="localCurrentDay" @update:modelValue="updateCurrentDay"/>
    <todo-list :day="localCurrentDay" @todos-updated="emitTodosUpdated"/>
  </div>
</template>

<script lang="ts" setup>
import { ref, watchEffect, defineProps, defineEmits } from "vue";
import CalendarList from "@/vue/organisms/calendar-list.vue";
import TodoList from "@/vue/organisms/todo-list.vue";
import TooltipWrapper from "@/vue/atoms/tooltip-wrapper.vue";

const props = defineProps<{ currentDay: Date }>();
const emit = defineEmits(["update:currentDay", "todos-updated"]);

const localCurrentDay = ref(props.currentDay);

watchEffect(() => {
  localCurrentDay.value = props.currentDay;
});

const updateCurrentDay = (newDay: Date) => {
  localCurrentDay.value = newDay;
  emit("update:currentDay", newDay);
};

const emitTodosUpdated = () => {
  emit("todos-updated");
};
</script>

<style lang="sass" scoped>
.todo-container
  flex-grow: 1
  max-width: 1000px
  min-width: 300px
  width: 100%
  border-radius: 16px
  background: rgba(234, 234, 234, 0.93)
  box-shadow: $content-shadow
  padding: 2rem
  display: flex
  flex-direction: column
  align-items: flex-start
  transition: width 0.3s ease-in-out

.todo-title
  font-size: 36px
  align-self: center
  margin-top: 12px
  margin-bottom: 2px

.tooltip-icon
  width: 27px
  height: 27px
  cursor: pointer

@media (max-width: 1500px)
  .todo-container
    margin-left: 1rem
    margin-right: 1rem
    justify-self: center

  .todo-content-container
    padding: 16px
    margin-bottom: 20px

@media (max-width: 1200px)
  .todo-container
    margin-left: 0.5rem
    margin-right: 0.5rem

  .todo-content-container
    padding: 12px
    margin-bottom: 15px

@media (max-width: 900px)
  .todo-container
    margin-left: 0.25rem
    margin-right: 0.25rem

  .todo-content-container
    padding: 8px
    margin-bottom: 10px

@media (max-width: 600px)
  .todo-container
    margin-left: 0
    margin-right: 0

  .todo-content-container
    padding: 4px
    margin-bottom: 5px

@media (max-width: 400px)
  .todo-content-container
    padding: 2px
    margin-bottom: 2px
</style>
