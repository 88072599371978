<template>
  <div class="chart-container">
    <header>
      <icon-chameleon
        :icon="MoodIcon"
        color="secondary100"
        alt="mood icon"
        aria-hidden="true"
      />
      <h4 class="title">
        Recent mood
      </h4>
    </header>
    <div v-if="totalCount > 0" class="chart">
      <div class="tracked">
        Tracked {{ totalCount }} times
      </div>
      <div class="graph">
        <div class="gradient" :style="gradientStyle">
          <div class="blur"/>
        </div>
      </div>
      <div class="legend">
        <div v-for="([key, item], index) in mood" :key="index" class="legend-item">
          <div class="color" :style="`background: ${moodValueToColor[key]}`"/>
          <div class="label">
            {{ moodValueToLabel[key] }}
          </div>
          <div class="percentage">
            {{ Math.round(item / totalCount * 100) }}%
          </div>
        </div>
      </div>
    </div>
    <no-data-placeholder v-else/>
  </div>
</template>

<script setup lang="ts">
import type {ComputedRef} from "vue";
import {computed, inject, onMounted, ref} from "vue";
import NoDataPlaceholder from "@/vue/molecules/my-progress/no-data-placeholder.vue";
import IconChameleon from "@/vue/atoms/icon-chameleon.vue";
import MoodIcon from "@/assets/img/icons/mood.svg";
import type {Api} from "@/ts/classes/api";
import {addToDate, convertDateToServerFormat, getDayOfWeek, subtractFromDate} from "@/ts/utils/date-pure-functions";
import {TaskType} from "@/ts/types/dto/activity.dto";

const $api = inject("$api")! as Api;

/* eslint-disable @typescript-eslint/naming-convention */
const moodValueToColor: Record<number, string> = {
  1: "#FFB828",
  2: "#FFE2A9",
  3: "#EBEEF1",
  4: "#1B2D3B80",
  5: "#1b2d3b",
};

const moodValueToLabel: Record<number, string> = {
  1: "very good",
  2: "good",
  3: "moderate",
  4: "bad",
  5: "very bad",
};

const moodValueToCount = ref<Record<number, number>>({
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
});

const mood: ComputedRef = computed(() => {
  return Object.entries(moodValueToCount.value).reverse();
});

const numberOfDays = 7;
const startDate: Date = subtractFromDate(new Date(), {days: numberOfDays - 1});
const days: Date[] = new Array(numberOfDays)
  .fill("")
  .map((day, index) => addToDate(startDate, {days: index}));

onMounted(async() => {
  try {
    const trackingItems = await $api.getTrackingList({
      startDate: convertDateToServerFormat(days[0]),
      endDate: convertDateToServerFormat(days[days.length - 1]),
      taskType: TaskType.mood,
    });
    trackingItems.forEach((item) => {
moodValueToCount.value[item.trackedAmount] += 1;
});
  } catch (err) {
    /* eslint-disable no-console */
    // TODO handle error
    console.error(err);
  }
});

const totalCount: ComputedRef<number> = computed(() => {
    return Object.values(moodValueToCount.value).reduce((prev: number, current: number) => prev + current, 0);
});

/* eslint-disable  @typescript-eslint/no-magic-numbers */
/* eslint-disable  max-len */
const gradientStyle: ComputedRef<string> = computed(() => {
  const gradientWidth = 400;
  return `background:
    radial-gradient(circle at top left, rgba(60, 87, 117, 1), transparent ${gradientWidth * moodValueToCount.value[5] / totalCount.value}px),
    radial-gradient(circle at top right, rgba(255, 184, 40, 1), transparent ${gradientWidth * moodValueToCount.value[1] / totalCount.value}px),
    radial-gradient(at bottom left, rgba(158, 171, 186, 1), transparent ${gradientWidth * moodValueToCount.value[4] / totalCount.value}px),
    radial-gradient(at bottom right, rgba(255, 226, 169, 1), transparent ${gradientWidth * moodValueToCount.value[2] / totalCount.value}px)
  `;
});
</script>

<style lang="sass" scoped>
.chart-container
  padding: 16px
  box-shadow: 0 0 10px rgba(15, 38, 78, 0.15)
  border-radius: 10px
  background-color: $color-white

header
  display: flex
  margin: 10px 0

.title
  font-size: 16px
  text-transform: uppercase
  margin: 0 0 0 10px
  display: flex
  justify-content: center
  align-items: center

.graph
  height: 170px
  background-color: rgba(235, 238, 241, 1)

.gradient
  width: 100%
  height: 100%

.blur
  width: 100%
  height: 100%
  backdrop-filter: blur(30px)

.tracked
  font-size: 14px
  font-weight: 400
  color: $color-primary-hc-navy-50
  margin: 10px 0

.legend
  display: flex
  justify-content: space-evenly
  margin-top: 10px

.legend-item
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.label
  text-transform: uppercase
  font-weight: 400
  font-size: 10px

.percentage
  font-weight: 700
  font-size: 14px

.color
  width: 14px
  height: 14px
  border-radius: 14px

.color,
.label,
.percentage
  margin: 2px 0
</style>
