<template>
  <div v-if="showText">
    <div class="text">
      Complete your screening to access the full features and benefits of your myHC360+ wellness program.
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Prop} from "vue-property-decorator";
import type {
  BiometricScreeningAppointment,
  BiometricScreeningStatus,
} from "@/ts/types/dto/biometric-screening.dto";
import {isAppointmentScheduledInFuture, isBioscreeningSubmitted} from "@/ts/utils/my-health/bioscreening";
import {isPhysicianFormPendingOrApproved} from "@/ts/utils/my-health/physician-form";
import type {GetPhysicianFormBiometricResponse} from "@/ts/types/dto/physician-form.dto";

@Component({
  name: "BiometricScreeningText",
  components: {},
})
export default class BiometricScreeningText extends Vue {
  @Prop({default: null})
  biometricStatus!: BiometricScreeningStatus | null;

  @Prop({default: null})
  appointment!: BiometricScreeningAppointment | null;

  @Prop({default: null})
  physicianForm!: GetPhysicianFormBiometricResponse | null;

  get isNoAppointmentScheduledInFuture(): boolean {
    return !isAppointmentScheduledInFuture(this.appointment);
  }

  get isBioscreeningNotSubmitted(): boolean {
    return !isBioscreeningSubmitted(this.biometricStatus);
  }

  get isPhysicianFormNotPendingAndNotApproved(): boolean {
    return !isPhysicianFormPendingOrApproved(this.physicianForm);
  }

  get showText(): boolean {
    return this.isNoAppointmentScheduledInFuture &&
      this.isBioscreeningNotSubmitted &&
      this.isPhysicianFormNotPendingAndNotApproved;
  }
}
</script>

<style lang="sass" scoped>
.text
  @include Roboto400
  color: $color-primary-hc-navy-100
  font-size: 16px
  letter-spacing: 0.1px
  line-height: 24px

</style>
