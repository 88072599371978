<template>
  <div ref="notificationRef" class="notification">
    <notification-icon :notification-target="notification.target"/>
    <h3>{{ notification.title }}</h3>
    <p class="body">{{ notification.body }}</p>
    <div class="unread"><i v-if="!isRead" class="unread-icon"></i></div>
    <div class="time">{{ get12HourTime(notification.createdAt) }}</div>
  </div>
</template>

<script lang="ts" setup>
import {getMonthDay, get12HourTime} from "@/ts/utils/date-pure-functions";
import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import {NotificationDTO} from "@/ts/types/dto/notification.dto";
import NotificationIcon from "@/vue/atoms/notification-icon.vue";

const props = defineProps<{
  notification: NotificationDTO;
}>();
const isRead = ref(props.notification.isRead)
const emit = defineEmits(["read"])
const notificationRef = ref(null)
let observer: IntersectionObserver

const markAsRead = (entries: IntersectionObserverEntry[]) => {
  const entry = entries[0]
  if (entry.isIntersecting) {
    // Add a small delay to signal the reading event to the user
    setTimeout(() => emit('read', props.notification), 1000)
    // Stop observing once marked as read
    if (notificationRef.value) {
      observer.unobserve(notificationRef.value)
    }
  }
};

onMounted(() => {
  observer = new IntersectionObserver(markAsRead, { threshold: 1 })
  if (!props.notification.isRead && notificationRef.value) {
    observer.observe(notificationRef.value)
  }
})

onUnmounted(() => {
  if (observer && notificationRef.value) {
    observer.unobserve(notificationRef.value)
  }
})

watch(() => props.notification.isRead, (newValue) => {
  isRead.value = newValue
})

const date = computed(() => {
  const dateObj = new Date(props.notification.createdAt)
  const localDate = new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000)
  return getMonthDay(new Date(localDate))
});
</script>

<style lang="sass" scoped>
.notification
  padding: 20px 32px
  display: grid
  grid-template-columns: 68px auto 20px 62px
  grid-template-rows: 20px auto
  gap: 5px
  background-color: $color-white

h3
  grid-row: 1
  grid-column: 2
  color: $color-primary-hc-navy-100
  font-size: 16px
  letter-spacing: 0.15px
  @include Asap700
  margin: 0
  line-height: 22px

.icon-wrapper
  grid-row: span 2
  width: 48px
  height: 48px
  margin-right: 24px
  display: flex

.body
  grid-column: 2 / 4
  grid-row: 2
  font-size: 14px
  line-height: 22px
  @include Roboto400
  letter-spacing: 0.2px
  margin: 0

.unread
  grid-column: 3
  grid-row: 1
  display: flex
  justify-content: end
  align-items: center

.unread-icon
  width: 8px
  height: 8px
  background-color: $color-status-error-red
  border-radius: 10px

.time
  grid-column: 4
  grid-row: 1
  justify-self: end
  color: $color-primary-hc-navy-50
  font-size: 14px
  line-height: 22px
  @include Roboto400
  letter-spacing: 0.2px
</style>
