<template>
  <div>
    <div class="header">
      <div>Schedule a coaching session</div>
      <router-link :to="$pagesPath.coaching.info" class="close">
        <img src="@/assets/img/x-mark.svg" alt="close"/>
      </router-link>
    </div>
    <loading-bar :current="1" :steps="1"/>
    <div class="calendar">
      <datepicker-calendar v-model="selectedDay" :available-dates="dates"/>
      <div class="date">
        <loading-suspense :loading="loading" :error="error">
          <time-selector
            v-if="availableSlots.length"
            v-model="selectedTime"
            :values="availableSlots"
            :selected-day="selectedDay"
          />
          <div v-else-if="hasAnyAvailableSlots">
            No available slots on this day
          </div>
          <div v-else class="no-available-slots">
            <p>There are no coaching slots currently available.</p>
            <p>Contact one of our coaches directly at:</p>
            <div class="coaching-contact">
              <p>Phone:</p>
              <a href="tel:1-866-511-0360">1-866-511-0360</a>
              <b>ext 5099</b>
            </div>
            <div class="coaching-contact">
              <p>Email:</p>
              <a href="mailto:healthcoach@healthcheck360.com">healthcoach@healthcheck360.com</a>
            </div>
          </div>
        </loading-suspense>
        <div class="phone">
          <p>Your Phone Number</p>
          <edit-phone-number v-model="phoneNumber"/>
        </div>
      </div>
    </div>
    <div class="button">
      <button-primary :disabled="!selectedTime || !phoneNumber" @click="schedule">
        Schedule <img src="@/assets/img/right-arrow-white.svg"/>
      </button-primary>
    </div>
    <schedule-coaching-modal
      v-if="showPopup"
      v-model="showPopup"
      :selected-time="selectedTime"
      :phone-number="phoneNumber"
    />
  </div>
</template>

<script lang="ts">
import {Component, Prop, Watch} from "vue-property-decorator";
import LoadingBar from "@/vue/atoms/loading-bar.vue";
import DatepickerCalendar from "@/vue/atoms/datepicker-calendar.vue";
import {SchedulingCoachingStoreMixin} from "@/ts/store/scheduling-coaching/scheduling-coaching-store-instance";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {convertDateToServerFormat, parseDate, today} from "@/ts/utils/date-pure-functions";
import TimeSelector from "@/vue/molecules/time-selector.vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import EditPhoneNumber from "@/vue/molecules/edit-field/edit-phone-number-input.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import ScheduleCoachingModal from "@/vue/organisms/schedule-coaching-modal.vue";
import {DefaultStore} from "@/ts/store/default/default-store";
import {vueStore} from "@/ts/store/vue-store";
import {getModule} from "vuex-module-decorators";

@Component({
  name: "CoachingSchedulePage",
  components: {
    LoadingBar,
    DatepickerCalendar,
    TimeSelector,
    LoadingSuspense,
    EditPhoneNumber,
    ButtonPrimary,
    ScheduleCoachingModal,
  },
})

export default class CoachingSelectTimePage extends mixins(LoadingMixin, SchedulingCoachingStoreMixin) {
  @Prop()
  step!: number;

  phoneNumber: string = "";

  dates: string[] = [];

  selectedDay: Date | null = today();

  selectedTime: Date | null = null;

  availableSlots: Date[] = [];

  showPopup: boolean = false;

  mounted(): void {
    const defaultStore: DefaultStore = getModule(DefaultStore, vueStore);
    this.phoneNumber = defaultStore.profile?.phoneNumber || "";
  }

  @DefaultGrowlError
  async created(): Promise<void> {
    this.dates = await this.$api.getCoachingAvailableDates();

    await this.handleAvailableSlots();
  }

  @Watch('selectedDay')
  @DefaultGrowlError
  async onSelectedDayChange(): Promise<void> {
    await this.handleAvailableSlots();
  }

  async handleAvailableSlots(): Promise<void> {
    this.selectedTime = null;
    const availableSlots = await this.$api.getCoachingAvailableDateSlots({
      date: convertDateToServerFormat(this.selectedDay!),
    });
    this.availableSlots = availableSlots.map((slot) => parseDate(slot));
  }

  schedule(): void {
    if (this.selectedTime && this.phoneNumber) {
      this.showPopup = true;
    }
  }

  get hasAnyAvailableSlots(): boolean {
    return this.dates.length > 0;
  }
}
</script>

<style lang="sass" scoped>
.header
  @include Asap700
  font-size: 20px
  line-height: 28px
  text-align: center
  color: $color-primary-hc-navy-100

  display: grid
  grid-template-columns: 1fr repeat(1, auto) 1fr
  grid-column-gap: 5px
  justify-items: center
  align-items: center
  padding: 60px 70px 27px

  > div
    grid-column-start: 2

.no-available-slots
  display: flex
  flex-direction: column
  align-items: center
  margin: auto

  > p
    @include Asap400
    font-size: 16px
    line-height: 12px
    color: $color-status-error-red
    text-align: center

.coaching-contact
  display: flex
  flex-direction: row
  align-items: center

  > p
    font-weight: bold
    color: $color-primary-hc-navy-100
    margin-right: 0.5rem


  > b
    font-weight: normal
    color: $color-primary-hc-navy-100
    margin-left: 0.5rem

.calendar
  display: flex
  flex-direction: row
  justify-content: center
  gap: 80px
  margin: 10rem 0

.date
  @include Asap700
  size: 16px
  color: $color-primary-hc-navy-100
  display: flex
  flex-direction: column
  justify-content: space-between

.phone
  max-width: 380px

.close
  margin-left: auto
  cursor: pointer

.button
  display: flex
  justify-content: center
</style>
