<template>
  <div class="point-cap-tracker">
    <div class="section-header">
      <img v-if="rewardType === RewardType.point" src="@/assets/img/trophy-icon.svg" alt="present"/>
      <img v-if="rewardType === RewardType.cash" src="@/assets/img/coin.svg" alt="present"/>
      <span class="points-section-text">
        {{ sectionName }}
        <span v-if="rewardType === RewardType.point"> Points</span>
        <span v-else-if="rewardType === RewardType.cash"> Cash</span>
      </span>
    </div>
    <div class="rule-values">
      <span class="total">{{ userTotal }}</span>
      <span class="grey-value">
        /
        <span v-if="rewardType === RewardType.cash">$</span>
        {{ ruleAmount }}
        <span v-if="rewardType === RewardType.point"> PTS</span>
      </span>
    </div>
    <loading-bar
      :steps="ruleAmount"
      :current="userTotal"
      class="loading-bar"
      theme="secondary-blue"
    />
    <div
      v-if="userTotal === ruleAmount"
      class="maxed-message"
    >
      <div class="maxed-icon">
        <span>🎉</span>
      </div>
      <div class="maxed-text">
        <span>Congratulations! You have maxed out
          <span v-if="rewardType === RewardType.point"> points</span>
          <span v-else-if="rewardType === RewardType.cash"> cash</span>
          for {{ maxedText }}, but you are welcome to continue completing {{ maxedText }}.</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {RewardType, UserPointCapTotalsDTO} from "@/ts/types/dto/reward.dto";
import {computed} from "vue";
import LoadingBar from "@/vue/atoms/loading-bar.vue";

const props = defineProps<{
    rule: UserPointCapTotalsDTO | null;
  }>();

  const sectionName = computed(() => {
    switch (props.rule?.pointCapRule.contentType) {
      case "activity":
        return "Activity";
      case "platform_activity":
        return "Platform Activity";
      case "leaderboard_challenge":
        return "Leaderboard Challenge";
      case "pathway":
        return "Pathway";
      default:
        return "";
    }
  });

  const maxedText = computed(() => {
    switch (props.rule?.pointCapRule.contentType) {
      case "activity":
        return "activities";
      case "platform_activity":
        return "platform activities";
      case "leaderboard_challenge":
        return "challenges";
      case "pathway":
        return "pathways";
      default:
        return "content in this rule";
    }
  });

  const ruleAmount = computed(() => {
    if (props.rule !== null) {
      return props.rule.pointCapRule.amount;
    } else {
      return 0;
    }
  });

  const userTotal = computed(() => {
    if (props.rule !== null) {
      return props.rule.total;
    } else {
      return 0;
    }
  });

  const rewardType = computed(() => {
    if (props.rule !== null) {
      return props.rule.pointCapRule.rewardType;
    } else {
      return "";
    }
  })
</script>

<style lang="sass" scoped>
.point-cap-tracker
  flex: 0 1 auto
  min-width: 400px
  max-width: 500px
  padding: 16px 12px
  background-color: $color-white
  box-shadow: $content-shadow
  border-radius: 16px
  display: flex
  flex-direction: column
  gap: 12px
  align-items: stretch

.section-header
   display: flex
   max-height: 22px
   justify-content: flex-start
   width: 100%
   @include Asap700
   font-size: 20px

.points-section-text
  margin-left: 6px

.rule-values
  display: flex
  align-items: baseline
  justify-content: flex-start
  gap: 4px
  width: 100%

.total
  @include Asap700
  font-size: 24px
  line-height: 32px
  color: $color-secondary-sea-foam-100

.grey-value
  @include Roboto400
  font-size: 14px
  line-height: 22px
  color: $color-primary-hc-navy-50
  text-transform: uppercase

.loading-bar
  width: 100%

.maxed-message
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  gap: 8px

.maxed-icon
  font-size: 36px
  display: flex
  align-items: center

.maxed-text
  flex: 1
  color: $color-primary-hc-navy-100
  @include Roboto400
  font-size: 14px
  line-height: 22px
</style>
