<template>
<div class="filter-wrapper" :class="{ active: showFilter }" @mouseenter="showFilter = true">    
  <div v-if="showFilter" class="bg-fade" @click="showFilter = false"></div>
    <div class="filter-container">
      <slot name="trigger" />
      <transition name="fade">
        <div v-if="showFilter" class="filter-bubble" @click.stop>
          <div class="listbox-group">
            <div class="listbox-headline">
              <p class="listbox-title">Selected</p>
              <p class="listbox-number">{{ selectedCategories.length }}</p>
            </div>
            <aria-list-box
              class="listbox"
              option-class-name="listbox__option"
              option-active-class-name="listbox__option--active"
              aria-labelledby="selected-listbox"
              ariaLabel="Unselect a filter"
              :options="selectedCategories"
              :selection="selectedIds"
              @removed-from-selection="toggleCategory"
            >
              <template #item="templateProps">
                <category-badge 
                  :value="templateProps.option.name"
                  :selected="true"
                  :color="templateProps.option.color"
                />
              </template>
            </aria-list-box>
          </div>
          <div class="listbox-group">
            <div class="listbox-headline">
              <p class="listbox-title">Available</p>
              <p class="listbox-number">{{ unselectedCategories.length }}</p>
            </div>
            <aria-list-box
              class="listbox"
              option-class-name="listbox__option"
              option-active-class-name="listbox__option--active"
              aria-labelledby="unselected-listbox"
              ariaLabel="Select a filter"
              :options="unselectedCategories"
              :selection="selectedIds"
              @added-to-selection="toggleCategory"
            >
              <template #item="templateProps">
                <category-badge 
                  :value="templateProps.option.name"
                  :color="templateProps.option.color"
                />
              </template>
            </aria-list-box>
          </div>
          <div class="buttons">
            <button-primary theme="white" @click="onCancel">Cancel</button-primary>
            <button-primary @click="applyFilter">Apply</button-primary>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, watch } from "vue";
import type { ContentCategory } from "@/ts/types/dto/program.dto";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import AriaListBox from "@/vue/molecules/aria-list-box.vue";
import CategoryBadge from "@/vue/atoms/category-badge.vue";

const props = defineProps<{
  categories: ContentCategory[];
  filterKey: string;
}>();

const emit = defineEmits<{
  (e: "applyFilter", selectedCategories: ContentCategory[]): void;
}>();

const showFilter = ref(false);
const selectedCategories = ref<ContentCategory[]>([]);
const previouslySelectedCategories = ref<ContentCategory[]>([]);

const localStorageKey = computed(() => `selectedCategories_${props.filterKey}`);

const selectedIds = computed((): Map<string, ContentCategory> => {
  const idMap = new Map<string, ContentCategory>();
  selectedCategories.value.forEach((category) => idMap.set(category.id, category));
  return idMap;
});

const unselectedCategories = computed(() =>
  props.categories.filter((category) => !selectedIds.value.has(category.id))
);

const toggleCategory = (category: ContentCategory): void => {
  const index = selectedCategories.value.findIndex((item) => item.id === category.id);
  if (index === -1) {
    selectedCategories.value.push(category);
  } else {
    selectedCategories.value.splice(index, 1);
  }
};

const onCancel = (): void => {
  selectedCategories.value = previouslySelectedCategories.value.slice();
  showFilter.value = false;
};

const applyFilter = (): void => {
  previouslySelectedCategories.value = selectedCategories.value.slice();

  localStorage.setItem(localStorageKey.value, JSON.stringify(selectedCategories.value));

  emit("applyFilter", selectedCategories.value.slice());
  showFilter.value = false;
};

onMounted(() => {
  const storedFilters = localStorage.getItem(localStorageKey.value);
  if (storedFilters && storedFilters !== "[]") {
    try {
      const parsedFilters: ContentCategory[] = JSON.parse(storedFilters);
      
      selectedCategories.value = parsedFilters.filter(category =>
        props.categories.some(cat => cat.id === category.id)
      );

      previouslySelectedCategories.value = selectedCategories.value.slice();

      emit("applyFilter", selectedCategories.value.slice());
    } catch (error) {
      console.error("Error parsing stored filters:", error);
    }
  }
});

watch(() => props.categories, (newCategories) => {
  selectedCategories.value = selectedCategories.value.filter(category =>
    newCategories.some(cat => cat.id === category.id)
  );
  previouslySelectedCategories.value = selectedCategories.value.slice();
});
</script>

<style lang="sass" scoped>
.filter-wrapper
  position: relative
  display: inline-block

.filter-wrapper.active
  z-index: 1300

.filter-container
  position: relative
  display: inline-block

.bg-fade
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba($color-primary-hc-navy-100, 0.5)
  z-index: 1000
  overflow: hidden

.filter-bubble
  position: absolute
  top: 150%
  left: 125%
  transform: translateX(-5%)
  background: white
  border-radius: 8px
  padding: 16px
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15)
  z-index: 1001

  &::after
    content: ""
    position: absolute
    top: -22px
    left: 0
    border-top-width: 0
    border-right-width: 45px
    border-bottom-width: 30px
    border-left-width: 0
    border-top-left-radius: 5px
    border-style: solid
    border-color: transparent transparent white transparent

.listbox-group
  margin-bottom: 8px

.listbox-headline
  display: flex
  flex-direction: row
  gap: 6px
  align-items: center

.listbox-title
  font-size: 16px
  margin-bottom: 4px

.listbox-number
  font-size: 16px
  color: $color-primary-hc-navy-50
  margin-bottom: 4px

.buttons
  display: flex
  gap: 8px
  margin-top: 16px

.listbox
  padding: 8px 0
  display: flex
  flex-wrap: wrap
  gap: 12px

.fade-enter-active, .fade-leave-active
  transition: opacity 0.2s

.fade-enter-from, .fade-leave-to
  opacity: 0
</style>