<template>
  <div class="card">
    <loading-suspense :loading="loading" :error="error">
      <div class="image-container">
        <img
          :src="fullImagePath"
          @error="handleImageError"
          alt="Service Image"
        />
      </div>
    </loading-suspense>
    
    <div class="content">
      <h2 class="title">{{ name }}</h2>
      <h5 class="provider">{{ provider }}</h5>
      <p class="description">{{ description }}</p>
    </div>

    <div class="website-section">
      <a
        :href="websiteUrl"
        class="external-link-button"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>Visit Website</span>
        <img
          v-if="externalLinkIcon"
          :src="externalLinkIcon"
          alt="external link icon"
          class="external-link-icon"
        />
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, inject, onMounted } from "vue";
import LoadingSuspense from "@/vue/molecules/loading-suspense.vue";
import PlaceholderImage from "@/assets/img/illustrations/no-activities-available-1.svg";
import externalLinkIcon from "@/assets/img/icons/external-link.svg";
import type { Api } from "@/ts/classes/api";

const $api: Api = inject("$api")! as Api;

const props = defineProps<{
  name: string;
  provider: string;
  description: string;
  imagePath: string;
  websiteUrl: string;
}>();

const fullImagePath = ref(PlaceholderImage);
const loading = ref(true);
const error = ref<string | null>(null);

const fetchImage = async () => {
  try {
    if (props.imagePath) {
      const imageUrl = await $api.getImage(props.imagePath);
      fullImagePath.value = imageUrl;
    }
  } catch (err) {
    console.error("Error fetching image:", err);
    error.value = "Failed to load image";
    fullImagePath.value = PlaceholderImage;
  } finally {
    loading.value = false;
  }
};

const handleImageError = (event: Event) => {
  (event.target as HTMLImageElement).src = PlaceholderImage;
};

onMounted(() => {
  fetchImage();
});
</script>

<style lang="sass" scoped>
.card
  display: flex
  flex-direction: column
  align-items: center
  padding: 20px
  box-shadow: 0 4px 12px rgba(15, 38, 78, 0.15)
  border-radius: 12px
  background-color: $color-white
  max-width: 400px
  text-align: center

.image-container
  height: 180px
  display: flex
  justify-content: center
  align-items: center
  overflow: hidden
  border-radius: 8px

  img
    width: 100%
    height: 100%
    object-fit: cover

.content
  padding: 16px 0

.title
  font-size: 22px
  font-weight: bold
  margin-bottom: 4px

.provider
  font-size: 16px

.description
  font-size: 14px
  color: $color-primary-hc-navy-100
  margin-top: 8px
  line-height: 1.4

.website-section
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 16px

.external-link-button
  text-decoration: none
  display: inline-flex
  align-items: center
  justify-content: center
  min-width: 218px
  min-height: 45px
  padding: 12px 0
  gap: 8px
  background-color: $color-primary-hc-navy-100
  color: white
  border: none
  border-radius: 8px
  cursor: pointer
  font-size: 16px
  letter-spacing: 0.5px
  line-height: 1
  @include Asap600
  transition: transform 0.1s ease-out, background-color 0.3s ease-out
  transform-origin: center

  &:hover
    transform: scale(1.05)
    background-color: darken($color-primary-hc-navy-100, 10%)

    .external-link-icon
      transform: translateX(5px)

.external-link-icon
  transition: transform 0.3s ease-out
</style>