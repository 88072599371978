<template>
  <aria-list-box
    v-if="editMode"
    class="listbox"
    option-class-name="listbox__option"
    option-active-class-name="listbox__option--active"
    orientation="horizontal"
    :options="interests"
    :selection="selectedIds"
    ariaLabel="Select your interests"
    @removed-from-selection="onSelectInterest"
    @added-to-selection="onSelectInterest"
  >
    <template #item="templateProps">
      <badge-rounded
        :value="templateProps.option.name"
        :theme="selectedIds.has(templateProps.option.id) ? 'dark' : 'grey'"
        :icon="selectedIds.has(templateProps.option.id) ? 'check' : 'plus'"
        size="large-thin"
      />
    </template>
  </aria-list-box>

  <div
    v-if="!editMode"
    class="listbox"
    :class="{'listbox--float-left': floatLeft}"
    role="list"
    aria-label="My interests"
  >
    <badge-rounded
      v-for="interest in uniqueInterests"
      :key="interest.id"
      role="listitem"
      :value="interest.name"
      theme="grey"
      size="large-thin"
    />
  </div>
</template>

<script lang="ts" setup>
import type {Interest} from "@/ts/types/store/onboarding-store-types";
import {computed, Ref, ref} from "vue";
import type {UserInterestDTO} from "@/ts/types/dto/profile.dto";
import BadgeRounded from "@/vue/atoms/badge-rounded.vue";
import AriaListBox from "@/vue/molecules/aria-list-box.vue";

const props = defineProps<{
  interests: UserInterestDTO[];
  editMode?: boolean;
  selectedInterests?: Interest[];
  floatLeft?: boolean;
}>();

const emit = defineEmits(["selectInterest"]);

const selectedIds = computed((): Map<string, Interest> => {
  const idMap = new Map<string, Interest>();
  props.selectedInterests?.forEach((interest: Interest) => {
    idMap.set(interest.id, interest);
  });
  return idMap;
});

// Filter out duplicate interests
const uniqueInterests = computed(() => {
  const seen = new Set();
  return props.interests.filter((interest) => {
    if (seen.has(interest.id)) {
      return false;
    }
    seen.add(interest.id);
    return true;
  });
});

const onSelectInterest = (interest: Interest): void => {
  emit("selectInterest", interest);
};

</script>

<style lang="sass" scoped>
.listbox
  width: auto
  display: flex
  align-items: center
  justify-content: center
  flex-wrap: wrap
  gap: 12px
  outline: none

  &--float-left
    justify-content: flex-start

  &:deep(.listbox__option)
    user-select: none
    background: none
    border: none
    padding: 0

  &:focus-visible:deep(.listbox__option--active)
    outline: 2px solid $color-secondary-sea-foam-100
    outline-offset: 2px
    border-radius: 4px

</style>
