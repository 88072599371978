<template>
  <action-card
    v-if="actions.length"
    title="Recommended Actions"
    description="These are actions we recommend to help you learn more about your overall well-being."
    :actions="actions"
  />
  <gatekeeper-modal
    :gatekeeper-item="selectedGatekeeperItem"
    :is-open="isGatekeeperModalOpen"
    @update:isOpen="closeModal"
  />
</template>

<script lang="ts" setup>
import ActionCard from "@/vue/molecules/action-card.vue";
import type {GatekeeperItem} from "@/ts/types/dto/program.dto";
import coachingIcon from "@/assets/img/icons/coaching-icon.svg";
import hraIcon from "@/assets/img/icons/hra-icon.svg";
import bioScreeningIcon from "@/assets/img/icons/bio-screen-icon.svg";
import SmokingIcon from "@/assets/img/icons/smoking.svg";
import gatekeeperIcon from "@/assets/img/icons/gatekeeper.svg";
import {ContentReferenceType} from "@/ts/types/dto/content-reference.dto";
import {computed, inject, ref} from "vue";
import type {Router} from "vue-router";
import type {PagesPath} from "@/ts/router/pages-path";
import GatekeeperModal from "@/vue/molecules/gatekeeper-modal.vue";

const props = defineProps<{
  gatekeeperItems: GatekeeperItem[];
}>();

const $router = inject("$router")! as Router;
const $pagesPath = inject<PagesPath>("$pagesPath")!;

const isGatekeeperModalOpen = ref(false);
const selectedGatekeeperItem = ref<GatekeeperItem | null>(null);

const actions = computed(() => {
  return props.gatekeeperItems
    .filter((item) => !item.isRequired)
    .map((item) => {
      let icon = "";
      let handleClick;
      let isHealthContent = true;

      switch (item.contentReference.type) {
        case ContentReferenceType.BiometricScreening:
          icon = bioScreeningIcon;
          handleClick = () => {
            void $router.push($pagesPath.myHealthPath.myhealth);
          };
          break;
        case ContentReferenceType.HealthRiskAssessment:
          icon = hraIcon;
          handleClick = () => {
            void $router.push($pagesPath.myHealthPath.myhealth);
          };
          break;
        case ContentReferenceType.Coaching:
          icon = coachingIcon;
          handleClick = () => {
            void $router.push($pagesPath.coaching.info);
          };
          break;
        case ContentReferenceType.NicotineAttestation:
          icon = SmokingIcon;
          handleClick = () => {
            void $router.push($pagesPath.nicotineAttestation.start);
          };
          break;
        default:
          isHealthContent = false;
          icon = gatekeeperIcon;
          handleClick = (item: GatekeeperItem) => {
            selectedGatekeeperItem.value = item;
            isGatekeeperModalOpen.value = true;
          };
          break;
      }

      return {
        name: item.name,
        icon,
        endDate: item.endDate,
        handleClick,
        isHealthContent,
        gatekeeperItem: item,
      };
    });
});

const closeModal = (): void => {
  isGatekeeperModalOpen.value = false;
};
</script>

<style lang="sass" scoped>
</style>
