<template>
  <alert-icon-card
    v-if="showDeadlineReached"
    theme="info"
  >
    <div class="text">
      The time for delivering the biometrics screening is over since the <span class="bold">{{ bioscreeningEndDate }}</span>.
      You can not use the screening anymore. Please reach out to your contact person for more information.
    </div>
  </alert-icon-card>
</template>

<script lang="ts">
import {Component, Vue, Prop} from "vue-property-decorator";
import AlertIconCard from "@/vue/atoms/alert-icon-card.vue";
import {formatDateShortMonthName} from "@/ts/utils/date-pure-functions";
import {hasProgramBioscreeningEndDateInPast} from "@/ts/utils/my-health/program-bioscreening";
import type {MyProgramResponse} from "@/ts/types/dto/program.dto";

@Component({
  name: "DeadlineReachedCard",
  components: {AlertIconCard}
})
export default class DeadlineReachedCard extends Vue {
  @Prop()
  program!: MyProgramResponse;

  get hasProgramBioscreeningEndDateInPast(): boolean {
    return hasProgramBioscreeningEndDateInPast(this.program);
  }

  get showDeadlineReached(): boolean {
    return this.hasProgramBioscreeningEndDateInPast;
  }

  get bioscreeningEndDate(): string {
    return formatDateShortMonthName(this.program.biometricScreeningEndDate!);
  }
}
</script>

<style lang="sass" scoped>
.bold
  @include Roboto700

.text
  @include Roboto400
  color: $color-primary-hc-navy-100
  font-size: 14px
  letter-spacing: 0.2px
  line-height: 22px
  margin: 0 0 4px
</style>
